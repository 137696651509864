import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";


const FaqCreatingAProfessionalLookingProfile = () => {
 
  const [isLoading, setIsLoading] = useState<boolean>(false);
  


  return (
    <>
      <main>
        <section className="page-title">
          <div className="container">
            <div className="row">
              <div className="col-md-12 text-center">
                <div className="heading">
                  <h2>Frequently Asked Questions</h2>
                  <ul className="breadcrumbs">
                    <li>
                      <Link to={`/faq`}>FAQs</Link>
                    </li>
                    <li>CREATING A PROFESSIONAL LOOKING PROFILE</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="single-faq pt-5 pb-5">
          <img
            src="assets/images/left-shape.png"
            alt="help-1"
            className="img-fluid-left after-img"
          />
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-8 text-left mb-4">
                <div className="heading">
                  <h2 className="text-uppercase">CREATING A PROFESSIONAL LOOKING PROFILE</h2>
                  <span className="left-divider"></span>
                </div>
              </div>
              <div className="col-lg-8">
                {/* <div className="text-center">
                  <img src="assets/faq/faq-logo.png"  />  
                </div>
              <p style={{"marginTop":"0in","marginRight":"0in","marginBottom":"8pt","marginLeft":"0in","fontSize":"11pt","fontFamily":"'Calibri', sansSerif","textAlign":"center"}}>&nbsp;</p> */}
              {/* <p style={{"marginTop":"0in","marginRight":"0in","marginBottom":"8pt","marginLeft":"0in","textAlign":"center"}}>
                  <strong><span style={{"fontSize":"27px","lineHeight":"107%"}}>Creating a professionalLooking profile</span></strong>
              </p> */}
              
              <p>
                  Welcome to MortgageBox.co.uk, where we give customers more options, better deals, and a higher acceptance rate by putting them in contact with 5 FCA registered mortgage advisers.&nbsp;
              </p>
              <p>
                In the next few pages, we walk you through the steps to setting up a professionally looking profile.
              </p>
              
              <strong style={{"fontSize":"18px","lineHeight":"107%"}}><span>2. Creating a profile</span></strong>
              
              <ul className="decimal_type" style={{"listStyleType":"undefined","marginTop":"0.1in","paddingLeft":"0","float":"left","width":"100%"}}>
                  <li>
                    <strong><span style={{"fontSize":"14px"}}>2.1 Pre-requisite</span></strong>
                  </li>
              </ul>
              <p>Registration is first required, after which you can create your profile. &nbsp;&nbsp;</p>
              
              <ul className="decimal_type" style={{"listStyleType":"undefined","marginTop":"0.15in","paddingLeft":"0","float":"left","width":"100%"}}>
                  <li>
                    <strong><span style={{"fontSize":"14px"}}>2.2 Navigate to the My Account page</span></strong>
                  </li>
              </ul>
              <p>
                  <span>From mortgagebox.co.uk either click&nbsp;</span><a href="https://mortgagebox.co.uk/adviser/my-account"><span>My Account</span></a><span>&nbsp;or directly enter&nbsp;</span><a href="https://mortgagebox.co.uk/adviser/my-account">https://mortgagebox.co.uk/adviser/myAccount</a>
              </p>
              
              <p>
                <span style={{"float":"left","width":"100%","textAlign":"center"}}>
                  <img src="assets/faq/2.1.png" style={{"border":"2px solid #ddd"}} />
                </span>
              </p>

              <ul className="decimal_type" style={{"listStyleType":"undefined","marginTop":"0.15in","paddingLeft":"0","float":"left","width":"100%"}}>
                  <li>
                    <strong><span style={{"fontSize":"14px"}}>2.3 Let&rsquo;s set up Account details</span></strong>
                  </li>
              </ul>              
              <p><span>There are two sections</span></p>
              
              <ul>
                  <li>
                      <strong><span>i. Company details</span></strong>
                  </li>
              </ul>
              <p style={{"marginTop":"0in","marginRight":"0in","marginBottom":"8pt","marginLeft":"0.5in"}}>
                  <span style={{"color":"#002060"}}>This section is optional and is used when you belong to a larger organisation.</span>
              </p>
              <p>
                <span style={{"float":"left","width":"100%","textAlign":"center"}}>
                  <img src="assets/faq/2.2.png" style={{"border":"2px solid #ddd"}} />
                </span>
              </p>
              <ul style={{"float":"left","width":"100%","marginTop":"15px"}}>
                  <li>
                      <strong><span>ii. Adviser profile</span></strong>
                  </li>
              </ul>
              <p style={{"marginTop":"0in","marginRight":"0in","marginBottom":"8pt","marginLeft":"0.5in"}}>
                  <span >This is the section where you start to create your unique profile. We recommend adding</span>
                  <ul style={{"listStyleType":"disc","marginLeft":"0in"}}>
                      <li style={{"display":"list-item","listStyle":"disc"}}><span >Profile picture &ndash; either of you or your company logo.</span></li>
                      <li style={{"display":"list-item","listStyle":"disc"}}><span >Contact number.</span></li>
                      <li style={{"display":"list-item","listStyle":"disc"}}><span >City / Town.</span></li>
                      <li style={{"display":"list-item","listStyle":"disc"}}><span >Gender.</span></li>
                      <li style={{"display":"list-item","listStyle":"disc"}}><span >Additional Language (optional).</span></li>
                  </ul>
                  <em><span >When you are happy click &lsquo;save&rsquo;</span></em>
              </p>
              <p>
                <span style={{"float":"left","width":"100%","textAlign":"center"}}>
                  <img src="assets/faq/2.3.png" style={{"border":"2px solid #ddd"}} />
                </span>
              </p>
              <ul className="decimal_type" style={{"listStyleType":"undefined","marginTop":"0.15in","paddingLeft":"0","float":"left","width":"100%"}}>
                  <li>
                    <strong><span style={{"fontSize":"14px"}}>2.4 Tell your customers about yourself</span></strong>
                  </li>
              </ul>
              <p>
                  <span>In the &lsquo;About&rsquo; section you can add colour to who you are and what you&rsquo;re about. We have provided example for both sections.</span>
                  <ul style={{"listStyleType":"disc","marginLeft":"0in"}}>
                      <li style={{"display":"list-item","listStyle":"disc"}}><span >In a Nutshell &ndash; Explain, in a sentence, why customer should choose you.</span></li>
                      <li style={{"display":"list-item","listStyle":"disc"}}><span >About the company &ndash; This is where you detail the services you provide</span></li>
                  </ul>
                  <em><span >When you are happy click &lsquo;save&rsquo;</span></em>
              </p>
              <p>
                <span style={{"float":"left","width":"100%","textAlign":"center"}}>
                  <img src="assets/faq/2.4.png" style={{"border":"2px solid #ddd"}} />
                </span>
              </p>
              <ul className="decimal_type" style={{"listStyleType":"undefined","marginTop":"0.15in","paddingLeft":"0","float":"left","width":"100%"}}>
                  <li>
                    <strong><span style={{"fontSize":"14px"}}>2.5 Detail your offers</span></strong>
                  </li>
              </ul>
              <p>
                  <span >
                      Listing and explaining your offers is a good way to catch the eye of potential customers. In the screen shot below we have provided some examples to help you get going. You can add as many or as few as you want.
                  </span>
              </p>
              <p>
                <span style={{"float":"left","width":"100%","textAlign":"center"}}>
                  <img src="assets/faq/2.5.png" style={{"border":"2px solid #ddd"}} />
                </span>
              </p>
              <ul className="decimal_type" style={{"listStyleType":"undefined","marginTop":"0.15in","paddingLeft":"0","float":"left","width":"100%"}}>
                  <li>
                    <strong><span style={{"fontSize":"14px"}}>2.6 Bring onboard your reviews</span></strong>
                  </li>
              </ul>
              <p>
                  <span>Reviews help you win new business. They don&apos;t have to just come from customers you found through mortgagebox.co.uk, they can come from your existing customers.</span>
              </p>
              <p>
                  <span>You can contact your customers through Facebook, Twitter, LinkedIn, WhatsApp or simply copy your unique link and email it.&nbsp;</span>
              </p>
              <p>
                <span style={{"float":"left","width":"100%","textAlign":"center"}}>
                  <img src="assets/faq/2.6.png" style={{"border":"2px solid #ddd"}} />
                </span>
              </p>
              <ul className="decimal_type" style={{"listStyleType":"undefined","marginTop":"0.15in","paddingLeft":"0","float":"left","width":"100%"}}>
                  <li>
                    <strong><span style={{"fontSize":"14px"}}>2.7 Let customers see your online presence</span></strong>
                  </li>
              </ul>
              <p>
                  <span >Provide your website and social media links for customers to browse and learn more about your business.</span>
              </p>
              <p>
                <span style={{"float":"left","width":"100%","textAlign":"center"}}>
                  <img src="assets/faq/2.7.png" style={{"border":"2px solid #ddd"}} />
                </span>
              </p>
              <ul className="decimal_type" style={{"listStyleType":"undefined","marginTop":"0.15in","paddingLeft":"0","float":"left","width":"100%"}}>
                  <li>
                    <strong><span style={{"fontSize":"14px"}}>2.8 Public profile</span></strong>
                  </li>
              </ul>
              <p>
                  <span >All of the information provided will be brought together to create your profile page as demonstrated.</span>
              </p>
              <p>
                <span style={{"float":"left","width":"100%","textAlign":"center"}}>
                  <img src="assets/faq/2.8.png" style={{"border":"2px solid #ddd"}} />
                </span>
              </p>
              </div>
            </div>
          </div>
          <img
            src="assets/images/right-shape.png"
            alt="help-1"
            className="img-fluid before-img"
          />
        </section>
      </main>
    </>
  );
};

export default FaqCreatingAProfessionalLookingProfile;
