import React, { useContext, useState, useEffect } from "react";
import { useToasts } from "react-toast-notifications";
import NumberFormat from "react-number-format";
import { LANGUAGES_ARR } from "../../Constants";
import { AuthContext } from "../../shared/context/auth-context";
import {
  numberWithCommas,
  numberWithOutCommas,
} from "../../utility/commonUtils";
import ReactTooltip from "react-tooltip";
import { AsyncTypeahead } from "react-bootstrap-typeahead";
import { searchPostalCode } from "../common/service";

const StepThree = (props: any) => {
  const { isLoggedIn } = useContext(AuthContext);
  const { addToast } = useToasts();
  const [innerSteps, setInnerSteps] = useState<number>(1);
  const [additionaloptions, setAdditionaloptions] = useState(false);

  const [isSeachLoading, setIsSeachLoading] = useState<boolean>(false);
  const [options, setOptions] = useState<any>([]);
  const [selectedOption, setselectedOption] = useState<any>([]);

  useEffect(() => {
    props.setFormData('contact_preference_face_to_face', 1);
    props.setFormData('contact_preference_online', 1);
    props.setFormData('contact_preference_telephone', 1);
    props.setFormData('contact_preference_evening_weekend', 1);
  }, []);

  const handleChangeSearch = (event: any) => {
    if (event.length) {
      setselectedOption([event[0]]);
      props.setFormData("post_code", event[0].Postcode);
    } else {
      setselectedOption([]);
    }
  };

  const handleSearch = async (query: any) => {
    setIsSeachLoading(true);
    const res = await searchPostalCode({ postal_code: query });
    if (res.status) {
      setOptions(res.data);
    }
    setIsSeachLoading(false);
  };
  // Bypass client-side filtering by returning `true`. Results are already
  // filtered by the search endpoint, so no need to do it again.
  const filterBy = () => true;

  const handleInnerStep = (newStep: number) => {
    
    if (newStep === 2) {
      if (props.formState.combined_income === "") {
        addToast("Please fill required field.", { appearance: "error" });
        return;
      } else if (
        Number(numberWithOutCommas(props.formState.combined_income)) < 0 ||
        Number(numberWithOutCommas(props.formState.combined_income)) > 1000000
      ) {
        addToast(
          `We are unable to help you with a combined income of ${props.formState.combined_income_currency}${props.formState.combined_income}`,
          {
            appearance: "error",
          }
        );
        return;
      }
      if (
        Number(numberWithOutCommas(props.formState.combined_income)) != 0 &&
        (Number(
          Number(numberWithOutCommas(props.formState.size_want)) /
            Number(numberWithOutCommas(props.formState.combined_income))) >= 8
        ) 
      ) {
        addToast(
          `We are unable to help you as your loan to income exceeds limits`,
          { appearance: "error" }
        );
        return;
      } else {
        setInnerSteps(() => newStep);
      }
    } else if (newStep === 4 && isLoggedIn) {
      props.finalStep();
    } else if (newStep === 5) {
      if (
        props.formState.name === "" ||
        props.formState.email === "" ||
        props.formState.post_code === "" ||
        props.formState.password === "" ||
        props.formState.hear_about_us === ""
      ) {
        addToast("Please fill required field.", { appearance: "error" });
        return;
      }
      props.signupStep();
    } else {
      setInnerSteps(() => newStep);
    }
  };

  const handleChange = (event: { target: HTMLInputElement } | any) => {
    const { name, value } = event.target;
    if (["combined_income"].includes(name)) {
      const newValue = numberWithOutCommas(value.replace(/[^\d,]/g, ""));
      props.setFormData([name], newValue);
    } else {
      props.setFormData([name], value);
    }
    event.preventDefault();
  };

  const handleChangeCheckbox = (event: { target: HTMLInputElement } | any) => {
    props.setFormData([event.target.name], +event.target.checked);
  };

  return (
    <>
      <section className="recent-sec advice-area-sec advice-area-hs pt-2">
        <img
          src="assets/images/expert-right.png"
          alt="help-1"
          className="img-fluid after-img"
        />
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="panel panel-default">
                <div className="panel-body">
                  <div className="stepper">
                    <div className="active-title d-md-none text-center">
                      <p className="text-white mb-3 font-weight-bold">
                        About you
                      </p>
                    </div>
                    <ul className="nav nav-tabs mb-3 mb-md-0" role="tablist">
                      <li role="presentation" className="completed">
                        <a
                          className="persistant-disabled d-none d-md-flex"
                          href="javascript:void(0)"
                          data-toggle="tab"
                          aria-controls="stepper-step-1"
                          role="tab"
                          title="Step 1"
                        >
                          <span className="round-tab mr-3">1</span>
                          <p>How can we help?</p>
                        </a>
                      </li>
                      <li role="presentation" className="completed">
                        <a
                          className="persistant-disabled d-none d-md-flex"
                          href="javascript:void(0)"
                          data-toggle="tab"
                          aria-controls="stepper-step-2"
                          role="tab"
                          title="Step 2"
                        >
                          <span className="round-tab mr-3">2</span>
                          <p>Your needs</p>
                        </a>
                      </li>
                      <li role="presentation" className="active">
                        <a
                          className="persistant-disabled d-none d-md-flex"
                          href="javascript:void(0)"
                          data-toggle="tab"
                          aria-controls="stepper-step-3"
                          role="tab"
                          title="Step 3"
                        >
                          <span className="round-tab  mr-3">3</span>
                          <p>About you</p>
                        </a>
                      </li>
                    </ul>
                    <form>
                      <div className="tab-content tab-data-content text-center">
                        <div
                          className="tab-pane fade active show"
                          role="tabpanel"
                          id="stepper-step-3"
                        >
                          {innerSteps === 1 && (
                            <div id="step3-part" className="step3-part1 active">
                              <h2>
                                What is the combined income of all applicants?
                              </h2>
                              <div className="heading mt-2">
                                <span></span>
                              </div>
                              <div className="row">
                                <div className="col-lg-8 offset-lg-2 col-md-10 offset-md-1 currency_icon">
                                  <span>
                                    <select
                                      name="combined_income_currency"
                                      value={
                                        props.formState.combined_income_currency
                                      }
                                      onChange={handleChange}
                                    >
                                      <option value="£">£</option>
                                      <option value="$">$</option>
                                      <option value="€">€</option>
                                    </select>
                                  </span>
                                  {/* <input
                                    type="tel"
                                    min="0"
                                    name="combined_income"
                                    id="combined_income"
                                    placeholder="750,000"
                                    onFocus={(e) => (e.target.placeholder = "")}
                                    onBlur={(e) =>
                                      (e.target.placeholder = "750,000")
                                    }
                                    value={numberWithCommas(
                                      props.formState.combined_income
                                    )}
                                    onChange={handleChange}
                                  /> */}
                                  <NumberFormat
                                    thousandSeparator={true}
                                    name="combined_income"
                                    id="combined_income"
                                    placeholder="50,000"
                                    onFocus={(e) => (e.target.placeholder = "")}
                                    onBlur={(e) =>
                                      (e.target.placeholder = "50,000")
                                    }
                                    value={numberWithCommas(
                                      props.formState.combined_income
                                    )}
                                    onChange={handleChange}
                                  />
                                </div>
                                <div className="col-lg-12 text-right">
                                  <button
                                    type="button"
                                    className="btn-bg-blue text-uppercase float-left"
                                    onClick={() => props.preLastStep()}
                                  >
                                    <i
                                      className="fa fa-chevron-left"
                                      aria-hidden="true"
                                    ></i>{" "}
                                    Back
                                  </button>
                                  <button
                                    type="button"
                                    className="btn-bg-blue text-uppercase current-next-step"
                                    onClick={() => handleInnerStep(2)}
                                  >
                                    Next Step
                                    <i
                                      className="fa fa-chevron-right"
                                      aria-hidden="true"
                                    ></i>
                                  </button>
                                </div>
                              </div>
                            </div>
                          )}

                          {innerSteps === 2 && (
                            <div
                              id="step3-part2"
                              className="step3-part1 about-title-sub"
                            >
                              <h2>
                                Is there anything else you feel is important?
                              </h2>

                              <div className="heading">
                                <span></span>
                              </div>
                              <div className="row mt-md-5 mt-4">
                                <div className="col-lg-8 offset-lg-2">
                                  <textarea
                                    name="description"
                                    id="step3_data_placeholder"
                                    placeholder="Optimise your adviser matches by providing detailed, non-personal information. The more specific your details, the quicker and more relevant the connections you will receive.
                                    Consider sharing specifics such as assisting a child in buying a home, acquiring a second home, exploring rental opportunities, or downsizing."
                                    onFocus={(e) => (e.target.placeholder = "")}
                                    onBlur={(e) =>
                                      (e.target.placeholder =
                                        "Optimise your adviser matches by providing detailed, non-personal information. The more specific your details, the quicker and more relevant the connections you will receive.Consider sharing specifics such as assisting a child in buying a home, acquiring a second home, exploring rental opportunities, or downsizing.")
                                    }
                                    onChange={handleChange}
                                  >
                                    {props.formState.description}
                                  </textarea>
                                </div>
                              </div>
                              <div className="row mt-md-4 mt-2 align-items-center text-left">
                                <div className="col-lg-8 offset-lg-2">
                                  <div className="row">
                                    <div className="col-md-4">
                                      <div className="form-group checkboxes-data mb-0">
                                        <input
                                          type="checkbox"
                                          id="self_employed"
                                          name="self_employed"
                                          value="1"
                                          onChange={handleChangeCheckbox}
                                          checked={
                                            props.formState.self_employed === 1
                                          }
                                        />
                                        <label htmlFor="self_employed">
                                          Self Employed
                                        </label>
                                      </div>
                                    </div>
                                    <div className="col-md-4">
                                      <div className="form-group checkboxes-data mb-0">
                                        <input
                                          type="checkbox"
                                          id="non_uk_citizen"
                                          name="non_uk_citizen"
                                          value="1"
                                          onChange={handleChangeCheckbox}
                                          checked={
                                            props.formState.non_uk_citizen === 1
                                          }
                                        />
                                        <label htmlFor="non_uk_citizen">
                                          Non-UK Citizen
                                        </label>
                                      </div>
                                    </div>
                                    <div className="col-md-4">
                                      <div className="form-group checkboxes-data mb-0">
                                        <input
                                          type="checkbox"
                                          id="adverse_credit"
                                          name="adverse_credit"
                                          value="1"
                                          onChange={handleChangeCheckbox}
                                          checked={
                                            props.formState.adverse_credit === 1
                                          }
                                        />
                                        <label htmlFor="adverse_credit">
                                          Adverse Credit
                                        </label>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-lg-12 text-right">
                                  <button
                                    type="button"
                                    className="btn-bg-blue text-uppercase float-left"
                                    onClick={() => handleInnerStep(1)}
                                  >
                                    <i
                                      className="fa fa-chevron-left"
                                      aria-hidden="true"
                                    ></i>{" "}
                                    Back
                                  </button>
                                  <button
                                    type="button"
                                    className="btn-bg-blue text-uppercase current-next-step"
                                    onClick={() => handleInnerStep(3)}
                                  >
                                    Next Step
                                    <i
                                      className="fa fa-chevron-right"
                                      aria-hidden="true"
                                    ></i>
                                  </button>
                                </div>
                              </div>
                            </div>
                          )}

                          {innerSteps === 3 && (
                            <div id="step3-part3" className="step3-part1">
                              <h2>Any preferences?</h2>
                              <p>
                                Get better results by giving us more
                                information.
                              </p>
                              <div className="heading">
                                <span></span>
                              </div>
                              <div className="row mt-4 align-items-center">
                                <div className="col-lg-12">
                                  <div className="contact-preference text-left">
                                    <p className="heading-contact-preference">Contact preference</p>
                                    <div className="align-items-center checkboxes">
                                      <div className="col-md-3">
                                        <div className="form-group checkboxes-data mb-0">
                                          <input
                                            type="checkbox"
                                            id="face_to_face"
                                            name="contact_preference_face_to_face"
                                            value="1"
                                            onChange={handleChangeCheckbox}
                                            checked={
                                              props.formState
                                                .contact_preference_face_to_face ===
                                              1
                                            }
                                          />
                                          <label htmlFor="face_to_face">
                                            Face to Face
                                          </label>
                                        </div>
                                      </div>
                                      <div className="col-md-3">
                                        <div className="form-group checkboxes-data mb-0">
                                          <input
                                            type="checkbox"
                                            id="online"
                                            name="contact_preference_online"
                                            value="1"
                                            onChange={handleChangeCheckbox}
                                            checked={
                                              props.formState
                                                .contact_preference_online === 1
                                            }
                                          />
                                          <label htmlFor="online">Online</label>
                                        </div>
                                      </div>
                                      <div className="col-md-3">
                                        <div className="form-group checkboxes-data mb-0">
                                          <input
                                            type="checkbox"
                                            id="telephone"
                                            name="contact_preference_telephone"
                                            value="1"
                                            onChange={handleChangeCheckbox}
                                            checked={
                                              props.formState
                                                .contact_preference_telephone ===
                                              1
                                            }
                                          />
                                          <label htmlFor="telephone">
                                            Telephone
                                          </label>
                                        </div>
                                      </div>
                                      <div className="col-md-3">
                                        <div className="form-group checkboxes-data mb-0">
                                          <input
                                            type="checkbox"
                                            id="evening_weekend"
                                            name="contact_preference_evening_weekend"
                                            value="1"
                                            onChange={handleChangeCheckbox}
                                            checked={
                                              props.formState
                                                .contact_preference_evening_weekend ===
                                              1
                                            }
                                          />
                                          <label htmlFor="evening_weekend">
                                            Evening / Weekend
                                          </label>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-lg-12 mt-4 sub-data text-right">
                                  <p
                                    onClick={() =>
                                      setAdditionaloptions(
                                        (prevAdditionaloptions) =>
                                          !prevAdditionaloptions
                                      )
                                    }
                                  >
                                    {additionaloptions ? "Hide" : "Show"}{" "}
                                    additional options
                                  </p>

                                  {additionaloptions && (
                                    <div
                                      className="collapse1 text-left datas"
                                      id="show_data1"
                                    >
                                      <div className="contact-preference text-left">
                                        <p>Adviser</p>
                                        <div className="row align-items-center checkboxes">
                                          <div className="col-md-4 mb-3 mb-md-0">
                                            <div className="form-group checkboxes-data mb-0">
                                              <input
                                                type="checkbox"
                                                id="local"
                                                name="advisor_preference_local"
                                                value="1"
                                                onChange={handleChangeCheckbox}
                                                checked={
                                                  props.formState
                                                    .advisor_preference_local ===
                                                  1
                                                }
                                              />
                                              <label htmlFor="local">
                                                Local
                                              
                                                <a
                                                href="#"
                                                onClick={(e) => e.preventDefault()}
                                                data-tip data-for="registerTip"
                                                // data-tip="Prioritises local advisers based on your postcode"
                                                >
                                                  <img
                                                    src="assets/images/information.png"
                                                    alt="information"
                                                    className="img-fluid information"
                                                  />
                                                </a>
                                              </label>

                                              <ReactTooltip id="registerTip" place="top" effect="solid">
                                                Prioritises local advisers based on your postcode
                                              </ReactTooltip>
                                            </div>
                                          </div>
                                          <div className="col-md-4">
                                            <div className="form-group mb-0">
                                              <label
                                                htmlFor="gender"
                                                className="mr-2 pl-0"
                                              >
                                                Gender
                                              </label>
                                              <select
                                                name="advisor_preference_gender"
                                                onChange={handleChange}
                                                value={
                                                  props.formState
                                                    .advisor_preference_gender
                                                }
                                              >
                                                <option value="0">Any</option>
                                                <option value="1">Male</option>
                                                <option value="2">
                                                  Female
                                                </option>
                                              </select>
                                            </div>
                                          </div>
                                          <div className="col-md-4">
                                            <div className="form-group mb-0">
                                              <div className="form-group mb-0">
                                                <label
                                                  htmlFor="language"
                                                  className="mr-2 pl-0"
                                                >
                                                  Language
                                                </label>
                                                <select
                                                  name="advisor_preference_language"
                                                  onChange={handleChange}
                                                  value={
                                                    props.formState
                                                      .advisor_preference_language
                                                  }
                                                >
                                                  {LANGUAGES_ARR.map(
                                                    (lang: {
                                                      value: string;
                                                      name: string;
                                                    }) => {
                                                      return (
                                                        <option
                                                          key={lang.value}
                                                          value={lang.value}
                                                        >
                                                          {lang.name}
                                                        </option>
                                                      );
                                                    }
                                                  )}
                                                  {/* <option value="English">
                                                    English
                                                  </option>
                                                  <option value="French">
                                                    French
                                                  </option>
                                                  <option value="German">
                                                    German
                                                  </option>
                                                  <option value="Hindi">
                                                    Hindi
                                                  </option>
                                                  <option value="Italian">
                                                    Italian
                                                  </option>
                                                  <option value="Polish">
                                                    Polish
                                                  </option>
                                                  <option value="Punjabi">
                                                    Punjabi
                                                  </option>
                                                  <option value="Spanish">
                                                    Spanish
                                                  </option>
                                                  <option value="Urdu">
                                                    Urdu
                                                  </option> */}
                                                </select>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="contact-preference mt-4 text-left">
                                        <p>Fee</p>
                                        <div className="row align-items-center checkboxes">
                                          <div className="col-md-12">
                                            <div className="form-group checkboxes-data mb-0">
                                              <input
                                                type="checkbox"
                                                id="doyou_adviser"
                                                name="fees_preference"
                                                value="1"
                                                onChange={handleChangeCheckbox}
                                                checked={
                                                  props.formState
                                                    .fees_preference === 1
                                                }
                                              />
                                              <label htmlFor="doyou_adviser">
                                                Do you want to consider an
                                                adviser that charges fees ?
                                                <a
                                                  href="#"
                                                  onClick={(e) => e.preventDefault()}
                                                  data-tip data-for="feeTip"
                                                  // data-tip="Only connect to advisers that do not charge fees. This is good for most mortgages. However, for some which are more complicated than average then you may need to consider paying a fee"
                                                >
                                                  <img
                                                    src="assets/images/information.png"
                                                    alt="information"
                                                    className="img-fluid information"
                                                  />
                                                </a>
                                              </label>
                                              <ReactTooltip id="feeTip" place="top" effect="solid">
                                                Only connect to advisers that do not charge fees. This is good for most mortgages. However, for some which are more complicated than average then you may need to consider paying a fee
                                              </ReactTooltip>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  )}
                                </div>
                                <div className="col-lg-12 text-right">
                                  <button
                                    type="button"
                                    className="btn-bg-blue text-uppercase float-left"
                                    onClick={() => handleInnerStep(2)}
                                  >
                                    <i
                                      className="fa fa-chevron-left"
                                      aria-hidden="true"
                                    ></i>{" "}
                                    Back
                                  </button>
                                  <button
                                    type="button"
                                    className="btn-bg-blue text-uppercase current-next-step"
                                    onClick={() => handleInnerStep(4)}
                                  >
                                    {isLoggedIn ? "Finish" : "Next Step"}
                                    <i
                                      className="fa fa-chevron-right"
                                      aria-hidden="true"
                                    ></i>
                                  </button>
                                </div>
                              </div>
                            </div>
                          )}

                          {innerSteps === 4 && (
                            <div id="step3-part4" className="step3-part1">
                              <h2>Nearly there</h2>
                              {/* <p>
                                If you already have account please
                                <a
                                  href="javsscript:void(0)"
                                  data-toggle="modal"
                                  data-target="#login_modal"
                                >
                                  Login
                                </a>
                              </p> */}
                              <div className="heading">
                                <span></span>
                              </div>
                              <div className="row mt-4 align-items-center">
                                <div className="col-lg-10 offset-lg-1">
                                  <div className="contact-form text-left">
                                    <div className="adviser-form mt-3">
                                      <div className="form-row">
                                        <div className="form-group col-lg-5 offset-lg-1">
                                          <label
                                            htmlFor="cust_name"
                                            className="p-0 mb-2"
                                          >
                                            Your name
                                          </label>
                                          <input
                                            type="text"
                                            id="cust_name"
                                            placeholder="Joe Bloggs"
                                            onFocus={(e) =>
                                              (e.target.placeholder = "")
                                            }
                                            onBlur={(e) =>
                                              (e.target.placeholder =
                                                "Joe Bloggs")
                                            }
                                            name="name"
                                            onChange={handleChange}
                                            value={props.formState.name}
                                            required
                                          />
                                        </div>
                                        <div className="form-group col-lg-5 offset-lg-1">
                                          <label
                                            htmlFor="cust_email"
                                            className="p-0 mb-2"
                                          >
                                            Email address
                                          </label>
                                          <input
                                            type="email"
                                            id="cust_email"
                                            placeholder="joi@bloogs.com"
                                            onFocus={(e) =>
                                              (e.target.placeholder = "")
                                            }
                                            onBlur={(e) =>
                                              (e.target.placeholder =
                                                "joi@bloogs.com")
                                            }
                                            name="email"
                                            onChange={handleChange}
                                            value={props.formState.email}
                                          />
                                        </div>
                                        <div className="form-group col-lg-5 offset-lg-1">
                                          <label
                                            htmlFor="cust_post_code"
                                            className="p-0 mb-2"
                                          >
                                            Postcode
                                          </label>
                                          {/* <input
                                            type="text"
                                            id="cust_post_code"
                                            placeholder="TW2 6DD"
                                            onFocus={(e) =>
                                              (e.target.placeholder = "")
                                            }
                                            onBlur={(e) =>
                                              (e.target.placeholder = "TW2 6DD")
                                            }
                                            name="post_code"
                                            onChange={handleChange}
                                            required
                                            value={props.formState.post_code}
                                          /> */}
                                          <AsyncTypeahead
                                            filterBy={filterBy}
                                            id="async-example"
                                            isLoading={isSeachLoading}
                                            labelKey="Postcode"
                                            minLength={2}
                                            onSearch={handleSearch}
                                            options={options}
                                            onChange={handleChangeSearch}
                                            selected={selectedOption}
                                            placeholder="TW2 6DD"
                                            className="postCodeSearchInnerAccount"
                                            renderMenuItemChildren={(
                                              option: any,
                                              props: any
                                            ) => (
                                              <React.Fragment>
                                                <span>{option?.Postcode}</span>
                                              </React.Fragment>
                                            )}
                                          />
                                        </div>
                                        <div className="form-group col-lg-5 offset-lg-1">
                                          <label
                                            htmlFor="cust_password"
                                            className="p-0 mb-2"
                                          >
                                            Password
                                          </label>
                                          <input
                                            type="password"
                                            id="cust_password"
                                            placeholder="Dog@1234"
                                            onFocus={(e) =>
                                              (e.target.placeholder = "")
                                            }
                                            onBlur={(e) =>
                                              (e.target.placeholder =
                                                "Dog@1234")
                                            }
                                            name="password"
                                            onChange={handleChange}
                                            value={props.formState.password}
                                          />
                                        </div>
                                        <div className="form-group col-lg-5 offset-lg-1">
                                          <label
                                            htmlFor="hear_about_us"
                                            className="p-0 mb-2"
                                          >
                                            How did you hear about us? <span className="required">*</span>
                                          </label>
                                          <select
                                            id="hear_about_us"
                                            name="hear_about_us"
                                            className="selectHearAbout"
                                            value={props.formState.hear_about_us}
                                            onChange={handleChange}
                                            required
                                          >
                                            <option value="">Select How did you hear about us?</option>
                                            <option value="Radio">Radio</option>
                                            <option value="Friend">Friend</option>
                                            <option value="Facebook">Facebook</option>
                                            <option value="Google">Google</option>
                                            <option value="LinkedIn">LinkedIn</option>
                                            <option value="Reddit">Reddit</option>
                                            <option value="Mumsnet">Mumsnet</option>
                                            <option value="Instagram">Instagram</option>
                                            <option value="Other">Other</option>
                                          </select>
                                          
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                
                                
                                
                                <div className="col-lg-12 text-right">
                                <button
                                    type="button"
                                    className="btn-bg-blue text-uppercase float-left"
                                    onClick={() => handleInnerStep(3)}
                                  >
                                    <i
                                      className="fa fa-chevron-left"
                                      aria-hidden="true"
                                    ></i>{" "}
                                    Back
                                  </button>
                                  <button
                                    type="button"
                                    className="btn-bg-blue text-uppercase finish-ste"
                                    onClick={() => handleInnerStep(5)}
                                  >
                                    FINISH
                                  </button>
                                </div>

                                <div className="col-lg-12 text-center small-para">
                                  <p>
                                    By submitting you agree to mortgagebox's
                                    <a href="/terms-conditions" target="_blank">
                                    &nbsp;Terms</a>&nbsp;and&nbsp;
                                    <a href="/privacy-policy" target="_blank">
                                    Privacy Policy
                                    </a>
                                  </p>
                                </div>

                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <ReactTooltip/>
        <img
          src="assets/images/expert-left.png"
          alt="help-1"
          className="img-fluid before-img"
          />
      </section>
    </>
  );
};

export default StepThree;
