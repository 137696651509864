import React, { useEffect, useState } from "react";
import { addAdvisorTeam, updateAdvisorTeam } from "../../service";
import $ from "jquery";
import "bootstrap";
import InlineAlert from "../../InlineAlert";
import useLoggedInUserData from "../../../../utility/useLoggedInUserData";

const initialState = {
  name: "",
  email: "",
  company_id: 0,
  team_id: 0,
  isError: false,
  responseMessage: "",
};

const AddTeamMemberModal = (props: any) => {
  const user = useLoggedInUserData();
  const [formState, setFormState] = useState({
    ...initialState,
    company_id: user?.userDetails?.company_id || 0,
  });

  useEffect(() => {
    if (props.editItem.name) {
      handlePageState({
        team_id: props.editItem.team_id,
        name: props.editItem.name,
        email: props.editItem.email,
        company_id: props.editItem.company_id,
        isError: false,
        responseMessage: "",
      });
    }
  }, [props.editItem]);

  const handlePageState = (newStates: any) => {
    setFormState((prevStates) => {
      return {
        ...prevStates,
        ...newStates,
      };
    });
  };

  const handleChange = (event: { target: HTMLInputElement } | any) => {
    const { name, value } = event.target;
    handlePageState({ [name]: value });
    event.preventDefault();
  };

  const onSubmitHandler = async (e: any) => {
    e.preventDefault();
    if (formState.company_id === 0) {
      handlePageState({
        responseMessage: "Please add company first",
        isError: true,
      });
      return;
    }
    let res;
    if (formState.team_id > 0) {
      res = await updateAdvisorTeam({
        name: formState.name,
        email: formState.email,
        team_id: formState.team_id,
      });
    } else {
      res = await addAdvisorTeam({
        name: formState.name,
        email: formState.email,
        company_id: formState.company_id,
      });
    }
    if (res.status) {
      if (props.editItem.team_id) {
        props.updateItem({
          team_id: formState.team_id,
          name: formState.name,
          email: formState.email,
        });
      }
      handlePageState({ responseMessage: res.message, isError: false });
      setTimeout(() => {
        $("#add_team_member").modal("hide");
        $("body").removeClass("modal-open");
        $(".modal-backdrop").remove();
        handlePageState({
          ...initialState,
          company_id: user?.userDetails?.company_id,
        });
        props.refetch();
      }, 1000);
    } else {
      handlePageState({ responseMessage: res.message, isError: true });
    }
  };

  return (
    <>
      <div
        className="mail-popup modal fade popup-model add_offeres"
        id="add_team_member"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-body">
              <div className="row">
                <div className="col-md-12">
                  <div className="heading text-center mb-3">
                    <h4 className="pb-lg-3 pb-2">Add Team Member</h4>
                  </div>
                </div>
              </div>
              <form onSubmit={onSubmitHandler}>
                {formState.responseMessage && (
                  <InlineAlert
                    isError={formState.isError}
                    message={formState.responseMessage}
                  />
                )}
                <div className="row">
                  <div className="col-12 mb-2">
                    <label>Name</label>
                    <input
                      type="text"
                      id="member_name"
                      name="name"
                      value={formState.name}
                      onChange={(e) =>
                        handlePageState({
                          name: e.target.value,
                        })
                      }
                      placeholder="Member Name"
                      onFocus={(e) => (e.target.placeholder = "")}
                      onBlur={(e) => (e.target.placeholder = "Member Name")}
                      required
                    />
                  </div>
                  <div className="col-12 mb-2">
                    <div className="description">
                      <label>Email</label>
                      <input
                        type="email"
                        id="member_email"
                        name="email"
                        value={formState.email}
                        onChange={(e) =>
                          handlePageState({
                            email: e.target.value,
                          })
                        }
                        placeholder="Member Email Address"
                        onFocus={(e) => (e.target.placeholder = "")}
                        onBlur={(e) =>
                          (e.target.placeholder = "Member Email Address")
                        }
                        required
                      />
                    </div>
                  </div>
                  <div className="col-md-12 text-center mt-lg-3 mt-0 settings-data">
                    <button
                      type="submit"
                      className="theme-btn text-uppercase mr-md-3 mr-1 mb-md-0 mb-3"
                    >
                      invite
                    </button>
                    <button
                      type="button"
                      className="theme-btn-border text-uppercase"
                      data-dismiss="modal"
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddTeamMemberModal;
