import React, { useEffect, useRef, useState } from "react";
import { ContentPages } from "../components/common/service";
import useLoggedInUserData from "../utility/useLoggedInUserData";


const Services = () => {
  const [services, setServices] = useState<any>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const user = useLoggedInUserData();
  useEffect(() => {
    const pathname = window.location.pathname; 
    if(user!=''){
      if(user.user_role==1){
        ContentPagesCall('adviser');
      }else{
        ContentPagesCall('customer');
      }
    }else{
      if(pathname=='adviser'){
        ContentPagesCall('adviser');
      }else{
        ContentPagesCall('customer');
      }
    }
  }, []);

  const ContentPagesCall = async (type:string) => {
    setIsLoading(true);
    const post:any = { page : 'services',type : type};
    const res = await ContentPages(post);
    if (res?.success || res.status) {
      setServices(res.data);
    }
    setIsLoading(false);
  };
  return (
    <>
      <section className="page-title">
        <div className="container">
          <div className="row">
            <div className="col-md-12 text-center">
              <div className="heading">
                <h2>{services.page_name}</h2> 
                {/* <p>In a mission to find a perfect mortgage advisor for you</p> */}
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="expert-advise-section pt-5">
        <img
          src="assets/images/expert-right.png"
          alt="help-1"
          className="img-fluid after-img"
        />
        <div className="container">
          <div className="row about-sec">
            <div className="col-lg-7 text-left">
              <div className="heading">
                <div className="left-content">
                  <p dangerouslySetInnerHTML={{__html: services.page_content}}></p>
                  {/* <p>
                    Traditional comparison and mortgage web sites are typically
                    linked to a single mortgage company that is more of a call
                    centre, designed for volume and run of the mill mortgages.
                    This can lead to issues like-
                  </p> */}
                  {/* <ul className="ml-0">
                    <li>How do I know I have the best deal?</li>
                    <li>
                      My mortgage adviser does not respond in a timely manner
                    </li>
                    <li>
                      My mortgage adviser does not seem to understand my needs
                    </li>
                    <li>
                      After 4 weeks, my mortgage adviser now says he can't help
                      as my need is too complex
                    </li>
                  </ul> */}
                </div>
              </div>
            </div>
            {/* <div className="col-lg-5 text-center mb-4 mb-lg-0">
              <div className="row">
                <div className="col-6">
                  <img
                    src="assets/images/about1.png"
                    alt="about-1"
                    className="img-fluid w-100"
                  />
                </div>
                <div className="col-6">
                  <img
                    src="assets/images/about2.png"
                    alt="about-2"
                    className="img-fluid w-100"
                  />
                </div>
                <div className="col-12 about-down">
                  <img
                    src="assets/images/about3.png"
                    alt="about-3"
                    className="img-fluid w-100"
                  />
                </div>
              </div>
            </div> */}
          </div>
          {/* <div className="row pt-5 pb-5">
            <div className="col-lg-5 text-center mb-4 mb-lg-0">
              <img
                src="assets/images/about4.png"
                alt="about-4"
                className="img-fluid w-100"
              />
            </div>
            <div className="col-lg-7 text-left">
              <div className="heading">
                <h2 className="text-uppercase">
                  OUR APPROACH TOWARDS FINDING YOUR PERFECT MORTGAGE ADVISER
                </h2>
                <span></span>
                <div className="left-content">
                  <p>
                    Mortgage box takes an entirely different approach. We
                    believe that the first step to getting the best mortgage
                    deal is finding the right adviser In under 60 seconds we
                    match you to five mortgage advisers who will compete for
                    your business Talk, message, meet them and compare their
                    advice, offers and incentives to ensure you find your
                    perfect adviser.
                  </p>
                  <p>No fuss, no hassle, just awesome mortgage advisers</p>
                </div>
              </div>
            </div>
          </div> */}
        </div>
        <img
          src="assets/images/expert-left.png"
          alt="help-1"
          className="img-fluid before-img"
        />
      </section>
    </>
  );
};
export default Services;
