import React, { useEffect, useState } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import {
  closeAdviceNeed,
  getAdviseAreaBid,
} from "../components/common/service";
import InlineAlert from "../components/common/InlineAlert";
import { capitalize } from "../utility/commonUtils";

const initialState = {
  id: 0,
  close_type: 0,
  advisor_id: 0,
  need_reminder: -1,
  initial_term: "2_years",
  initial_term_number: 0,
  start_date: "",
  isError: false,
  responseMessage: "",
};

const CloseAdviceAreaNeed = (props: any) => {
  const history = useHistory();
  const urlParams: { id: any } = useParams();
  const { id } = urlParams;
  const [isStep, setIsStep] = useState<number>(1);
  const [formState, setFormState] = useState({
    ...initialState,
    id: id,
  });
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [acceptedBids, setAcceptedBids] = useState<object[]>([]);
  useEffect(() => {
    if (id) {
      getAdviseAreaBidCall(id, 1);
    }
  }, []);

  const getAdviseAreaBidCall = async (id: number, status: number) => {
    setIsLoading(true);
    const res = await getAdviseAreaBid(id, status);
    if (res?.success || res.status) {
      setAcceptedBids(res.data.filter((bid: any) => bid.advisor_status === 1));
    }
    setIsLoading(false);
  };

  const handlePageState = (newStates: any) => {
    setFormState((prevStates) => {
      return {
        ...prevStates,
        ...newStates,
      };
    });
  };

  const handleChange = (event: { target: HTMLInputElement } | any) => {
    let { name, value } = event.target;
    if (name === "close_type") {
      value = +value;
      if (value > 0) {
        handlePageState({
          advisor_id: value,
          close_type: 0,
          responseMessage: "",
          isError: false,
        });
      } else {
        handlePageState({
          advisor_id: 0,
          close_type: value === -1 ? 1 : 2,
          responseMessage: "",
          isError: false,
        });
      }
      return false;
    } else if (name === "need_reminder") {
      value = +value;
    }
    if(name === "initial_term"){
      if(value=='other'){
          $(".inputInitial").removeClass('hide');
      }else{
        $(".inputInitial").addClass('hide');
      }
    }
    handlePageState({ [name]: value, responseMessage: "", isError: false });
  };

  const handleNextStep = () => {
    if (formState.advisor_id > 0 || formState.close_type > 0) {
      setIsStep(2);
    } else {
      handlePageState({
        responseMessage: "Please choose at least one option.",
        isError: true,
      });
    }
  };

  const onSubmitHandler = async (e: any) => {
    e.preventDefault();
    if (formState.need_reminder === -1) {
      handlePageState({
        responseMessage: "Please select an option.",
        isError: true,
      });
      return;
    } else if (formState.need_reminder == 1) {
      // handlePageState({
      //   responseMessage: "Please select mortgage start date.",
      //   isError: true,
      // });
      // return;
      if (formState.start_date === "") {
        handlePageState({
          responseMessage: "Please select mortgage start date.",
          isError: true,
        });
        return;
      }
    }

    const res = await closeAdviceNeed(formState);
    if (res.status) {
      handlePageState({ responseMessage: res.message, isError: false });
      history.push("/dashboard");
    } else {
      handlePageState({ responseMessage: res.message, isError: true });
    }
  };

  return (
    <>
      <main className="dashboard">
        <section className="page-title leadmatch">
          <div className="container">
            <div className="row">
              <div className="col-md-12 mb-5 mb-md-0"></div>
            </div>
          </div>
        </section>

        <section className="recent-sec advice-area-sec">
          <img
            src="assets/images/expert-right.png"
            alt="help-1"
            className="img-fluid after-img"
          />
          <div className="container">
            <div className="row">
              <div className="col-12 back-btn mt-lg-3 mb-lg-3 mt-3 mb-0">
                <Link to={`/advice-area/${id}/offers`} className="back-btn">
                  <i className="fa fa-angle-left" aria-hidden="true"></i>
                  Back
                </Link>
              </div>
            </div>
          </div>
          {isStep === 1 ? (
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-lg-12">
                  <div className="recent-box mb-3">
                    <div className="row align-items-center justify-content-center">
                      <div className="col-lg-12">
                        <div id="profile-tab" className="row">
                          <div className="col-lg-12 col-md-12 pt-lg-4 pt-0 mb-md-3 mb-0">
                            <div className="heading adviser-content text-center text-center mb-4">
                              <h2 className="mb-lg-4 mb-2">
                                Which mortgage adviser did you go with?
                              </h2>
                              <span></span>
                            </div>
                          </div>
                          <div className="col-xl-6 offset-xl-3 col-lg-8 offset-lg-2 col-md-10 offset-md-1">
                            <div className="close-form mt-3">
                              {formState.responseMessage && (
                                <InlineAlert
                                  isError={formState.isError}
                                  message={formState.responseMessage}
                                />
                              )}
                              <form>
                                {/* TODO: Advisor who bids are shown here */}
                                {acceptedBids.length ? (
                                  <div className="form-row row-border">
                                    {acceptedBids.map((bid: any) => (
                                      <>
                                        <div
                                          key={bid.advisor_id}
                                          className="form-group col-lg-12"
                                        >
                                          <input
                                            type="radio"
                                            id={`cust_name${bid.advisor_id}`}
                                            name="close_type"
                                            value={bid.advisor_id}
                                            onChange={handleChange}
                                            checked={
                                              formState.advisor_id ===
                                              bid.advisor_id
                                            }
                                          />
                                          <label
                                            htmlFor={`cust_name${bid.advisor_id}`}
                                            className="mb-2"
                                          >
                                            <b>
                                              {bid.display_name &&
                                              bid.display_name != ""
                                                ? capitalize(
                                                    bid.display_name.toString()
                                                  )
                                                : bid.name && bid.name != ""
                                                ? capitalize(
                                                    bid.name.toString()
                                                  )
                                                : null}
                                            </b>{" "}
                                            {capitalize(bid.company_name)}
                                          </label>
                                        </div>
                                        <span className="border"></span>
                                      </>
                                    ))}
                                  </div>
                                ) : null}
                                <div
                                  className={`form-row row-border ${
                                    formState.isError && "errorState"
                                  }`}
                                >
                                  <div className="form-group col-lg-12">
                                    <input
                                      type="radio"
                                      id="cust_name3"
                                      name="close_type"
                                      value={-1}
                                      onChange={handleChange}
                                      checked={formState.close_type === 1}
                                    />
                                    <label
                                      htmlFor="cust_name3"
                                      className="mb-2"
                                    >
                                      Someone not on Mortgagebox
                                    </label>
                                  </div>
                                  <span className="border"></span>
                                  <div className="form-group col-lg-12">
                                    <input
                                      type="radio"
                                      id="cust_name4"
                                      name="close_type"
                                      value={-2}
                                      onChange={handleChange}
                                      checked={formState.close_type === 2}
                                    />
                                    <label
                                      htmlFor="cust_name4"
                                      className="mb-2"
                                    >
                                      In the end I didn’t need a mortgage
                                      adviser
                                    </label>
                                  </div>
                                </div>
                                <div className="form-row align-items-center text-center">
                                  <div className="form-group col-6 close-advisor centerbtncen-next">
                                    <Link
                                      to={`/advice-area/${id}/offers`}
                                      className="normal-btn text-uppercase cacle-bnt mr-md-3 mr-0"
                                    >
                                      CANCEL
                                    </Link>
                                  </div>
                                  <div className="form-group col-6 text-right centerbtncen-next">
                                    <button
                                      type="button"
                                      className="theme-btn text-uppercase"
                                      onClick={handleNextStep}
                                    >
                                      Next
                                      <i
                                        className="fa fa-chevron-right ml-2"
                                        aria-hidden="true"
                                      ></i>
                                    </button>
                                  </div>
                                </div>
                              </form>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className="container">
              <div className="row justify-content-center ">
                <div className="col-lg-12">
                  <div className="recent-box">
                    <div className="row justify-content-center">
                      <div className="col-12">
                        <form onSubmit={onSubmitHandler}>
                          <div className="row align-items-center version2-data close-reminder-sec text-center">
                            <div className="col-12">
                              <div className="heading">
                                <h2 className="no-space">
                                  Would you like a reminder when your deal is
                                  coming to an end?
                                </h2>
                                <span></span>
                              </div>
                            </div>

                            <div className="col-lg-4 offset-lg-4 col-md-6 offset-md-3 mt-md-5 mt-3 form-1">
                              <div className="form-row sel-data">
                                {formState.responseMessage && (
                                  <InlineAlert
                                    isError={formState.isError}
                                    message={formState.responseMessage}
                                  />
                                )}
                                <div className="col-6">
                                  <label className="radio-img text-uppercase">
                                    <input
                                      id="yes_sure"
                                      type="radio"
                                      name="need_reminder"
                                      value={1}
                                      onChange={handleChange}
                                    />
                                    <span>YES, SURE</span>
                                  </label>
                                </div>
                                <div className="col-6">
                                  <label className="radio-img text-uppercase">
                                    <input
                                      id="no_sorry"
                                      type="radio"
                                      name="need_reminder"
                                      value={0}
                                      onChange={handleChange}
                                    />
                                    <span>NO, SORRY</span>
                                  </label>
                                </div>
                              </div>
                            </div>
                            <span className="border"></span>
                            <div className="col-lg-8 offset-lg-2 col-md-10 offset-md-1 mt-md-3 mt-3 form-2">
                              {formState.need_reminder === 1 && (
                                <div className="need_reminder_div">
                                  <h6>INITIAL TERM</h6>
                                  <div className="mt-md-5 mb-md-5 mt-2 mb-2">
                                    <div className="form-row mb-4 mb-md-0">
                                      <div className="col-md-3 col-6">
                                        <label className="radio-img text-uppercase">
                                          <input
                                            type="radio"
                                            name="initial_term"
                                            value="2_years"
                                            onChange={handleChange}
                                            checked={
                                              formState.initial_term ===
                                              "2_years"
                                            }
                                          />
                                          <span>2 YEARS</span>
                                        </label>
                                      </div>
                                      <div className="col-md-3 col-6">
                                        <label className="radio-img text-uppercase">
                                          <input
                                            type="radio"
                                            name="initial_term"
                                            value="5_years"
                                            onChange={handleChange}
                                            checked={
                                              formState.initial_term ===
                                              "5_years"
                                            }
                                          />
                                          <span>5 YEARS</span>
                                        </label>
                                      </div>
                                      <div className="col-md-3 col-6">
                                        <label className="radio-img text-uppercase">
                                          <input
                                            type="radio"
                                            name="initial_term"
                                            value="10_years"
                                            onChange={handleChange}
                                            checked={
                                              formState.initial_term ===
                                              "10_years"
                                            }
                                          />
                                          <span>10 YEARS</span>
                                        </label>
                                      </div>
                                      <div className="col-md-3 col-6">
                                      <label className="radio-img text-uppercase">
                                      <input
                                            type="radio"
                                            name="initial_term"
                                            value="other"
                                            onChange={handleChange}
                                            checked={
                                              formState.initial_term ===
                                              "other"
                                            }
                                          />
                                          <span>OTHER</span>
                                        </label>
                                      
                                        {/* <select
                                          name="initial_term"
                                          id="initial_term"
                                          className={`${
                                            formState.initial_term ===
                                              "other" && "selectedSelectBox"
                                          }`}
                                          onChange={handleChange}
                                        >
                                          <option>OTHER</option>
                                          <option value="other">OTHER</option>
                                        </select> */}
                                        {/* <i
                                          className="fa fa-chevron-down"
                                          aria-hidden="true"
                                        ></i> */}
                                      </div>
                                    </div>
                                  </div>
                                  <div className="mt-md-4 mb-md-4 mt-2 mb-2 inputInitial hide">
                                    <div className="form-row text-center">
                                      <div className="col-xl-4 offset-xl-4 col-lg-6 offset-lg-3 col-md-6 offset-md-3 col126 dates">
                                        <input
                                          type="number"
                                          name="initial_term_number"
                                          placeholder=""
                                          value={formState.initial_term_number}
                                          onChange={handleChange}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                  <p className="mb-md-5 mb-4">
                                    Mortgagebox will send a reminder email 3
                                    months before your deal comes to an end
                                  </p>
                                  <h6>MORTGAGE START DATE</h6>
                                  <div className="mt-md-4 mb-md-4 mt-2 mb-2">
                                    <div className="form-row text-center">
                                      <div className="col-xl-4 offset-xl-4 col-lg-6 offset-lg-3 col-md-6 offset-md-3 col126 dates">
                                        <input
                                          type="date"
                                          name="start_date"
                                          placeholder="30 May 2021"
                                          value={formState.start_date}
                                          onChange={handleChange}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              )}

                              <div className="float-right">
                                <button
                                  type="submit"
                                  className="theme-btn finish text-uppercase"
                                >
                                  FINISH
                                </button>
                              </div>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}

          <img
            src="assets/images/expert-left.png"
            alt="help-1"
            className="img-fluid before-img"
          />
        </section>
      </main>
    </>
  );
};

export default CloseAdviceAreaNeed;
