import React from "react";
import { Link } from "react-router-dom";

const AdvicerTwo = () => {
  return (
    <React.Fragment>
      <section className="advice-herosection">
        <div className="container">
          <div className="container-center ">
            <div className="heroTitleHomeHeader-advicer ">
              Connect with Borrowers Actively <br />
              Looking for a Mortgage
            </div>

            <p className="heroHomeSubTitle-advicer  ">
              Free to browse, pay only for leads you want. No sign-up or
              subscription fees.
            </p>
            <div className="header-two-btn ">
              <Link to={'/register'}>
              <img
                alt="logo"
                src={"/assets/images/newpagesimages/signup.png"}
              />
              </Link>
              <Link to={'/pricing'}>
              <img
                alt="logo"
                src={"/assets/images/newpagesimages/pricing.png"}
                className="ml-md-5"
              />
              </Link>
            </div>
          </div>
        </div>
      </section>

      <section className="advice-section-two">
        <p>Build Your Business With MortgageBox</p>
        <div className="container py-5">
          <div className="row">
            <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 mb-4 d-flex justify-content-center">
              <div className="bg-white card-container-advicer rounded shadow-sm py-4 px-4 d-flex flex-column align-items-center">
                <img
                  src="/assets/images/newpagesimages/cardThirdIcon.png"
                  alt=""
                  width="100"
                  className="img-fluid rounded-circle mb-3 img-thumbnail shadow-sm"
                />
                <div className="mb-2 text-center h6" >Browse High-Quality Leads</div>
                <span className="text-center medium text-muted ">
                  Directly connect with active borrowers looking for mortgages.
                </span>
              </div>
            </div>
            <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 mb-4 d-flex justify-content-center">
              <div className="bg-white card-container-advicer rounded shadow-sm py-4 px-4 d-flex flex-column align-items-center">
                <img
                  src="/assets/images/newpagesimages/noSignUp.png"
                  alt=""
                  width="100"
                  className="img-fluid rounded-circle mb-3 img-thumbnail shadow-sm"
                />
                <div className="mb-2 text-center h6" style={{color:"black"}}>
                  No Sign-Up or Subscription Fees
                </div>
                <span className="text-center medium text-muted">
                  Review all incoming leads at no cost and without providing any
                  card details.
                </span>
              </div>
            </div>
            <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 mb-4 d-flex justify-content-center">
              <div className="bg-white card-container-advicer rounded shadow-sm py-4 px-4 d-flex flex-column align-items-center">
                <img
                  src="/assets/images/newpagesimages/LowCost.png"
                  alt=""
                  width="100"
                  className="img-fluid rounded-circle mb-3 img-thumbnail shadow-sm"
                />
                <div className="mb-2 text-center h6" style={{color:"black"}}>Low-Cost Leads</div>
                <span className="text-center medium text-muted">
                  Pay just £12 per lead, based on a £200k mortgage. Only pay for
                  the ones you choose.
                </span>
              </div>
            </div>
            <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 mb-4 d-flex justify-content-center">
              <div className="bg-white card-container-advicer rounded shadow-sm py-4 px-4 d-flex flex-column align-items-center">
                <img
                  src="/assets/images/newpagesimages/minimised.png"
                  alt=""
                  width="100"
                  className="img-fluid rounded-circle mb-3 img-thumbnail shadow-sm"
                />
                <div className="mb-2 text-center h6" style={{color:"black"}}>Minimised Competition</div>
                <span className="text-center medium text-muted">
                  Each lead is shared with a maximum of 5 advisors, increasing
                  your chances of success.
                </span>
              </div>
            </div>
            <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 mb-4 d-flex justify-content-center">
              <div className="bg-white card-container-advicer rounded shadow-sm py-4 px-4 d-flex flex-column align-items-center">
                <img
                  src="/assets/images/newpagesimages/riskFreeTrial.png"
                  alt=""
                  width="100"
                  className="img-fluid rounded-circle mb-3 img-thumbnail shadow-sm"
                />
                <div className="mb-2 text-center h6" style={{color:"black"}}>Risk-Free Trial</div>
                <span className="text-center medium text-muted">
                  Experience MortgageBox with no commitments - your first 5
                  leads are free.
                </span>
              </div>
            </div>
            <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 mb-4 d-flex justify-content-center">
              <div className="bg-white card-container-advicer rounded shadow-sm py-4 px-5  d-flex flex-column align-items-center">
                <img
                  src="/assets/images/newpagesimages/hugeDiscout.png"
                  alt=""
                  width="100"
                  className="img-fluid rounded-circle mb-3 img-thumbnail shadow-sm"
                />
                <div className="mb-2 text-center h6" style={{color:"black"}}>
                  Huge Discounts for Older Leads
                </div>
                <span className="text-center medium text-muted">
                  50% after 24 hours, 75% after 48 hours, Free after 72 hours.
                </span>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* <section className="adivce-section-three">
        <h1 className="text-center ">How MortgageBox Works</h1>
        <p className="advice-section-three-subtitle py-3">
        Qualified mortgage leads, real-time at a low cost. View all leads as they are listed <br/> and easily identify those that interest you.
        </p>
        <div className=" d-md-flex    justify-content-center gap-5    py-3">
          <img alt="polygon" src="/assets/images/newpagesimages/browse.png" className="polygon-card" />
          <img alt="polygon" src="/assets/images/newpagesimages/select.png" className="polygon-card" />
          <img alt="polygon" src="/assets/images/newpagesimages/connect.png" className="polygon-card" />
          
        </div>
      </section> */}

      <section className="adivce-section-three">
        <h1 className="text-center ">How MortgageBox Works</h1>
        <p className="advice-section-three-subtitle py-3  ">
        Qualified mortgage leads in real-time at a low cost. View all leads as they are listed <br/> and easily identify those that interest you.
        </p>
        <div className=" three-card-advice-section   py-3">
          <div className="mb-3 mb-md-mb-0 mr-md-1    ">
            <svg
              viewBox="0 0 405 350"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
           
            >
              <path
                d="M303.462 0.499996L404.422 175L303.462 349.5L101.538 349.5L0.57766 175L101.538 0.499987L303.462 0.499996Z"
                fill="white"
                fill-opacity="0.3"
                stroke="white"
              />

              <svg
                x="23"
                y="20"
                width="359"
                height="310"
                viewBox="0 0 359 310"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M359 155L269.25 310L89.75 310L-6.77527e-06 155L89.75 -1.17693e-05L269.25 -3.9231e-06L359 155Z"
                  fill="white"
                />

                <image
                  xlinkHref="/assets/images/newpagesimages/hexgonalIconOne.png"
                  x="55%"
                  y="28%"
                  width="70"
                  height="70"
                  transform="translate(-50, -50)"
                />
                <text
                  x="50%"
                  y="43%"
                  dominantBaseline="middle"
                  textAnchor="middle"
                  fill="#333333"
                  fontSize="20"
                  fontWeight="bold"
                  fontFamily="Axiforma , sans-serif"
                >
                  BROWSE
                </text>
                <text
                  x="50%"
                  y="53%"
                   fontFamily="Axiforma,sans-serif"
                  dominantBaseline="middle"
                  textAnchor="middle"
                  fill="#333333"
                  fontSize="17"
                >
                  Customers enter their,
                </text>
                <text
                  x="50%"
                  y="62%"
                 fontFamily="Axiforma,sans-serif"
                  dominantBaseline="middle"
                  textAnchor="middle"
                  fill="#333333"
                  fontSize="17"
                >
                  mortgage needs, which are
                </text>
                <text
                  x="50%"
                  y="70%"
                   fontFamily="Axiforma,sans-serif"
                  dominantBaseline="middle"
                  textAnchor="middle"
                  fill="#333333"
                  fontSize="17"
                >
                  instantly matched to your
                </text>
                <text
                  x="50%"
                  y="78%"
                   fontFamily="Axiforma,sans-serif"
                  dominantBaseline="middle"
                  textAnchor="middle"
                  fill="#333333"
                  fontSize="17"
                >
                  preferences and appear on
                </text>
                <text
                  x="50%"
                  y="86%"
                   fontFamily="Axiforma,sans-serif"
                  dominantBaseline="middle"
                  textAnchor="middle"
                  fill="#333333"
                 fontSize="17"
                >
                  the leads page.
                </text>
              </svg>
            </svg>
          </div>
          <div className="mb-3 mb-md-mb-0 mr-md-1    ">
            <svg
              viewBox="0 0 405 350"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
           
            >
              <path
                d="M303.462 0.499996L404.422 175L303.462 349.5L101.538 349.5L0.57766 175L101.538 0.499987L303.462 0.499996Z"
                fill="white"
                fill-opacity="0.3"
                stroke="white"
              />

              <svg
                x="23"
                y="20"
                width="359"
                height="310"
                viewBox="0 0 359 310"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M359 155L269.25 310L89.75 310L-6.77527e-06 155L89.75 -1.17693e-05L269.25 -3.9231e-06L359 155Z"
                  fill="white"
                />

                <image
                  xlinkHref="/assets/images/newpagesimages/hexagonalIconTwo.png"
                  x="55%"
                  y="28%"
                  width="70"
                  height="70"
                  transform="translate(-50, -50)"
                />
                <text
                  x="50%"
                  y="43%"
                  dominantBaseline="middle"
                  textAnchor="middle"
                  fill="#333333"
                  fontSize="20"
                  fontWeight="bold"
                  fontFamily="Axiforma , sans-serif"
                >
                  SELECT
                </text>
                <text
                  x="50%"
                  y="53%"
                   fontFamily="Axiforma,sans-serif"
                  dominantBaseline="middle"
                  textAnchor="middle"
                  fill="#333333"
                 fontSize="17"
                >
                 You choose and only pay for  
                </text>
                <text
                  x="50%"
                  y="62%"
                   fontFamily="Axiforma,sans-serif"
                  dominantBaseline="middle"
                  textAnchor="middle"
                  fill="#333333"
                fontSize="17"
                >
                  the leads you want. Act
                </text>
                <text
                  x="50%"
                  y="70%"
                   fontFamily="Axiforma,sans-serif"
                  dominantBaseline="middle"
                  textAnchor="middle"
                  fill="#333333"
                  fontSize="17"
                >
                 quickly, as a maximum of
                </text>
                <text
                  x="50%"
                  y="78%"
                   fontFamily="Axiforma,sans-serif"
                  dominantBaseline="middle"
                  textAnchor="middle"
                  fill="#333333"
                 fontSize="17"
                >
                  five advisers can be 
                </text>
                <text
                  x="50%"
                  y="86%"
                   fontFamily="Axiforma,sans-serif"
                  dominantBaseline="middle"
                  textAnchor="middle"
                  fill="#333333"
                fontSize="17"
                >
                  introduced.
                </text>
              </svg>
            </svg>
          </div>
          <div className="mb-3 mb-md-mb-0 mr-md-1    ">
            <svg
              viewBox="0 0 405 350"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
           
            >
              <path
                d="M303.462 0.499996L404.422 175L303.462 349.5L101.538 349.5L0.57766 175L101.538 0.499987L303.462 0.499996Z"
                fill="white"
                fill-opacity="0.3"
                stroke="white"
              />

              <svg
                x="23"
                y="20"
                width="359"
                height="310"
                viewBox="0 0 359 310"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M359 155L269.25 310L89.75 310L-6.77527e-06 155L89.75 -1.17693e-05L269.25 -3.9231e-06L359 155Z"
                  fill="white"
                />

                <image
                  xlinkHref="/assets/images/newpagesimages/hexagonalIconThree.png"
                  x="55%"
                  y="28%"
                  width="70"
                  height="70"
                  transform="translate(-50, -50)"
                />
                <text
                  x="50%"
                  y="43%"
                  dominantBaseline="middle"
                  textAnchor="middle"
                  fill="#333333"
                  fontSize="20"
                  fontWeight="bold"
                  fontFamily="Axiforma,sans-serif"
                >
                  CONNECT
                </text>
                <text
                  x="50%"
                  y="53%"
                   fontFamily="Axiforma,sans-serif"
                  dominantBaseline="middle"
                  textAnchor="middle"
                  fill="#333333"
                  fontSize="17"
                >
                  Engage with customers,
                </text>
                <text
                  x="50%"
                  y="62%"
                  fontFamily="Axiforma,sans-serif"
                  dominantBaseline="middle"
                  textAnchor="middle"
                  fill="#333333"
               fontSize="17"
                >
                  offering tailored services. 
                </text>
                <text
                  x="50%"
                  y="70%"
                   fontFamily="Axiforma,sans-serif"
                  dominantBaseline="middle"
                  textAnchor="middle"
                  fill="#333333"
                fontSize="17"
                >
                  Show why you're the
                </text>
                <text
                  x="50%"
                  y="78%"
                   fontFamily="Axiforma,sans-serif"
                  dominantBaseline="middle"
                  textAnchor="middle"
                  fill="#333333"
                  fontSize="17"
                >
                  perfect adviser for their 
                </text>
                <text
                  x="50%"
                  y="86%"
                   fontFamily="Axiforma,sans-serif"
                  dominantBaseline="middle"
                  textAnchor="middle"
                  fill="#333333"
                fontSize="17"
                >
                  needs.
                </text>
              </svg>
            </svg>
          </div>
          
        </div>
      </section>

      <section className="advice-expection ">
        <div className="container">
          <p className="advice-expection-title">
            Expectations for Mortgage Advisers
          </p>
          <div className="advice-expection-subtitle">
            In return for providing high-quality leads at a low price we expect
            advisers to:
          </div>
          <div className="d-flex flex-column flex-lg-row justify-content-center align-items-center pt-5">
            <div className="flex-shrink-0  mb-lg-0">
              <img
                alt="logo"
                src="/assets/images/newpagesimages/expectio.png"
                className="img-fluid bg-white m-0 p-0" 
              />
            </div>
            <div className="bg-white p-3">
              <div className="advice-verified-text d-flex align-items-start mb-3">
                <img
                  alt="logo"
                  src="/assets/images/newpagesimages/verified.png"
                  className="me-2  "
                />
                <p className="m-0">
                  Offer MortgageBox customers great deals tailored to their
                  needs at the lowest possible cost.
                </p>
              </div>
              <div className="advice-verified-text d-flex  align-items-start mb-3">
                <img
                  alt="logo"
                  src="/assets/images/newpagesimages/verified.png"
                  className="me-2 "
                />
                <p className="m-0">
                  Retail mortgage advisers are to be FCA registered. Commercial
                  brokers, please contact us.
                </p>
              </div>
              <div className="advice-verified-text d-flex align-items-start mb-3">
                <img
                  alt="logo"
                  src="/assets/images/newpagesimages/verified.png"
                  className="me-2"
                />
                <p className="m-0">
                  Offer customers a warm welcome and a free, no-obligation chat
                  to identify the best solutions for their mortgage need.
                </p>
              </div>
              <div className="advice-verified-text d-flex align-items-start mb-3">
                <img
                  alt="logo"
                  src="/assets/images/newpagesimages/verified.png"
                  className="me-2"
                />
                <p className="m-0">
                  Take away the stress and hassle by guiding customers through
                  the entire process, from searching for deals to organising the
                  legal aspects.
                </p>
              </div>
              <div className="advice-verified-text d-flex align-items-start">
                <img
                  alt="logo"
                  src="/assets/images/newpagesimages/verified.png"
                  className="me-2"
                />
                <p className="m-0 mt-1">Uphold the MortgageBox ethos.</p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </React.Fragment>
  );
};

export default AdvicerTwo;
