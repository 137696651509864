import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useToasts } from "react-toast-notifications";
import ReactTooltip from "react-tooltip";
import {
  getNotificationPreferences,
  updateNotificationPreferences,
} from "../../components/common/service";
import { ADVISER_MY_ACCOUNT } from "../../Constants";

const initialState = {
  new_lead: 0,
  newslatter: 0,
  direct_contact: 0,
  monthly_invoice: 0,
  direct_message: 0,
  accept_offer: 0,
  decline_offer: 0,
  lead_match: 0,
  review: 0,
  promotional: 0,
};

const AdviserNotifications = () => {
  const { addToast } = useToasts();
  const [formState, setFormState] = useState(initialState);

  useEffect(() => {
    getAdvisorLinksCall();
  }, []);

  const getAdvisorLinksCall = async () => {
    const res = await getNotificationPreferences();
    if (res.data) {
      delete res.data.id;
      delete res.data.updated_at;
      delete res.data.created_at;
      delete res.data.user_id;
      setFormState(res.data);
    }
  };

  const handlePageState = (newStates: any) => {
    setFormState((prevStates: any) => {
      return {
        ...prevStates,
        ...newStates,
      };
    });
  };

  const handleChangeCheckbox = (event: { target: HTMLInputElement } | any) => {
    const { name, checked } = event.target;
    handlePageState({ [name]: +checked });
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    const res = await updateNotificationPreferences(formState);
    if (res.status) {
      addToast(res.message, { appearance: "success" });
    } else {
      addToast(res.message, { appearance: "error" });
    }
  };

  return (
    <>
      <main className="need-offers">
        <section className="page-title my-account-title">
          <div className="container">
            <div className="row">
              <div className="col-md-12 mb-5 mb-md-0"></div>
            </div>
          </div>
        </section>

        <section className="recent-sec directory-sec advice-area-sec register-final-sec pt-4 pb-5 myaccount-sec preference-product-sec">
          <div className="container">
            <div className="row">
              <div className="col-12 back-btn mt-lg-4 mb-lg-3 mt-md-5 mt-5 mb-0">
                <Link
                  to={`${ADVISER_MY_ACCOUNT}/general-info`}
                  className="back-btn"
                >
                  <i className="fa fa-angle-left" aria-hidden="true"></i>
                  My Account
                </Link>
              </div>
            </div>
          </div>

          <div className="container">
            <div className="row">
              <div className="col-12 mb-3 small-breadcumbs">
                <h5 className="text-white">
                  <img
                    src="assets/images/notifications.png"
                    className="img-fluid"
                    alt=""
                  />{" "}
                  Notifications
                </h5>
              </div>
            </div>
            <div className="recent-box settings-data mb-lg-4 mb-0">
              <div className="row justify-content-center">
                <div className="col-xl-10 col-lg-12 mb-lg-4 mb-0">
                  <div className="tabbing">
                    <div className="tab-content mt-lg-4 mt-2">
                      <div className="profile-datas">
                        <h5 className="theme-color mb-3">
                          Communication preferences
                        </h5>
                        <p>
                          Choose how and when you want to receive notifications
                          from mortgagebox. This is also the place to choose
                          which newsletters and promotions you want to receive.
                        </p>
                      </div>

                      <div className="preference-defaults preference-notification mt-lg-5 mt-4">
                        <form method="post" onSubmit={handleSubmit}>
                          <div className="team-memebers-data pl-xl-5 pr-xl-5">
                            <div className="profile-datas notification-data">
                              <h5 className="mb-3">
                                Email
                                <p>
                                  Not getting our e-mails?
                                  <a
                                    href="javascript:void(0)"
                                    data-tip="Check your Junk and Spam folders for missed email"
                                  >
                                    <img
                                      src="assets/images/information.png"
                                      alt="information"
                                      className="img-fluid information"
                                    />
                                  </a>
                                </p>
                              </h5>
                              <span className="border-bottom"></span>
                            </div>

                            <div className="row member-data m-0">
                              <div className="col-7 text-left">
                                <p>New leads matching my profile</p>
                              </div>
                              <div className="col-5 text-right">
                                <label className="switch">
                                  <input
                                    type="checkbox"
                                    name="new_lead"
                                    value={1}
                                    onChange={handleChangeCheckbox}
                                    checked={formState.new_lead === 1}
                                  />
                                  <span className="slider round"></span>
                                </label>
                              </div>
                            </div>
                            <div className="row member-data m-0">
                              <div className="col-7 text-left">
                                <p>Newsletter</p>
                              </div>
                              <div className="col-5 text-right">
                                <label className="switch">
                                  <input
                                    type="checkbox"
                                    name="newslatter"
                                    value={1}
                                    onChange={handleChangeCheckbox}
                                    checked={formState.newslatter === 1}
                                  />
                                  <span className="slider round"></span>
                                </label>
                              </div>
                            </div>
                            <div className="row member-data m-0">
                              <div className="col-7 text-left">
                                <p>Customers directly contacting me</p>
                              </div>
                              <div className="col-5 text-right">
                                <label className="switch">
                                  <input
                                    type="checkbox"
                                    name="direct_contact"
                                    value={1}
                                    onChange={handleChangeCheckbox}
                                    checked={formState.direct_contact === 1}
                                  />
                                  <span className="slider round"></span>
                                </label>
                              </div>
                            </div>
                            <div className="row member-data m-0">
                              <div className="col-7 text-left">
                                <p>Monthly invoice</p>
                              </div>
                              <div className="col-5 text-right">
                                <label className="switch">
                                  <input
                                    type="checkbox"
                                    name="monthly_invoice"
                                    value={1}
                                    onChange={handleChangeCheckbox}
                                    checked={formState.monthly_invoice === 1}
                                  />
                                  <span className="slider round"></span>
                                </label>
                              </div>
                            </div>
                            <div className="row member-data m-0">
                              <div className="col-7 text-left">
                                <p>Customers sending me a message</p>
                              </div>
                              <div className="col-5 text-right">
                                <label className="switch">
                                  <input
                                    type="checkbox"
                                    name="direct_message"
                                    value={1}
                                    onChange={handleChangeCheckbox}
                                    checked={formState.direct_message === 1}
                                  />
                                  <span className="slider round"></span>
                                </label>
                              </div>
                            </div>
                            <div className="row member-data m-0">
                              <div className="col-7 text-left">
                                <p>Customers accepting my offer</p>
                              </div>
                              <div className="col-5 text-right">
                                <label className="switch">
                                  <input
                                    type="checkbox"
                                    name="accept_offer"
                                    value={1}
                                    onChange={handleChangeCheckbox}
                                    checked={formState.accept_offer === 1}
                                  />
                                  <span className="slider round"></span>
                                </label>
                              </div>
                            </div>
                            <div className="row member-data m-0">
                              <div className="col-7 text-left">
                                <p>Customers decling my offer</p>
                              </div>
                              <div className="col-5 text-right">
                                <label className="switch">
                                  <input
                                    type="checkbox"
                                    name="decline_offer"
                                    value={1}
                                    onChange={handleChangeCheckbox}
                                    checked={formState.decline_offer === 1}
                                  />
                                  <span className="slider round"></span>
                                </label>
                              </div>
                            </div>
                            <div className="row member-data m-0">
                              <div className="col-7 text-left">
                                <p>
                                  A summary of leads I’m matched to each day
                                </p>
                              </div>
                              <div className="col-5 text-right">
                                <label className="switch">
                                  <input
                                    type="checkbox"
                                    name="lead_match"
                                    value={1}
                                    onChange={handleChangeCheckbox}
                                    checked={formState.lead_match === 1}
                                  />
                                  <span className="slider round"></span>
                                </label>
                              </div>
                            </div>
                            <div className="row member-data m-0">
                              <div className="col-7 text-left">
                                <p>New reviews on my profile</p>
                              </div>
                              <div className="col-5 text-right">
                                <label className="switch">
                                  <input
                                    type="checkbox"
                                    name="review"
                                    value={1}
                                    onChange={handleChangeCheckbox}
                                    checked={formState.review === 1}
                                  />
                                  <span className="slider round"></span>
                                </label>
                              </div>
                            </div>
                            <div className="row member-data m-0">
                              <div className="col-7 text-left">
                                <p>Promotional email</p>
                              </div>
                              <div className="col-5 text-right">
                                <label className="switch">
                                  <input
                                    type="checkbox"
                                    name="promotional"
                                    value={1}
                                    onChange={handleChangeCheckbox}
                                    checked={formState.promotional === 1}
                                  />
                                  <span className="slider round"></span>
                                </label>
                              </div>
                            </div>
                            <div className="row m-0">
                              <div className="col-md-12 text-center mt-lg-4 mt-0">
                                <button
                                  type="submit"
                                  className="theme-btn text-uppercase mr-md-3 mr-1 mb-md-0 mb-3"
                                >
                                  Save
                                </button>
                              </div>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <img
            src="assets/images/business_right.png"
            alt="help-1"
            className="img-fluid before-img"
          />
          <img
            src="assets/images/business_left.png"
            alt="help-1"
            className="img-fluid after-img"
          />
        </section>
      </main>
      <ReactTooltip />
    </>
  );
};

export default AdviserNotifications;
