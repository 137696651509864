import React from "react";
import Routes from "../../Routes";

const Layout = () => {
  return (
    <>
      <Routes />
    </>
  );
};

export default Layout;
