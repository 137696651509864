import React, { useContext, useEffect, useState } from "react";
import {
  getUserProfile,
  searchPostalCode,
  updateUserProfile,
} from "../components/common/service";
import LoadingSpinner from "../components/common/LoadingSpinner";
import { validateEmail } from "../utility/commonUtils";
import InlineAlert from "../components/common/InlineAlert";
import ChangePassword from "../components/common/modals/ChangePassword";
import { Link } from "react-router-dom";
import { AuthContext } from "../shared/context/auth-context";
import { LOGGEDIN_USER } from "../Constants";
import { AsyncTypeahead } from "react-bootstrap-typeahead";
import useLoggedInUserData from "../utility/useLoggedInUserData";

const initialState = {
  name: "",
  email: "",
  post_code: "",
  isError: false,
  responseMessage: "",
};

const UserAccount = () => {
  const user = useLoggedInUserData();
  const { login: setLoggedInUserData } = useContext(AuthContext);
  const [formState, setFormState] = useState(initialState);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [isSeachLoading, setIsSeachLoading] = useState<boolean>(false);
  const [options, setOptions] = useState<any>([]);
  const [selectedOption, setselectedOption] = useState<any>([]);

  const handleChangeSearch = (event: any) => {
    if (event.length) {
      setselectedOption([event[0]]);
      handlePageState({ post_code: event[0].Postcode });
    } else {
      setselectedOption([]);
    }
  };

  const handleSearch = async (query: any) => {
    setIsSeachLoading(true);
    const res = await searchPostalCode({ postal_code: query });
    if (res.status) {
      setOptions(res.data);
    }
    setIsSeachLoading(false);
  };
  // Bypass client-side filtering by returning `true`. Results are already
  // filtered by the search endpoint, so no need to do it again.
  const filterBy = () => true;

  useEffect(() => {
    
    if (!user.id) {
      getUserProfileCall();
    } else if (!formState.name) {
      setFormState((prevState) => {
        return {
          ...prevState,
          name: user.name,
          email: user.email,
          post_code: user.post_code,
        };
      });
      setselectedOption([{ id: 0, Postcode: user.post_code }]);
      setOptions([{ id: 0, Postcode: user.post_code }]);
    }
  }, [user]);

  const getUserProfileCall = async () => {
    setIsLoading(true);
    const res = await getUserProfile();
    if (res?.user) {
      setFormState(res.user);
    }
    setIsLoading(false);
  };

  const handlePageState = (newStates: any) => {
    setFormState((prevStates) => {
      return {
        ...prevStates,
        ...newStates,
      };
    });
  };

  const handleChange = (event: { target: HTMLInputElement } | any) => {
    const { name, value } = event.target;
    handlePageState({ [name]: value });
    event.preventDefault();
  };

  const onSubmitHandler = async (e: any) => {
    e.preventDefault();
    if (!validateEmail(formState.email)) {
      handlePageState({ responseMessage: "Email is required.", isError: true });
      return;
    }
    if (formState.name === "") {
      handlePageState({
        responseMessage: "Name is required.",
        isError: true,
      });
      return;
    }
    if (formState.post_code === "") {
      handlePageState({
        responseMessage: "Postcode is required.",
        isError: true,
      });
      return;
    } else {
      const existingOptions = options.map((option: any) => option.Postcode);
      if (!existingOptions.includes(formState.post_code)) {
        handlePageState({
          responseMessage: "Postcode is invalid. Please use valid postcode",
          isError: true,
        });
        return;
      }
    }

    const res = await updateUserProfile(formState);
    if (res.status) {
      handlePageState({
        responseMessage: "Profile updated successfully.",
        isError: false,
      });
      const updatedUser = { ...user };
      updatedUser.name = formState.name;
      updatedUser.email = formState.email;
      updatedUser.post_code = formState.post_code;
      localStorage.setItem(LOGGEDIN_USER, JSON.stringify(updatedUser));
      setLoggedInUserData(updatedUser);
    } else {
      handlePageState({ responseMessage: res.message, isError: true });
    }
  };

  return (
    <>
      <main className="dashboard">
        <section className="page-title my-account-title leadmatch">
          <div className="container">
            <div className="row">
              <div className="col-md-12 mb-5 mb-md-0"></div>
            </div>
          </div>
        </section>

        <section className="recent-sec advice-area-sec">
          <img
            src="assets/images/expert-right.png"
            alt="help-1"
            className="img-fluid after-img"
          />
          <div className="container">
            <div className="row">
              <div className="col-12 back-btn mt-lg-3 mb-lg-3 mt-3 mb-0">
                <Link to={`/dashboard`} className="back-btn">
                  <i className="fa fa-angle-left" aria-hidden="true"></i>
                  Back to Dashboard
                </Link>
              </div>
            </div>
          </div>
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-12">
                <div className="recent-box account-page pt-5 mb-3">
                  <div className="row align-items-center justify-content-center mb-4">
                    <div className="col-lg-6">
                      <div id="profile-tab" className="row">
                        <div className="col-lg-12 col-md-12">
                          <div className="heading adviser-content text-center text-center mb-4">
                            <h2 className="mb-lg-4 mb-2">Account details</h2>
                            <p className="mb-lg-4 mb-2">
                              Manage your account details, email and password.
                              We'll use your email address to contact you but
                              won't share it with advisers.
                            </p>
                            <span></span>
                          </div>
                        </div>

                        {isLoading ? (
                          <div className="col-lg-12 col-md-12">
                            <LoadingSpinner loading={isLoading} />
                          </div>
                        ) : (
                          <div className="col-lg-10 offset-lg-1 col-md-12">
                            {formState.responseMessage && (
                              <InlineAlert
                                isError={formState.isError}
                                message={formState.responseMessage}
                              />
                            )}
                            <div className="account-form mt-3">
                              <form onSubmit={onSubmitHandler}>
                                <div className="form-row">
                                  <div className="form-group col-lg-12">
                                    <label
                                      htmlFor="cust_name"
                                      className="p-0 mb-2"
                                    >
                                      Your name
                                    </label>
                                    <input
                                      type="text"
                                      id="cust_name"
                                      name="name"
                                      value={formState?.name}
                                      onChange={handleChange}
                                      placeholder="Joe Bloggs"
                                      onFocus={(e) =>
                                        (e.target.placeholder = "")
                                      }
                                      onBlur={(e) =>
                                        (e.target.placeholder = "Joe Bloggs")
                                      }
                                      required
                                    />
                                  </div>
                                  <div className="form-group col-lg-12">
                                    <label
                                      htmlFor="cust_post_code"
                                      className="p-0 mb-2"
                                    >
                                      Post code
                                    </label>
                                    {/* <input
                                      type="text"
                                      id="cust_post_code"
                                      name="post_code"
                                      value={formState?.post_code}
                                      onChange={handleChange}
                                      placeholder="TW2 6DD"
                                      onFocus={(e) =>
                                        (e.target.placeholder = "")
                                      }
                                      onBlur={(e) =>
                                        (e.target.placeholder = "TW2 6DD")
                                      }
                                      required
                                    /> */}
                                    <AsyncTypeahead
                                      filterBy={filterBy}
                                      id="async-example"
                                      isLoading={isSeachLoading}
                                      labelKey="Postcode"
                                      minLength={2}
                                      onSearch={handleSearch}
                                      options={options}
                                      onChange={handleChangeSearch}
                                      selected={selectedOption}
                                      placeholder="TW2 6DD"
                                      className="postCodeSearchInnerAccount"
                                      renderMenuItemChildren={(
                                        option: any,
                                        props: any
                                      ) => (
                                        <React.Fragment>
                                          <span>{option?.Postcode}</span>
                                        </React.Fragment>
                                      )}
                                    />
                                  </div>
                                  <div className="form-group col-lg-12">
                                    <label
                                      htmlFor="cust_email"
                                      className="p-0 mb-2"
                                    >
                                      Email address
                                    </label>
                                    <input
                                      type="email"
                                      id="cust_email"
                                      name="email"
                                      value={formState?.email}
                                      onChange={handleChange}
                                      placeholder="joi@bloogs.com"
                                      onFocus={(e) =>
                                        (e.target.placeholder = "")
                                      }
                                      onBlur={(e) =>
                                        (e.target.placeholder =
                                          "joi@bloogs.com")
                                      }
                                    />
                                  </div>
                                  <div className="form-group col-lg-12">
                                    <a
                                      href="javascript:void(0)"
                                      className="font-weight-bold"
                                      data-toggle="modal"
                                      data-target="#Change_Password"
                                      data-backdrop="static"
                                      data-keyboard="false"
                                    >
                                      Change password
                                    </a>
                                  </div>
                                  <div className="form-group col-md-12 text-center mt-lg-4 mt-0">
                                    <button
                                      type="submit"
                                      className="theme-btn text-uppercase mr-md-3 mr-1"
                                    >
                                      Save
                                    </button>
                                    {/* <button
                                      type="button"
                                      className="theme-btn-border text-uppercase"
                                    >
                                      Cancel
                                    </button> */}
                                  </div>
                                </div>
                              </form>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <img
            src="assets/images/expert-left.png"
            alt="help-1"
            className="img-fluid before-img"
          />
        </section>
      </main>

      <ChangePassword />
    </>
  );
};

export default UserAccount;
