import React, { useEffect, useState } from "react";
import { useToasts } from "react-toast-notifications";
import { MORTGAGE_ADVICE_ARR } from "../../Constants";
import useLoggedInUserData from "../../utility/useLoggedInUserData";
import {
  getAdvisorDefaultPreference,
  getServiceType,
  getServiceTypeWithAuth,
  updateAdvisorDefaultPreference,
} from "../common/service";

const initialState = {
};

const AdviserPrefrencesDefault = () => {
  const user = useLoggedInUserData();
  const [serviceType, setService] = useState<any>([]);
  const { addToast } = useToasts();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [formState, setFormState] = useState<any>({
    ...initialState,
  });

  useEffect(() => {
    getAdvisorDefaultPreferenceCall();
    getServices();
  }, []);

  const getServices = async () => {
    setIsLoading(true);
    const res = await getServiceTypeWithAuth();
    if (res?.success || res.status) {
      setService(res.data);
      // setFormState(res.data);
    }
    setIsLoading(false);
  };
  const getAdvisorDefaultPreferenceCall = async () => {
    const res = await getAdvisorDefaultPreference();
    if (res.data) {
      delete res.data.id;
      delete res.data.updated_at;
      delete res.data.created_at;
      setFormState(res.data);
    }
  };

  const handlePageState = (newStates: any) => {
    setFormState((prevStates: any) => {
      return {
        ...prevStates,
        ...newStates,
      };
    });
  };

  const handleChangeCheckbox = (event: { target: HTMLInputElement } | any) => {
    const { name, checked } = event.target;
    handlePageState({ [name]: +checked });
  };

  const handleChange = (event: { target: HTMLInputElement } | any) => {
    const { name, value } = event.target;
    handlePageState({ [name]: value });
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    const res = await updateAdvisorDefaultPreference(formState);
    if (res.status) {
      addToast(res.message, { appearance: "success" });
    } else {
      addToast(res.message, { appearance: "error" });
    }
  };

  return (
    <>
      <div className="profile-datas">
        <h5 className="theme-color mb-3">Estimated value</h5>
        <p>
          Create your own lead value estimate based on mortgage size, product
          and fee. It allows you to easily see a leads potential net worth.
        </p>
      </div>

      <form onSubmit={handleSubmit}>
        <div className="preference-defaults fees_percentage mt-lg-5 mt-4">
          <div className="row mb-md-4 mb-1 pb-2">
            <div className="col-lg-3 col-md-4 col-4">
              <div className="default-title">
                <h6>Mortgage type</h6>
              </div>
            </div>
            <div className="col-lg-3 col-md-4 col-4 text-center">
              <div className="default-title">
                <h6>Fee</h6>
                <p>(% of mortgage)</p>
              </div>
            </div>
            <div className="col-xl-4 col-lg-4 col-md-4 col-4 text-center">
              <div className="default-title">
                <h6>Lead value</h6>
                <p>(Based on £200k mortgage)</p>
              </div>
            </div>
          </div>

          {serviceType.map((adv_area: any) => {
            // formState[adv_area.id] = adv_area.value;
            return (
              <>
                <div
                  key={adv_area.id}
                  className="row align-items-center defaults-data"
                >
                  <div className="col-lg-3 col-md-4">
                    <p className="mb-md-0 mb-2">{adv_area.name}</p>
                  </div>
                  <div className="col-lg-3 col-md-4 col-8 text-center percentage_value">
                    <input
                      type="number"
                      className="mb-0"
                      name={adv_area.id}
                      id={adv_area.id}
                      placeholder="0.30"
                      defaultValue={adv_area.value}
                      onChange={handleChange}
                      onFocus={(e) => (e.target.placeholder = "")}
                      onBlur={(e) => (e.target.placeholder = "0.30")}
                      min="0"
                      max="100"
                      step="0.01"
                      pattern="[0-9]*"
                      required
                    />
                    <span>%</span>
                  </div>
                  <div className="col-xl-4 col-lg-4 col-md-4 col-4 text-center">
                    <div className="default-title">
                      <p>
                        {formState[adv_area.id]
                          ? `£${2000 * formState[adv_area.id]}`
                          : adv_area.value?`£${2000 * adv_area.value}`
                          :"--"}
                      </p>
                    </div>
                  </div>
                </div>
              </>
            );
          })}
          <hr />
          <div className="row m-0">
            <div className="col-md-12 text-center mt-lg-4 mt-0">
              <button
                type="submit"
                className="theme-btn text-uppercase mr-md-3 mr-1 mb-md-0 mb-3"
              >
                Save
              </button>
            </div>
          </div>
        </div>
      </form>
    </>
  );
};

export default AdviserPrefrencesDefault;
