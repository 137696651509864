export const ADVISOR_DEFAULT_IMAGE: string =
  "assets/images/no-photo-available.png";
export const COMPANY_DEFAULT_IMAGE: string =
  "assets/images/my_accounts/wp_placeholder.png";
export const USER_DEFAULT_IMAGE: string =
  "assets/images/no-photo-available.png";
export const UTC_FORMAT: string = "YYYY-MM-DDTHH:mm:ss.SSS[Z]";
export const DEFAULT_CURRENCY: string = "£";
export const SPINNER_COLOR: string = "#2d7af1";
export const NO_DATA_FOUND: string = "No data found.";
export const NO_DECLINE_OFFERS_FOUND: string = "No declined offers";

export const DEFAULT_BLANK_DATE: string = "0000-00-00 00:00:00";
export const VERIFY_TO_ACCEPT: string = "to accept this offer.";
export const ADVISER_ROLE_1: string = "Mortgage_Adviser";
export const ADVISER_ROLE_2: string = "Contact_Administrator";

export const GENDER_ARR: any = ["Any", "Male", "Female"];
export const ADVICEAREA_STATUS_ARR: any = ["Inactive", "In Progress", "Closed"];
export const BID_STATUS_ARR: any = [
  "In Progress",
  "Accepted",
  "Closed",
  "Decline",
];
export const ADVISOR_BID_STATUS_ARR: any = [
  "In Progress",
  "Hired",
  "Closed",
  "Decline",
];

export const ADVISER_SEARCH_RANGE: any = [
  { value: 10, name: "10km" },
  { value: 25, name: "25km" },
  { value: 50, name: "50km" },
  { value: 100, name: "100km" },
  { value: -1, name: "National" },
];

export const LANGUAGES_ARR: any = [
  { value: "English", name: "English" },
  { value: "French", name: "French" },
  { value: "German", name: "German" },
  { value: "Hindi", name: "Hindi" },
  { value: "Italian", name: "Italian" },
  { value: "Polish", name: "Polish" },
  { value: "Punjabi", name: "Punjabi" },
  { value: "Spanish", name: "Spanish" },
  { value: "Urdu", name: "Urdu" },
];

export const MORTGAGE_ADVICE_ARR: any = [
  { key: "remortgage", value: "remortgage", name: "Remortgage" },
  { key: "first_buyer", value: "first time buyer", name: "First time buyer" },
  { key: "next_buyer", value: "next time buyer", name: "Next time buyer" },
  { key: "but_let", value: "buy to let", name: "Buy to let" },
  { key: "equity_release", value: "equity release", name: "Equity release" },
  { key: "overseas", value: "overseas", name: "Overseas" },
  { key: "self_build", value: "self build", name: "Self build" },
  {
    key: "mortgage_protection",
    value: "mortgage protection",
    name: "Mortgage protection",
  },
  { key: "secured_loan", value: "secured loan", name: "Secured loan" },
  { key: "bridging_loan", value: "bridging loan", name: "Bridging loan" },
  { key: "commercial", value: "commercial", name: "Commercial" },
  { key: "something_else", value: "something else", name: "Something else" },
  { key: "bridging_loan", value: "bridging loan", name: "Bridging loan" },
  { key: "commercial", value: "commercial", name: "Commercial" },
  { key: "something_else", value: "something else", name: "Something else" },
  { key: "bridging_loan", value: "bridging loan", name: "Bridging loan" },
  { key: "commercial", value: "commercial", name: "Commercial" },
  { key: "something_else", value: "something else", name: "Something else" },
  { key: "bridging_loan", value: "bridging loan", name: "Bridging loan" },
  { key: "commercial", value: "commercial", name: "Commercial" },
  { key: "something_else", value: "something else", name: "Something else" },
];

export const MORTGAGE_PRICE_ARR: any = [
  { key: "0_74", value: "0_74", name: "£0-£75k" },
  { key: "75_249", value: "75_249", name: "£75-£249k" },
  { key: "250_499", value: "250_499", name: "£250-£499k" },
  { key: "500_999", value: "500_999", name: "£500-£900k" },
  { key: "1m", value: "1m", name: "£1m+" },
];

export const MORTGAGE_PRICE_USEREND_ARR: any = [
  { key: "75", value: "75", name: "£0-£99k" },
  { key: "175", value: "175", name: "£100k-£249k" },
  { key: "325", value: "325", name: "£250k-£399k" },
  { key: "500", value: "500", name: "£400k-£599k" },
  { key: "800", value: "800", name: "£600k-£1m" },
  { key: "1000", value: "1000", name: "£1m+" },
];

export const MORTGAGE_TIME_ARR: any = [
  {
    key: "asap",
    value: "as soon as possible",
    name: "As soon as possible",
    href: "#sub5",
  },
  {
    key: "next_3_month",
    value: "in the next 3 months",
    name: "In the next 3 months",
    href: "#sub6",
  },
  {
    key: "more_3_month",
    value: "in more than 3 months",
    name: "In more than 3 months",
    href: "#sub7",
  },
];
