import React, { useContext, useEffect, useState } from "react";
import { getAdviceArea, getUserProfile } from "../components/common/service";
import ResendEmail from "../components/common/modals/ResendEmail";
import { NO_DATA_FOUND } from "../Constants";
import LoadingSpinner from "../components/common/LoadingSpinner";
import AdviceAreaRow from "../components/dashboardPage/AdviceAreaRow";
import { AuthContext } from "../shared/context/auth-context";
import RegisterInterest from "../components/common/modals/RegisterInterest";
import { Link } from "react-router-dom";
import RecentMessages from "../components/dashboardPage/RecentMessages";
import { getCurrentDateTime } from "../utility/commonUtils";
var isLoaded = false;
const UserDashboard = () => {
  const { user } = useContext(AuthContext);
  const [greetDatetime, setGreetDatetime] = useState<any>({
    greet: "",
    datetimestr: "",
  });
  const [userInfo, setUserInfo] = useState<any>({ id: 0 });
  const [adviceArea, setAdviceArea] = useState<object[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isAPICalled, setisAPICalled] = useState<boolean>(false);

  // useEffect(() => {
  //   setGreetDatetime(getCurrentDateTime());
  //   getAdviceAreaCall();
  // }, []);
  useEffect(() => {
    setGreetDatetime(getCurrentDateTime());
    getAdviceAreaCall();
    if(!isLoaded){
      isLoaded = true;
      setisAPICalled(true);
      
      if (user.id) {
        setUserInfo(user);
      } else {
        getUserProfileCall();
      }
    }
    
  }, []);

  const getUserProfileCall = async () => {
    setIsLoading(true);
    const res = await getUserProfile();
    if (res?.user) {
      setUserInfo(res.user);
    }
    setIsLoading(false);
  };

  const getAdviceAreaCall = async () => {
    const res = await getAdviceArea();
    if (res?.success || res.status) {
      setAdviceArea(res.data);
      
      // isLoaded = true;
    }
    setIsLoading(false);
  };

  return (
    <>
      <main className="dashboard">
        <section className="page-title">
          <div className="container">
            <div className="row">
              <div className="col-md-12 mb-5 mb-md-3">
                <div className="heading">
                  <h5 className="text-white text-uppercase">
                    {greetDatetime.greet}, {userInfo.name}
                  </h5>
                  <p>{greetDatetime.datetimestr}</p>
                </div>
              </div>
            </div>

            {userInfo.id > 0 && userInfo.email_status === 0 && (
              <div className="recent-box mb-4">
                <div className="row align-items-center">
                  <div className="col-lg-8">
                    <div className="required align-items-center">
                      <i className="fas fa-exclamation-triangle mr-3 mb-2 mb-md-0 text-center"></i>
                      Please activate your account so we can connect you with
                      Mortgage Advisers.
                    </div>
                  </div>
                  <div className="col-lg-4 mt-2 mt-lg-0 text-center text-md-right">
                    <a
                      href="javascript:void(0)"
                      data-toggle="modal"
                      data-target="#Resend-mail"
                      className="resend-link"
                    >
                      Resend activation mail
                    </a>
                  </div>
                </div>
              </div>
            )}

            {/* <div className="row">
              <div className="col-md-12 mb-5 mb-md-0">
                <div className="heading text-uppercase">
                  <h5 className="text-white">dashboard</h5>
                </div>
              </div>
            </div> */}
          </div>
        </section>

        <section
          className={`${
            userInfo.id > 0 && userInfo.email_status === 0
              ? "recent-sec"
              : "recent-sec-new"
          } pt-5`}
        >
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-12">
                <div className="recent-box">
                  <div className="row align-items-center justify-content-center mb-4">
                    <div className="col-lg-11">
                      <div className="row">
                        <div className="col-md-12">
                          <div className="sec-title">
                            <h2 className="m-0 theme-color recent-title">
                              Recent needs
                            </h2>
                          </div>
                        </div>
                        {/* <div className="col-md-6 text-center text-md-right">
                          <div className="sec-title mt-4 mt-md-0">
                            <a href="javascript:void(0)" className="theme-btn text-uppercase">
                              post a mortgage need
                            </a>
                          </div>
                        </div> */}
                      </div>
                    </div>
                  </div>

                  <LoadingSpinner loading={isLoading} />

                  {!isLoading && adviceArea.length ? (
                    <div className="row justify-content-center">
                      <div className="col-md-11 mt-2 table-strucure">
                        <div className="row main-heading text-uppercase">
                          <div className="col-md-3 col-6 data">
                            <span>Service</span>
                          </div>
                          <div className="col-md-3 col-6 data">
                            <span>need</span>
                          </div>
                          <div className="col-md-2 col-6 data">
                            <span>date</span>
                          </div>
                          <div className="col-lg-2 col-md-1 col-6 data">
                            <span>Offers</span>
                          </div>
                          <div className="col-md-2 col-6 data">
                            <span>status</span>
                          </div>
                        </div>

                        {adviceArea.map((advice: any) => (
                          <AdviceAreaRow key={advice?.id} advice={advice} />
                        ))}
                      </div>
                      {/* <div className="col-md-12 pt-3 text-center">
                        <div className="view-btn text-center">
                          <a href="javascript:void(0)" className="font-weight-bold">
                            View all
                          </a>
                        </div>
                      </div> */}
                    </div>
                  ) : (
                    <div className="col-md-12 pt-3 text-center">
                      <div className="view-btn text-center font-weight-bold">
                        {NO_DATA_FOUND}
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* Info: Client commented this on 21Jun2021 Feedback.20210621.docx  */}
        {/* <RecentMessages /> */}

        <section className="pt-5 pb-5">
          <div className="container">
            <div className="row different-boxes">
              <div className="col-lg-6 mb-5 mb-lg-0">
                <div className="different-box invite-box">
                  <div className="box-img mb-3">
                    <img
                      src="assets/images/refer.png"
                      alt="refer"
                      className="img-fluid"
                    />
                  </div>
                  <h6>Refer a friend</h6>
                  <span></span>
                  <p>
                    If you had a great experience using mortgagebox then let
                    your friends know. We don't offer any rewards but do promise
                    your friends the same great service.
                  </p>
                  <div className="text-right mt-4">
                    <Link
                      to={`/invite-now`}
                      className="theme-btn text-uppercase"
                    >
                      invite now
                    </Link>
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="different-box invite-box">
                  <div className="box-img mb-3">
                    <img
                      src="assets/images/expanding.png"
                      alt="expanding"
                      className="img-fluid"
                    />
                  </div>
                  <h6>We're expanding...</h6>
                  <span></span>
                  <p className="m-0">
                    We&#39;re expanding to cover other property related
                    professions such as Solicitor, Surveyor, Builder, Decorator.
                    We would love to have your input to help shape the future
                    direction of Mortgagebox.
                  </p>
                  <div className="text-right mt-4">
                    <a
                      href="javascript:void(0)"
                      data-toggle="modal"
                      data-target="#register_your_interest"
                      className="theme-btn text-uppercase"
                    >
                      register your interest
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>

      <ResendEmail />
      <RegisterInterest />
    </>
  );
};

export default UserDashboard;
