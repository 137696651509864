import React, { useEffect, useState, useRef } from "react";
import {
  getUserNotes,
  addNotes,
  UpdateNotes,
} from "../../components/common/service";
import LoadingSpinner from "../../components/common/LoadingSpinner";
import { useToasts } from "react-toast-notifications";
import moment from "moment";

const scrollToRef = (ref: any) => window.scrollTo(0, ref.current.offsetTop);
const initialState = {
  notes: "",
  advice_id: 0,
  id: 0,
};

const AdviceNotes = (props: any) => {
  
  const { addToast } = useToasts();
  const myRef = useRef(null);
  const executeScroll = () => scrollToRef(myRef);
  const advice_id = props.advice.id;

  const [userNotes, setUserNotes] = useState<object[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [formState, setFormState] = useState({
    ...initialState,
    advice_id: advice_id,
  });

  useEffect(() => {
    if (advice_id) {
      getUserNotesCall(advice_id);
    }
  }, []);

  const getUserNotesCall = async (advice_id: number) => {
    setIsLoading(true);
    const res = await getUserNotes(advice_id);
    if (res?.success || res?.status) {
      setUserNotes(res.data);
    }
    setIsLoading(false);
  };

  const handlePageState = (newStates: any) => {
    setFormState((prevStates) => {
      return {
        ...prevStates,
        ...newStates,
      };
    });
  };

  const handleNotesChange = (event: { target: HTMLInputElement } | any) => {
    handlePageState({ notes: event.target.value });
    event.preventDefault();
  };

  const handleAddNotes = async (e: any) => {
    e.preventDefault();
    let res;
    if (formState.id > 0) {
      res = await UpdateNotes(formState);
    } else {
      res = await addNotes(formState);
    }
    if (res.success || res.status) {
      addToast(res.message, { appearance: "success" });
      handlePageState({ notes: "", id: 0 });
      getUserNotesCall(advice_id);
    } else {
      addToast("Notes can not be blank", { appearance: "error" });
    }
  };

  const editNote = (note: any) => {
    handlePageState({
      notes: note.notes,
      advice_id: note.advice_id,
      id: note.id,
    });
    executeScroll();
  };

  return (
    <>
      <div className="col-lg-12 mb-5">
        <div className="recent-box">
          <div className="row justify-content-center">
            <div className="col-lg-11">
              <div className="row need-details mb-3">
                <div className="col-md-12">
                  <h2 className="mb-2">Note to self</h2>
                </div>
              </div>
              <div className="left-content mb-4 mb-lg-5">
                <LoadingSpinner loading={isLoading} />
                {!isLoading &&
                  userNotes.map((note: any) => (
                    <p key={note.id}>
                      <strong>{moment(note.created_at).format("ll")} -</strong>{" "}
                      {note.notes}
                      {"   "}
                      {props.advice.status === 1 && (
                        <i
                          onClick={() => editNote(note)}
                          className="editNote fal fa-pencil mr-2"
                        ></i>
                      )}
                    </p>
                  ))}
              </div>

              {props.advice.status === 1 && (
                <div className="add-form">
                  <form onSubmit={handleAddNotes}>
                    <div className="row mb-3">
                      <div className="col-12">
                        <textarea
                          id="notes-textarea"
                          name="notes"
                          value={formState.notes}
                          onChange={handleNotesChange}
                        ></textarea>
                      </div>
                    </div>
                    <div ref={myRef} className="row">
                      <div className="col-12 text-right">
                        <button className="theme-btn text-uppercase">
                          {formState.id > 0 ? (
                            <>
                              <i className="far fa-pencil mr-2" />
                              Save
                            </>
                          ) : (
                            <>
                              <i className="far fa-plus mr-2" />
                              Add
                            </>
                          )}
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AdviceNotes;
