import React, { useEffect, useState } from "react";
import { ADVISER_SEARCH_RANGE } from "../../../Constants";
import { AsyncTypeahead } from "react-bootstrap-typeahead";
import { searchPostalCode } from "../service";

const initialState = {
  postcode: "",
};
const EditLocationModal = (props: any) => {
  const handleChange = (event: { target: HTMLInputElement } | any) => {
    const { name, value } = event.target;
    props.handlePageState({ [name]: value });
  };
  const [isSeachLoading, setIsSeachLoading] = useState<boolean>(false);
  const [options, setOptions] = useState<any>([]);
  const [selectedOption, setselectedOption] = useState<any>([]);
  const [formState, setFormState] = useState(initialState);

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    props.onSubmit(e);
  };
  const handlePageState = (newStates: any) => {
    setFormState((prevStates: any) => {
      return {
        ...prevStates,
        ...newStates,
      };
    });
  };

  useEffect(() => {
    setTimeout(() => {
      $(".rbt-input-main").val(props.formState.post_code);
    },100);
  },[props.formState]);

  const handleSearch = async (query: any) => {
    setIsSeachLoading(true);
    const res = await searchPostalCode({ postal_code: query });
    if (res.status) {
      setOptions(res.data);
    }
    setIsSeachLoading(false);
  };

  const showSelectedValue = (event: any) => {
    setTimeout(() => {
      $(".rbt-input-main").val(props.formState.post_code);
    },100);
  };

  const handleChangeSearch = (event: any) => {
    if (event.length) {
      setselectedOption([event[0]]);
      handlePageState({ postcode: event[0].Postcode });
      props.formState.post_code = event[0].Postcode;
    } else {
      setselectedOption([]);
    }
  };
  const filterBy = () => true;

  return (
    <>
      <div
        className="mail-popup modal fade popup-model location_popup nutshell_popup about_company_popup"
        id="edit_location"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-body">
              <form onSubmit={handleSubmit}>
                <div className="row">
                  <div className="col-md-12">
                    <div className="location-title text-center mb-4">
                      <h4 className="text-uppercase theme-color">
                        WHERE WOULD YOU LIKE TO SEE LEADS FROM?
                      </h4>
                      <p>{props.formState.miles==-1?"Across all postcodes":props.locationInfo}</p>
                      <span className="border-bottom"></span>
                    </div>
                  </div>

                  <div className="col-md-12">
                    <div className="location_form">
                      <div className="row">
                        <div className="col-md-6">
                          <label>Postcode</label>
                          {/* <input
                            type="text"
                            id="postcode"
                            placeholder="TW12 2SF"
                            onFocus={(e) => (e.target.placeholder = "")}
                            onBlur={(e) => (e.target.placeholder = "TW12 2SF")}
                            name="post_code"
                            value={props.formState.post_code}
                            onChange={handleChange}
                            required
                          /> */}
                          <AsyncTypeahead
                            filterBy={filterBy}
                            id="async-example"
                            isLoading={isSeachLoading}
                            labelKey="Postcode"
                            minLength={2}
                            onSearch={handleSearch}
                            options={options}
                            defaultInputValue={props.formState.post_code}
                            onChange={handleChangeSearch}
                            onBlur={showSelectedValue}
                            selected={selectedOption}
                            placeholder="TW12 1AA"
                            className="postCodeSearchInnerAccount"
                            renderMenuItemChildren={(option: any, props: any) => (
                              <React.Fragment>
                                <span>{option?.Postcode}</span>
                              </React.Fragment>
                            )}
                          />
                        </div>
                        <div className="col-md-6">
                          <label>Distance</label>
                          <select
                            className="Distance"
                            name="miles"
                            value={props.formState.miles}
                            onChange={handleChange}
                          >
                            {/* <option value="-1">National</option> */}
                            {ADVISER_SEARCH_RANGE.map(
                              (range: { value: string; name: string }) => {
                                return (
                                  <option key={range.value} value={range.value}>
                                    {range.name}
                                  </option>
                                );
                              }
                            )}
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-12 mb-md-0 mb-3 mt-lg-4 mt-3 text-center">
                    <button
                      type="submit"
                      className="theme-btn text-uppercase mr-md-3 mr-1 mb-md-0 mb-3"
                    >
                      Continue
                    </button>
                    <button
                      type="button"
                      className="theme-btn-border text-uppercase"
                      data-dismiss="modal"
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EditLocationModal;
