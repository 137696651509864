import React, { useState, useEffect } from "react";
import { useToasts } from "react-toast-notifications";
import { prettyTime } from "../../utility/commonUtils";
import { getAdvisorLinks, setAdvisorLinks } from "../common/service";
const initialState = {
  web_address: "",
  facebook: "",
  twitter: "",
  linkedin_link: "",
  updated_at: "",
  web_address_update:"",
  fb_update:"",
  twitter_update:"",
  linkedin_update:"",
};

const AdviserProfileWebLinks = () => {
  const { addToast } = useToasts();
  const [formState, setFormState] = useState(initialState);

  const handlePageState = (newStates: any) => {
    setFormState((prevStates) => {
      return {
        ...prevStates,
        ...newStates,
      };
    });
  };

  useEffect(() => {
    getAdvisorLinksCall();
  }, []);
  // formState

  const getAdvisorLinksCall = async () => {
    const res = await getAdvisorLinks();
    if (res.data) {
      setFormState(res.data);
      handlePageState({
        web_address_update: res.data.web_address_update,
        web_address: res.data.web_address,
        fb_update: res.data.fb_update,
        facebook: res.data.facebook,
        twitter_update: res.data.twitter_update,
        twitter: res.data.twitter,
        linkedin_update: res.data.linkedin_update,
        linkedin_link: res.data.linkedin_link,
      });
    }
  };


  const onSubmitHandler = async (e: any) => {
    e.preventDefault();
    const res = await setAdvisorLinks({
      web_address: formState.web_address,
      facebook: formState.facebook,
      twitter: formState.twitter,
      linkedin_link: formState.linkedin_link,
    });
    if (res.status) {
      addToast(res.message, { appearance: "success" });
    } else {
      addToast(res.message, { appearance: "error" });
    }
  };

  return (
    <>
      <div className="tab-pane container active web-links-data">
        <div className="row">
          <div className="col-xl-12">
            <form onSubmit={onSubmitHandler}>
              <div className="pl-xl-5 pl-0 pr-xl-0 pr-0 mt-xl-3 mt-0 profile-datas offers-data">
                <div className="row">
                  <div className="col-12">
                    <h5 className="mb-3">Web links</h5>
                  </div>
                  <div className="col-md-6 order-md-1 order-1">
                    <p>
                      <img src="assets/images/my_accounts/link.png" alt="" />
                      Add a link to your company website
                    </p>
                  </div>
                  <div className="col-md-6 order-md-2 order-3 text-right dates">
                    <p>
                      Last edited:{" "}
                      {formState?.web_address_update}
                    </p>
                  </div>
                  <div className="col-12 order-md-3 order-2">
                    <input
                      type="text"
                      id="web_links"
                      // pattern="^((https?|ftp|smtp):\/\/)?(www.)?[a-z0-9]+\.[a-z]+(\/[a-zA-Z0-9#]+\/?)*$"
                      placeholder="www.bercrowadvisers.co.uk"
                      onFocus={(e) => (e.target.placeholder = "")}
                      onBlur={(e) =>
                        (e.target.placeholder = "www.bercrowadvisers.co.uk")
                      }
                      name="web_address"
                      value={formState.web_address}
                      onChange={(e) =>
                        handlePageState({
                          web_address: e.target.value,
                        })
                      }
                    />
                  </div>
                </div>

                <span className="borders text-center"></span>

                <div className="row pt-2">
                  <div className="col-12 mb-3">
                    <h5 className="mb-2">Social Media</h5>
                    <p>Add links to your existing company social media</p>
                  </div>
                  <div className="col-md-6">
                    <p>
                      <img src="assets/images/my_accounts/social2.png" alt="" />
                      Facebook address
                    </p>
                  </div>
                  <div className="col-md-6 text-right dates">
                    <p>
                      Last edited:{" "}
                      {formState?.fb_update}
                    </p>
                  </div>
                  <div className="col-12">
                    <input
                      type="text"
                      id="fb_link"
                      placeholder="https://www.facebook.com/"
                      onFocus={(e) => (e.target.placeholder = "")}
                      onBlur={(e) =>
                        (e.target.placeholder = "https://www.facebook.com/")
                      }
                      name="facebook"
                      value={formState.facebook}
                      onChange={(e) =>
                        handlePageState({
                          facebook: e.target.value,
                        })
                      }
                    />
                  </div>
                </div>
                <div className="row mt-3">
                  <div className="col-md-6">
                    <p>
                      <img src="assets/images/my_accounts/social4.png" alt="" />
                      Twitter username
                    </p>
                  </div>
                  <div className="col-md-6 text-right dates">
                    <p>
                      Last edited:{" "}
                      {formState?.twitter_update}
                    </p>
                  </div>
                  <div className="col-12">
                    <div className="linkedin-data">
                      <img src="assets/images/my_accounts/social1.png" alt="" />
                      <input
                        type="text"
                        id="twitter_uname"
                        placeholder="username"
                        onFocus={(e) => (e.target.placeholder = "")}
                        onBlur={(e) => (e.target.placeholder = "username")}
                        name="twitter"
                        value={formState.twitter}
                        onChange={(e) =>
                          handlePageState({
                            twitter: e.target.value,
                          })
                        }
                      />
                    </div>
                  </div>
                </div>
                <div className="row mt-3">
                  <div className="col-md-6">
                    <p>
                      <img src="assets/images/my_accounts/social3.png" alt="" />
                      Linkedin
                    </p>
                  </div>
                  <div className="col-md-6 text-right dates">
                    <p>
                      Last edited:{" "}
                      {formState?.linkedin_update}
                    </p>
                  </div>
                  <div className="col-12">
                    <div className="linkedin-data">
                      <img src="assets/images/my_accounts/social1.png" alt="" />
                      <input
                        type="text"
                        id="linkedin_uname"
                        placeholder="username"
                        onFocus={(e) => (e.target.placeholder = "")}
                        onBlur={(e) => (e.target.placeholder = "username")}
                        name="linkedin_link"
                        value={formState.linkedin_link}
                        onChange={(e) =>
                          handlePageState({
                            linkedin_link: e.target.value,
                          })
                        }
                      />
                    </div>
                  </div>
                </div>

                <div className="row mt-xl-4 mt-3">
                  <div className="form-group col-md-12 text-center mt-lg-4 mt-0">
                    <button
                      type="submit"
                      className="theme-btn text-uppercase mr-md-3 mr-1 mb-md-0 mb-3"
                    >
                      Save
                    </button>
                    <button
                      type="button"
                      className="theme-btn-border text-uppercase"
                      data-dismiss="modal"
                      aria-label="Cancle"
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default AdviserProfileWebLinks;
