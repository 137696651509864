// GoogleAnalytics.ts

declare global {
    interface Window {
      dataLayer: any[];
      gtag: (...args: any[]) => void;
    }
  }
  
  export const initGoogleAnalytics = () => {
    // Replace 'G-YFYJR4EG3T' with your Google Analytics tracking ID
    const GA_TRACKING_ID = 'AW-16550290653';//'G-YFYJR4EG3T';
  
    window.dataLayer = window.dataLayer || [];
    function gtag(...args: any[]) {
      window.dataLayer.push(...args);
    }
    window.gtag = gtag;
  
    gtag('js', new Date());
    gtag('config', GA_TRACKING_ID);
  };
  