import React from "react";
import { prettyTime } from "../../utility/commonUtils";
import $ from "jquery";
import "bootstrap";

const AdviserProfileReviewsSingle = (props: any) => {
  const getStarColor = (rating: number) => {
    switch (rating) {
      case 1:
        return "red";
      case 2:
        return "yellow";
      case 3:
        return "yellow";
      case 4:
        return "green";
      case 5:
        return "green";

      default:
        break;
    }
  };

  const showStarRatings = (rating: number) => {
    let element = "p";
    let ratingColor = getStarColor(rating);
    if (props.isPublic) {
      element = "span";
      ratingColor = "checked";
    }
    let starData = "";
    for (let index = 1; index <= 5; index++) {
      starData += `<${element} class="fa fa-star ${
        rating >= index ? ratingColor : ""
      }" aria-hidden="true"></${element}>`;
    }
    if (props.isPublic) {
      starData += `<span class="review-text ml-3">${props.review?.review_title}</span>`;
    }
    return starData;
  };

  const handleChange = (event: { target: HTMLInputElement } | any) => {
    props.handleReplySpam(props?.review?.id);
    const modalId = event.target.value;
    if (modalId) {
      $("#" + modalId).modal("show");
    }
    event.preventDefault();
  };

  return (
    <>
      {/* isPublic are from Advisor public profile page */}
      {props.isPublic ? (
        <>
          <div className="row mb-3">
            <div className="col-lg-12 mb-3">
              <div className="review-name d-flex align-items-center">
                <h5 className="m-0 mr-4">
                  {props.review?.reviewer_name || props.review?.name}
                </h5>{" "}
                {props.review?.mortgage_type ? (
                  <span>({props.review?.mortgage_type})</span>
                ) : null}
              </div>
            </div>
            <div className="col-md-6 mb-3">
              <div
                className={
                  Math.round(Number(props.review?.rating)) > 0
                    ? `review-star review-star-rating-${Math.round(
                        Number(props.review?.rating)
                      )}`
                    : "review-star review-star-rating-0"
                }
                dangerouslySetInnerHTML={{
                  __html: showStarRatings(props.review?.rating),
                }}
              ></div>
            </div>
            <div className="col-md-6 mb-3 text-md-right">
              <span className="text-uppercase">
                {prettyTime(props.review?.created_at, false, true)}
              </span>
            </div>
            <div className="col-lg-12 mb-3">
              <p>{props.review?.reviews}</p>
            </div>
            {props.review?.is_replied==1 ?<div className="col-lg-12 mb-3">
            <div className="reply-content">
              <p className="mb-0">{props.review?.reply}</p>
            </div>
          </div>:""}
          </div>
        </>
      ) : (
        <>
          <div className="reviewdata mt-3">
            <div className="row">
              <div className="col-lg-9 col-md-8 col-7">
                <ul className="pl-0 ml-0 mb-0 name-days">
                  <li>
                    <h5 className="mb-0">
                      {props.review?.reviewer_name || props.review?.name} 
                      {props.review.area_id!=0 ? ` (${props?.review?.area?.service?.name})` : ""}
                    </h5>
                    <p className="mb-0">
                      {props.review?.mortgage_type
                        ? `(${props.review?.mortgage_type})`
                        : null}
                    </p>
                  </li>
                  <li>
                    <p>{prettyTime(props.review?.created_at, false, true)}</p>
                  </li>
                </ul>
              </div>
              <div className="col-lg-3 col-md-4 col-5 mb-md-0 mb-3 text-right">
                <div className="replay-btn">
                  <div className="select-data">
                    <select className="change_option" onChange={handleChange}>
                      <option value="">Action</option>
                      {props.review?.is_replied==0 ?<option value="reply_review">REPLY</option>:""}
                      <option value="mark_as_spam">SPAM</option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-12 text-left">
                <div
                  className="star mb-1 text-center d-inline-block"
                  dangerouslySetInnerHTML={{
                    __html: showStarRatings(props.review?.rating),
                  }}
                ></div>
                <p className="ml-2 d-inline-block">
                  {props.review?.review_title}
                </p>
                {props.review?.is_invited==1?
                <p className="ml-2 d-inline-block" style={{"color": "#8d8d8d"}}>
                  Invited <i className="fa fa-check" style={{"color": "green"}} aria-hidden="true"></i>
                </p>:null}
              </div>
              <div className="col-12">
                <p>{props.review?.reviews}</p>
              </div>
              {props.review?.is_replied==1 ?<div className="col-12 mb-3">
                <div className="reply-content display-reply">
                  <p className="mb-0 reply-name">{props.review?.reply}</p>
                  <p className="mb-0 reply-date">{props.review?.replied_on}</p>
                </div>
              </div>:""}
            </div>
            <div className="row">
              <div className="col-md-10 offset-md-1 col-12">
                <span className="borders text-center"></span>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default AdviserProfileReviewsSingle;
