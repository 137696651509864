import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";


const FaqInner = () => {
  const getFaqList =  () => {
    const currentfaq = JSON.parse(localStorage.getItem('faqList') || '{}');
  return currentfaq.lists;
};
const getFaqCategory =  () => {
  const currentfaq = JSON.parse(localStorage.getItem('faqList') || '{}');
  return currentfaq;
};
  const [faqCat, setCategory] = useState<any>(getFaqCategory());
  const [faqPopular, setFaq] = useState<any>(getFaqList());
  const [isLoading, setIsLoading] = useState<boolean>(false);
  


  return (
    <>
      <main>
        <section className="page-title">
          <div className="container">
            <div className="row">
              <div className="col-md-12 text-center">
                <div className="heading">
                  <h2>Frequently Asked Questions</h2>
                  <ul className="breadcrumbs">
                    <li>
                      <Link to={`/faq`}>FAQs</Link>
                    </li>
                    <li>{faqCat.name}</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="single-faq pt-5 pb-5">
          <img
            src="assets/images/left-shape.png"
            alt="help-1"
            className="img-fluid-left after-img"
          />
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-7 text-left mb-4">
                <div className="heading">
                  <h2 className="text-uppercase">popular</h2>
                  <span className="left-divider"></span>
                </div>
              </div>
              <div className="col-lg-7">
                <div id="accordion">
                {faqPopular.length?
                (faqPopular.map(
                (row: any, index: any) =>
                  <div className="card">
                    <div className="card-header">
                      <a
                        className="collapsed card-link"
                        data-toggle="collapse"
                        href={"#faq_" + row.id}
                      >
                        {row.question}
                      </a>
                    </div>
                    <div
                      id={"faq_" + row.id}
                      className="collapse"
                      data-parent="#accordion"
                    >
                      <div className="card-body" dangerouslySetInnerHTML={{__html: row.answer}}>
                      </div>
                    </div>
                  </div>
                  )):
                  <div className="col-lg-6">
                      <p>Popular faq not found</p>
                    </div>
      }
                </div>
              </div>
            </div>
          </div>
          <img
            src="assets/images/right-shape.png"
            alt="help-1"
            className="img-fluid before-img"
          />
        </section>
      </main>
    </>
  );
};

export default FaqInner;
