import React from "react";

const NoBidsClosed = () => {
  return (
    <>
      <div className="col-lg-12">
        <div className="recent-box">
          <div className="row justify-content-center">
            <div className="col-12">
              <div className="row align-items-center version2-data">
                <div className="col-xl-7 col-lg-6">
                  <div className="data heading">
                    <h2>What's happening?</h2>
                    <span></span>
                    <p>
                      No offers received.
                    </p>
                  </div>
                </div>
                <div className="col-xl-5 col-lg-6 text-center">
                  <img
                    src="assets/images/v2_img.png"
                    alt="v2_img"
                    className="img-fluid"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default NoBidsClosed;
