import React, { useContext } from "react";
import { Route, Redirect } from "react-router-dom";
import { AuthContext } from "../shared/context/auth-context";
import useLoggedInUserData from "../utility/useLoggedInUserData";

const PrivateRoute: React.FC<{
  component: React.FC;
  path: string;
  role?: number;
  exact: boolean;
}> = (props) => {
  const { isLoggedIn } = useContext(AuthContext);
  const user = useLoggedInUserData();
 console.log(user)
  let isAccess = false;
  if (props.role === undefined) {
    isAccess = true;
  } else if (props?.role === user?.user_role) {
    isAccess = true;
  }

  return isLoggedIn && isAccess ? (
    <Route path={props.path} exact={props.exact} component={props.component} />
  ) : (
    <Redirect to="/" />
  );
};
export default PrivateRoute;
