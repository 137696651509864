import React from "react";

const PricingDetails = () => {
  return (
    <>
      <main className="need-offers">
        <section className="page-title"></section>

        <section className="recent-sec advice-area-sec directory-sec pricing-sec pt-5 pb-5">
          <img
            src="assets/images/expert-right.png"
            alt="help-1"
            className="img-fluid after-img"
          />
          <div className="container">
            <div className="row justify-content-center ">
              <div className="col-lg-12">
                <div className="recent-box">
                  <div className="row justify-content-center">
                    <div className="col-12">
                      <form>
                        <div className="row align-items-center version2-data close-reminder-sec text-center">
                          <div className="col-xl-6 offset-xl-3 col-lg-6 offset-lg-3 col-md-8 offset-md-2">
                            <div className="heading">
                              <h2 className="no-space">Cost Structure</h2>
                              <p>
                                You choose and only pay for the leads you want.
                                Costs are based on the size of the mortgage.
                              </p>
                              <span></span>
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-lg-8 offset-lg-2 col-md-10 offset-md-1">
                            <div className="row mt-md-2 mt-3">
                              <div className="col-12">
                                <h5>MORTGAGE ADVISER</h5>
                              </div>
                            </div>
                            <div className="pricing-structure main">
                              <div className="row">
                                <div className="col-md-8 col-6">
                                  <h6>Mortgage Size</h6>
                                </div>
                                <div className="col-md-4 col-6">
                                  <h6>Cost per lead</h6>
                                </div>
                              </div>
                            </div>
                            {/* <div className="pricing-structure">
                              <div className="row">
                                <div className="col-md-8 col-6">
                                  <p>£0 - £74,999</p>
                                </div>
                                <div className="col-md-4 col-6">
                                  <p>Free</p>
                                </div>
                              </div>
                            </div> */}
                            <div className="pricing-structure">
                              <div className="row">
                                <div className="col-md-8 col-6">
                                  <p>£0 - £999,999</p>
                                </div>
                                <div className="col-md-4 col-6">
                                  <p>£0 - £60 (0.006%)</p>
                                </div>
                              </div>
                            </div>
                            <div className="pricing-structure">
                              <div className="row">
                                <div className="col-md-8 col-6">
                                  <p>£1,000,000+</p>
                                </div>
                                <div className="col-md-4 col-6">
                                  <p>Capped at £60</p>
                                </div>
                              </div>
                            </div>
                            <div className="row mt-xl-5 mt-4">
                              {/* <div className="col-12">
                                <h5>EARLY BIRD</h5>
                                <p>
                                  A premium service that exists only for the
                                  first hour a new deal goes live helping you
                                  source better leads and making it less likely
                                  you miss out.
                                </p>
                                <p>
                                  Cost per lead are increased by <br />
                                  <span className="d-block ml-5">
                                    - 50% for the first hour
                                  </span>
                                </p>
                              </div> */}
                            </div>
                            <div className="row mt-xl-5 mt-4">
                              <div className="col-12">
                                <h5>DISCOUNTS</h5>
                                <p>
                                  We hate unfilled slots as it means customers
                                  have less choice. We therefore discount big
                                  and early! Good for you and good for the
                                  customer.
                                </p>
                                <p>
                                  Cost per lead are reduced by <br />
                                  <span className="d-block ml-5">
                                    - 50% after 24 hours{" "}
                                  </span>
                                  <span className="d-block ml-5">
                                    - 75% after 48 hours{" "}
                                  </span>
                                  <span className="d-block ml-5">
                                    - 100% after 72 hours (yes totally free){" "}
                                  </span>
                                </p>
                                <p>
                                  Leads are removed after five advisers have
                                  been introduced or they are older than two
                                  weeks.
                                </p>
                              </div>
                            </div>
                            <div className="row mt-xl-5 mt-4 pt-xl-5 pt-0 text-center bottom-text">
                              <div className="col-12">
                                <p>Prices shown are exclusive of VAT</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <img
            src="assets/images/expert-left.png"
            alt="help-1"
            className="img-fluid before-img"
          />
        </section>
      </main>
    </>
  );
};
export default PricingDetails;
