import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";


const FaqAdviserRegistrationStepByStepGuide = () => {
  
  const [isLoading, setIsLoading] = useState<boolean>(false);
  
  return (
    <>
      <main>
        <section className="page-title">
          <div className="container">
            <div className="row">
              <div className="col-md-12 text-center">
                <div className="heading">
                  <h2>Frequently Asked Questions</h2>
                  <ul className="breadcrumbs">
                    <li>
                      <Link to={`/faq`}>FAQs</Link>
                    </li>
                    <li>ADVISER REGISTRATION GUIDE</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="single-faq pt-5 pb-5">
          <img
            src="assets/images/left-shape.png"
            alt="help-1"
            className="img-fluid-left after-img"
          />
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-8 text-left mb-4">
                <div className="heading">
                  <h2 className="text-uppercase">ADVISER REGISTRATION GUIDE</h2>
                  <span className="left-divider"></span>
                </div>
              </div>
              <div className="col-lg-8">
                {/* <div className="text-center">
                  <img src="assets/faq/faq-logo.png"  />  
                </div>
                <p style={{"marginTop":"0in","marginRight":"0in","marginBottom":"8pt","marginLeft":"0in","fontSize":"11pt","fontFamily":"'Calibri', sansSerif","textAlign":"center"}}>&nbsp;</p> */}

                {/* <p style={{"marginTop":"0in","marginRight":"0in","marginBottom":"8pt","marginLeft":"0in","fontSize":"11pt","fontFamily":"'Calibri', sansSerif","textAlign":"center"}}>
                    <strong><span style={{"fontSize":"27px","lineHeight":"107%"}}>Adviser Registration &ndash; StepByStep guide</span></strong>
                </p> */}

                <p>
                    Welcome to MortgageBox.co.uk, where we give customers more options, better deals, and a higher acceptance rate by putting them in contact with 5 FCA registered mortgage advisers.&nbsp;
                </p>

              <p>
                In the next few pages, we walk you through the steps to register. A second guide ‘Creating a profile’ will then help you set up a professionally looking profile.
              </p>
              <p><strong>First, why sign-up?</strong></p>

              <ul style={{"listStyleType":"disc","marginLeft":"0in"}}>
                  <li style={{"display":"list-item","listStyle":"disc","paddingBottom":"7px"}}><strong>High-quality Leads at Your Fingertips:</strong> Directly connect with active borrowers looking for mortgages.</li>
                  <li style={{"display":"list-item","listStyle":"disc","paddingBottom":"7px"}}><strong>Total Control, Zero Risk</strong>: Enjoy the freedom to review all incoming leads without any cost. Pay only for the ones you choose, ensuring every investment counts.</li>
                  <li style={{"display":"list-item","listStyle":"disc","paddingBottom":"7px"}}><strong>Maximized Opportunities, Minimized Competition</strong>: Each lead is shared with a maximum of only 5 advisors, enhancing your chances of success.</li>
                  <li style={{"display":"list-item","listStyle":"disc","paddingBottom":"7px"}}><strong>Risk-free Trial</strong>: Experience MortgageBox with no commitments  your first 5 leads are on us!</li>
                  <li style={{"display":"list-item","listStyle":"disc","paddingBottom":"7px"}}><strong>Big discounts for older leads</strong>: 50% after 24 hours, 75% after 48 hours, Free after 72 hours.</li>
                  <li style={{"display":"list-item","listStyle":"disc","paddingBottom":"7px"}}><strong>Not just another lead list</strong>:Real-time leads you choose, matched to your preferences and customers are directly contactable.</li>
              </ul>

              <strong style={{"fontSize":"18px","lineHeight":"107%"}}><span>1. Registration</span></strong>
              
              <ul className="decimal_type" style={{"listStyleType":"undefined","marginTop":"0.1in","paddingLeft":"0","float":"left","width":"100%"}}>
                  <li>
                    <strong><span style={{"fontSize":"14px"}}>1.1 Pre-requisite</span></strong>
                  </li>
              </ul>
              <p>
                  Mortgage Advisers must be FCA registered. When you register you will need your FCA number to hand as this will be verified before letting you purchase leads and contact customers. &nbsp;&nbsp;
              </p>
              
              <ul className="decimal_type" style={{"listStyleType":"undefined","marginTop":"0.25in","paddingLeft":"0","float":"left","width":"100%"}}>
                  <li>
                    <strong><span style={{"fontSize":"14px"}}>1.2 Navigate to the adviser home page</span></strong>
                  </li>
              </ul>
              <p>
                  <span>From mortgagebox.co.uk either click&nbsp;</span><a href="https://mortgagebox.co.uk/adviser"><span>Are you an adviser?</span></a>
                  <span>&nbsp;or directly enter&nbsp;</span><a href="https://mortgagebox.co.uk/adviser"><span>https://mortgagebox.co.uk/adviser</span></a>
              </p>
              <p>
                <span style={{"float":"left","width":"100%","textAlign":"center"}}>
                  <img src="assets/faq/1.1.png" style={{"border":"2px solid #ddd"}} />
                </span>
              </p>
              
              <ul className="decimal_type" style={{"listStyleType":"undefined","marginTop":"0.25in","paddingLeft":"0","float":"left","width":"100%"}}>
                  <li>
                    <strong><span style={{"fontSize":"14px"}}>1.3 Sign Up</span></strong>
                  </li>
              </ul>
              <p>
                  <span>From the adviser home page, you can check out features and pricing models. Assuming you are happy the next step is to Sign Up.</span>
              </p>
              <p><span>Click the button &ldquo;Sign Up&rdquo;</span></p>
              <p>
                <span style={{"float":"left","width":"100%","textAlign":"center"}}>
                  <img src="assets/faq/1.2.png" style={{"border":"2px solid #ddd"}} />
                </span>
              </p>
              
              <ul className="decimal_type" style={{"listStyleType":"undefined","marginTop":"0.25in","paddingLeft":"0","float":"left","width":"100%"}}>
                  <li>
                    <strong><span style={{"fontSize":"14px"}}>1.4 What area do you cover?</span></strong>
                  </li>
              </ul>
              <p>
                  <span>Either enter &lsquo;National&rsquo; or a radius from your office location postcode. Then click &lsquo;Next&rsquo;.</span>
              </p>
              <p>
                  <em>
                      <span style={{"fontSize":"16px","lineHeight":"107%"}}>
                          Initially, we will be targeting specific areas of the country, more than others, to allow performance to be closely monitored. Therefore we would recommend that you choose &lsquo;National&rsquo; &ndash; this can be changed at
                          any time.
                      </span>
                  </em>
              </p>
              <p>
                <span style={{"float":"left","width":"100%","textAlign":"center"}}>
                  <img src="assets/faq/1.3.png" style={{"border":"2px solid #ddd"}} />
                </span>
              </p>
              
              <ul className="decimal_type" style={{"listStyleType":"undefined","marginTop":"0.25in","paddingLeft":"0","float":"left","width":"100%"}}>
                  <li>
                    <strong><span style={{"fontSize":"14px"}}>1.5 Now tell us about yourself</span></strong>
                  </li>
              </ul>

              <p>
                  <span>
                      We just need your name, email, FCA number along with a password. Optionally you can enter a company name. Please make sure you double-check your FCA number to avoid delays in Sign Up. Once you are happy click &lsquo;See
                      leads&rsquo;.
                  </span>
              </p>
              <p>
                  <em><span>We also need you to confirm that you have read and agreed to the terms and conditions and privacy policy.</span></em>
              </p>
              <p>
                <span style={{"float":"left","width":"100%","textAlign":"center"}}>
                  <img src="assets/faq/1.4.png" style={{"border":"2px solid #ddd"}} />
                </span>
              </p>
              <ul className="decimal_type" style={{"listStyleType":"undefined","marginTop":"0.25in","paddingLeft":"0","float":"left","width":"100%"}}>
                  <li>
                    <strong><span style={{"fontSize":"14px"}}>1.6 That&rsquo;s it, you are now registered!</span></strong>
                  </li>
              </ul>
              <p>
                  <span>
                      You are now registered but before we let you contact customers, we first need you to confirm your email address and secondly, we require up to 24 hours to check your FCA registration.
                  </span>
              </p>
              <p>
                  <span>In the meantime, you can check out live leads (we launch April 2024, until then you will see test leads) and set up your profile.</span>
              </p>
              <p>
                  <span>The page below is our live leads page, which you are taken to following registration. The key areas of the page are:</span>
              </p>
              
              
              <ul>
                  <li>
                      <strong><span>Navigation menu</span></strong>
                  </li>
              </ul>

              <p style={{"marginTop":"0in","marginRight":"0in","marginBottom":"8pt","marginLeft":"0.5in"}}>
                  <span style={{"color":"#002060"}}>Allows you to switch between your Dashboard, Live leads, Purchased leads and My Account.</span>
              </p>
              <ul>
                  <li>
                      <strong><span>Warnings</span></strong>
                  </li>
              </ul>
              <p style={{"marginTop":"0in","marginRight":"0in","marginBottom":"8pt","marginLeft":"0.5in"}}>
                  <span style={{"color":"#002060"}}>Normally you will not see this area. However, after first registering you will see warnings about email and FCA number verifications being outstanding.</span>
              </p>
              <ul>
                  <li>
                      <strong><span>RealTime lead list</span></strong>
                  </li>
              </ul>
              <p style={{"marginTop":"0in","marginRight":"0in","marginBottom":"8pt","marginLeft":"0.5in"}}>
                  <span style={{"color":"#002060"}}>Shows a summary of all leads that meet your filter criteria (more on that later). Click on a lead and further details are shown in Area 4.</span>
              </p>
              <ul>
                  <li>
                      <strong><span>Mortgage details</span></strong>
                  </li>
              </ul>
              <p style={{"marginTop":"0in","marginRight":"0in","marginBottom":"8pt","marginLeft":"0.5in"}}>
                  <span style={{"color":"#002060"}}>This area shows additional detail on the selected lead, including location, estimated lead value and cost of the lead in Area 5.</span>
              </p>
              <ul>
                  <li>
                      <strong><span>Lead cost</span></strong>
                  </li>
              </ul>
              <p style={{"marginTop":"0in","marginRight":"0in","marginBottom":"8pt","marginLeft":"0.5in"}}>
                  <span style={{"color":"#002060"}}>
                      The cost of a lead will vary depending on the mortgage size and time that the lead has been listed. A lead will be listed until either 5 advisers have been introduced or 14 days have passed. Each day that passes and the lead remains
                      available, the cost of the lead will halve. After three days the lead becomes free.
                  </span>
              </p>
              <ul>
                  <li>
                      <strong><span>Available introductions</span></strong>
                  </li>
              </ul>
              <p style={{"marginTop":"0in","marginRight":"0in","marginBottom":"8pt","marginLeft":"0.5in"}}>
                  <span style={{"color":"#002060"}}>Up to 5 mortgage advisers will be introduced to a customer. This area shows how many slots have been taken, which in the example below is one.</span>
              </p>
              <p>
                <span style={{"float":"left","width":"100%","textAlign":"center"}}>
                  <img src="assets/faq/1.5.png" style={{"border":"2px solid #ddd"}} />
                </span>
              </p>

              <ul className="decimal_type" style={{"listStyleType":"undefined","marginTop":"0.25in","paddingLeft":"0","float":"left","width":"100%"}}>
                  <li>
                    <strong><span style={{"fontSize":"14px"}}>1.7 The next step is to confirm your email address</span></strong>
                  </li>
              </ul>
              
              <p style={{"marginTop":"0in","marginRight":"0in","marginBottom":"8pt","marginLeft":"0in"}}>
                  <span>An email will be sent to you. Open the email and click the &ldquo;Verify&rdquo; button to confirm your email address.</span>
              </p>
              
              <p>
                <span style={{"float":"left","width":"100%","textAlign":"center"}}>
                  <img src="assets/faq/1.6.png" style={{"border":"2px solid #ddd"}} />
                </span>
              </p>
              
              <p style={{"marginTop":"20px","marginRight":"0in","marginBottom":"8pt","marginLeft":"0in","float":"left","width":"100%"}}>
                  <strong><span>I can&rsquo;t see an email from you?</span></strong>
              </p>
              <p style={{"marginTop":"0in","marginRight":"0in","marginBottom":"8pt","marginLeft":"0in"}}><span>There are a number of steps to take.</span></p>
              <div style={{"marginTop":"0in","marginRight":"0in","marginBottom":"8pt","marginLeft":"0in"}}>
                  <ol style={{"marginBottom":"0in","listStyleType":"lowerAlpha"}}>
                      <li style={{"marginTop":"0in","marginRight":"0in","marginBottom":"8pt","marginLeft":"0in"}}>
                          <span>Check your spam folder and add&nbsp;</span><a href="mailto:no-reply@mortgagebox.co.uk"><span>noReply@mortgagebox.co.uk</span></a>
                          <span>&nbsp;to your contacts.</span>
                      </li>
                      <li style={{"marginTop":"0in","marginRight":"0in","marginBottom":"8pt","marginLeft":"0in"}}>
                          <span>Resend the registration email &ndash; Click &lsquo;Resend activation mail&rsquo;  see step 1.6, link is immediately below (2).</span>
                      </li>
                      <li style={{"marginTop":"0in","marginRight":"0in","marginBottom":"8pt","marginLeft":"0in"}}>
                          <span>Check your email address is correct by navigating to &lsquo;My Account&rsquo; and &lsquo;Account Details&rsquo; or click&nbsp;</span>
                          <a href="https://mortgagebox.co.uk/adviser/my-account/general-info"><span>https://mortgagebox.co.uk/adviser/my-account/general-info</span></a>
                          <span>.</span>
                      </li>
                  </ol>
              </div>
              <ul className="decimal_type" style={{"listStyleType":"undefined","marginTop":"0.25in","paddingLeft":"0","float":"left","width":"100%"}}>
                  <li>
                    <strong><span style={{"fontSize":"14px"}}>1.8 FCA registration check</span></strong>
                  </li>
              </ul>
              <p>
                  <span>
                      The final step is for us to verify your FCA registration number which can take up to 24 hours. Once verified you will have full access to mortgagebox.co.uk including the ability to purchase leads. Your first 5 leads are on us&nbsp;
                  </span>
                  <span>😊</span>
              </p>
              <p>
                <span style={{"float":"left","width":"100%","textAlign":"center"}}>
                  <img src="assets/faq/1.7.png" style={{"border":"2px solid #ddd"}} />
                </span>
              </p>
              </div>
            </div>
          </div>
          <img
            src="assets/images/right-shape.png"
            alt="help-1"
            className="img-fluid before-img"
          />
        </section>
      </main>
    </>
  );
};

export default FaqAdviserRegistrationStepByStepGuide;
