import React, { useEffect, useState } from "react";
import $ from "jquery";
import "bootstrap";
import MortgageCard from "../../components/adviser/MortgageCard";
import MortgageCardDetails from "../../components/adviser/MortgageCardDetails";
import LoadingSpinner from "../../components/common/LoadingSpinner";
import {
  advisorMatchedLeads,
  getAdviserUserProfile,
  getNeedDetails,
  markAreaAsRead,
  getServiceType,
  searchMortgageNeeds,
  getCountFilter,
  getMortgageSize,
  getServiceTypeWithAuth,
} from "../../components/common/service";
import { prettyTimeAgo } from "../../utility/commonUtils";
import { ADVISER_LEADS_FILTER, LOGGEDIN_USER, NO_DATA_FOUND,ADVISER_LEADS_MATCHES,
  MORTGAGE_ADVICE_ARR,
  MORTGAGE_PRICE_ARR, 
  ADVISER_PREFERENCES_URL} from "../../Constants";
import { Link, useHistory } from "react-router-dom";
import useLoggedInUserData from "../../utility/useLoggedInUserData";
import ResendEmail from "../../components/common/modals/ResendEmail";
import { Button } from "bootstrap";
var currentDate = new Date();



const AdviserLeadsMatches = (props: any) => {
  var currentDate = new Date();
  let timer: any;
  let initialState = {
    search: "",
    status: Array(),
    promotion: Array(),
    advice_area: Array(),
    mortgage_value: Array(),
    lead_submitted: Array(),
    fees_preference: Array(),
    unread:0,
    read:0,
    notintrested:0,
    none_promotion:0,
    fifty_promotion:0,
    seventyfive_promotion:0,
    free_promotion:0,
    anytime:0,
    last_1_hour:0,
    today:0,
    yesterday:0,
    less_3_days:0,
    less_3_week:0,
    no_fee:0,
    would_consider:0,
    page :1,
  };
  const [lastFeatched, setLastFeatched] = useState<any>(
    prettyTimeAgo(currentDate.toString())
  );
  timer = setInterval(() => {
    setLastFeatched(prettyTimeAgo(currentDate.toString()));
  }, 1000 * 60 * 15);

 
  const [leads, setLeads] = useState<object[]>([]);
  const [formState, setFormState] = useState(initialState);
  const [page, setPage] = useState<number>(1);
  const [searchpage, setSearchPage] = useState<number>(1);
  const [hasMorePage, setHasMorePage] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [selectedLead, setSelectedLead] = useState<object>({});

  const queryParams = new URLSearchParams(window.location.search);
  const accepted_id = queryParams.get("accepted_id");
  const service_id = queryParams.get("service_id");
  const time = queryParams.get("time");
  const discount = queryParams.get("discount");

  const history = useHistory();
  const [serviceType, setService] = useState<any>([]);
  const [isSearched, setIsSearched] = React.useState<boolean>(false);
  const [countData, setCountData] = useState<any>({});
  const [totalLeads, settotalLeads] = useState<number>(0);
  const [mortgageSize, setMortgageSize] = useState<any>([]);
  const [showHideFilter, setShowHideFilter] = useState<boolean>(false);
  const [apiLoaded, setApiLoaded] = useState<boolean>(false);
  const [isSearchAPiCall, setIsSearchAPiCall] = useState<boolean>(false);
  const handlePageState = (newStates: any) => {
    setFormState((prevStates: any) => {
      return {
        ...prevStates,
        ...newStates,
      };
    });
  };
  
  const user = useLoggedInUserData();
  useEffect(() => {
    
    timer = setInterval(() => {
      setLastFeatched(prettyTimeAgo(currentDate.toString()));
    }, 100 * 60 * 15);
    // Clear timeout if the component is unmounted
    return () => clearInterval(timer);
  });

  const getUserProfileCall = async () => {
    setIsLoading(true);
    const res = await getAdviserUserProfile();
    if (res?.user) {
      if(res.user.email_status==1){
        user.email_status = 1;
      }
      localStorage.setItem(LOGGEDIN_USER, JSON.stringify(res.user));
    }
    if (res?.user_services) {
      formState.advice_area = res?.user_services;
    }
    if (res?.user_perferce) {
      formState.fees_preference = res?.user_perferce;
    }
    // setIsLoading(false);
  };
  

  useEffect(() => {
    getUserProfileCall();
    getMortgageSizeData();
    getServices();
    getCount();
    setIsLoading(true);
    setApiLoaded(true);
    if (props.location.state && props.location.state?.filteredLeads) {
      if (!isLoading) {
        setLeads(props.location.state.filteredLeads);
        setSelectedLead(props.location.state.filteredLeads[0]);
      }
    }
  }, [props.location.state]);

  useEffect(() => {
    if (
      !props.location.state?.filteredLeads ||
      props.location.state?.filteredLeads.lengh === 0
    ) { 
      advisorMatchedLeadsCall(page);
    }
  }, []);

  const loadMoreLeads = async () => {    
    if(isSearchAPiCall){
        formState.page = searchpage;
        setIsSearched(true);
        setIsLoading(true);
        const res = await searchMortgageNeeds(formState);
        setTimeout(() => {
          if (res.status) {      
            if (searchpage === 1) {
              setLeads(res.data);
            } else {
              setLeads((oldLeads) => [...oldLeads, ...res.data]);
            }
            setHasMorePage(res.has_more_page);
            setSearchPage((oldPage) => (res.has_more_page ? oldPage + 1 : oldPage));
            if (res.data.length) {        
              settotalLeads(res.total);
              setSelectedLead(res.data[0]);
              setIsSearchAPiCall(true);
              setShowHideFilter(false);
              markAreaAsRead({'area_id':res.data[0].id}).then((res) => {
                setIsLoading(false);
              });
              var currentDate = new Date();
              setLastFeatched(prettyTimeAgo(currentDate.toString()));
            }
            
          } else {
            setLeads([]);
            setIsLoading(false);
            setIsSearched(false);
          }
        }, 2000);
        
        setIsLoading(false);
    }else{
      advisorMatchedLeadsCall(page);
    }                                                                                            
    // advisorMatchedLeadsCall(page);
  };

  const advisorMatchedLeadsCall = async (page: number) => {
    setIsLoading(true);
    let res: any;
    if (accepted_id) {
      res = await getNeedDetails({ area_id: accepted_id });
      if (res.status) {
        setLeads([res.data]);
        setSelectedLead(res.data);
      }
    } else {
      if(service_id!=undefined){
        res = await advisorMatchedLeads(page,'service_id',service_id);
      }else if(time!=undefined){
        res = await advisorMatchedLeads(page,'time',time);
      }else if(discount!=undefined){
        res = await advisorMatchedLeads(page,'discount',discount);
      }else if(isSearchAPiCall){
        res = await searchMortgageNeeds(formState);
      }else{
        res = await advisorMatchedLeads(page,'','');
      }
      if (res.status) {
        if (page === 1) {
          setLeads(res.data);
        } else {
          setLeads((oldLeads) => [...oldLeads, ...res.data]);
        }
        setHasMorePage(res.has_more_page);
        setPage((oldPage) => (res.has_more_page ? oldPage + 1 : oldPage));
        if (res.data.length) {
          // var datetime = res.data[0].created_at;
          // setLastFeatched(prettyTimeAgo(datetime));
          settotalLeads(res.total);
          setSelectedLead(res.data[0]);
          setIsSearchAPiCall(false);
          setIsLoading(true);
          markAreaAsRead({'area_id':res.data[0].id}).then((res) => {
            setIsLoading(false);
          });
          var currentDate = new Date();
          setLastFeatched(prettyTimeAgo(currentDate.toString()));
        }
      }
    }
    setIsLoading(false);
  };

  const lastFeatchedArr = lastFeatched.split(/(?:^\S+)\s/);
  if (lastFeatchedArr[0] && lastFeatchedArr[0] > 15) {
    setLastFeatched(prettyTimeAgo(new Date().toString()));
  }

  const getServices = async () => {
    setIsLoading(true);
    const res = await getServiceTypeWithAuth();
    if (res?.success || res.status) {
      setService(res.data);
    }
    setIsLoading(false);
  };


  const getMortgageSizeData = async () => {
    const res = await getMortgageSize();
    if (res?.success || res.status) {
      setMortgageSize(res.data);
    }
  };

  const getCount = async () => {
    setIsLoading(true);
    const res = await getCountFilter();
    if (res?.success || res.status) {
      setCountData(res.data);
      handlePageState({
        unread : res.data.unread
      });
    }
    setIsLoading(false);
  };

  const setShowHideFilterCheck = async() => {
    setShowHideFilter(true);
  };

  const goBackToList = async() => {
    setShowHideFilter(false);
  };

  const handleChangeCheckbox = (event: { target: HTMLInputElement } | any) => {
    const { name, value, checked } = event.target;
    if (["local_advisor"].includes(name)) {
      handlePageState({ [name]: +checked });
    } else {
      setFormState((prevStates: any) => {
        const item = prevStates[name];
        return {
          ...prevStates,
          [name]: checked
            ? [...item, value]
            : item.filter((item: string) => item !== value),
        };
      });
    }
    
  };

  const handleChange = (event: { target: HTMLInputElement } | any) => {
    const { name, value } = event.target;
    handlePageState({ [name]: value });
  };

  const handleClearFilters = () => {
    setFormState(initialState);
    $('.status').prop('checked', false);
    $('.promotion').prop('checked', false);
    $('.advice_area').prop('checked', false);
    $('.mortgage_value').prop('checked', false);
    $('.lead_submitted').prop('checked', false);
    $('.fees_preference').prop('checked', false);
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    setIsSearched(true);
    setIsLoading(true);
    const res = await searchMortgageNeeds(formState);
    setTimeout(() => {
      if (res.status) {      
        if (searchpage === 1) {
          setLeads(res.data);
        } else {
          setLeads((oldLeads) => [...oldLeads, ...res.data]);
        }
        setHasMorePage(res.has_more_page);
        setSearchPage((oldPage) => (res.has_more_page ? oldPage + 1 : oldPage));
        if (res.data.length) {        
          settotalLeads(res.total);
          setSelectedLead(res.data[0]);
          setIsSearchAPiCall(true);
          setShowHideFilter(false);
          var currentDate = new Date();
          setLastFeatched(prettyTimeAgo(currentDate.toString()));
        }
        
      } else {
        setLeads([]);
        setIsLoading(false);
        setIsSearched(false);
      }
    }, 2000);
    
    setIsLoading(false);
  };

  return (
    <>
    {apiLoaded?
      <main className="dashboard">
      
      {user.email_status === 0 || user?.userDetails?.FCA_verified == null?(
      <section
          className="page-title adviser-dashboard h400-new"
          style={{ "height": "170px" ,"padding": "111px 0 0 0"}}
        >
            <div className="container">
              <div className="recent-box mb-0 warning-header lead-advisor-nir">
                <div className="row align-items-center">
                  <div className="col-lg-8">
                    <div className="required align-items-center">
                      <i className="fas fa-exclamation-triangle mr-3 mb-2 mb-md-0 text-center"></i>
                      {user.email_status == 0 && user?.userDetails?.FCA_verified == null ?(<>
                      <p style={{"display": "inline-block","color": "red"}}>Please verify email to accept mortgage leads</p>
                      <p style={{"color": "red"}}>We are verifying your FCA registration, typically completed in less than 24 hours. Once confirmed you will be up and running and able to accept mortgage leads</p></>):null}
                      {user.email_status == 0 && user?.userDetails?.FCA_verified != null ?"Please verify email to accept mortgage leads":""}
                      {user.email_status != 0 && user?.userDetails?.FCA_verified == null ?"We are verifying your FCA registration, typically completed in less than 24 hours. Once confirmed you will be up and running and able to accept mortgage leads":""}
                    </div>
                  </div>
                  <div className="col-lg-4 mt-2 mt-lg-0 text-center text-md-right">
                    {user.email_status == 0?
                      <a
                        href="javascript:void(0)"
                        data-toggle="modal"
                        data-target="#Resend-mail"
                        className="resend-link"
                      >
                        Resend activation mail
                      </a>:null}
                  </div>
                  
                </div>
              </div>
            </div>
         
        </section>
         ):null}
        <section className="page-title h-370 h70">
          <div className="container">
            <div className="row">
              <div className="col-md-12 mb-5 mb-md-0"></div>
            </div>
          </div>
        </section>

        <section className="recent-sec advice-area-sec profile-sec advice-lead-matches advice-matches-datas new-advice-matches pb-5 marfintop190 nir-lead-advisor-page">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-12">
                <div className="recent-box">
                  <div className="row align-items-center justify-content-center mb-4">
                    <div className="col-lg-12">
                      <div className="row">
                        {showHideFilter?
                        <div className="col-xl-4 col-lg-5 col-md-12 mb-4 pr-xl-3 pl-xl-3 left-right-acc">
                          <div id="accordion2" className="accordion2">
                            <div className="address-acc">
                              <div id="left_side" className="mt-lg-0 mt-3">
                                <div className="left-sidebar">
                                  {/* <form > */}
                                    {/* onSubmit={handleSubmit} */}
                                    <div className="leads-data p-0">
                                      <h4 className="filter-backbtn">
                                        Filter{" "}
                                        <a
                                          onClick={goBackToList}
                                          className="back-btn text-right"
                                        >
                                          <i
                                            className="fa fa-angle-left"
                                            aria-hidden="true"
                                          ></i>{" "}
                                          Back
                                        </a>
                                      </h4>
                                      <p>Keyword search </p>
                                      <div className="form-group">
                                        <div className="row">
                                          <div className="col-12 search-bar">
                                            <i
                                              className="fa fa-search"
                                              aria-hidden="true"
                                            ></i>
                                            <input
                                              type="search"
                                              id="search_filter"
                                              placeholder="Search"
                                              name="search"
                                              value={formState.search}
                                              onChange={handleChange}
                                            />
                                          </div>
                                        </div>
                                      </div>
                                    </div>

                                    <div className="directory-section">
                                      <div id="accordion" className="accordion">
                                        <div className="accordion-directory">
                                          <div
                                            className="ques collapsed mb-4"
                                            data-toggle="collapse"
                                            aria-expanded="true"
                                            data-href="#collapse-1"
                                            data-target="#collapse-1"
                                          >
                                            <p>Status</p>
                                          </div>
                                          <div
                                            id="collapse-1"
                                            className="collapse show "
                                            data-parent="#accordion"
                                          >
                                            <div className="answers">
                                              <div className="row">
                                                <div className="col-12 col-md-6 col-6">
                                                  <div className="form-group checkboxes-data mb-0">
                                                    <input
                                                      type="checkbox"
                                                      id="unread"
                                                      name="status"
                                                      className ="status"
                                                      value={"unread"}
                                                      checked={formState.status.includes('unread')}
                                                      onChange={
                                                        handleChangeCheckbox
                                                      }
                                                    />
                                                    <label htmlFor="unread">
                                                      Unread ({countData?.unread})
                                                    </label>
                                                  </div>
                                                  <div className="form-group checkboxes-data mb-0">
                                                    <input
                                                      type="checkbox"
                                                      id="read"
                                                      name="status"
                                                      className ="status"
                                                      value={"read"}
                                                      checked={formState.status.includes('read')}
                                                      onChange={
                                                        handleChangeCheckbox
                                                      }
                                                    />
                                                    <label htmlFor="read">
                                                      Read ({countData?.read})
                                                    </label>
                                                  </div>
                                                </div>
                                                <div className="col-12 col-md-6 col-6">
                                                  <div className="form-group checkboxes-data mb-0">
                                                    <input
                                                      type="checkbox"
                                                      id="not_interested"
                                                      name="status"
                                                      className ="status"
                                                      value={"not-interested"}
                                                      checked={formState.status.includes('not-interested')}
                                                      onChange={
                                                        handleChangeCheckbox
                                                      }
                                                    />
                                                    <label htmlFor="not_interested">
                                                      Not Interested ({countData?.not_intrest})
                                                    </label>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>

                                        <span className="border"></span>
                                        <div className="accordion-directory">
                                          <div
                                            className="ques collapsed mb-4"
                                            data-toggle="collapse"
                                            data-href="#collapse-2"
                                            data-target="#collapse-2"
                                            aria-expanded="true"
                                          >
                                            <p>Promotion</p>
                                          </div>
                                          <div
                                            id="collapse-2"
                                            className="collapse"
                                            data-parent="#accordion"
                                          >
                                            <div className="answers">
                                              <div className="row">
                                                <div className="col-12 col-md-6 col-6">
                                                  {/* <div className="form-group checkboxes-data mb-0">
                                                    <input
                                                      type="checkbox"
                                                      id="early_bids"
                                                      name="promotion"
                                                      value={"early-bird"}
                                                      onChange={
                                                        handleChangeCheckbox
                                                      }
                                                    />
                                                    <label htmlFor="early_bids">
                                                      Early bird
                                                    </label>
                                                  </div> */}
                                                  <div className="form-group checkboxes-data mb-0">
                                                    <input
                                                      type="checkbox"
                                                      id="none"
                                                      name="promotion"
                                                      className ="promotion"
                                                      value={"none"}
                                                      checked={formState.promotion.includes('none')}
                                                      onChange={
                                                        handleChangeCheckbox
                                                      }
                                                    />
                                                    <label htmlFor="none">
                                                      None ({countData?.none})
                                                    </label>
                                                  </div>
                                                  <div className="form-group checkboxes-data mb-0">
                                                    <input
                                                      type="checkbox"
                                                      id="50_off"
                                                      name="promotion"
                                                      value={"50-off"}
                                                      className ="promotion"
                                                      checked={formState.promotion.includes('50-off')}
                                                      onChange={
                                                        handleChangeCheckbox
                                                      }
                                                    />
                                                    <label htmlFor="50_off">
                                                      50% off ({countData?.half})
                                                    </label>
                                                  </div>
                                                </div>
                                                <div className="col-12 col-md-6 col-6">
                                                  <div className="form-group checkboxes-data mb-0">
                                                    <input
                                                      type="checkbox"
                                                      id="75_off"
                                                      name="promotion"
                                                      className ="promotion"
                                                      value={"75-off"}
                                                      checked={formState.promotion.includes('75-off')}
                                                      onChange={
                                                        handleChangeCheckbox
                                                      }
                                                    />
                                                    <label htmlFor="75_off">
                                                      75% off ({countData?.third})
                                                    </label>
                                                  </div>
                                                  <div className="form-group checkboxes-data mb-0">
                                                    <input
                                                      type="checkbox"
                                                      id="free"
                                                      name="promotion"
                                                      className ="promotion"
                                                      value={"free"}
                                                      checked={formState.promotion.includes('free')}
                                                      onChange={
                                                        handleChangeCheckbox
                                                      }
                                                    />
                                                    <label htmlFor="free">
                                                      Free ({countData?.free})
                                                    </label>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>

                                        <span className="border"></span>
                                        <div className="accordion-directory">
                                          <div
                                            className="ques collapsed mb-4"
                                            data-toggle="collapse"
                                            data-href="#collapse-3"
                                            data-target="#collapse-3"
                                            aria-expanded="true"
                                          >
                                            <p>Advise area</p>
                                          </div>
                                          <div
                                            id="collapse-3"
                                            className="collapse  "
                                            data-parent="#accordion"
                                          >
                                            {/* show */}
                                            <div className="answers">
                                              <div className="row">
                                                <div className="col-12 col-md-6 col-6">
                                                  {serviceType.slice(
                                                    0,
                                                    6
                                                  ).map((adv_area: any) => {
                                                    return (
                                                      <>
                                                        <div
                                                          key={adv_area.id}
                                                          className="form-group checkboxes-data mb-0"
                                                        >
                                                          <input
                                                            type="checkbox"
                                                            id={adv_area.id}
                                                            name="advice_area"
                                                            className ="advice_area"
                                                            
                                                            value={
                                                              adv_area.id
                                                            }
                                                            // defaultChecked={adv_area.is_checked}
                                                            // checked={adv_area.is_checked}
                                                            checked={formState.advice_area.includes(String(adv_area.id))}
                                                            onChange={
                                                              handleChangeCheckbox
                                                            }
                                                          />
                                                          <label
                                                            htmlFor={
                                                              adv_area.id
                                                            }
                                                          >
                                                            {adv_area.name} ({adv_area.service_count})
                                                          </label>
                                                        </div>
                                                      </>
                                                    );
                                                  })}
                                                </div>
                                                <div className="col-12 col-md-6 col-6">
                                                  {serviceType.slice(
                                                    6,
                                                    12
                                                  ).map((adv_area: any) => {
                                                    return (
                                                      <>
                                                        <div
                                                          key={adv_area.id}
                                                          className="form-group checkboxes-data mb-0"
                                                        >
                                                          <input
                                                            type="checkbox"
                                                            id={adv_area.id}
                                                            name="advice_area"
                                                            className ="advice_area"
                                                            value={
                                                              adv_area.id
                                                            }
                                                            checked={formState.advice_area.includes(String(adv_area.id))}
                                                            onChange={
                                                              handleChangeCheckbox
                                                            }
                                                          />
                                                          <label
                                                            htmlFor={
                                                              adv_area.id
                                                            }
                                                          >
                                                            {adv_area.name} ({adv_area.service_count})
                                                          </label>
                                                        </div>
                                                      </>
                                                    );
                                                  })}
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>

                                        <span className="border"></span>
                                        <div className="accordion-directory">
                                          <div
                                            className="ques collapsed mb-4"
                                            data-toggle="collapse"
                                            data-href="#collapse-4"
                                            data-target="#collapse-4"
                                            aria-expanded="true"
                                          >
                                            <p>Mortgage size</p>
                                          </div>
                                          <div
                                            id="collapse-4"
                                            className="collapse"
                                            data-parent="#accordion"
                                          >
                                            <div className="answers">
                                              <div className="row">
                                                <div className="col-12 col-md-6 col-6">
                                                  {mortgageSize.slice(
                                                    0,
                                                    3
                                                  ).map((adv_price: any) => {
                                                    return (
                                                      <>
                                                        <div
                                                          key={adv_price.value}
                                                          className="form-group checkboxes-data mb-0"
                                                        >
                                                          <input
                                                            type="checkbox"
                                                            id={adv_price.key}
                                                            name="mortgage_value"
                                                            className ="mortgage_value"
                                                            value={
                                                              adv_price.key
                                                            }
                                                            checked={formState.mortgage_value.includes(String(adv_price.key))}
                                                            onChange={
                                                              handleChangeCheckbox
                                                            }
                                                          />
                                                          <label
                                                            htmlFor={
                                                              adv_price.key
                                                            }
                                                          >
                                                            {adv_price.name} ({adv_price.size_count})
                                                          </label>
                                                        </div>
                                                      </>
                                                    );
                                                  })}
                                                </div>
                                                <div className="col-12 col-md-6 col-6">
                                                  {mortgageSize.slice(
                                                    3,
                                                    5
                                                  ).map((adv_price: any) => {
                                                    return (
                                                      <>
                                                        <div
                                                          key={adv_price.key}
                                                          className="form-group checkboxes-data mb-0"
                                                        >
                                                          <input
                                                            type="checkbox"
                                                            id={adv_price.key}
                                                            name="mortgage_value"
                                                            className ="mortgage_value"
                                                            value={
                                                              adv_price.key
                                                            }
                                                            onChange={
                                                              handleChangeCheckbox
                                                            }
                                                          />
                                                          <label
                                                            htmlFor={
                                                              adv_price.key
                                                            }
                                                          >
                                                            {adv_price.name} ({adv_price.size_count})
                                                          </label>
                                                        </div>
                                                      </>
                                                    );
                                                  })}
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>

                                        <span className="border"></span>
                                        <div className="accordion-directory">
                                          <div
                                            className="ques collapsed mb-4"
                                            data-toggle="collapse"
                                            data-href="#collapse-5"
                                            data-target="#collapse-5"
                                            aria-expanded="true"
                                          >
                                            <p>Lead submitted</p>
                                          </div>
                                          <div
                                            id="collapse-5"
                                            className="collapse"
                                            data-parent="#accordion"
                                          >
                                            <div className="answers">
                                              <div className="row">
                                                <div className="col-12 col-md-6 col-6">
                                                  <div className="form-group checkboxes-data mb-0">
                                                    <input
                                                      type="checkbox"
                                                      id="anytime"
                                                      name="lead_submitted"
                                                      className ="lead_submitted"
                                                      value={"anytime"}
                                                      checked={formState.lead_submitted.includes('anytime')}
                                                      onChange={
                                                        handleChangeCheckbox
                                                      }
                                                    />
                                                    <label htmlFor="anytime">
                                                      Anytime ({countData?.anytime})
                                                    </label>
                                                  </div>
                                                  <div className="form-group checkboxes-data mb-0">
                                                    <input
                                                      type="checkbox"
                                                      id="last_1_hour"
                                                      name="lead_submitted"
                                                      className ="lead_submitted"
                                                      value={"last_hour"}
                                                      checked={formState.lead_submitted.includes('last_hour')}
                                                      onChange={
                                                        handleChangeCheckbox
                                                      }
                                                    />
                                                    <label htmlFor="last_1_hour">
                                                      Last 1 hour ({countData?.last_hour})
                                                    </label>
                                                  </div>
                                                  <div className="form-group checkboxes-data mb-0">
                                                    <input
                                                      type="checkbox"
                                                      id="today"
                                                      name="lead_submitted"
                                                      className ="lead_submitted"
                                                      value={"today"}
                                                      checked={formState.lead_submitted.includes('today')}
                                                      onChange={
                                                        handleChangeCheckbox
                                                      }
                                                    />
                                                    <label htmlFor="today">
                                                      Today ({countData?.today})
                                                    </label>
                                                  </div>
                                                </div>
                                                <div className="col-12 col-md-6 col-6">
                                                  <div className="form-group checkboxes-data mb-0">
                                                    <input
                                                      type="checkbox"
                                                      id="Yesterday"
                                                      name="lead_submitted"
                                                      className ="lead_submitted"
                                                      value={"yesterday"}
                                                      checked={formState.lead_submitted.includes('yesterday')}
                                                      onChange={
                                                        handleChangeCheckbox
                                                      }
                                                    />
                                                    <label htmlFor="Yesterday">
                                                      Yesterday ({countData?.yesterday})
                                                    </label>
                                                  </div>
                                                  <div className="form-group checkboxes-data mb-0">
                                                    <input
                                                      type="checkbox"
                                                      id="less_3"
                                                      name="lead_submitted"
                                                      className ="lead_submitted"
                                                      value={"less_3_days"}
                                                      checked={formState.lead_submitted.includes('less_3_days')}
                                                      onChange={
                                                        handleChangeCheckbox
                                                      }
                                                    />
                                                    <label htmlFor="less_3">
                                                      &lt; 3 days ({countData?.three_days})
                                                    </label>
                                                  </div>
                                                  <div className="form-group checkboxes-data mb-0">
                                                    <input
                                                      type="checkbox"
                                                      id="less_1"
                                                      name="lead_submitted"
                                                      className ="lead_submitted"
                                                      value={"less_1_week"}
                                                      checked={formState.lead_submitted.includes('less_1_week')}
                                                      onChange={
                                                        handleChangeCheckbox
                                                      }
                                                    />
                                                    <label htmlFor="less_1">
                                                      &lt; 1 week ({countData?.one_week})
                                                    </label>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>

                                        <span className="border"></span>
                                        <div className="accordion-directory">
                                          <div
                                            className="ques collapsed mb-4"
                                            data-toggle="collapse"
                                            data-href="#collapse-6"
                                            data-target="#collapse-6"
                                            aria-expanded="true"
                                          >
                                            <p>Customer fee preference</p>
                                          </div>
                                          <div
                                            id="collapse-6"
                                            className="collapse"
                                            data-parent="#accordion"
                                          >
                                            <div className="answers">
                                              <div className="row">
                                                <div className="col-12 col-md-6 col-6">
                                                  <div className="form-group checkboxes-data mb-0">
                                                    <input
                                                      type="checkbox"
                                                      id="no_fee"
                                                      name="fees_preference"
                                                      className ="fees_preference"
                                                      value={"no_fee"}
                                                      checked={formState.fees_preference.includes('no_fee')}
                                                      onChange={
                                                        handleChangeCheckbox
                                                      }
                                                    />
                                                    <label htmlFor="no_fee">
                                                      No fee ({countData?.preference_no})
                                                    </label>
                                                  </div>
                                                </div>
                                                <div className="col-12 col-md-6 col-6">
                                                  <div className="form-group checkboxes-data mb-0">
                                                    <input
                                                      type="checkbox"
                                                      id="would_consider"
                                                      name="fees_preference"
                                                      className ="fees_preference"
                                                      value={"would_consider"}
                                                      checked={formState.fees_preference.includes('would_consider')}
                                                      onChange={
                                                        handleChangeCheckbox
                                                      }
                                                    />
                                                    <label htmlFor="would_consider">
                                                      Would consider ({countData?.preference})
                                                    </label>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>

                                        <span className="border"></span>
                                        <div className="">
                                          <div className="form-row">
                                            <div className="col-xl-6 col-lg-6 col-md-6 col-6 mb-3 text-center">
                                              <button
                                                // type="submit"
                                                className="theme-btn"
                                                type="button"
                                                        onClick={
                                                          handleSubmit
                                                        }
                                              >
                                                APPLY 
                                              </button>
                                            </div>
                                            <div className="col-xl-6 col-lg-6 col-md-6 col-6 mb-3 text-center">
                                              <button className="theme-btn-border" onClick={handleClearFilters}>
                                                CLEAR
                                              </button>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  {/* </form> */}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>:
                        <div className="col-xl-4 col-lg-5 col-md-12 mb-4 pr-xl-3 pl-xl-3 left-right-acc">
                          <div id="accordion" className="accordion">
                            <div className="address-acc">
                              <div
                                className="cards collapsed"
                                data-toggle="collapse"
                                aria-expanded="true"
                              >
                                <h6>Lead Summary</h6>
                              </div>
                              <div
                                id="left_side"
                                className="collapse show mt-lg-0 mt-3"
                                data-parent="#accordion"
                              >
                                <div className="row align-items-center tabss">
                                  <div className="col-xl-12 text-center">
                                    <p>
                                      {!accepted_id ? (
                                        <b>
                                          {totalLeads ? totalLeads : "No"}{" "}
                                          leads matching you <Link to={`${ADVISER_PREFERENCES_URL}/products`}>
                                          filter
                                        </Link>
                                        </b>
                                      ) : (
                                        <b>Direct Enquiry</b>
                                      )}
                                      {/* <b>
                                        {leads.length ? leads.length : "No"}{" "}
                                        leads matching your{" "}
                                        <Link to={ADVISER_LEADS_FILTER}>
                                          filter
                                        </Link>
                                      </b> */}
                                    </p>
                                  </div>
                                  {!accepted_id ? (
                                    <div className="col-xl-12 col-12 text-center">
                                      <span>Updated {lastFeatched}</span>
                                    </div>
                                  ) : null}
                                  <div className="col-12">
                                    <span className="border-bottom"></span>
                                  </div>
                                </div>
                                <div
                                  className={`left-sidebar ${
                                    leads.length > 2 && "left-sidebar-height"
                                  }`}
                                >
                                  <LoadingSpinner loading={isLoading} />

                                  {/* <button
                                    style={{
                                      display:
                                        !isLoading && leads.length
                                          ? "block"
                                          : "none",
                                    }}
                                    className="theme-btn see_new_leads"
                                  >
                                    <i
                                      className="fa fa-arrow-up mr-1"
                                      aria-hidden="true"
                                    ></i>{" "}
                                    SEE NEW LEADS
                                  </button> */}

                                  <div className="lead-location">
                                    <ul className="nav nav-pills">
                                      {leads.length ? (
                                        leads.map((lead: any, index) => (
                                          <MortgageCard 
                                            key={`${lead.id}-${index}`}
                                            {...lead}
                                            index={index}
                                            updateSelectedLead={() =>
                                              setSelectedLead(lead)
                                            }
                                          />
                                        ))
                                      ) : !isLoading ? (
                                        <li className="col-md-12 pt-3 text-center">
                                          <div className="col-md-12 pt-3 text-center">
                                            <div className="view-btn text-center font-weight-bold">
                                              {NO_DATA_FOUND}
                                            </div>
                                          </div>
                                        </li>
                                      ) : null}
                                    </ul>
                                  </div>

                                  <div
                                    style={{
                                      display:
                                        !isLoading && leads.length
                                          ? "block"
                                          : "none",
                                    }}
                                    className="row pt-2 mt-4"
                                  >
                                    <div className="col-12 text-center">
                                      {hasMorePage ? (
                                        <button
                                          className="btn-border-blue load-more"
                                          onClick={loadMoreLeads}
                                        >
                                          LOAD MORE
                                        </button>
                                      ) : null}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>}

                        <div className="profile-data col-xl-8 col-lg-7 col-md-12 left-right-acc">
                          <div id="accordion" className="accordion">
                            <div className="address-acc">
                              <div
                                className="cards collapsed"
                                data-toggle="collapse"
                                aria-expanded="true"
                              >
                                <h6>Lead Details</h6>
                              </div>
                              <div
                                id="right_side"
                                className="collapse show mt-lg-0 mt-3"
                                data-parent="#accordion"
                              >
                                <div className="tab-content">
                                  <div
                                    className="tab-pane fade show active"
                                    id="tab1"
                                    role="tabpanel"
                                    aria-labelledby="flamingo-tab"
                                  >
                                    {leads.length ? (
                                      <MortgageCardDetails {...selectedLead} />
                                    ) : isLoading ? (
                                      <LoadingSpinner loading={isLoading} />
                                    ) : (
                                      <div className="col-md-12 pt-3 text-center">
                                        <div className="view-btn text-center font-weight-bold">
                                          {NO_DATA_FOUND}
                                        </div>
                                      </div>
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>:
      <LoadingSpinner loading={isLoading} />} 
      <ResendEmail />
    </>
  );
};

export default AdviserLeadsMatches;
