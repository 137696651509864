import React, { useEffect, useRef, useState } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import LoadingSpinner from "../../components/common/LoadingSpinner";
import { getInvoice, getInvoiceData, downloadInvoice } from "../../components/common/service";
import useLoggedInUserData from "../../utility/useLoggedInUserData";
import {
  ADVISER_MY_ACCOUNT,
  ADVISER_PAYMENT_PAY_INVOICE,
  ADVISER_BASE_URL,
} from "../../Constants";
import ReactToPrint from "react-to-print";
import moment from "moment";
import { numberWithCommas } from "../../utility/commonUtils";

var invoiceDate = '';
const AdviserCreditInvoice = () => {
  
  const history = useHistory();
  const user = useLoggedInUserData();
  const componentRef = useRef<any>();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isAPICalled, setisAPICalled] = useState<boolean>(false);
  const [invoiceData, setInvoiceData] = useState<any>({});
  const [siteAddress, setSiteAddress] = useState<any>({});
  const [monthdata, setMonthData] = useState<any>({});

  const [billingAddress, setBillingAddress] = useState<any>({});

  // const [invoiceDate, setInvoiceDate] = useState<any>(
  //   // moment().format("MM/YYYY")
  // );


  useEffect(() => {
    if(!isAPICalled){
      setIsLoading(true);
      setisAPICalled(true);
      getInvoiceData({'date':invoiceDate}).then((res) => {
          if (res.status) {
            setInvoiceData(res.data.invoice);
            setSiteAddress(res.data.site_address);
            setBillingAddress(res.data.billing);
            setMonthData(res.data.month_data);
            if(res.data.month_data.length!=0){
              invoiceDate = res.data.month_data[0].month+"/"+res.data.month_data[0].year;
            }
            setIsLoading(false);
          }
      });
    }
  });

  const getInvoiceCall = async (datest: string) => {
    invoiceDate = datest;
    setIsLoading(true);
    let date:any = datest;
    const res = await getInvoiceData({'date':datest});
    if (res.status) {
      setInvoiceData(res.data.invoice);
    }
    setIsLoading(false);
  };

  const downloadInvoiceData = async () => {
    setIsLoading(true);
    const res = await downloadInvoice({'date':invoiceDate});
    setIsLoading(false);
  };
  const getinvoiceDate = async (value: string) => {
    invoiceDate = value;
    getInvoiceCall(invoiceDate);
  };
  const handlePayInvoice = () => {
    // ADVISER_PAYMENT_PAY_INVOICE
    var month = moment().format("MM");
    var year = moment().format("YYYY");
    const month_array = invoiceDate.split('/');
    console.log(invoiceDate);
    console.log(month_array);
    var actual_month = parseInt(month_array[0]);
    var actual_year = parseInt(month_array[1]);
    //console.log(month_array[0]);
   /// console.log(month);
   // console.log(actual_month);
   // console.log(actual_year);
    // if(month_array[0]=month){
    //   var actual_month = Number(month_array[0]) - 1;
    // }
   
    
    if(month_array[1]!=undefined){
        year = month_array[1];
        actual_year = Number(year);
      //  console.log(actual_year);
    }
    if(actual_month==0){
      actual_month = 12;
      actual_year = Number(month_array[1]) - 1;
    //  console.log(actual_year);
     // console.log(actual_month);
    }
    else{
      //console.log(actual_month);
    }
    // month_array[0] = actual_month;
    // if(month_array[0]!=undefined){
      
    //   if(month_array[0]=='1'){
    //     month = 'January';
    //   }else if(month_array[0]=='2'){
    //     month = 'Feburary';
    //   }else if(month_array[0]=='3'){
    //     month = 'March';
    //   }else if(month_array[0]=='4'){
    //     month = 'April';
    //   }else if(month_array[0]=='5'){
    //     month = 'May';
    //   }else if(month_array[0]=='6'){
    //     month = 'June';
    //   }else if(month_array[0]=='7'){
    //     month = 'July';
    //   }else if(month_array[0]=='8'){
    //     month = 'August';
    //   }else if(month_array[0]=='9'){
    //     month = 'September';
    //   }else if(month_array[0]=='10'){
    //     month = 'October';
    //   }else if(month_array[0]=='11'){
    //     month = 'November';
    //   }else if(month_array[0]=='12'){
    //     month = 'December';
    //   }
    // }

    if(month_array[0]!=undefined){      
      if(actual_month==1){
        month = 'January';
      }else if(actual_month==2){
        month = 'Feburary';
      }else if(actual_month==3){
        month = 'March';
      }else if(actual_month==4){
        month = 'April';
      }else if(actual_month==5){
        month = 'May';
      }else if(actual_month==6){
        month = 'June';
      }else if(actual_month==7){
        month = 'July';
      }else if(actual_month==8){
        month = 'August';
      }else if(actual_month==9){
        month = 'September';
      }else if(actual_month==10){
        month = 'October';
      }else if(actual_month==11){
        month = 'November';
      }else if(actual_month==12){
        month = 'December';
      }
    }
    if (invoiceData) {
      //console.log(invoiceData);
      //console.log( month+" "+year);
      history.push({
        pathname: ADVISER_PAYMENT_PAY_INVOICE,
        state: {
          invoice: {
            invoice_id: invoiceData.id,
            invoice_number: invoiceData.invoice_number,
            invoice_date: invoiceDate,
            invoice_amount: invoiceData.total_due,
            invoice_date_arr:monthdata,
            invoice_show_month : month+" "+year
          },
        },
      });
    }
  };

  return (
    <>
      
      <main className="need-offers">        
        <section className="page-title my-account-title">
          <div className="container">
            <div className="row">
              <div className="col-md-12 mb-5 mb-md-0"></div>
            </div>
          </div>
        </section>

        <section className="recent-sec directory-sec advice-area-sec register-final-sec pt-4 pb-5 myaccount-sec credit-invoice-sec">
          <div className="container">
            <div className="row">
              <div className="col-12 back-btn mt-lg-4 mb-lg-3 mt-md-5 mt-5 mb-0">
                <Link to={ADVISER_MY_ACCOUNT} className="back-btn">
                  <i className="fa fa-angle-left" aria-hidden="true"></i>
                  My Account
                </Link>
              </div>
            </div>
          </div>

          <div className="container">
            <div ref={componentRef} className="recent-box settings-data mb-4">
              <div className="row align-items-center justify-content-center mb-4">
                <div className="col-lg-12">
                  <LoadingSpinner loading={isLoading} />
                  {!isLoading ? (
                    <>
                      <div id="profile-tab" className="row pb-3">
                        <div className="col-lg-4 col-md-4 mb-4 pr-xl-5 pl-xl-5">
                          <div className="text-center site_logo_address mb-4">
                            <img
                              src="assets/images/mortgage.png"
                              className="img-fluid"
                              alt=""
                            />
                          </div>
                          <div className="invoice-add">
                            {/* <p>Dancing Ledge ltd</p>
                            <span>
                              123 High Street, Imaginary town
                              <br />
                              Surrey TW12 2AA, United Kingdom
                            </span>
                            <span>This is not a payment address</span>
                            <span>
                              VAT Number: GB1234567890
                              <br />
                              Company number 12345678
                            </span> */}
                            <p>
                              <span>
                                {/* {invoiceData?.invoice_data?.seller_address
                                  ? invoiceData?.invoice_data?.seller_address
                                      .toString()
                                      .replace(/\\n/g, "\n")
                                  : null} */}
                                  Kemata ltd, trading as MortgageBox.co.uk
                                  <br/>
                                  20-22 Wenlock Road
                                  <br/>
                                  London, N1 7GU
                                  <br/>
                                  United Kingdom
                                  <br/>
                              </span>
                            </p>
                            {/* <p>
                              <span>
                                {siteAddress?siteAddress.value:""}
                              </span>
                            </p> */}
                            

                            
                            {billingAddress!=null?
                            <div className="mt-lg-4 pt-lg-3">
                              <p>Bill To</p>
                              {/* {billingAddress!=null?billingAddress?.value:""} */}
                              <p>
                              <span>
                                  {billingAddress.invoice_name!=''?billingAddress.invoice_name:""}
                                  <br />
                                  {billingAddress.address_one!=''?billingAddress.address_one:""}
                                  {billingAddress.address_two!=null?(<><br />
                                  {billingAddress.address_two}</>):null}
                                  {billingAddress.city!=''?(<><br />
                                  {billingAddress.city } </>):null}
                                  {billingAddress.post_code!=''?billingAddress.post_code:""}
                                </span>
                              </p>
                              {/* <p>
                                <span>{invoiceData?.invoice_data?.bill_to_address}</span>
                              </p> */}
                            </div>
                            :null}
                          </div>
                        </div>
                        <div className="profile-data col-lg-8 col-md-8">
                          <div className="invoice-data">
                            <div className="row text-center mb-2">
                              <div className="col-12">
                                {invoiceData?.total_due!= 0 ? (
                                  <button
                                    className="theme-btn"
                                    onClick={handlePayInvoice}
                                  >
                                    Pay outstanding invoice
                                  </button>
                                ) : null}
                              </div>
                            </div>
                            <div className="row align-items-end">
                              <div className="col-6">
                                {invoiceData?.invoice_number ? (
                                  <p className="mb-0">
                                    Invoice number
                                    <br />
                                    {invoiceData.invoice_number}
                                  </p>
                                ) : null}
                              </div>
                              <div className="col-6 text-right">
                                <h4>Invoice</h4>
                                <form>
                                  <div className="form-group mb-0">
                                    <div className="select-data">
                                    {monthdata.length!=0?
                                    <select
                                    name="new_date"
                                    defaultValue = {invoiceDate}
                                    onChange={(e) =>
                                      getinvoiceDate(e.target.value)
                                    }
                                    value={invoiceDate}
                                  >
                                     {monthdata.map(
                                    (fee: any, index: any) =>
                                          <option
                                          // selected={invoiceDate == `${fee.month}/${fee.year}`}
                                          defaultValue={invoiceDate}
                                          value={`${fee.month}/${fee.year}`}
                                        >
                                          {fee.show_days}
                                        </option>
                                          )}
                                  </select>
                                    //   <select name="new_date"
                                    //     onChange={(e) =>
                                    //       getinvoiceDate(e.target.value)
                                    //     }
                                    //   >
                                    //     {invoiceData?.month_data.map(
                                    // (fee: any, index: any) =>
                                    //       <option
                                    //       // selected={invoiceDate == `${fee.month}/${fee.year}`}
                                    //       defaultValue={invoiceDate}
                                    //       value={`${fee.month}/${fee.year}`}
                                    //     >
                                    //       {fee.show_days}
                                    //     </option>
                                    //       )}
                                    //   </select>
                                      :""}
                                    </div>
                                  </div>
                                </form>
                              </div>
                            </div>
                            <span className="border-bottom"></span>
                            <form>
                            {invoiceData!=null?
                              <div className="account-summary">
                                <div className="row">
                                  <div className="col-6">
                                    <h5>Account Summary</h5>
                                  </div>
                                  {invoiceData!=null?
                                  <div className="col-6 text-right dates">
                                    <p>
                                      {invoiceData.summary!=null?invoiceData?.summary:""}
                                    </p>
                                  </div>:""}
                                </div>
                                {invoiceData!=null?
                                <div className="row summary">
                                  <div className="col-12">
                                    
                                    {invoiceData!=null?
                                    <ol>
                                      {invoiceData?.unpaid_prevoius_invoice > 0 ? (
                                        <li>
                                          <div className="dot-div"></div>
                                          <div className="text-div">
                                            <span className="text-span">
                                              Previous Balance
                                            </span>
                                            <span className="text-span pull-right">
                                              £
                                              {invoiceData?.unpaid_prevoius_invoice}
                                            </span>
                                          </div>
                                        </li>
                                      ) : invoiceData?.paid_prevoius_invoice > 0 ? (<li>
                                      <div className="dot-div"></div>
                                      <div className="text-div">
                                        <span className="text-span">
                                          Invoice payment received{" "}
                                          {moment(
                                            invoiceData?.last_paid_date
                                          ).format("DD-MMM-YYYY")}{" "}
                                          - Thank you
                                        </span>
                                        <span className="text-span pull-right">
                                          £{invoiceData?.paid_prevoius_invoice}
                                        </span>
                                      </div>
                                    </li>):null}
                                        
                                    </ol>
                                    :<div>{invoiceData?.total_due ? null : (
                                      <p
                                        className="text-center"
                                        style={{ paddingTop: "32px" }}
                                      >
                                        <h6>Invoice not found.</h6>
                                      </p>
                                    )}</div>}
                                  </div>
                                </div>:null}
                                  <div className="row summary">
                                    <div className="col-12">
                                      <h6>New fees</h6>
                                      <div className="row summary ml-lg-3">
                                        <div className="col-12">
                                          <ol>
                                                <li>
                                                  <div className="dot-div"></div>
                                                  <div className="text-div">
                                                    <span className="text-span">
                                                      Cost of Leads
                                                    </span>
                                                    <span className="text-span pull-right">
                                                      £{invoiceData?.cost_of_lead}
                                                    </span>
                                                  </div>
                                                </li>
                                                {/* {invoiceData?.sub_total_without_tax!=0?<li>
                                                  <div className="dot-div"></div>
                                                  <div className="text-div">
                                                    <span className="text-span">
                                                      Total taxable amount
                                                    </span>
                                                    <span className="text-span pull-right">
                                                      £{invoiceData?.sub_total_without_tax}
                                                    </span>
                                                  </div>
                                                </li>:null} */}
                                                {invoiceData?.vat_amount!=0?<li>
                                                  <div className="dot-div"></div>
                                                  <div className="text-div">
                                                    <span className="text-span">
                                                      VAT at a rate 
                                                    </span>
                                                    <span className="text-span pull-right">
                                                      £{invoiceData?.vat_amount}
                                                    </span>
                                                  </div>
                                                </li>:null}
                                                <li>
                                                  <div className="dot-div"></div>
                                                  <div className="text-div">
                                                    <span className="text-span">
                                                      <b>Subtotal</b>
                                                    </span>
                                                    <span className="text-span pull-right">
                                                      £{invoiceData?.subtotal}
                                                    </span>
                                                  </div>
                                                </li>
                                          </ol>
                                        </div>
                                      </div>
                                    </div>
                                  </div>

                                  {invoiceData!=null?
                                  <div className="row summary">
                                    <div className="col-12">
                                      <h6>Discounts and credits</h6>
                                      <div className="row summary ml-lg-3">
                                        <div className="col-12">
                                          <ol>
                                            <li>
                                              <div className="dot-div"></div>
                                              <div className="text-div">
                                                <span className="text-span">
                                                  Discounts
                                                </span>
                                                <span className="text-span pull-right">
                                                  £
                                                  {invoiceData?.discount}
                                                </span>
                                              </div>
                                            </li>
                                            <li>
                                              <div className="dot-div"></div>
                                              <div className="text-div">
                                                <span className="text-span">
                                                  Free introductions
                                                </span>
                                                <span className="text-span pull-right">
                                                  £
                                                  {invoiceData?.free_introduction}
                                                </span>
                                              </div>
                                            </li>
                                            <li>
                                              <div className="dot-div"></div>
                                              <div className="text-div">
                                                <span className="text-span">
                                                  <b>Sub total</b>
                                                </span>
                                                <span className="text-span pull-right">
                                                  <b>
                                                    £
                                                    {invoiceData?.discount_subtotal}
                                                  </b>
                                                </span>
                                              </div>
                                            </li>
                                          </ol>
                                        </div>
                                      </div>
                                    </div>
                                  </div>:""}
                                  {invoiceData!=null?
                                (invoiceData!=null ? (
                                  <div className="row summary">
                                    <div className="col-12">
                                      <ol className="mt-2 mb-2">
                                        <li className="total_due">
                                          <div className="dot-div"></div>
                                          <div className="text-div">
                                            <span className="text-span text-title">
                                              Total Due
                                            </span>
                                            <span className="text-span pull-right">
                                              £{invoiceData?.total_due}
                                            </span>
                                          </div>
                                        </li>
                                      </ol>
                                    </div>
                                  </div>
                                ) : null)
                                :""}
                              </div>:
                              <div className="text-center"><p style={{ color: "red" }}>
                              No invoices</p></div>}
                              {/* {invoiceData!=null?
                              (invoiceData?.total_due ? (
                                <div className="account-summary mt-3">
                                  <div className="row">
                                    <div className="col-12">
                                      <h5>Tax Summary</h5>
                                    </div>
                                  </div>
                                  <div className="row summary">
                                    <div className="col-12">
                                      <ol>
                                        <li>
                                          <div className="dot-div"></div>
                                          <div className="text-div">
                                            <span className="text-span">
                                              Total taxable amount
                                            </span>
                                            <span className="text-span pull-right">
                                              £{invoiceData?.total_taxable_amount}
                                            </span>
                                          </div>
                                        </li>
                                        <li>
                                          <div className="dot-div"></div>
                                          <div className="text-div">
                                            <span className="text-span">
                                              VAT at a rate 
                                            </span>
                                            <span className="text-span pull-right">
                                              £{invoiceData?.vat}
                                            </span>
                                          </div>
                                        </li>
                                        <li>
                                          <div className="dot-div"></div>
                                          <div className="text-div">
                                            <span className="text-span">
                                              <b>
                                                Total current invoice amount
                                              </b>
                                            </span>
                                            <span className="text-span pull-right">
                                              <b>
                                                £{invoiceData?.total_current_invoice}
                                              </b>
                                            </span>
                                          </div>
                                        </li>
                                      </ol>
                                    </div>
                                  </div>
                                </div>
                              ) : null):""} */}
                            </form>
                            {invoiceData!=null?
                            (invoiceData?.total_due ? (
                              <div className="row">
                                <div className="col-12 text-right">
                                <a target="_blank"
                                            href={`/backend/download-invoice/${user.id}/${invoiceData.month}-${invoiceData.year}`}
                                            className="read-btn mt-3 d-block"
                                          >
                                            Download Invoice
                                          </a>
                                  {/* <a
                                    onClick={downloadInvoiceData}
                                    // href="javascript:void(0)"
                                    className="mt-3 d-block"
                                    
                                  >
                                    Download Invoice
                                  </a> */}
                                  {/* <ReactToPrint
                                    trigger={() => (
                                    )}
                                    content={() => componentRef.current}
                                  /> */}
                                </div>
                              </div>
                            ) : null)
                            :""}
                          </div>
                        </div>
                      </div>
                      <span className="border-bottom"></span>

                      <div className="row">
                        <div className="col-12">
                        {invoiceData!=null?
                            <div className="account-summary mt-3 pb-3">
                              <div className="row">
                                <div className="col-12">
                                  <h5>New Fees</h5>
                                </div>
                              </div>
                              <div className="news-summary-data">
                                <div className="mt-md-3">
                                  <div className="titles">
                                    <div className="row">
                                      <div className="col-md-2">
                                        <p>
                                          <b>Accepted Date</b>
                                        </p>
                                      </div>
                                      <div className="col-md-2">
                                        <p>
                                          <b>Customer</b>
                                        </p>
                                      </div>
                                      <div className="col-md-2 text-center">
                                        <p>
                                          <b>Mortgage</b>
                                        </p>
                                      </div>
                                      <div className="col-md-2">
                                        <p>
                                          <b>Status</b>
                                        </p>
                                      </div>
                                      <div className="col-md-2">
                                        <p>
                                          <b>Fee type</b>
                                        </p>
                                      </div>
                                      <div className="col-md-2 text-center">
                                        <p>
                                          <b>Amount</b>
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="tables">
                                  <>
                                    {invoiceData?.new_fees_arr &&
                          invoiceData.new_fees_arr.length ? invoiceData.new_fees_arr.map(
                                      (fee: any, index: any) => (
                                        <div
                                          className="datas"
                                          
                                        >
                                          <div className="row">
                                            <div className="col-md-2">
                                              <p>
                                                <b>Date : </b>
                                                {fee.date}
                                              </p>
                                            </div>
                                            <div className="col-md-2">
                                              <p>
                                                <b>Customer : </b>
                                                {fee.area.user.name}
                                              </p>
                                            </div>
                                            <div className="col-md-2 text-right m-lefttext">
                                              <p>
                                                <b>Mortgage : </b>£
                                                {numberWithCommas(fee.area.size_want)}
                                              </p>
                                            </div>
                                            <div className="col-md-2">
                                              <p>
                                                <b>Status : </b>
                                                {fee.status_type}
                                              </p>
                                            </div>
                                            <div className="col-md-2">
                                              <p>
                                                <b>Fee type : </b>
                                                {fee?.area?.service?.name ||
                                                  fee?.area?.service?.name}
                                              </p>
                                            </div>
                                            <div className="col-md-2 text-center">
                                              <p>
                                                <b>Amount : </b>£
                                                {fee.cost_leads}
                                              </p>
                                            </div>
                                          </div>
                                        </div>
                                      )
                                    ): <div className="row">
                                    <div className="col-md-12 text-center">
                                      <p style={{ color: "red" }}>
                                        Record not found</p>
                                    </div> </div>}
                                    {invoiceData?.new_fees_arr &&
                          invoiceData.new_fees_arr.length ?
                                    <div className="datas totals">
                                      <p>
                                        <b>
                                          New Fees total: £
                                          {numberWithCommas(
                                            invoiceData?.subtotal
                                          )}
                                        </b>
                                      </p>
                                    </div>:null}
                                  </>
                                </div>
                              </div>
                            </div>:null}
                         
                        {invoiceData!=null?
                          <div className="account-summary mt-4">
                            <div className="row">
                              <div className="col-12">
                                <h5>Discounts and Credits</h5>
                              </div>
                            </div>
                              <div className="news-summary-data">
                                <div className="mt-md-3">
                                  <div className="titles">
                                    <div className="row">
                                      <div className="col-md-2">
                                        <p>
                                          <b>Accepted Date</b>
                                        </p>
                                      </div>
                                      <div className="col-md-2">
                                        <p>
                                          <b>Customer</b>
                                        </p>
                                      </div>
                                      <div className="col-md-2 text-center">
                                        <p>
                                          <b>Mortgage</b>
                                        </p>
                                      </div>
                                      <div className="col-md-2">
                                        <p>
                                          <b>Status</b>
                                        </p>
                                      </div>
                                      <div className="col-md-2">
                                        <p>
                                          <b>Fee type</b>
                                        </p>
                                      </div>
                                      <div className="col-md-2 text-center">
                                        <p>
                                          <b>Amount</b>
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="tables">
                                  {invoiceData?.discount_credit_arr.length>0?invoiceData.discount_credit_arr.map(
                                    (discount: any, index: any) => (
                                      <div
                                        className="datas"
                                        key={
                                          discount.date
                                            ? discount.date
                                                .toString()
                                                .replace(" ", "-")
                                            : index
                                        }
                                      >
                                        <div className="row">
                                          <div className="col-md-2">
                                            <p>
                                              <b>Date : </b>
                                              {discount.date}</p>
                                          </div>
                                          <div className="col-md-2">
                                            <p>
                                              <b>Customer : </b>
                                              {discount.area.user.name}
                                            </p>
                                          </div>
                                          <div className="col-md-2 text-right m-lefttext">
                                            <p>
                                              <b>Mortgage : </b>£
                                              {numberWithCommas(discount.area.size_want)}
                                            </p>
                                          </div>
                                          <div className="col-md-2">
                                            <p>
                                              <b>Status : </b>
                                              {discount.status_type}
                                            </p>
                                          </div>
                                          <div className="col-md-2">
                                            <p>
                                              <b>Fee type : </b>
                                              {discount?.discount_cycle ||
                                                  discount?.discount_cycle}
                                            </p>
                                          </div>
                                          <div className="col-md-2 text-center">
                                            <p>
                                              <b>Amount : </b>£
                                              {discount?.discount_cycle == 'Refund'?numberWithCommas(discount.cost_leads):numberWithCommas(discount.cost_discounted)}
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                    )
                                  ) : <div className="row">
                                  <div className="col-md-12 text-center">
                                    <p style={{ color: "red" }}>
                                    None</p>
                                  </div> </div>}
                                  {invoiceData?.discount_credit_arr.length>0?
                                  <div className="datas totals">
                                    <p>
                                      <b>
                                        Credit total: £
                                        {invoiceData?.discount_subtotal}
                                      </b>
                                    </p>
                                  </div>:null}
                                </div> 
                              </div>
                            
                          </div>:null}
                        </div>
                      </div>
                    </>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
          <img
            src="assets/images/business_right.png"
            alt="help-1"
            className="img-fluid before-img"
          />
          <img
            src="assets/images/business_left.png"
            alt="help-1"
            className="img-fluid after-img"
          />
        </section>
      </main>
      
    </>
  );
};

export default AdviserCreditInvoice;
