import React from "react";

const AboutInaNutshellModal = () => {
  return (
    <>
      <div
        className="mail-popup modal fade popup-model nutshell_popup"
        id="nutshell_example"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-body">
              <div className="row">
                <div className="col-md-12">
                  <div className="heading text-center mb-4">
                    <h4 className="text-uppercase theme-color">
                      IN A NUTSHELL EXAMPLE
                    </h4>
                    <p>
                      Bespoke personalised advice from experienced, professional
                      and friendly adviser with appointments carried out at a
                      time to suit you.
                    </p>
                  </div>
                </div>
                <div className="col-md-4 offset-md-4 mb-md-0 mb-3 text-center">
                  <button
                    type="button"
                    className="theme-btn"
                    data-dismiss="modal"
                  >
                    OK
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AboutInaNutshellModal;
