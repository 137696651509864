import React, { useEffect, useState, useContext } from "react";
import { ADVISER_LEADS_MATCHES, ADVISER_SEARCH_RANGE, JWT_TOKEN, LOGGEDIN_USER } from "../Constants";
import {
  AdvisorSignUpRequest,
  searchPostalCode,
} from "../components/common/service";
import { useToasts } from "react-toast-notifications";
import { useHistory } from "react-router-dom";
import { validateEmail } from "../utility/commonUtils";
import { AsyncTypeahead } from "react-bootstrap-typeahead";
import { AuthContext } from "../shared/context/auth-context";

const initialState = {
  serve_range: "",
  post_code: "",
  name: "",
  email: "",
  fca_number: "",
  company_name: "",
  password: "",
  accept_tnc: false,
  invitedBy: "",
  type:""
};

const AdvisorSignUp = () => {
  const auth = useContext(AuthContext);
  const { addToast } = useToasts();
  const history = useHistory();

  const [isSeachLoading, setIsSeachLoading] = useState<boolean>(false);
  const [options, setOptions] = useState<any>([]);
  const [selectedOption, setselectedOption] = useState<any>([]);
  // const [isLoading, setIsLoading] = useState<boolean>(false);

  const queryParams = new URLSearchParams(window.location.search);
  const invitedBy = queryParams.get("invitedBy");
  const invitedTo = queryParams.get("invitedTo");
  const invitedToEmail = queryParams.get("invitedToEmail");
  const invitedForCompany = queryParams.get("invitedForCompany");
  let type = "";
  
  useEffect(() => {
    if (invitedBy && invitedBy != "") {
      handlePageState({ invitedBy: invitedBy});
    }
    if(invitedToEmail && invitedToEmail!=''){
      setTimeout(() => {
        handlePageState({ email: decodeURI(invitedToEmail),type:'invite_team'});
      }, 1000);
    }
  }, [invitedBy]);

  const handleChangeSearch = (event: any) => {
    if (event.length) {
      setselectedOption([event[0]]);
      handlePageState({ post_code: event[0].Postcode });
      handleErrorData("post_code", false);
    } else {
      setselectedOption([]);
    }
  };

  const handleSearch = async (query: any) => {
    setIsSeachLoading(true);
    const res = await searchPostalCode({ postal_code: query });
    if (res.status) {
      setOptions(res.data);
    }
    setIsSeachLoading(false);
  };
  // Bypass client-side filtering by returning `true`. Results are already
  // filtered by the search endpoint, so no need to do it again.
  const filterBy = () => true;

  const [steps, setSteps] = useState<number>(1);
  const [errorState, setErrorState] = useState<object | any>(initialState);
  const [formState, setFormState] = useState<object | any>(initialState);
  

  const handlePageState = (newStates: any) => {
    setFormState((prevStates: any) => {
      return {
        ...prevStates,
        ...newStates,
        // email:invitedToEmail,
        company_name:invitedForCompany,
      };
    });
  };

  const handleChange = (event: { target: HTMLInputElement } | any) => {
    const { name, value } = event.target;
    setFormState((prevState: object | any) => {
      return {
        ...prevState,
        [name]: value,
      };
    });
    handleErrorData(name, !Boolean(value));
    event.preventDefault();
  };

  const handleErrorData = (key: string, value: boolean) => {
    setErrorState((prevState: object | any) => {
      return {
        ...prevState,
        [key]: value,
      };
    });
  };

  const validateSteps = (currentStep: number) => {

    if (currentStep === 1) {
      if (formState.serve_range === "") handleErrorData("serve_range", true);
      if (formState.post_code === "") handleErrorData("post_code", true);
      if (formState.serve_range !== "" && formState.post_code !== "") {
        setErrorState(initialState);
        setSteps(() => 2);
      }
    } else if (currentStep === 2) {
      let isValid = true;
      if (formState.name === "") {
        handleErrorData("name", true);
        isValid = false;
      }
      if (formState.email === "" || !validateEmail(formState.email)) {
        handleErrorData("email", true);
        isValid = false;
      }
      if (formState.fca_number === "") {
        handleErrorData("fca_number", true);
        isValid = false;
      }
      // if (formState.company_name === "") {
      //   handleErrorData("company_name", true);
      //   isValid = false;
      // }
      if (formState.password === "" || formState.password.length < 6) {
        handleErrorData("password", true);
        isValid = false;
      }
      if (formState.accept_tnc === false) {
        handleErrorData("accept_tnc", true);
        isValid = false;
      }
      if (isValid) {
        setErrorState(initialState);
        return true;
      }
      return false;
    }
  };

  const handleChangeCheckbox = (event: { target: HTMLInputElement } | any) => {
    const value = event.target.checked;
    setFormState((prevState: object | any) => {
      return {
        ...prevState,
        accept_tnc: value,
      };
    });
    handleErrorData("accept_tnc", !Boolean(value));
  };

  const onSubmitHandler = async (e: any) => {
    e.preventDefault();
    if (!validateSteps(2)) {
      return;
    }
    const res = await AdvisorSignUpRequest(formState);
    if (res.status || res.success) {
      addToast(res.message, { appearance: "success" });
      localStorage.setItem(JWT_TOKEN, res.token);
      localStorage.setItem(LOGGEDIN_USER, JSON.stringify(res.data));
      auth.login(res.data);
      setTimeout(() => {
        history.push(`${ADVISER_LEADS_MATCHES}`, {
          from: `/dashboard`,
        });
      }, 1000);
    } else {
      addToast(res.message || res?.error?.email[0], { appearance: "error" });
    }
  };

  return (
    <>
      <main className="dashboard">
        <section className="page-title h-430">
          <div className="container">
            <div className="row">
              <div className="col-md-12 mb-5 mb-md-0"></div>
            </div>
          </div>
        </section>

        <section className="recent-sec advice-area-sec register-final-sec register-postcode">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-12">
                {steps === 1 && (
                  <div className="recent-box account-page pt-5 mb-3">
                    <div className="row align-items-center justify-content-center mb-4">
                      <div className="col-xl-8 col-lg-10">
                        <div id="profile-tab" className="row">
                          <div className="col-lg-12 col-md-12">
                            <div className="heading adviser-content text-center text-center mb-4">
                              <h2 className="mb-lg-4 mb-2">
                                Where would you like to see leads from?
                              </h2>
                              <p className="mb-lg-4 mb-2">
                                Tells us the area you cover so we can show you
                                leads for your location
                              </p>
                              <span></span>
                            </div>
                          </div>
                          <div className="col-xl-8 offset-xl-2 col-lg-10 offset-lg-1 col-md-10 offset-md-1">
                            <div className="account-form mt-3 contact-preference">
                              <form>
                                <div className="form-row">
                                  <div className="form-group col-lg-12 p-0">
                                    <label
                                      htmlFor="cust_name"
                                      className="p-0 mb-2"
                                    >
                                      I serve customers
                                    </label>
                                  </div>

                                  <div className="form-group col-md-5 mb-0 p-0">
                                    <div className="select-data">
                                      <select
                                        name="serve_range"
                                        value={formState.serve_range}
                                        onChange={handleChange}
                                        className={
                                          errorState.serve_range
                                            ? "formElementError"
                                            : undefined
                                        }
                                      >
                                        <option value="">Select Range</option>
                                        {ADVISER_SEARCH_RANGE.map(
                                          (range: {
                                            value: string;
                                            name: string;
                                          }) => {
                                            return (
                                              <option
                                                key={range.value}
                                                value={range.value}
                                              >
                                                {range.name}
                                              </option>
                                            );
                                          }
                                        )}
                                      </select>
                                    </div>
                                  </div>
                                  <div className="form-group col-md-2 p-0 text-center">
                                    <label
                                      htmlFor="cust_name"
                                      className="p-0 mt-2 pt-1 mb-2"
                                    >
                                      from
                                    </label>
                                  </div>
                                  <div className="form-group col-md-5 p-0 location">
                                    {/* <input
                                      type="text"
                                      id="post_code"
                                      name="post_code"
                                      placeholder="TW2 6DD"
                                      onFocus={(e) =>
                                        (e.target.placeholder = "")
                                      }
                                      onBlur={(e) =>
                                        (e.target.placeholder = "TW2 6DD")
                                      }
                                      value={formState.post_code}
                                      onChange={handleChange}
                                      className={
                                        errorState.post_code
                                          ? "formElementError"
                                          : undefined
                                      }
                                    /> */}
                                    <AsyncTypeahead
                                      filterBy={filterBy}
                                      id="async-example"
                                      isLoading={isSeachLoading}
                                      labelKey="Postcode"
                                      minLength={2}
                                      onSearch={handleSearch}
                                      options={options}
                                      onChange={handleChangeSearch}
                                      selected={selectedOption}
                                      placeholder="TW2 6DD"
                                      className={
                                        errorState.post_code
                                          ? "postCodeSearchInner formElementErrorInner"
                                          : "postCodeSearchInner"
                                      }
                                      renderMenuItemChildren={(
                                        option: any,
                                        props: any
                                      ) => (
                                        <React.Fragment>
                                          <span>{option?.Postcode}</span>
                                        </React.Fragment>
                                      )}
                                    />
                                  </div>
                                  <div className="form-group col-md-12 text-center mt-lg-4 mt-0 text-center">
                                    <button
                                      type="button"
                                      className="theme-btn text-u\ppercase mr-md-3 mr-1"
                                      onClick={() => validateSteps(1)}
                                    >
                                      NEXT{" "}
                                      <i
                                        className="fa fa-chevron-right ml-2"
                                        aria-hidden="true"
                                      ></i>
                                    </button>
                                  </div>
                                </div>
                              </form>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                {steps === 2 && (
                  <div className="recent-box account-page pt-5 mb-3">
                    <div className="row align-items-center justify-content-center mb-4">
                      <div className="col-xl-8 col-lg-10">
                        <div id="profile-tab" className="row">
                          <div className="col-lg-12 col-md-12">
                            <div className="heading adviser-content text-center text-center mb-4">
                              <h2 className="mb-lg-4 mb-2">
                                Finally, just a few details
                              </h2>
                              <p className="mb-lg-4 mb-2">
                                You're one step away from seeing our mortgage
                                leads. Once registered you can add a photo,
                                provide company background, specify product and
                                customer preferences, add additional advisers
                                and much more.
                              </p>
                              <span></span>
                            </div>
                          </div>
                          <div className="col-lg-12 col-md-12">
                            <div className="account-form mt-3 final-form">
                              <form onSubmit={onSubmitHandler}>
                                <div className="form-row">
                                  <div className="col-lg-6">
                                    <div className="form-row">
                                      <div className="form-group col-lg-12">
                                        <label
                                          htmlFor="cust_name"
                                          className="p-0 mb-2"
                                        >
                                          Your name
                                        </label>
                                        <input
                                          type="text"
                                          id="cust_name"
                                          name="name"
                                          placeholder="Joe Bloggs"
                                          onFocus={(e) =>
                                            (e.target.placeholder = "")
                                          }
                                          onBlur={(e) =>
                                            (e.target.placeholder =
                                              "Joe Bloggs")
                                          }
                                          onChange={handleChange}
                                          className={
                                            errorState.name
                                              ? "formElementError"
                                              : undefined
                                          }
                                        />
                                      </div>{" "}
                                      <div
                                        className="form-group col-lg-12 lftp5"
                                        style={{
                                          paddingLeft: 0,
                                          paddingRight: "15px",
                                        }}
                                      >
                                        <label
                                          htmlFor="fca_number"
                                          className="p-0 mb-2"
                                        >
                                          Individual FCA number
                                        </label>
                                        <input
                                          type="text"
                                          id="fca_number"
                                          name="fca_number"
                                          placeholder="FCA personal reference number"
                                          onFocus={(e) =>
                                            (e.target.placeholder = "")
                                          }
                                          onBlur={(e) =>
                                            (e.target.placeholder = "FCA personal reference number")
                                          }
                                          onChange={handleChange}
                                          className={
                                            errorState.fca_number
                                              ? "formElementError"
                                              : undefined
                                          }
                                        />
                                      </div>
                                      <div className="form-group col-lg-12">
                                        <label
                                          htmlFor="cust_password"
                                          className="p-0 mb-2"
                                        >
                                          Password
                                        </label>
                                        <input
                                          type="password"
                                          id="cust_password"
                                          name="password"
                                          placeholder="Dog@1234"
                                          onFocus={(e) =>
                                            (e.target.placeholder = "")
                                          }
                                          onBlur={(e) =>
                                            (e.target.placeholder = "Dog@1234")
                                          }
                                          onChange={handleChange}
                                          className={
                                            errorState.password
                                              ? "formElementError"
                                              : undefined
                                          }
                                        />
                                        {errorState.password &&
                                          formState.password.length < 6 && (
                                            <small
                                              className={`formElementTxtError`}
                                            >
                                              The password must be at least 6
                                              characters.
                                            </small>
                                          )}
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-lg-6">
                                    <div className="form-row">
                                      <div className="form-group col-lg-12">
                                        <label
                                          htmlFor="cust_email"
                                          className="p-0 mb-2"
                                        >
                                          Email address
                                        </label>
                                        {invitedToEmail!=null ?
                                        <input
                                          type="text"
                                          id="cust_email"
                                          name="email"
                                          placeholder="joi@bloogs.com1"
                                          onFocus={(e) =>
                                            (e.target.placeholder = "")
                                          }
                                          onBlur={(e) =>
                                            (e.target.placeholder =
                                              "joi@bloogs.com")
                                          }
                                          disabled
                                          value={formState?.email}
                                          onChange={handleChange}
                                          className={
                                            errorState.email
                                              ? "formElementError"
                                              : undefined
                                          }
                                        />:<input
                                          type="email"
                                          id="cust_email"
                                          name="email"
                                          placeholder="joi@bloogs.com2"
                                          onFocus={(e) =>
                                            (e.target.placeholder = "")
                                          }
                                          onBlur={(e) =>
                                            (e.target.placeholder =
                                              "joi@bloogs.com")
                                          }
                                          onChange={handleChange}
                                          className={
                                            errorState.email
                                              ? "formElementError"
                                              : undefined
                                          }
                                        />}
                                      </div>

                                      <div
                                        className="form-group col-lg-12 lftp5"
                                        style={{
                                          paddingLeft: 0,
                                          paddingRight: "15px",
                                        }}
                                      >
                                        <label
                                          htmlFor="comp_name"
                                          className="p-0 mb-2"
                                        >
                                          Company name
                                        </label>
                                        {invitedForCompany!=null?
                                        <input
                                          type="text"
                                          id="comp_name"
                                          name="company_name"
                                          placeholder="Oxyon corporation"
                                          onFocus={(e) =>
                                            (e.target.placeholder = "")
                                          }
                                          disabled
                                          value={formState?.company_name}
                                          onBlur={(e) =>
                                            (e.target.placeholder =
                                              "Oxyon corporation")
                                          }
                                          onChange={handleChange}
                                          className={
                                            errorState.company_name
                                              ? "formElementError"
                                              : undefined
                                          }
                                        />:<input
                                          type="text"
                                          id="comp_name"
                                          name="company_name"
                                          placeholder="Oxyon corporation"
                                          onFocus={(e) =>
                                            (e.target.placeholder = "")
                                          }
                                          onBlur={(e) =>
                                            (e.target.placeholder =
                                              "Oxyon corporation")
                                          }
                                          onChange={handleChange}
                                          className={
                                            errorState.company_name
                                              ? "formElementError"
                                              : undefined
                                          }
                                        />}
                                        <span>
                                          If you are not a business you can
                                          leave this blank
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="form-row">
                                  <div className="col-lg-12">
                                    <div className="form-row">
                                      <div className="form-group col-xl-8 offset-xl-2 checkboxes-data mb-lg-0 mb-3 mt-4 text-center">
                                        <input
                                          type="checkbox"
                                          id="accept_tnc"
                                          name="accept_tnc"
                                          value="1"
                                          onChange={handleChangeCheckbox}
                                        />
                                        <label
                                          htmlFor="accept_tnc"
                                          className={
                                            errorState.accept_tnc
                                              ? "formElementError"
                                              : undefined
                                          }
                                        >
                                          I have read and agree to the{" "}
                                          <a href="/adviser/terms-conditions" target="_blank">
                                            terms and conditions
                                          </a>{" "}
                                          and{" "}
                                          <a href="/adviser/privacy-policy" target="_blank">
                                            privacy policy
                                          </a>
                                        </label>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-lg-12">
                                    <div className="form-group col-md-12 text-center mt-lg-4 mt-0 text-center">
                                      <button
                                        type="submit"
                                        className="theme-btn text-uppercase mr-md-3 mr-1"
                                      >
                                        SEE LEADS
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </form>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
          <img
            src="assets/images/expert-left.png"
            alt="help-1"
            className="img-fluid before-img"
          />
        </section>
      </main>
    </>
  );
};

export default AdvisorSignUp;
