import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { contactUs } from "../components/common/service";
import useLoggedInUserData from "../utility/useLoggedInUserData";
import LoadingSpinner from "../components/common/LoadingSpinner";
import InlineAlert from "../components/common/InlineAlert";
import { validateEmail } from "../utility/commonUtils";
const currentUser = JSON.parse(localStorage.getItem('loggedIn_user') || '{}');
const initialState = {
  name: "",
  email: "",
  mobile: "",
  message: "",
  isError: false,
  responseMessage: "",
};

const ContactUs = () => {
  const [faqPopular, setFaq] = useState<any>([]);
  const [faqCategory, setFaqCategory] = useState<any>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [formState, setFormState] = useState(initialState);
  const imageUrl = `${process.env.REACT_APP_IMAGE_URL}faq_category/original/`;
  const user = useLoggedInUserData();  
  const handlePageState = (newStates: any) => {
    setFormState((prevStates) => {
      return {
        ...prevStates,
        ...newStates,
      };
    });
  };
  const handleChange = (event: { target: HTMLInputElement } | any) => {
    const { name, value } = event.target;
    handlePageState({ [name]: value });
    event.preventDefault();
  };

  const onSubmitHandler = async (e: any) => {
    e.preventDefault();
    if (!validateEmail(formState.email)) {
      handlePageState({ responseMessage: "Email is required.", isError: true });
      return;
    }
    if (formState.name === "") {
      handlePageState({
        responseMessage: "Name is required.",
        isError: true,
      });
      return;
    }
    if (formState.message === "") {
      handlePageState({
        responseMessage: "Message is required.",
        isError: true,
      });
      return;
    }
    const res = await contactUs(formState);
    if (res.status) {
      handlePageState({
        responseMessage: "Contact us query submitted successfully.",
        isError: false,
      });
      const initialState = {
        name: "",
        email: "",
        mobile: "",
        message: "",
      };
      handlePageState({ ...initialState });
    } else {
      handlePageState({ responseMessage: res.message, isError: true });
    }
  };
  function setFaqLocalStorage(faq: any) {
    localStorage.setItem('faqList',JSON.stringify(faq));
  }
  return (
    <>
      <main className="dashboard">
        <section className="page-title">
          <div className="container">
            <div className="row">
              <div className="col-md-12 mb-5 mb-md-0"></div>
            </div>
          </div>
        </section>

        <section className="recent-sec advice-area-sec">
          <img
            src="assets/images/expert-right.png"
            alt="help-1"
            className="img-fluid after-img"
          />
          <div className="container">
            <div className="row">
              <div className="col-12 back-btn mt-lg-3 mb-lg-3 mt-3 mb-0">
                <Link to={`/dashboard`} className="back-btn">
                  <i className="fa fa-angle-left" aria-hidden="true"></i>
                  Back to Dashboard
                </Link>
              </div>
            </div>
          </div>
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-12">
                <div className="recent-box account-page pt-5 mb-3">
                  <div className="row align-items-center justify-content-center mb-4">
                    <div className="col-lg-6">
                      <div id="profile-tab" className="row">
                        <div className="col-lg-12 col-md-12">
                          <div className="heading adviser-content text-center text-center mb-4">
                            <h2 className="mb-lg-4 mb-2">Contact Us</h2>
                            <span></span>
                          </div>
                        </div>

                        {isLoading ? (
                          <div className="col-lg-12 col-md-12">
                            <LoadingSpinner loading={isLoading} />
                          </div>
                        ) : (
                          <div className="col-lg-10 offset-lg-1 col-md-12">
                            {formState.responseMessage && (
                              <InlineAlert
                                isError={formState.isError}
                                message={formState.responseMessage}
                              />
                            )}
                            <div className="account-form mt-3">
                              <form onSubmit={onSubmitHandler}>
                                <div className="form-row">
                                  <div className="form-group col-lg-12">
                                    <label
                                      htmlFor="cust_name"
                                      className="p-0 mb-2"
                                    >
                                      Your name
                                    </label>
                                    <input
                                      type="text"
                                      name="name"
                                      placeholder="Name"
                                      value={formState.name}
                                      onChange={handleChange}
                                      required
                                    />
                                  </div>
                                  <div className="form-group col-lg-12">
                                    <label
                                      htmlFor="cust_email"
                                      className="p-0 mb-2"
                                    >
                                      Email address
                                    </label>
                                    <input
                                      type="email"
                                      id="cust_email"
                                      name="email"
                                      placeholder="Email"
                                      value={formState.email}
                                      onChange={handleChange}
                                      required
                                    />
                                  </div>
                                  <div className="form-group col-lg-12">
                                    <label
                                      htmlFor="cust_name"
                                      className="p-0 mb-2"
                                    >
                                      Mobile
                                    </label>
                                    <input
                                      type="text"
                                      id="cust_name"
                                      name="mobile"
                                      placeholder="Mobile"
                                      value={formState.mobile}
                                      onChange={handleChange}
                                    />
                                  </div>
                                  <div className="form-group col-lg-12">
                                    <label
                                      htmlFor="cust_name"
                                      className="p-0 mb-2"
                                    >
                                      Message
                                    </label>
                                    <textarea
                                      id="notes-textarea"
                                      name="message"
                                      placeholder="Message"
                                      value={formState.message}
                                      onChange={handleChange}
                                      required
                                    ></textarea>
                                  </div>
                                  <div className="form-group col-md-12 text-center mt-lg-4 mt-0">
                                    <button
                                      type="submit"
                                      className="theme-btn text-uppercase mr-md-3 mr-1"
                                    >
                                      Submit
                                    </button>
                                  </div>
                                </div>
                              </form>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <img
            src="assets/images/expert-left.png"
            alt="help-1"
            className="img-fluid before-img"
          />
        </section>
      </main>
    </>
  );
};

export default ContactUs;
