import React, { useEffect, useState } from "react";
import { Link,useParams } from "react-router-dom";
import LoadingSpinner from "../../components/common/LoadingSpinner";
import { useToasts } from "react-toast-notifications";
import {
  getServiceType,
  searchAdvisorsViaFilters,
  searchPostalCode,
  searchLocationPostal,
} from "../../components/common/service";
import AdviserProfileSearchedCard from "../../components/directory/AdviserProfileSearchedCard";
import CreateMortgageNeedNotice from "../../components/directory/CreateMortgageNeedNotice";
import { AsyncTypeahead } from "react-bootstrap-typeahead";

import {
  GENDER_ARR,
  LANGUAGES_ARR,
  MORTGAGE_ADVICE_ARR,
  MORTGAGE_PRICE_ARR,
  MORTGAGE_PRICE_USEREND_ARR,
  MORTGAGE_TIME_ARR,
  NO_DATA_FOUND,
} from "../../Constants";

const initialState = {
  post_code: "",
  advice_area: [],
  mortgage_value: [],
  how_soon: [],
  local_advisor: 0,
  gender: "",
  language: "",
  county: "", 
  localauthority: "",
  place: ""
};

const Directory = (props: any) => {
  const urlParams: { county: any, localauthority: any, place: any } = useParams();
  var { county, localauthority,place } = urlParams;
  const [formState, setFormState] = useState(initialState);
  const [advisorsList, setAdvisorsList] = useState<any>([]);
  const [advisorsList2, setAdvisorsList2] = useState<any>([]);
  const [isSearched, setIsSearched] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { addToast } = useToasts();
  const [isSeachLoading, setIsSeachLoading] = useState<boolean>(false);
  const [isLocationSeachLoading, setIsLocationSeachLoading] = useState<boolean>(false);
  const [options, setOptions] = useState<any>([]);
  const [locationOptions, setLocationOptions] = useState<any>([]);
  const [selectedOption, setselectedOption] = useState<any>([]);
  const [selectedLocationOption, setselectedLocationOption] = useState<any>([]);
  const [serviceType, setService] = useState<any>([]);
  useEffect(() => {
    getServices();
  }, []);
  const getServices = async () => {
    setIsLoading(true);
    const res = await getServiceType();
    if (res?.success || res.status) {
      setService(res.data);
    }
    setIsLoading(false);
  };
  const handleChangeSearch = (event: any) => {
    if (event.length) {
      setselectedOption([event[0]]);
      handlePageState({ post_code: event[0].Postcode });
    } else {
      handlePageState({ post_code: '' });
      setselectedOption([]);
    }
  };

  const handleLocationChangeSearch = (event: any) => {
    if (event.length) {
      setselectedLocationOption([event[0]]);
      handlePageState({ post_code: '', county: event[0].County, localauthority:  event[0].LocalAuthority, place: event[0].Place });
    } else {
      setselectedLocationOption([]);
      handlePageState({ post_code: '', county: '', localauthority:  '', place: '' });
    }
  };

  const handleSearch = async (query: any) => {
    setIsSeachLoading(true);
    const res = await searchPostalCode({ postal_code: query });
    if (res.status) {
      setOptions(res.data);
    }
    setIsSeachLoading(false);
  };

  const handleLocationSearch = async (query: any) => {
    setIsLocationSeachLoading(true);
    const res = await searchLocationPostal({ search_key: query });
    if (res.status) {
      setLocationOptions(res.data);
    }
    setIsLocationSeachLoading(false);
  };
  // Bypass client-side filtering by returning `true`. Results are already
  // filtered by the search endpoint, so no need to do it again.
  const filterBy = () => true;
  const locationFilterBy = () => true;

  React.useEffect(() => {
    handlePageState({ post_code: props.location.state.post_code });
    setselectedOption([{ id: 0, Postcode: props.location.state.post_code }]);
    if (!isLoading) {
      fetchResults(props.location.state.post_code);
    }
  }, [props.location.state.post_code]);


  const handlePageState = (newStates: any) => {
    setFormState((prevStates: any) => {
      return {
        ...prevStates,
        ...newStates,
      };
    });
  };

  const handleChangeCheckbox = (event: { target: HTMLInputElement } | any) => {
    const { name, value, checked } = event.target;
    if (["local_advisor"].includes(name)) {
      handlePageState({ [name]: +checked });
    } else {
      setFormState((prevStates: any) => {
        const item = prevStates[name];
        return {
          ...prevStates,
          [name]: checked
            ? [...item, value]
            : item.filter((item: string) => item !== value),
        };
      });
    }
  };

  const handleChange = (event: { target: HTMLInputElement } | any) => {
    // const { name, value, checked } = event.target;
    // setFormState((prevStates: any) => {
    //   const item = prevStates[name];
    //   return {
    //     ...prevStates,
    //     [name]: checked
    //       ? [...item, value]
    //       : item.filter((item: string) => item !== value),
    //   };
    // });
    const { name, value } = event.target;
    handlePageState({ [name]: value });
  };

  const handleClearFilters = () => {
    setFormState(initialState);
    $('.advice_area').prop('checked', false);
    $('.how_soon').prop('checked', false);
    $('.mortgage_value').prop('checked', false);
    $('.local_advisor').prop('checked', false);
    // $(".form-group.checkboxes-data label").css("background-color", "unset !important");
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    fetchResults(formState.post_code);
  };

  const fetchResults = async (post_code: any) => {
    setIsSearched(true);
    setIsLoading(true);
    if(!post_code){
      post_code = '';
    }
    //console.log({post_code: post_code, county: county, localauthority: localauthority, place: place });
    //const FormData = { ...formState, post_code: post_code, county: county, localauthority: localauthority, place: place };
    var FormData = { ...formState};
    if(FormData.place=='' && FormData.post_code==''){
      if(post_code!=''){
        FormData = { ...formState, post_code: post_code};
      }
      else{
        setIsLoading(false);
        setIsSearched(false);
        addToast("Please select location or postal code.", { appearance: "error" });
        return false;
      }
    }
    const res = await searchAdvisorsViaFilters(FormData);
    if (res.status) {      
      if(res.data.length>2){
        const rows1 = [];
        for (let i = 0; i < 2; i++) {
          rows1.push(res.data[i]);
        }
        setAdvisorsList(rows1);
        const rows = [];
        for (let i = 2; i < res.data.length; i++) {
            rows.push(res.data[i]);
        }
        setAdvisorsList2(rows);
      }
      else{
        setAdvisorsList(res.data);
        setAdvisorsList2([]);  
      }
    } else {
      setAdvisorsList([]);
      setAdvisorsList2([]);
    }
    setIsLoading(false);
    setIsSearched(false);
  };

  return (
    <>
      <main className="dashboard">
        <section className="page-title leadmatch">
          <div className="container">
            <div className="row">
              <div className="col-md-12 mb-5 mb-md-0"></div>
            </div>
          </div>
        </section>

        <section className="recent-sec advice-area-sec profile-sec">
          <div className="container">
            <div className="row"></div>
          </div>
          <div className="container">
            <div className="row justify-content-center mt-3 mb-5">
              <div className="col-lg-12">
                <div className="recent-box">
                  <div className="row align-items-center justify-content-center mb-4 directory-section">
                    <div className="col-lg-12">
                      <div id="profile-tab" className="row">
                        <div className="col-lg-4 col-md-12 mb-5">
                          <div className="left-content">
                            <form onSubmit={handleSubmit}>
                              <div className="">
                                <h6>Search Tip</h6>
                                <p>
                                  Get better matches by giving us more
                                  information
                                </p>
                              </div>
                              <span className="border"></span>
                              <div className="">
                                <p>Mortgage advisers near</p>
                                <div className="form-row">
                                  <div className="col-12">
                                    {/* <input
                                      type="text"
                                      placeholder="TW12 2SF"
                                      name="post_code"
                                      value={formState.post_code}
                                      onChange={handleChange}
                                    /> */}
                                    <AsyncTypeahead
                                      filterBy={filterBy}
                                      id="async-example"
                                      isLoading={isSeachLoading}
                                      labelKey="Postcode"
                                      minLength={2}
                                      onSearch={handleSearch}
                                      options={options}
                                      onChange={handleChangeSearch}
                                      selected={selectedOption}
                                      placeholder="TW12 1AA"
                                      defaultInputValue={props.location.state.post_code}
                                      className="postCodeSearchInnerAccount"
                                      renderMenuItemChildren={(
                                        option: any,
                                        props: any
                                      ) => (
                                        <React.Fragment>
                                          <span>{option?.Postcode}</span>
                                        </React.Fragment>
                                      )}
                                    />
                                  </div>
                                  <div className="col-12 center"  style={{ textAlign: "center", marginBottom: 10 }}>
                                    OR
                                  </div>
                                  <div className="col-12">
                                    {/* <input
                                      type="text"
                                      placeholder="TW12 2SF"
                                      name="post_code"
                                      value={formState.post_code}
                                      onChange={handleChange}
                                    /> */}
                                    <AsyncTypeahead
                                      filterBy={locationFilterBy}
                                      id="location-async-example"
                                      isLoading={isLocationSeachLoading}
                                      labelKey="location"
                                      minLength={3}
                                      onSearch={handleLocationSearch}
                                      options={locationOptions}
                                      onChange={handleLocationChangeSearch}
                                      selected={selectedLocationOption}
                                      placeholder="Enter Location"
                                      className="postCodeSearchInnerAccount"
                                      renderMenuItemChildren={(
                                        locationOptions: any,
                                        props: any
                                      ) => (
                                        <React.Fragment>
                                          <span>{locationOptions?.location}</span>
                                        </React.Fragment>
                                      )}
                                    />
                                  </div>
                                </div>
                              </div>

                              <span className="border"></span>
                              <div id="accordion" className="accordion">
                                <div className="accordion-directory">
                                  <div
                                    className="ques collapsed mb-4"
                                    data-toggle="collapse"
                                    aria-expanded="true"
                                    data-href="#collapse-0"
                                    data-target="#collapse-0"
                                  >
                                    <p>What would you like advice on?</p>
                                  </div>
                                  <div
                                    id="collapse-0"
                                    className="collapse"
                                    data-parent="#accordion"
                                  >
                                    <div className="answers">
                                      <div className="row">
                                        <div className="col-md-6 col-6">
                                          {serviceType.slice(0, 6).map(
                                            (adv_area: any) => {
                                              return (
                                                <>
                                                  <div
                                                    key={adv_area.value}
                                                    className="form-group checkboxes-data mb-0"
                                                  >
                                                    <input
                                                      type="checkbox"
                                                      className = "advice_area"
                                                      id={adv_area.id}
                                                      name="advice_area"
                                                      value={adv_area.id}
                                                      onChange={
                                                        handleChangeCheckbox
                                                      }
                                                    />
                                                    <label
                                                      htmlFor={adv_area.id}
                                                    >
                                                      {adv_area.name}
                                                    </label>
                                                  </div>
                                                </>
                                              );
                                            }
                                          )}
                                        </div>
                                        <div className="col-md-6 col-6">
                                          {serviceType.slice(6).map(
                                            (adv_area: any) => {
                                              return (
                                                <>
                                                  <div
                                                    key={adv_area.id}
                                                    className="form-group checkboxes-data mb-0"
                                                  >
                                                    <input
                                                      type="checkbox"
                                                      id={adv_area.id}
                                                      className = "advice_area"
                                                      name="advice_area"
                                                      value={adv_area.id}
                                                      onChange={
                                                        handleChangeCheckbox
                                                      }
                                                    />
                                                    <label
                                                      htmlFor={adv_area.id}
                                                    >
                                                      {adv_area.name}
                                                    </label>
                                                  </div>
                                                </>
                                              );
                                            }
                                          )}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>

                                <span className="border"></span>
                                <div className="accordion-directory">
                                  <div
                                    className="ques collapsed mb-4"
                                    data-toggle="collapse"
                                    aria-expanded="true"
                                    data-href="#collapse-2"
                                    data-target="#collapse-2"
                                  >
                                    <p>What size mortgage do you want?</p>
                                  </div>
                                  <div
                                    id="collapse-2"
                                    className="collapse"
                                    data-parent="#accordion"
                                  >
                                    <div className="answers">
                                      <div className="row">
                                        <div className="col-md-6 col-6">
                                          {MORTGAGE_PRICE_USEREND_ARR.slice(0, 3).map(
                                            (adv_price: any) => {
                                              return (
                                                <>
                                                  <div
                                                    key={adv_price.value}
                                                    className="form-group checkboxes-data mb-0"
                                                  >
                                                    <input
                                                      type="checkbox"
                                                      id={adv_price.key}
                                                      className = "mortgage_value"
                                                      name="mortgage_value"
                                                      value={adv_price.key}
                                                      onChange={
                                                        handleChangeCheckbox
                                                      }
                                                    />
                                                    <label
                                                      htmlFor={adv_price.key}
                                                    >
                                                      {adv_price.name}
                                                    </label>
                                                  </div>
                                                </>
                                              );
                                            }
                                          )}
                                        </div>
                                        <div className="col-md-6 col-6">
                                          {MORTGAGE_PRICE_USEREND_ARR.slice(3, 6).map(
                                            (adv_price: any) => {
                                              return (
                                                <>
                                                  <div
                                                    key={adv_price.key}
                                                    className="form-group checkboxes-data mb-0"
                                                  >
                                                    <input
                                                      type="checkbox"
                                                      id={adv_price.key}
                                                      className = "mortgage_value"
                                                      name="mortgage_value"
                                                      value={adv_price.key}
                                                      onChange={
                                                        handleChangeCheckbox
                                                      }
                                                    />
                                                    <label
                                                      htmlFor={adv_price.key}
                                                    >
                                                      {adv_price.name}
                                                    </label>
                                                  </div>
                                                </>
                                              );
                                            }
                                          )}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>

                                <span className="border"></span>
                                <div className="accordion-directory">
                                  <div
                                    className="ques collapsed mb-4"
                                    data-toggle="collapse"
                                    aria-expanded="true"
                                    data-href="#collapse-3"
                                    data-target="#collapse-3"
                                  >
                                    <p>How soon?</p>
                                  </div>
                                  <div
                                    id="collapse-3"
                                    className="collapse"
                                    data-parent="#accordion"
                                  >
                                    <div className="answers">
                                      <div className="row">
                                        <div className="col-md-12 col-12">
                                          {MORTGAGE_TIME_ARR.map(
                                            (adv_time: any) => {
                                              return (
                                                <>
                                                  <div
                                                    key={adv_time.key}
                                                    className="form-group checkboxes-data mb-0"
                                                  >
                                                    <input
                                                      type="checkbox"
                                                      id={adv_time.key}
                                                      name="how_soon"
                                                      className = "how_soon"
                                                      value={adv_time.key}
                                                      onChange={
                                                        handleChangeCheckbox
                                                      }
                                                    />
                                                    <label
                                                      htmlFor={adv_time.key}
                                                    >
                                                      {adv_time.name}
                                                    </label>
                                                  </div>
                                                </>
                                              );
                                            }
                                          )}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>

                                <span className="border"></span>
                                <div className="accordion-directory">
                                  <div
                                    className="ques collapsed mb-4"
                                    data-toggle="collapse"
                                    aria-expanded="true"
                                    data-href="#collapse-4"
                                    data-target="#collapse-4"
                                  >
                                    <p>Adviser preference</p>
                                  </div>
                                  <div
                                    id="collapse-4"
                                    className="collapse"
                                    data-parent="#accordion"
                                  >
                                    <div className="form-row">
                                      <div className="col-12 mb-3">
                                        <div className="form-group checkboxes-data mb-0">
                                          <input
                                            type="checkbox"
                                            id="local_advisers"
                                            name="local_advisor"
                                            className = "local_advisor"
                                            value={1}
                                            onChange={handleChangeCheckbox}
                                          />
                                          <label htmlFor="local_advisers">
                                            Local advisers only
                                          </label>
                                        </div>
                                      </div>
                                      <div className="col-12 mb-3">
                                        <div className="row align-items-center">
                                          <div className="col-md-6">
                                            <div className="form-group mb-0">
                                              <p className="mb-0">
                                                Adviser gender
                                              </p>
                                            </div>
                                          </div>
                                          <div className="col-md-6 directory_popup">
                                            <div className="form-group checkboxes-data mb-0">
                                              <select
                                                id="adviser_gender"
                                                name="gender"
                                                onChange={handleChange}
                                                value={formState.gender}
                                              >
                                                {GENDER_ARR.map(
                                                  (gender: any) => {
                                                    return (
                                                      <option
                                                        key={gender}
                                                        value={gender}
                                                      >
                                                        {gender}
                                                      </option>
                                                    );
                                                  }
                                                )}
                                              </select>
                                              <i
                                                className="fa fa-chevron-down"
                                                aria-hidden="true"
                                              ></i>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="col-12 mb-3">
                                        <div className="row align-items-center">
                                          <div className="col-md-6">
                                            <div className="form-group mb-0">
                                              <p className="mb-0">
                                                Adviser language
                                              </p>
                                            </div>
                                          </div>
                                          <div className="col-md-6 directory_popup">
                                            <div className="form-group checkboxes-data mb-0">
                                              <select
                                                id="adviser_language"
                                                name="language"
                                                onChange={handleChange}
                                                value={formState.language}
                                              >
                                                {LANGUAGES_ARR.map(
                                                  (lang: {
                                                    value: string;
                                                    name: string;
                                                  }) => {
                                                    return (
                                                      <option
                                                        key={lang.value}
                                                        value={lang.value}
                                                      >
                                                        {lang.name}
                                                      </option>
                                                    );
                                                  }
                                                )}
                                              </select>
                                              <i
                                                className="fa fa-chevron-down"
                                                aria-hidden="true"
                                              ></i>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <span className="border"></span>

                              <div className="">
                                <div className="form-row">
                                  <div className="col-xl-6 col-lg-12 col-md-6 col-6 mb-3 text-center">
                                    <button type="submit" className="theme-btn">
                                      SEARCH
                                    </button>
                                  </div>
                                  <div className="col-xl-6 col-lg-12 col-md-6 col-6 mb-3 text-center">
                                    <button
                                      className="theme-btn-border"
                                      onClick={handleClearFilters}
                                    >
                                      CLEAR
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </form>
                          </div>
                        </div>
                        <div className="profile-data col-lg-8 col-md-12">
                          <div className="right-content ml-lg-4 ml-0">
                            <h5 className="text-uppercase">
                              {advisorsList.length
                                ? `top ${advisorsList.length + advisorsList2.length} mortgage advisers near ${formState?.place!=''?formState?.place:formState?.post_code}`
                                : null}
                            </h5>
                            <span className="border"></span>

                            <LoadingSpinner loading={isLoading} />
                            {!isLoading && advisorsList.length ? (
                              advisorsList.map((advisor: any, index: any) => (
                                <AdviserProfileSearchedCard
                                  key={advisor.id}
                                  advisor={advisor}
                                />
                              ))
                            ) : (
                              <>
                                {/* <div className="col-md-12 pt-3 text-center">
                                  <div className="view-btn text-center font-weight-bold">
                                    {NO_DATA_FOUND}
                                  </div>
                                </div> */}
                                <CreateMortgageNeedNotice />
                              </>
                            )}

                            {!isLoading && advisorsList.length ? (
                            <div className="login_popup">
                            <div className="datas mb-md-5 left-content" style={{ borderRadius: 25 }}>
                            <div className="para-boder" style={{ padding: 30 }}>
                              <h5 className="mb-3 text-white">
                                Don't just talk to one adviser for such a big decision!
                              </h5>
                              <p>
                                Free expert mortgage advice from five advisers competing to secure you the
                                best deal possible, giving you more choice and higher acceptance rates.
                                Save hundreds of pounds.
                              </p>
                              <a
                                className="theme-btn-border"
                                href="/need"
                                style={{
                                  border: "1px solid #ffffff",
                                  backgroundColor: "transparent",
                                  color: "#ffffff"
                                }}
                              >
                                Match Me
                              </a>
                            </div>
                          </div>
                          </div>
                          
                            ) : 
                              null
                            }


                            {!isLoading && advisorsList2.length ? (
                              advisorsList2.map((advisor: any, index: any) => (
                                <AdviserProfileSearchedCard
                                  key={advisor.id}
                                  advisor={advisor}
                                />
                              ))
                            ) : 
                              null
                            }
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  );
};
export default Directory;
