import React, { useEffect, useState }  from "react";
import { DEFAULT_CURRENCY } from "../../Constants";
import {
  capitalize,
  numberWithCommas,
  prettyTimeAgo,
} from "../../utility/commonUtils";
import { markAreaAsRead } from "../common/service";

const MortgageCard = (props: any) => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  
  const pathname = window.location.pathname; 
  useEffect(() => {
    
  }, [props]);

  const getCostOfLead = props.cost_of_lead || "";
  const getCostOfLeadDrops = props.cost_of_lead_drop || "";
  const fullName = props.isAccepted
    ? [props.name]
    : props.name
    ? props.name.split(" ")
    : [];

  const fullNameS = props.isAccepted? '': fullName[1]? fullName[1]: '';
  return (
    <li
      className="nav-item location-data read"
      style={{ display: props.index < 2 ? "list-item" : "list-item" }}
      onClick={() => {
        props.updateSelectedLead();
        setIsLoading(true);
        markAreaAsRead({'area_id':props.id}).then((res) => {
          setIsLoading(false);
        });
        // background-color: unset;
        // border: 1px solid #a6a8ab !important;
      }}
    >
      <a
        className={(props.is_read? "unread-area":'unread-area')+" p-0"}
        data-toggle="pill"
        href="#tab1"
        role="tab"
        aria-controls="pills-flamingo"
        aria-selected="true"
      >
        <div>
          <div className="row align-items-center pb-1">
            <div className="col-12">
              <ul className="pl-0 ml-0 mb-0 name-loc-days">
                <li>
                  <h4 className="mb-0">
                    {capitalize(fullName.length ? fullName[0] : "")} {capitalize(fullName.length>1 ? fullName[1].charAt(0) : "")} {pathname!='/adviser/leads-accepted'?<span style={{ color: "#000" }}>({props?.service? (capitalize(props?.service.name)):""})</span>:<div><span style={{ color: "#000" }}>({props?.service? (capitalize(props?.service.name)):""})</span></div>}
                  </h4>
                </li>
                {/* <li>
                  <p className="mb-0">
                    <img src="assets/images/icon1.png" alt="" /> Long Dutton
                  </p>
                </li> */}
                <li>
                  <span style={{ float: "right" }}>
                    {prettyTimeAgo(props?.created_at)}
                  </span>
                </li>
              </ul>
            </div>
          </div>
          <div className="row mt-md-2 mt-2">
            <div className="col-7">
              <h6>Mortgage need</h6>
            </div>
            <div className="col-5">
              <h6>
                <b>
                  {props?.size_want_currency || DEFAULT_CURRENCY}
                  {numberWithCommas(props?.size_want)}
                </b>
              </h6>
            </div>
            <div className="col-7">
              <h6>Property value</h6>
            </div>
            <div className="col-5">
              <h6>
                <b>
                  {props?.property_currency || DEFAULT_CURRENCY}
                  {numberWithCommas(props?.property)}
                </b>
              </h6>
            </div>
            <div className="col-7">
              <h6>Combined income</h6>
            </div>
            <div className="col-5">
              <h6>
                <b>
                  {props?.combined_income_currency || DEFAULT_CURRENCY}
                  {numberWithCommas(props?.combined_income)}
                </b>
              </h6>
            </div>
            {/* {pathname!='/adviser/leads-accepted'?
            <div className="col-12 mt-2">
              <h6>
                <img src="assets/images/icon2.png" alt="" /> {getCostOfLead}
              </h6>
            </div>:null}
            {pathname!='/adviser/leads-accepted'? getCostOfLeadDrops != "" ? (
              <div className="col-12 mt-2">
                <h6>
                  <img src="assets/images/icon3.png" alt="" />{" "}
                  {getCostOfLeadDrops}
                </h6>
              </div>
            ) : null:null} */}
            {pathname!='/adviser/leads-accepted'?
            <div className="col-12 mt-2">
              <h6>
                <img src="assets/images/icon2.png" alt="" /> Cost of lead: {props?.cost_of_lead_amount}
              </h6>
            </div>:null}
          </div>
        </div>
      </a>
    </li>
  );
};

export default MortgageCard;
