import React, { useEffect, useState } from "react";
import useLoggedInUserData from "../../../../utility/useLoggedInUserData";
import InlineAlert from "../../InlineAlert";
import { addReview, ReportNeedSpam } from "../../service";
import $ from "jquery";
import "bootstrap";

const initialState = {
  reason: "",
  area_id: 0,
  isError: false,
  responseMessage: "",
};

const ReportSpamModal = (props: any) => {
  const user = useLoggedInUserData();

  const [formState, setFormState] = useState(initialState);

  useEffect(() => {
    handlePageState({
      area_id: props.id,
    });
  }, [props.id]);

  const handlePageState = (newStates: any) => {
    setFormState((prevStates) => {
      return {
        ...prevStates,
        ...newStates,
      };
    });
  };

  const onSubmitHandler = async (e: any) => {
    e.preventDefault();
    const res = await ReportNeedSpam({
      area_id: props.lead.id,
      reason: formState.reason,
    });
    if (res.status) {
      handlePageState({ responseMessage: res.message, isError: false });
      setTimeout(() => {
        $("#report_as_spam").modal("hide");
        $("body").removeClass("modal-open");
        $(".modal-backdrop").remove();
        handlePageState({ ...initialState });
      }, 1000);
    } else {
      handlePageState({ responseMessage: res.message, isError: true });
    }
  };

  return (
    <>
      <div
        className="mail-popup modal fade popup-model add_offeres"
        id="report_as_spam"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-body">
              <div className="row">
                <div className="col-md-12">
                  <div className="heading text-center mb-4">
                    <h4 className="pb-2">Report Spam</h4>
                  </div>
                </div>
              </div>
              <form onSubmit={onSubmitHandler}>
                {formState.responseMessage && (
                  <InlineAlert
                    isError={formState.isError}
                    message={formState.responseMessage}
                  />
                )}
                <div className="row">
                  <div className="col-12">
                    <div className="reason">
                      <label>Reason</label>
                      <textarea
                        id="reason"
                        name="reason"
                        value={formState.reason}
                        onChange={(e) =>
                          handlePageState({
                            reason: e.target.value,
                          })
                        }
                        required
                      ></textarea>
                    </div>
                  </div>
                  <div className="col-md-12 text-center mt-lg-2 mt-0 settings-data">
                    <button
                      type="submit"
                      className="theme-btn text-uppercase mr-md-3 mr-1 mb-md-0 mb-3"
                    >
                      Report
                    </button>
                    <button
                      type="button"
                      className="theme-btn-border text-uppercase"
                      data-dismiss="modal"
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ReportSpamModal;
