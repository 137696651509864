import React, { useState, useContext } from "react";
import { useHistory } from "react-router-dom";
import {
  ADVISER_LEADS_MATCHES,
  JWT_TOKEN,
  LOGGEDIN_USER,
} from "../../Constants";
import { AuthContext } from "../../shared/context/auth-context";
import { validateEmail } from "../../utility/commonUtils";
import { forgotPasswordRequest, LoginRequest } from "./service";
import $ from "jquery";
import "bootstrap";
import InlineAlert from "./InlineAlert";

const initialState = {
  email: "",
  password: "",
  isError: false,
  responseMessage: "",
};

const Login = () => {
  const history = useHistory();
  const auth = useContext(AuthContext);
  const [formState, setFormState] = useState(initialState);
  const [isLoginForm, setIsLoginForm] = useState(true);
  const pathname = window.location.pathname; 
  const handlePageState = (newStates: any) => {
    setFormState((prevStates) => {
      return {
        ...prevStates,
        ...newStates,
      };
    });
  };
  const handleMessage = (event: { target: HTMLInputElement } | any) => {
    $(".showActive").addClass('hide');
  };

  const handleChange = (event: { target: HTMLInputElement } | any) => {
    const { name, value } = event.target;
    handlePageState({ [name]: value });
    event.preventDefault();
  };

  const onSubmitHandler = async (e: any) => {
    e.preventDefault();
    if (!validateEmail(formState.email)) {
      handlePageState({ responseMessage: "Email is required.", isError: true });
      return;
    }

    if (isLoginForm) {
      onLoginAction();
    } else {
      onForgotAction();
    }
  };

  const onLoginAction = async () => {
    if (formState.password === "") {
      handlePageState({
        responseMessage: "Password is required.",
        isError: true,
      });
      return;
    } else if (formState.password.length < 6) {
      handlePageState({
        responseMessage: "The password must be at least 6 characters.",
        isError: true,
      });
      return;
    }

    const res = await LoginRequest(formState);
    if (res.success || res.status) {
      localStorage.setItem(JWT_TOKEN, res.token);
      localStorage.setItem(LOGGEDIN_USER, JSON.stringify(res.data));
      auth.login(res.data);
      handlePageState({ responseMessage: "Login success", isError: false });
      $("#login_modal").modal("hide");
      // ($("#login_modal") as any).modal("hide");
      $("body").removeClass("modal-open");
      $(".modal-backdrop").remove();
      if (res.data.user_role) {
        history.push(ADVISER_LEADS_MATCHES, { from: "register" });
      } else {
        history.push("/dashboard", { from: "/" });
      }
    } else {
      handlePageState({ responseMessage: res.message, isError: true });
    }
  };

  const onForgotAction = async () => {
    const res = await forgotPasswordRequest({ email: formState.email });
    if (res.success || res.status) {
      handlePageState({ responseMessage: res.message, isError: false });
    } else {
      handlePageState({ responseMessage: res.message, isError: true });
    }
  };

  return (
    <>
      <div className="content">
        <h5 className="mb-3">{isLoginForm ? pathname=='/adviser'?"Adviser Login":"Login" : "Forgot password"}</h5>
        <div className="account-form text-left mt-4">
          <form onSubmit={onSubmitHandler}>
            <div className="form-row">
              <div className="alert alert-success showActive hide">Activation successful – now login, set your preferences and immediately see matched leads </div>
              {formState.responseMessage && (
                <InlineAlert
                  isError={formState.isError}
                  message={formState.responseMessage}
                />
              )}
              <div className="form-group col-lg-12">
                <label htmlFor="cust_email" className="p-0 mb-2">
                  Email
                </label>
                <input
                  type="email"
                  id="cust_email"
                  name="email"
                  placeholder="email@domain.com"
                  value={formState.email}
                  onFocus={ handleMessage }
                  onChange={handleChange}
                />
              </div>
              {isLoginForm && (
                <div className="form-group col-lg-12 mb-1">
                  <label htmlFor="cust_password" className="p-0 mb-2">
                    Password
                  </label>
                  <input
                    type="password"
                    id="cust_password"
                    placeholder=""
                    name="password"
                    onFocus={handleMessage}
                    value={formState.password}
                    onChange={handleChange}
                  />
                </div>
              )}

              <div className="form-group col-lg-12 text-right">
                <a
                  href="javascript:void(0)"
                  onClick={() => setIsLoginForm((prevState) => !prevState)}
                >
                  {isLoginForm ? "Forgot?" : "Back to login"}
                </a>
              </div>

              {isLoginForm ?<div className="form-group col-lg-10 offset-lg-1 text-center">
                <p>
                  By using our website you, agree to our{" "}
                  {pathname=='/adviser'? <a href="/adviser/terms-conditions" target="_blank">Terms and conditions</a>:<a href="/terms-conditions" target="_blank">Terms and conditions</a>}
                </p>
              </div>:null}
              <div className="form-group col-md-12 text-center mt-0">
                <button
                  type="submit"
                  className="theme-btn text-uppercase mr-md-3 mr-1"
                >
                  {isLoginForm ? "Log in" : "Reset"}
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default Login;
