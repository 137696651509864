/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/style-prop-object */
import React from "react";


import { Link } from "react-router-dom";

const PricingTwo = () => {
  return (
    <React.Fragment>
      <section className="pricing-herosection">
        <div className="container">
          <div className="container-center">
            <div className="pricing-herosection-title"><p>MortgageBox Pricing</p> </div>
            <div className="pricing-herosection-subtitle">
           <p>  New advisers receive a starter pack with five free introductions.
              After that <br/> we have the most competitive rates around.</p> 
            </div>
            <Link to="/register">
            <img alt="logo" className="sign-up-btn-price" src="/assets/images/newpagesimages/signup.png" />
        </Link>
          </div>
        </div>
      </section>
      <section className="procing-section-two">
        <p className="py-3">Why use MortgageBox?</p>

        <section className="pricing-section-two">
 
  <div className="container py-0">
    <div className="row">
      <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 mb-5 d-flex justify-content-center">
        <div className="advice-card rounded shadow-sm  py-4 px-4 d-flex flex-column align-items-start">
          <p className="advice-title-section-two">You're in control</p>
          <div className="rectangle">
            <div className="horizontal-line"></div>
          </div>
          <div className="advice-second-container">
            <img
              alt="logo"
              src="/assets/images/newpagesimages/verifiedWhite.png"
            />
          You decide which customers to contact and you'll know the cost upfront.
          </div>
          <div className="advice-second-container">
            <img
              alt="logo"
              src="/assets/images/newpagesimages/verifiedWhite.png"
            />
            Powerful dashboard to track leads, spending & revenue.
          </div>
          <div className="advice-second-container">
            <img
              alt="logo"
              src="/assets/images/newpagesimages/verifiedWhite.png"
            />
           Billed monthly.
          </div>
        </div>
      </div>
      <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 mb-5 d-flex justify-content-center">
        <div className="advice-card rounded shadow-sm py-4 px-4 d-flex flex-column align-items-start">
          <p className="advice-title-section-two">Costs based on mortgage</p>

          <div className="rectangle">
            <div className="horizontal-line"></div>
          </div>
         
          <div className="advice-second-container">
            <img
              alt="logo"
              src="/assets/images/newpagesimages/verifiedWhite.png"
            />
            We calculate the cost of contacting a customer based on the size of mortgage.
          </div>
          <div className="advice-second-container">
            <img
              alt="logo"
              src="/assets/images/newpagesimages/verifiedWhite.png"
            />
          As an example, a lead for a £200K remortgage would cost £12.
          </div>
          <div className="advice-second-container">
            <img
              alt="logo"
              src="/assets/images/newpagesimages/verifiedWhite.png"
            />
          A maximum of five advisers are introduced.
          </div>
        </div>
      </div>
      <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 mb-5 d-flex justify-content-center">
        <div className="advice-card rounded shadow-sm py-4 px-4 d-flex flex-column align-items-start">
          <p className="advice-title-section-two">Great value and free to try</p>
          <div className="rectangle">
            <div className="horizontal-line"></div>
          </div>
          <div className="advice-second-container">
            <img
              alt="logo"
              src="/assets/images/newpagesimages/verifiedWhite.png"
            />
        Most competitive rates around for high quality leads.
          </div>
          <div className="advice-second-container">
            <img
              alt="logo"
              src="/assets/images/newpagesimages/verifiedWhite.png"
            />
      Big discounts automatically applied if slots remain unfilled - 50% after 24 hours, 75% after 48 hours.
          </div>
          <div className="advice-second-container">
            <img
              alt="logo"
              src="/assets/images/newpagesimages/verifiedWhite.png"
            />
      Try it for free...with five free introductions. We're confident you'll like it!
          </div>
        </div>
      </div>
    </div>
  </div>
  <div className="advice-section-see-details text-center">
  <Link to={'/pricing-details'}>
    <img
      alt="logo"
      src="/assets/images/newpagesimages/seeCostDetails.png"
    />
    </Link>
  </div>
</section>

      </section>

      <section className="section-advice-faq mt-5">
        <p className="text-center py-0 ">Frequently Asked Questions</p>

       {/* <div className="accordion-list-container pb-5">


          {/* Assuming Accordion is a component that takes title and content props */}
         {/* /<Accordion  /> */}
      

     {/* </div>  */} 
      </section> 

      <section className="we-belive-section  pricing-faq mt-0 mb-5">
          <div className="container">
            <div className="row">
              {/* <div className="col-md-12 text-center"> */}
                {/* <div className="belive-content">
                  <div className="heading">
                    <h2>FREQUENTLY ASKED QUESTIONS</h2>
                    <span></span>
                  </div>
                </div> */}
              {/* </div> */}
              <div className="col-lg-10 offset-lg-1 mt-lg-0 mt-0">
                <div id="accordion" className="accordion">
                  <div className="accordion-ques">
                    <div
                      className="ques collapsed"
                      data-toggle="collapse"
                      aria-expanded="true"
                      data-href="#collapse-1"
                      data-target="#collapse-1"
                    >
                      <h5>
                        
                        How does MortgageBox work?
                      </h5>
                    </div>
                    <div
                      id="collapse-1"
                      className="collapse show"
                      data-parent="#accordion"
                      
                    >
                      <div className="answers">
                        <p>
                          Customers complete a short questionnaire outlining their mortgage needs. We then take this and match it against mortgage advisers areas of expertise and preferences. Up to five mortgage advisers can then get in contact with the customer to detail how they can help. It is then up to the customer to review and pick the adviser best suited to their needs.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="accordion-ques">
                    <div
                      className="ques collapsed"
                      data-toggle="collapse"
                      aria-expanded="false"
                      data-href="#collapse-2"
                      data-target="#collapse-2"
                    >
                      <h5>
                      
                        How much does a lead cost?
                      </h5>
                    </div>
                    <div
                      id="collapse-2"
                      className="collapse"
                      data-parent="#accordion"
                    >
                      <div className="answers">
                        <p>
                          We provide leads at a highly competitive price that you are unlikely to find elsewhere. Furthermore, we restrict introductions to just 5 mortgage advisers and will aggressively reduce the lead price to zero over three days if less than five advisers have been introduced.   
                        </p>
                        <p>
                          All MortgageBox leads are priced based on expected mortgage size, product, and time the lead has been listed. As an example, a lead for a £200k repayment mortgage would initially cost £12, before reducing should not all 5 lead slots be taken. First by 50% after 24 hours, then 75% after 48 hours, and then 100% after 73 hours.    
                        </p>
                        <p>
                          The price is always visible before responding to the lead.   
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="accordion-ques">
                    <div
                      className="ques collapsed"
                      data-toggle="collapse"
                      aria-expanded="false"
                      data-href="#collapse-3"
                      data-target="#collapse-3"
                    >
                      <h5>
                       
                        How many responses can a customer receive?
                      </h5>
                    </div>
                    <div
                      id="collapse-3"
                      className="collapse"
                      data-parent="#accordion"
                    >
                      <div className="answers">
                        <p>
                          Our target is to put between 5 advisers in contact with the customer. Certain requests, typically the ones where the customer has provided good detail, will be fully responded to in a quick time.
                        </p>
                        <p>
                          You are also able to see how many professionals have already responded to each customer and can use this information to make a decision on whether you wish to respond to that particular customer as well.
                        </p>
                        <p>
                          We aim to provide a healthy level of competition so that the customer gets a great deal. This helps spread the word about MortgageBox and ensures we get more and more customers registering their mortgage needs.  
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="accordion-ques">
                    <div
                      className="ques collapsed"
                      data-toggle="collapse"
                      aria-expanded="false"
                      data-href="#collapse-4"
                      data-target="#collapse-4"
                    >
                      <h5>
                     
                        How do customers choose an adviser?
                      </h5>
                    </div>
                    <div
                      id="collapse-4"
                      className="collapse"
                      data-parent="#accordion"
                    >
                      <div className="answers">
                        <p>There is no one rule as we are sure you are already aware. Our guidance to customers on questions to ask advisers includes the following:</p>
                        <p>
                          When selecting a mortgage adviser, it's essential to ask the right questions to ensure you make informed decisions about your mortgage. Here is a selection of questions you should consider asking:
                          <ul className="ml-0 text-dark">
                            <li>What types of mortgages do you offer? Understand the various mortgage options available, including fixed-rate, adjustable-rate, and others, to determine which might be the best fit for your situation.</li>
                            <li>What is the current interest rate for the mortgage I'm interested in? Interest rates can vary, so it's essential to know the current rate for the specific type of mortgage you're considering.</li>
                            <li>
                              What is the annual percentage rate (APR)? The APR includes not only the interest rate but also any additional fees associated with the mortgage. This will give you a more accurate picture of the total cost of the loan.
                            </li>
                            <li>
                              What are the down payment requirements? Understanding how much you need to put down upfront is crucial for budgeting and determining if you're eligible for certain loan programs.
                            </li>
                            <li>
                              What are the closing costs? Closing costs can add significantly to the overall cost of buying a home. Ask for an estimate to ensure you're financially prepared for these expenses.
                            </li>
                            <li>
                              What is the loan term? Determine whether the mortgage is for a 15-year, 20-year, 30-year, or another term. This affects your monthly payments and the total interest paid over the life of the loan.  
                            </li>
                            <li>
                              What is the minimum credit score required? Find out what credit score is needed to qualify for the mortgage and what steps you can take to improve your credit if necessary.
                            </li>
                            <li>
                              Are there any prepayment penalties or restrictions? Some mortgages have penalties for paying off the loan early. Make sure you understand the terms regarding prepayment.  
                            </li>
                            <li>
                              What is the process for getting pre-approved? Getting pre-approved for a mortgage is crucial when shopping for a home. Understand the steps involved and the documentation required. 
                            </li>
                            <li>
                              What is your experience and track record as a mortgage adviser? Assess and confirm the adviser's expertise, experience, FCA registration, and success in helping clients secure mortgages. You may also want to ask for references.  
                            </li>
                            <li>
                              What are the potential risks and challenges with this mortgage? It's essential to have a realistic view of potential challenges or drawbacks associated with the mortgage you're considering. 
                            </li>
                            <li>
                              Are there any special offers or cashback deals for borrowers right now?
                            </li>
                          </ul>
                        </p>
                        <p>Remember to take notes and don't hesitate to ask follow-up questions. The goal is to gain a thorough understanding of your mortgage options and make the best choice for your financial situation.</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
    </React.Fragment>


  );
};

export default PricingTwo;
