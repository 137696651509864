import React, { useContext, useEffect } from "react";
import { JWT_TOKEN } from "../../Constants";
import { AuthContext } from "../../shared/context/auth-context";

const Logout = () => {
  const auth = useContext(AuthContext);

  useEffect(() => {
    localStorage.clear();
    auth.logout();
    window.location.href = "/";
  }, []);

  return <></>;
};

export default Logout;
