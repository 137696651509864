import React, { useEffect, useRef, useState } from "react";
import { ReactMultiEmail, isEmail } from "react-multi-email";
import "react-multi-email/style.css";
import { useToasts } from "react-toast-notifications";

import {
  FacebookShareButton,
  FacebookIcon,
  TwitterShareButton,
  TwitterIcon,
  LinkedinShareButton,
  LinkedinIcon,
  WhatsappShareButton,
  WhatsappIcon,
} from "react-share";
import {
  getUserProfile,
  inviteUsersRequest,
} from "../../components/common/service";
import useLoggedInUserData from "../../utility/useLoggedInUserData";
var slug = "";
const InviteNow = (props: any) => {
  const user = useLoggedInUserData();
  const [userInfo, setUserInfo] = useState<any>({ id: 0 });
  const { addToast } = useToasts();
  const queryParams = new URLSearchParams(window.location.search);
  const invitedToEmail = queryParams.get("invitedToEmail");
  
  const textAreaRef = useRef<any>(null);
  useEffect(() => {
    if (userInfo.id === 0) {
      getUserProfileCall();
    }
  }, []);

  const getUserProfileCall = async () => {
    const res = await getUserProfile();
    if (res?.user) {
      slug = res.user.slug;
      setUserInfo(res.user);
    }
  };

  const copyToClipboard = (e: { target: HTMLInputElement } | any) => {
    textAreaRef.current.select();
    document.execCommand("copy");
    e.target.focus();
    addToast(`URL ${shareUrl} copied to clipboard.`, { appearance: "info" });
  };

  const shareTitle = "Please check this awesome mortgage website";
  let shareUrl = "";
  user.slug = slug;
  if(user.user_role==1){
    if(invitedToEmail!=null){
        const email = encodeURI(invitedToEmail);
        shareUrl = `${process.env.REACT_APP_FRONTEND_URL}invite-advisor/${user.slug}?invitedToEmail=${email}`;
    }else{
        shareUrl = `${process.env.REACT_APP_FRONTEND_URL}invite-advisor/${user.slug}`;
    }
    
  }else{
    if(invitedToEmail!=null){
        const email = encodeURI(invitedToEmail);
        shareUrl = `${process.env.REACT_APP_FRONTEND_URL}invite/${user.slug}?invitedToEmail=${email}`;
    }else{
        shareUrl = `${process.env.REACT_APP_FRONTEND_URL}invite/${user.slug}`;
    }
  }
    
  
  const [emailsState, setEmailsState] = useState<string[]>([]);

  const onSubmitHandler = async (e: any) => {
    e.preventDefault();
    if (emailsState.length === 0) {
      addToast("Please type at least one email address.", {
        appearance: "error",
      });
      return;
    }
    const res = await inviteUsersRequest({ emails: emailsState ,'user_role':user.user_role});
    if (res.status) {
      addToast(res.message, { appearance: "success" });
      setEmailsState([]);
    } else {
      addToast(res.message, { appearance: "error" });
    }
  };

  const shareReferralLinkData = (
    <form>
      <div className="form-group mt-3">
        <div className="row">
          <div className="col-lg-12 col-md-12 col-12">
            <input
              type="text"
              id="refere_link"
              className="refere_link w-100"
              value={shareUrl}
              ref={textAreaRef}
              readOnly
            />
          </div>
          <div className="col-lg-12 col-md-12 col-12 copy_share_social">
            <ul className="pl-0 ml-0 mb-0 share_links">
              <li>
                <FacebookShareButton url={shareUrl} quote={shareTitle}>
                  <FacebookIcon size={32} round={true} />
                </FacebookShareButton>
              </li>
              <li>
                <TwitterShareButton url={shareUrl} title={shareTitle}>
                  <TwitterIcon size={32} round={true} />
                </TwitterShareButton>
              </li>
              <li>
                <LinkedinShareButton url={shareUrl} title={shareTitle}>
                  <LinkedinIcon size={32} round={true} />
                </LinkedinShareButton>
              </li>
              <li>
                <WhatsappShareButton url={shareUrl} title={shareTitle}>
                  <WhatsappIcon size={32} round={true} />
                </WhatsappShareButton>
              </li>
            </ul>
            <button
              type="button"
              className="copy_btn theme-btn text-center"
              onClick={copyToClipboard}
            >
              Copy Link
            </button>
          </div>
        </div>
      </div>
    </form>
  );

  const shareEmailData = (
    <form onSubmit={onSubmitHandler}>
      <div className="form-group mt-3">
        <div className="row">
          <div className="col-12">
            <ReactMultiEmail
              placeholder="Separate email addresses using commas"
              emails={emailsState}
              onChange={(_emails: string[]) => {
                setEmailsState(_emails);
              }}
              validateEmail={(email) => {
                if (emailsState.length === 5) {
                  return false;
                }
                return isEmail(email); // return boolean
              }}
              getLabel={(
                email: string,
                index: number,
                removeEmail: (index: number) => void
              ) => {
                return (
                  <div data-tag key={index}>
                    {email}
                    <span data-tag-handle onClick={() => removeEmail(index)}>
                      ×
                    </span>
                  </div>
                );
              }}
            />
            <p>
              <small>You can add up to 5 emails each time.</small>
            </p>
            <button
              type="submit"
              className="copy_btn theme-btn text-center float-right"
            >
              Send
            </button>
          </div>
        </div>
      </div>
    </form>
  );

  return (
    <>
      {props.page === "advisorReviews" ? (
        <>
          <div className="row adviser-referfrnd-sec">
            <div className="col-md-12">{shareReferralLinkData}</div>
            {/* <div className="col-md-12">{shareEmailData}</div> */}
          </div>
        </>
      ) : (
        <>
          <main>
            <section className="page-title">
              <div className="container">
                <div className="row">
                  <div className="col-md-12 mb-5 mb-md-0"></div>
                </div>
              </div>
            </section>

            <section className="recent-sec advice-area-sec register-final-sec pb-5 adviser-referfrnd-sec">
              <div className="container">
                <div className="recent-box">
                  <div className="row justify-content-center">
                    <div className="col-xl-10 col-lg-12 text-center mb-4">
                      <div className="heading">
                        <h2 className="mb-2">Refer a friend</h2>
                        <span></span>
                      </div>
                    </div>
                  </div>
                  <div className="row justify-content-center">
                    <div className="col-xl-10 col-lg-12 mb-4">
                      <div className="row">
                        <div className="col-lg-7">
                          <div className="boxes">
                            <p className="mb-0">
                              <b>Share you referral link</b>
                            </p>
                            <p className="mb-1">
                              Copy your personal referral link and share it with
                              youe friend.
                            </p>
                            {shareReferralLinkData}
                          </div>
                        </div>
                        <div className="col-lg-5">
                          <div className="boxes">
                            <p className="mb-0">
                              <b>Refer by email</b>
                            </p>
                            <p className="mb-1">
                              Enter your contacts and we’ll invite them for you.
                            </p>
                            {shareEmailData}
                          </div>
                        </div>
                      </div>
                      <div className="row mt-lg-4 mt-0">
                        <div className="col-lg-12 mb-2">
                          <div className="boxes">
                            <p className="mb-0">
                              <b>
                                Number of Friends Invited :{" "}
                                {userInfo?.invite_count || user?.invite_count}
                              </b>
                            </p>
                            {userInfo?.user_role ? (
                              <form>
                                <div className="form-group mt-3 mb-0">
                                  <div className="row">
                                    <div className="col-12">
                                      <div className="range-before">
                                        <div className="range-slider text-right">
                                          <input
                                            className="input-range"
                                            name="distance_val"
                                            id="distance_val"
                                            type="range"
                                            value={userInfo?.invite_count || user?.invite_count}
                                            min="0"
                                            step={userInfo?.invite_count || user?.invite_count}
                                            max="20"
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </form>
                            ) : null}
                          </div>
                        </div>

                        {userInfo?.user_role ? (
                          <div className="col-md-12 col-12">
                            <p className="mb-0">
                              Free introductions generated: 15
                            </p>
                          </div>
                        ) : null}
                      </div>
                    </div>

                    {userInfo?.user_role ? (
                      <div className="row mt-lg-4 pt-lg-2 mt-0 pt-0">
                        <div className="col-12 text-center">
                          <p>
                            * You will receive 5 free introductions once your
                            friend signs up and the FCA registration check has
                            completed
                          </p>
                        </div>
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>
              <img
                src="assets/images/expert-left.png"
                alt="help-1"
                className="img-fluid before-img"
              />
            </section>
          </main>
        </>
      )}
    </>
  );
};

export default InviteNow;
