import moment from "moment";
import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { useToasts } from "react-toast-notifications";
import { stripeSaveCard, getInvoiceData } from "../../components/common/service";
import LoadingSpinner from "../../components/common/LoadingSpinner";
import AddNewCards from "../../components/common/modals/AddNewCards";
import InlineAlert from "../../components/common/InlineAlert";
import {
  checkoutFromSavedCard,
  stripeGetAllCards,
} from "../../components/common/service";
import {
  ADVISER_DASHBOARD,
  ADVISER_MY_ACCOUNT,
  ADVISER_PAYMENT_INVOICES,
} from "../../Constants";
import { capitalize } from "../../utility/commonUtils";

const initialState = {
  cardNumber: "",
  expiryDate: "",
  cvc: "",
  isError: false,
  responseMessage: "",
};

const AdviserPayInvoice = (props: any) => {
  const history = useHistory();
  const { addToast } = useToasts();
  const [selectedCardId, setSelectedCardId] = useState<any>("");
  const [savedCards, setSavedCards] = useState<object[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isPaymentLoading, setIsPaymentLoading] = useState<boolean>(false);
  const [invoiceData, setInvoiceData] = useState<any>({});
  const [invoiceDate, setInvoiceDate] = useState<any>({});

  const [formState, setFormState] = useState(initialState);

  useEffect(() => {
    setTimeout(() => {
      if(props.location.state!=undefined){
        setInvoiceData(props.location.state.invoice);
      }
    }, 1000);
  }, []);

  useEffect(() => {
    // stripeGetAllCardsCall();
  }, []);

  const stripeGetAllCardsCall = async (e:any) => {
    setIsLoading(true);
    const res = await stripeGetAllCards();
    if (res.data && res.data.data && res.data.data.length) {
      setSavedCards(res.data.data);
      setSelectedCardId(res.data.data[0]["id"]);
      onSubmitHandler(e,res.data.data[0]["id"]);
    }
    setIsLoading(false);
  };

  // const onRadioChanged = (event: { target: HTMLInputElement } | any) => {
  //   if (!isPaymentLoading) {
  //     setSelectedCardId(event.target.value);
  //   }
  // };

  const handleChange = (event: { target: HTMLInputElement } | any) => {
    const { name, value } = event.target;
    handlePageState({ [name]: value });
    event.preventDefault();
  };

  const handlePageState = (newStates: any) => {
    setFormState((prevStates) => {
      return {
        ...prevStates,
        ...newStates,
      };
    });
  };

  const makeSpaceInCard = (event: { target: HTMLInputElement } | any) =>{   
    setTimeout(() => {
      // var val = $(".cc-number").val();       

      var newval = '';         
      var val = event.target.value.replace(/\s/g, ''); 


    // if value is not blank, then test the regex

    for(var i = 0; i < val.length; i++) {             
      if(i%4 == 0 && i > 0) newval = newval.concat(' ');             
      newval = newval.concat(val[i]);         
    }        

    // format in same input field 
    $(".cc-number").val(newval); 
    },50);     
      
  }

  const onSubmitHandlerCard = async (e: any) => {
    e.preventDefault();
    const expiryDates = formState.expiryDate.split("-");
    const postedData = {
      account_number: formState.cardNumber,
      exp_year: expiryDates[0],
      exp_month: expiryDates[1],
      cvc: formState.cvc,
      invoice_id:invoiceData?.invoice_id,
      amount:invoiceData?.invoice_amount
    };
    const res = await stripeSaveCard(postedData);
    if (res.status) {
      addToast(res.message, { appearance: "success" });
      // handlePageState({ responseMessage: res.message, isError: false });
      setTimeout(() => { 
        
        $("#add_new_card").modal("hide");
        $("body").removeClass("modal-open");
        $(".modal-backdrop").remove();
        handlePageState({ ...initialState });
        history.push(ADVISER_PAYMENT_INVOICES, { from: "/" });
        // stripeGetAllCardsCall(e);
        // props.refetch();
      }, 3000);
    } else {
      addToast(res.message, { appearance: "error" });
      // handlePageState({ responseMessage: res.message, isError: true });
    }
  };

  const getinvoiceDate = async (value: string) => {
    getInvoiceCall(invoiceDate);
  };

  const getInvoiceCall = async (datest: string) => {
    
    setIsLoading(true);
    let date:any = datest;
    const res = await getInvoiceData({'date':datest});
    if (res.status) {
      setInvoiceData(res.data.invoice); 
    }
    setIsLoading(false);
  };

  const onSubmitHandler = async (e: any,card_id:any) => {
    e.preventDefault();
    // setIsPaymentLoading(true);
    // const postedData = {
    //   invoice_id: invoiceData?.invoice_id,
    //   amount: invoiceData?.invoice_amount,
    //   card_id: card_id,
    //   description: `Paying Invoice #${invoiceData?.invoice_number}`,
    // };
    // const res = await checkoutFromSavedCard(postedData);
    // if (res.status) {
    //   addToast(res.message, { appearance: "success" });
    //   setTimeout(() => {
    //     history.push(ADVISER_DASHBOARD, { from: "/" });
    //   }, 1000);
    // } else {
    //   addToast(res.message, { appearance: "error" });
    // }
    // setIsPaymentLoading(true);
  };

  return (
    <>
      <main className="need-offers">
        <section className="page-title my-account-title">
          <div className="container">
            <div className="row">
              <div className="col-md-12 mb-5 mb-md-0"></div>
            </div>
          </div>
        </section>

        <section className="recent-sec directory-sec advice-area-sec pt-4 pb-5 preference-product-sec">
          <div className="container">
            <div className="row">
              <div className="col-12 back-btn mt-lg-4 mb-lg-3 mt-md-5 mt-5 mb-0">
                <Link to={ADVISER_MY_ACCOUNT} className="back-btn">
                  <i className="fa fa-angle-left" aria-hidden="true"></i> My
                  Account
                </Link>
              </div>
            </div>
          </div>

          <div className="container">
            <div className="recent-box settings-data mb-lg-4 mb-0 saved-card-sec">
              <div className="row justify-content-center">
                <div className="col-xl-10 col-lg-12 mb-lg-4 mb-0">
                  <div className="tabbing">
                    <div className="tab-content mt-lg-4 mt-2">
                      <div className="preference-product-sec">
                        <div className="settings-data">
                          <h5 className="theme-color mb-4">Invoice payment</h5>
                        </div>
                      </div>

                      <div className="pay-with-card">
                        <div className="row">
                          <div className="col-xl-12 col-lg-12">
                            <LoadingSpinner loading={isLoading} />
                          </div>
                          {!isLoading ? (
                            <>
                              {/* <div className="col-xl-6 col-lg-6">
                                <div className="left-side mb-lg-0 mb-3">
                                  {savedCards.length ? (
                                    <h6 className="mb-4">Pay with</h6>
                                  ) : null}

                                  <div className="row">
                                    {savedCards.map((card: any, index) => (
                                      <div key={card.id} className="col-12">
                                        <p>
                                          <input
                                            type="radio"
                                            id={`card_${card.id}`}
                                            name="pay-card"
                                            value={card.id}
                                            onChange={onRadioChanged}
                                            checked={selectedCardId === card.id}
                                          />
                                          <label htmlFor={`card_${card.id}`}>
                                            <b>
                                              {capitalize(card.card.brand)}{" "}
                                              ending {card.card.last4}
                                            </b>
                                            <span>
                                              Expires {card.card.exp_month}/
                                              {card.card.exp_year}
                                            </span>
                                          </label>
                                        </p>
                                      </div>
                                    ))}
                                    <div className="col-12">
                                      <a
                                        href="javascript:void(0)"
                                        data-toggle="modal"
                                        data-target="#add_new_card"
                                        className="add-card mt-2 d-block"
                                      >
                                        <b>Add payment option</b>
                                      </a>
                                    </div>
                                  </div>
                                </div>
                              </div> */}
                              {
                              invoiceData?.invoice_amount ? (
                                <div className="col-xl-6 col-lg-6">
                                  <div className="left-side right-side">
                                    <form>
                                      <div className="row align-items-center mb-3">
                                        <div className="col-md-12 text-center">
                                          <h2>
                                            Invoice #
                                            {invoiceData?.invoice_number}
                                          </h2>

                                          <div className="form-group mb-0">
                                            <div className="select-data">
                                                {invoiceData?.invoice_show_month}  
                                            {/* {invoiceData?.invoice_date_arr.length!=0?
                                                <select
                                                name="new_date"
                                                defaultValue = {invoiceDate}
                                                onChange={(e) =>
                                                  getinvoiceDate(e.target.value)
                                                }
                                                value={invoiceDate}
                                              >
                                                {invoiceData?.invoice_date_arr.map(
                                                (fee: any, index: any) =>
                                                      <option
                                                      // selected={invoiceDate == `${fee.month}/${fee.year}`}
                                                      defaultValue={invoiceDate}
                                                      value={`${fee.month}/${fee.year}`}
                                                    >
                                                      {fee.show_days}
                                                    </option>
                                                      )}
                                              </select>:null} */}
                                              {/* <select>
                                                <option
                                                  value={
                                                    invoiceData?.invoice_date_arr
                                                  }
                                                >
                                                  {moment(
                                                    invoiceData?.invoice_date
                                                  ).format("MMMM YYYY")}
                                                </option>
                                              </select> */}
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="row">
                                        <div className="col-12 text-center">
                                          <p>
                                            <b>
                                              Total due &nbsp; £
                                              {invoiceData?.invoice_amount}
                                            </b>
                                          </p>
                                        </div>
                                      </div>
                                      <div className="row">
                                        <div className="col-12 text-center">
                                        {isPaymentLoading?<a
                                          href="javascript:void(0)"
                                          className="theme-btn"
                                        >
                                          <b>Confirm & Pay</b>
                                        </a>:<a
                                          href="javascript:void(0)"
                                          data-toggle="modal"
                                          data-target="#add_new_card"
                                          className="theme-btn"
                                        >
                                          <b>Confirm & Pay</b>
                                        </a>}
                                          {/* <button
                                            type="submit"
                                            className="theme-btn"
                                            disabled={isPaymentLoading}
                                          >
                                            Confirm & Pay
                                          </button> */}
                                        </div>
                                      </div>
                                      <div className="row mt-3">
                                        <div className="col-12 text-center">
                                          <Link to={ADVISER_PAYMENT_INVOICES}>
                                            See Details
                                          </Link>
                                        </div>
                                      </div>
                                    </form>
                                  </div>
                                </div>
                              ) : null}
                            </>
                          ) : null}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
      <div
        className="mail-popup modal fade popup-model nutshell_popup about_company_popup firstmessage_popup"
        id="add_new_card"
        data-backdrop="static"
        data-keyboard="false"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-body">
              <div className="row">
              <div className="col-md-12">
                <div className="close-btn" style={{ width: "100%" }}>
                  <a
                    href="javascript:void(0)"
                    className="close-btn"
                    data-dismiss="modal"
                    aria-label="Close"
                    style={{ float: "right" }}
                  >
                    <i className="fa fa-close" aria-hidden="true"></i>
                  </a>
                </div></div>
                <div className="col-md-12">
                  <div className="heading text-center mb-2">
                    <h4 className="text-uppercase theme-color">Make the Payment</h4>
                    <span></span>
                  </div>
                  <div className="mb-4 mt-lg-4 pt-lg-2 mt-3">
                    <form onSubmit={onSubmitHandlerCard}>
                    {formState.responseMessage && (
                      <InlineAlert
                        isError={formState.isError}
                        message={formState.responseMessage}
                      />
                    )}
                      <div id="credit" className="row">
                        <div className="col-md-12">
                          <div className="form-group">
                            <label>Card Number</label>
                            <input
                              name="cardNumber"
                              value={formState.cardNumber}
                              onChange={handleChange}
                              className="cc-number"
                              type="tel"
                              // pattern="\d*"
                              minLength={16}
                              maxLength={19}
                              placeholder="**** **** **** ****"
                              onFocus={(e) => (e.target.placeholder = "")}
                              onBlur={(e) =>
                                (e.target.placeholder = "**** **** **** ****")
                              }
                              onKeyUp={makeSpaceInCard}
                              required
                            />
                          </div>
                        </div>
                        <div className="col-md-7">
                          <div className="form-group">
                            <label>Expiry Date</label>
                            <input
                              name="expiryDate"
                              value={formState.expiryDate}
                              onChange={handleChange}
                              className="cc-expires"
                              type="month"
                              min="2021-07"
                              max="2031-07"
                              placeholder="MM / YYYY"
                              onFocus={(e) => (e.target.placeholder = "")}
                              onBlur={(e) =>
                                (e.target.placeholder = "MM / YYYY")
                              }
                              required
                            />
                          </div>
                        </div>
                        <div className="col-md-5">
                          <div className="form-group">
                            <label>CVC</label>
                            <input
                              name="cvc"
                              value={formState.cvc}
                              onChange={handleChange}
                              className="cc-cvc"
                              type="tel"
                              pattern="\d*"
                              minLength={3}
                              maxLength={4}
                              placeholder="CVC"
                              onFocus={(e) => (e.target.placeholder = "")}
                              onBlur={(e) => (e.target.placeholder = "CVC")}
                              required
                            />
                          </div>
                        </div>
                        <div className="col-md-12 text-center mt-lg-4 mt-0">
                          <button
                            type="button"
                            className="cancel-btn text-uppercase mr-md-3 mr-1 mb-md-0 mb-3"
                            data-dismiss="modal"
                          >
                            Cancel
                          </button>
                          <button
                            type="submit"
                            className="theme-btn text-uppercase"
                          >
                            Pay
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <AddNewCards refetch={stripeGetAllCardsCall} /> */}
    </>
  );
};

export default AdviserPayInvoice;
