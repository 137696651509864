import React, { useState } from "react";
import InlineAlert from "../InlineAlert";
import { updateUserPassword } from "../service";

const initialState = {
  old_password: "",
  new_password: "",
  confirm_password: "",
  isError: false,
  responseMessage: "",
};

const ChangePassword = () => {
  const [formState, setFormState] = useState(initialState);

  const handlePageState = (newStates: any) => {
    setFormState((prevStates) => {
      return {
        ...prevStates,
        ...newStates,
      };
    });
  };

  const handleChange = (event: { target: HTMLInputElement } | any) => {
    const { name, value } = event.target;
    handlePageState({ [name]: value });
    event.preventDefault();
  };

  const onSubmitHandler = async (e: any) => {
    e.preventDefault();
    if (
      formState.old_password.length < 6 ||
      formState.new_password.length < 6 ||
      formState.confirm_password.length < 6
    ) {
      handlePageState({
        responseMessage: "The password must be at least 6 characters.",
        isError: true,
      });
      return;
    } else if (formState.new_password !== formState.confirm_password) {
      handlePageState({
        responseMessage: "The password and confirm password should match.",
        isError: true,
      });
      return;
    }

    const res = await updateUserPassword(formState);
    if (res.status || res.success) {
      handlePageState({ responseMessage: res.message, isError: false });
      setTimeout(() => {
        handlePageState({ ...initialState });
        window.location.reload();
        // $("#Change_Password").modal("hide");
        // $("body").removeClass("modal-open");
        // $(".modal-backdrop").remove();
      }, 500);
    } else {
      handlePageState({ responseMessage: res.error, isError: true });
    }
  };

  const handleClose = () => {
    handlePageState(initialState);
  };

  return (
    <>
      <div
        className="modal fade popup-modal"
        id="Change_Password"
        role="dialog"
        aria-labelledby="Change_PasswordTitle"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-body text-center">
              <h3>Change password</h3>
              {formState.responseMessage && (
                <div style={{ textAlign: "left" }}>
                  <InlineAlert
                    isError={formState.isError}
                    message={formState.responseMessage}
                  />
                </div>
              )}
              <form onSubmit={onSubmitHandler} className="text-left">
                <div className="form-row">
                  <div className="form-group check-data col-lg-12">
                    <label htmlFor="old_password" className="p-0 mb-2">
                      Old password
                    </label>
                    <input
                      type="password"
                      id="old_password"
                      name="old_password"
                      placeholder="Old Password"
                      onFocus={(e) => (e.target.placeholder = "")}
                      onBlur={(e) => (e.target.placeholder = "Old Password")}
                      value={formState.old_password}
                      onChange={handleChange}
                      required
                    />
                  </div>
                  <div className="form-group check-data col-lg-12">
                    <label htmlFor="new_password" className="p-0 mb-2">
                      New password
                    </label>
                    <input
                      type="password"
                      id="new_password"
                      name="new_password"
                      placeholder="New Password"
                      onFocus={(e) => (e.target.placeholder = "")}
                      onBlur={(e) => (e.target.placeholder = "New Password")}
                      value={formState.new_password}
                      onChange={handleChange}
                      required
                    />
                  </div>
                  <div className="form-group check-data col-lg-12">
                    <label htmlFor="confirm_password" className="p-0 mb-2">
                      Confirm new password
                    </label>
                    <input
                      type="password"
                      id="confirm_password"
                      name="confirm_password"
                      placeholder="Confirm Password"
                      onFocus={(e) => (e.target.placeholder = "")}
                      onBlur={(e) => (e.target.placeholder = "123@password")}
                      value={formState.confirm_password}
                      onChange={handleChange}
                      required
                    />
                    {formState.new_password.length === 6 &&
                      formState.new_password === formState.confirm_password && (
                        <i className="fa fa-check" aria-hidden="true"></i>
                      )}
                  </div>
                  <div className="form-group col-md-12 text-center mt-lg-4 mt-0 passchangebtn">
                    <button
                      type="submit"
                      className="theme-btn text-uppercase mr-md-3 mr-1"
                    >
                      Save
                    </button>
                    <button
                      type="button"
                      className="theme-btn-border text-uppercase"
                      data-dismiss="modal"
                      aria-label="Cancle"
                      onClick={handleClose}
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default ChangePassword;
