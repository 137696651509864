import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import Tooltip from "rc-tooltip";
import "rc-tooltip/assets/bootstrap.css";
import AdviserProfileReviewsSingle from "../components/adviser/AdviserProfileReviewsSingle";
import LoadingSpinner from "../components/common/LoadingSpinner";
import LeaveReviewModal from "../components/common/modals/advisorModals/LeaveReviewModal";
import MakeAnEnquiryModal from "../components/common/modals/advisorModals/MakeAnEnquiryModal";
import { getAdvisorProfile } from "../components/common/service";
import { ADVISOR_DEFAULT_IMAGE, NO_DATA_FOUND } from "../Constants";
import { getAdviserProfileImage, getStarCls } from "../utility/commonUtils";
import useLoggedInUserData from "../utility/useLoggedInUserData";

const AdvisorProfile = () => {
  const urlParams: { id: any } = useParams();
  const { id } = urlParams;
  const history = useHistory();
  // const backendStorageUrl = process.env.REACT_APP_BACKEND_URL_STORAGE_ADVISOR || "";

  const user = useLoggedInUserData();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [profileData, setProfileData] = useState<any>({});
  const [offersData, setOffersData] = useState<any>([]);
  const [reviewRatingData, setReviewRatingData] = useState<any>([]);
  const [isAPICalled, setisAPICalled] = useState<boolean>(false);

  useEffect(() => {
    if(!isAPICalled){
      getAdvisorProfileCall(id);
      setisAPICalled(true);
    }
    
  }, []);

  const getAdvisorProfileCall = async (advisor_id: number) => {
    setIsLoading(true);
    const res = await getAdvisorProfile(advisor_id);
    if (res?.status) {
      setProfileData(res.data);
      setOffersData(res.offers);
      setReviewRatingData({
        avarageRating: res.avarageRating,
        avarageRatingTotal: res.total,
        data: res.review_rating,
      });
    }
    setIsLoading(false);
  };

  return (
    <>
      <main className="dashboard">
        <section className="page-title leadmatch">
          <div className="container">
            <div className="row">
              <div className="col-md-12 mb-5 mb-md-0"></div>
            </div>
          </div>
        </section>

        <section className="recent-sec advice-area-sec profile-sec">
          <div className="container">
            <div className="row">
              <div className="col-12 back-btn mt-lg-3 mb-lg-3 mt-3 mb-0">
                <a
                  href="javsscript:void(0)"
                  className="back-btn"
                  onClick={() => {
                    history.goBack();
                  }}
                >
                  <i className="fa fa-angle-left" aria-hidden="true"></i>
                  Back
                </a>
              </div>
            </div>
          </div>
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-12">
                <div className="recent-box">
                  <div className="row align-items-center justify-content-center mb-4">
                    <div className="col-lg-12">
                      <LoadingSpinner loading={isLoading} />
                      <div id="profile-tab" className="row">
                        {!isLoading && (
                          <>
                            <div className="col-lg-4 col-md-12 mb-4 pr-xl-5 pl-xl-5">
                              <div className="adviser-pic text-center mb-4">
                                <img
                                  src={getAdviserProfileImage(profileData)}
                                  onError={(event: any) =>
                                    event.target.setAttribute(
                                      "src",
                                      ADVISOR_DEFAULT_IMAGE
                                    )
                                  }
                                  className="img-fluid"
                                  alt=""
                                />
                              </div>
                              <div className="adviser-content text-center">
                                <h2>{profileData?.display_name}</h2>
                                <p className="mb-1">
                                  {profileData?.company_name}
                                </p>
                                <p className="location">
                                  <i className="fas fa-map-marker-alt mr-2"></i>
                                  {profileData?.city
                                    ? `${profileData?.city}, `
                                    : null}
                                  {profileData?.postcode}
                                </p>
                              </div>
                              <div
                                className={
                                  Math.round(
                                    Number(reviewRatingData?.avarageRating)
                                  ) > 0
                                    ? `mt-4 mb-4 text-center review-star-rating-${Math.round(
                                        Number(reviewRatingData?.avarageRating)
                                      )}`
                                    : "mt-4 mb-4 text-center review-star-rating-0"
                                }
                              >
                                <span
                                  className={getStarCls(
                                    1,
                                    reviewRatingData?.avarageRating
                                  )}
                                  aria-hidden="true"
                                ></span>
                                <span
                                  className={getStarCls(
                                    2,
                                    reviewRatingData?.avarageRating
                                  )}
                                  aria-hidden="true"
                                ></span>
                                <span
                                  className={getStarCls(
                                    3,
                                    reviewRatingData?.avarageRating
                                  )}
                                  aria-hidden="true"
                                ></span>
                                <span
                                  className={getStarCls(
                                    4,
                                    reviewRatingData?.avarageRating
                                  )}
                                  aria-hidden="true"
                                ></span>
                                <span
                                  className={getStarCls(
                                    5,
                                    reviewRatingData?.avarageRating
                                  )}
                                  aria-hidden="true"
                                ></span>
                                <p className="review-text">
                                  <a href={`/adviser-profile/${id}#Reviews`}>
                                    {reviewRatingData?.avarageRatingTotal
                                      ? reviewRatingData?.avarageRatingTotal
                                      : "No"}{" "}
                                    Reviews
                                  </a>
                                </p>
                                <p>
                                  Used by {profileData?.completed_bid} Mortgagebox{" "}
                                  {profileData?.completed_bid <= 1
                                    ? "customer"
                                    : "customers"}
                                </p>
                              </div>
                              {user.id!=profileData.advisorId? profileData.is_make_enquiry_visible!=1?
                              <div className="adviser-social mt-5 mb-5">
                                <ul>
                                  <li>
                                    <i className="fas fa-phone-alt"></i>
                                    {profileData?.phone_number!=''?<a href={`tel: ${profileData?.phone_number}`}>
                                    {profileData?.phone_number}
                                    </a>:<a href={`tel: ${profileData?.phone_number}`}>
                                    {profileData?.phone_number}
                                    </a>}
                                  </li>
                                  {profileData?.web_address ? (
                                    <li>
                                      <i className="far fa-link mr-3"></i>
                                      <a
                                        target="_blank"
                                        rel="noreferrer"
                                        href={profileData?.web_address}
                                      >
                                        {profileData?.web_address}
                                      </a>
                                    </li>
                                  ) : null}
                                  <li>
                                    <i className="fal fa-envelope mr-3"></i>
                                    <a href = {`mailto: ${profileData?.email}`}>
                                      {profileData?.email}
                                    </a>
                                  </li>
                                  {profileData?.facebook ? (
                                    <li>
                                      <i className="fab fa-facebook-f mr-3"></i>
                                      <a
                                        target="_blank"
                                        rel="noreferrer"
                                        href={profileData?.facebook}
                                      >
                                        Facebook
                                      </a>
                                    </li>
                                  ) : null}
                                  {profileData?.twitter ? (
                                    <li>
                                      <i className="fab fa-twitter mr-3"></i>
                                      <a
                                        target="_blank"
                                        rel="noreferrer"
                                        href={`https://twitter.com/${profileData?.twitter}`}
                                      >
                                        Twitter
                                      </a>
                                    </li>
                                  ) : null}
                                  {profileData?.linkedin_link ? (
                                    <li>
                                      <i className="fab fa-linkedin mr-3"></i>
                                      <a
                                        target="_blank"
                                        rel="noreferrer"
                                        href={`https://www.linkedin.com/${profileData?.linkedin_link}`}
                                      >
                                        Linkedin
                                      </a>
                                    </li>
                                  ) : null}
                                </ul>
                              </div>:null:null}
                              <div className="text-center mb-4">
                                <a
                                  target = "_blank"
                                  href={`https://register.fca.org.uk/s/search?q=${profileData?.FCANumber}&type=Individuals`}
                                  className="theme-btn-border text-uppercase w-100"
                                >
                                  FCA Registered-{profileData?.FCANumber}
                                </a>
                              </div>
                              {user.id!=profileData.advisorId? profileData.is_make_enquiry_visible==1?
                              <div className="text-center mb-4">
                                <a
                                  href="javascript:void(0)"
                                  className="theme-btn text-uppercase d-block"
                                  data-toggle="modal"
                                  data-target={`#direcory_enquiry_${profileData.advisorId}`}
                                >
                                  Make an Enquiry
                                </a>
                              </div>:null:null}
                              {/* TODO: Message Advisor */}
                              {/* <div className="text-center mb-4">
                                <a
                                  href="javascript:void(0)"
                                  className="theme-btn text-uppercase w-100"
                                >
                                  MESSAGE ADVISER
                                </a>
                              </div> */}
                            </div>
                            <div className="profile-data col-lg-8 col-md-12">
                              <div className="datas ml-lg-4 ml-0">
                                <div className="tab-content" id="myTabContent">
                                  <div
                                    className="tab-pane fade show active"
                                    id="nutshell"
                                    role="tabpanel"
                                    aria-labelledby="home-tab"
                                  >
                                    <div className="row">
                                      <div
                                        id="Nutshell"
                                        className="col-lg-12 pt-md-0 pt-3"
                                      >
                                        <h4 className="text-uppercase mb-4">
                                          in a nutshell
                                        </h4>
                                        <p>{profileData?.short_description}</p>
                                      </div>
                                      <div
                                        id="About"
                                        className="col-lg-12 pt-3"
                                      >
                                        <h4 className="text-uppercase mb-4">
                                          about
                                        </h4>
                                        <p>{profileData.company_id!=0?profileData?.company_about:profileData?.about_us}</p>
                                      </div>

                                      <div
                                        id="Offers"
                                        className="col-lg-12 pt-3"
                                      >
                                        <h4 className="text-uppercase mb-4">
                                          offers
                                        </h4>

                                        {offersData?.length ? (
                                          <ul className="offer-points">
                                            {offersData.map((offer: any) => (
                                              <li key={offer.offer_id}>
                                                <Tooltip
                                                  placement="top"
                                                  overlay={
                                                    <span
                                                      dangerouslySetInnerHTML={{
                                                        __html: `<p>${offer.description.replace(
                                                          /\./g,
                                                          ".<p>"
                                                        )}</p>`,
                                                      }}
                                                    ></span>
                                                  }
                                                >
                                                  <a
                                                    href="#"
                                                    onClick={(e) =>
                                                      e.preventDefault()
                                                    }
                                                    style={{ color: "#1f1414" }}
                                                  >
                                                    {offer.offer}
                                                  </a>
                                                </Tooltip>
                                              </li>
                                            ))}
                                          </ul>
                                        ) : (
                                          <ul>
                                            <li>{NO_DATA_FOUND}</li>
                                          </ul>
                                        )}
                                      </div>
                                    </div>
                                    <div
                                      id="Reviews"
                                      className="row align-items-center pt-3 mb-4"
                                    >
                                      <div className="col-md-6 col-6 review-text mb-0 mb-md-0">
                                        <h4 className="text-uppercase">
                                          reviews{" "}
                                          <span className="review-no">
                                            {reviewRatingData?.data &&
                                            reviewRatingData?.data.length
                                              ? `(${reviewRatingData.avarageRatingTotal})`
                                              : ""}
                                          </span>
                                        </h4>
                                      </div>
                                      {user.id!=profileData.advisorId?
                                      <div className="col-md-6 col-6 text-right review-btn">
                                        <button
                                          className="theme-btn-border text-uppercase"
                                          data-toggle="modal"
                                          data-target="#leaveReviewModal"
                                        >
                                          leave a review
                                        </button>
                                      </div>:null}
                                    </div>

                                    {reviewRatingData.data &&
                                    reviewRatingData.data.length
                                      ? reviewRatingData.data.map(
                                          (review: any) => (
                                              (review.spam_status!=1?
                                              <AdviserProfileReviewsSingle
                                              key={review.id}
                                              review={review}
                                              isPublic
                                            />
                                            :"")
                                          )
                                        )
                                      : null}

                                    {/* TODO: Pagination for reviews */}

                                    {/* <div className="row">
                                  <div className="col-12 text-center">
                                    <div className="pagination mr-pagination mt-md-5 mt-3">
                                      <ul className="pl-0 ml-0 w-100">
                                        <li>
                                          <a href="javascript:void(0)">
                                            <i
                                              className="fa fa-angle-left"
                                              aria-hidden="true"
                                            ></i>
                                          </a>
                                        </li>
                                        <li>
                                          <a href="javascript:void(0)" className="active">
                                            1
                                          </a>
                                          <a href="javascript:void(0)">2</a>
                                          <a href="javascript:void(0)">3</a>
                                        </li>
                                        <li>
                                          <a href="javascript:void(0)">
                                            <i
                                              className="fa fa-angle-right"
                                              aria-hidden="true"
                                            ></i>
                                          </a>
                                        </li>
                                      </ul>
                                    </div>
                                  </div>
                                </div> */}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
      {/* <ReactTooltip /> */}
      <LeaveReviewModal advisor={profileData} />
      <MakeAnEnquiryModal advisor={profileData} />
    </>
  );
};

export default AdvisorProfile;
