import React from "react";
import { Link, useParams } from "react-router-dom";
import AdviserPrefrencesCustomer from "../../components/adviser/AdviserPrefrencesCustomer";
import AdviserPrefrencesDefault from "../../components/adviser/AdviserPrefrencesDefault";
import AdviserPrefrencesFirstmessage from "../../components/adviser/AdviserPrefrencesFirstmessage";
import AdviserPrefrencesLocation from "../../components/adviser/AdviserPrefrencesLocation";
import AdviserPrefrencesPrducts from "../../components/adviser/AdviserPrefrencesPrducts";
import { ADVISER_MY_ACCOUNT, ADVISER_PREFERENCES_URL } from "../../Constants";

const AdviserPreferences = () => {
  const urlParams: { id: any; tab: any } = useParams();
  const { tab } = urlParams;

  const getTabDescription = () => {
    switch (tab) {
      case "products":
        return <AdviserPrefrencesPrducts />;
      case "customer":
        return <AdviserPrefrencesCustomer />;
      case "location":
        return <AdviserPrefrencesLocation />;
      case "firstmessage":
        return <AdviserPrefrencesFirstmessage />;
      case "default":
        return <AdviserPrefrencesDefault />;
      default:
        return <></>;
    }
  };

  return (
    <>
      <main className="need-offers">
        <section className="page-title my-account-title">
          <div className="container">
            <div className="row">
              <div className="col-md-12 mb-5 mb-md-0"></div>
            </div>
          </div>
        </section>

        <section className="recent-sec directory-sec advice-area-sec register-final-sec pt-4 pb-5 myaccount-sec preference-product-sec new-preference-product-sec">
          <div className="container">
            <div className="row">
              <div className="col-12 back-btn mt-lg-4 mb-lg-3 mt-md-5 mt-5 mb-0">
                <Link to={ADVISER_MY_ACCOUNT} className="back-btn">
                  <i className="fa fa-angle-left" aria-hidden="true"></i> My
                  Account
                </Link>
              </div>
            </div>
          </div>

          <div className="container">
            <div className="row">
              <div className="col-12 mb-3 small-breadcumbs">
                <h5 className="text-white">
                  {tab !== "default" ? (
                    <>
                      <img
                        src="assets/images/preference1.png"
                        className="img-fluid"
                        alt=""
                      />{" "}
                      Preferences{" "}
                    </>
                  ) : (
                    <>Defaults</>
                  )}
                </h5>
              </div>
            </div>
            <div className="recent-box mb-4">
              <div className="row my-accoutns-data completed-data">
                <div className="col-xl-12">
                  <p className="mb-0">
                    {tab !== "default" ? (
                      <>
                        Tailor mortgagebox so that it fits in with your way of
                        working, helping you find better leads, save time and
                        allowing you to work more efficiently.
                      </>
                    ) : (
                      <>
                        Customise your defaults to suit your business. Need
                        further options? Let us know as we’d love to make our
                        system even better.
                      </>
                    )}
                  </p>
                </div>
              </div>
            </div>
            <div className="recent-box settings-data mb-4">
              <div className="row justify-content-center">
                <div className="col-xl-10 col-lg-12 mb-4">
                  <div className="tabbing">
                    {!tab ||
                      (tab !== "default" && (
                        <ul className="nav nav-pills setting-tabs mt-mb-4 mt-lg-4 mb-2 mt-2">
                          <li className="nav-item">
                            <Link
                              to={`${ADVISER_PREFERENCES_URL}/products`}
                              className={`nav-link ${
                                tab === "products" ? "active" : ""
                              }`}
                            >
                              Product
                            </Link>
                          </li>
                          <li className="nav-item">
                            <Link
                              to={`${ADVISER_PREFERENCES_URL}/customer`}
                              className={`nav-link ${
                                tab === "customer" ? "active" : ""
                              }`}
                            >
                              Customer
                            </Link>
                          </li>
                          <li className="nav-item">
                            <Link
                              to={`${ADVISER_PREFERENCES_URL}/location`}
                              className={`nav-link ${
                                tab === "location" ? "active" : ""
                              }`}
                            >
                              Location
                            </Link>
                          </li>
                          <li className="nav-item">
                            <Link
                              to={`${ADVISER_PREFERENCES_URL}/firstmessage`}
                              className={`nav-link ${
                                tab === "firstmessage" ? "active" : ""
                              }`}
                            >
                              First Message
                            </Link>
                          </li>
                        </ul>
                      ))}

                    <div className="tab-content mt-lg-5 mt-4">
                      {getTabDescription()}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <img
            src="assets/images/business_right.png"
            alt="help-1"
            className="img-fluid before-img"
          />
          <img
            src="assets/images/business_left.png"
            alt="help-1"
            className="img-fluid after-img"
          />
        </section>
      </main>
    </>
  );
};

export default AdviserPreferences;
