import React, { useState, useEffect } from "react";
import { useToasts } from "react-toast-notifications";
import { validateEmail } from "../../../utility/commonUtils";
import { registerInterest } from "../service";
import $ from "jquery";
import "bootstrap";
import useLoggedInUserData from "../../../utility/useLoggedInUserData";

const RegisterInterest = () => {
  const { addToast } = useToasts();
  const user = useLoggedInUserData();

  const [errorState, setErrorState] = useState<object | any>({
    email: "",
    message: "",
  });
  const [formState, setFormState] = useState({ email: "", message: "" });

  const handleChange = (event: { target: HTMLInputElement } | any) => {
    const { name, value } = event.target;
    setFormState((prevState) => {
      return {
        ...prevState,
        [name]: value,
      };
    });
    handleErrorData(name, !Boolean(value));
    event.preventDefault();
  };

  const handleErrorData = (key: string, value: boolean) => {
    setErrorState((prevState: object | any) => {
      return {
        ...prevState,
        [key]: value,
      };
    });
  };

  useEffect(() => {
    setTimeout(() => {
      if(user!=''){
        setFormState({ email: user.email, message: "" });
      }      
    },500);
    
    
  }, []);

  const onSubmitHandler = async (e: any) => {
    e.preventDefault();
    if (formState.email === "" || !validateEmail(formState.email)) {
      handleErrorData("email", true);
      return;
    }
    if (formState.message === "") {
      handleErrorData("message", true);
      return;
    }
    const res = await registerInterest(formState);
    if (res.status || res.success) {
      addToast(res.message, { appearance: "success" });
      setFormState({ email: "", message: "" });
      $("#register_your_interest").modal("hide");
      $("body").removeClass("modal-open");
      $(".modal-backdrop").remove();
    } else {
      addToast(res.message, { appearance: "error" });
    }
  };

  return (
    <>
      <div className="mail-popup modal fade p-0" id="register_your_interest">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-body">
              <div className="row">
                <div className="col-md-12 text-center">
                  <div className="heading adviser-content">
                    <h4 className="text-uppercase theme-color md-lg-0 mb-2">
                      REGISTER YOUR INTEREST
                    </h4>
                    <p>
                      We are excited to be building a radically better approach
                      and plan to launch Spring 2021.
                    </p>
                    <span></span>
                  </div>

                  <div className="tab-content mt-4">
                    <div className="tab-pane container active" id="User">
                      <div className="row">
                        <div className="col-md-12">
                          <div className="adviser-form">
                            <form onSubmit={onSubmitHandler}>
                              <div className="form-row">
                                <div className="form-group col-md-12">
                                  <input
                                    type="email"
                                    id="cust-email"
                                    name="email"
                                    placeholder="Email Address"
                                    onFocus={(e) => (e.target.placeholder = "")}
                                    onBlur={(e) =>
                                      (e.target.placeholder = "Email Address")
                                    }
                                    value={formState.email}
                                    onChange={handleChange}
                                    className={
                                      errorState.email
                                        ? "formElementError"
                                        : undefined
                                    }
                                  />
                                </div>
                                <div className="form-group col-md-12">
                                  <textarea
                                    id="message"
                                    name="message"
                                    placeholder="Optionally, let us know If you would like to be part of our community for early access and help shape the future."
                                    value={formState.message}
                                    onChange={handleChange}
                                    className={
                                      errorState.message
                                        ? "formElementError"
                                        : undefined
                                    }
                                  ></textarea>
                                </div>
                                <div className="form-group col-md-12 text-center">
                                  <button
                                    type="submit"
                                    className="theme-btn text-uppercase"
                                  >
                                    Register{" "}
                                    <i
                                      className="fa fa-chevron-right"
                                      aria-hidden="true"
                                    ></i>
                                  </button>
                                </div>
                              </div>
                            </form>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default RegisterInterest;
