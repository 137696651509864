import React, { useEffect, useState } from "react";
import $ from "jquery";
import "bootstrap";
import MortgageCard from "../../components/adviser/MortgageCard";
import MortgageCardDetails from "../../components/adviser/MortgageCardDetails";
import LoadingSpinner from "../../components/common/LoadingSpinner";
import {
  advisorMatchedLeads,
  getServiceType,
  searchMortgageNeeds,
  getCountFilter,
  getMortgageSize,
} from "../../components/common/service";
import { prettyTimeAgo } from "../../utility/commonUtils";
import {
  ADVISER_LEADS_MATCHES,
  MORTGAGE_ADVICE_ARR,
  MORTGAGE_PRICE_ARR,
  NO_DATA_FOUND,
} from "../../Constants";
import { Link, useHistory } from "react-router-dom";

let initialState = {
  search: "",
  status: [],
  promotion: [],
  advice_area: [],
  mortgage_value: [],
  lead_submitted: [],
  fees_preference: [],
  unread:0,
  read:0,
  notintrested:0,
  none_promotion:0,
  fifty_promotion:0,
  seventyfive_promotion:0,
  free_promotion:0,
  anytime:0,
  last_1_hour:0,
  today:0,
  yesterday:0,
  less_3_days:0,
  less_3_week:0,
  no_fee:0,
  would_consider:0,
};

const AdviserLeadsFilter = () => {
  const history = useHistory();
  const [serviceType, setService] = useState<any>([]);
  // const [counts, setCount] = useState<any>({});
  const [formState, setFormState] = useState(initialState);
  const [isSearched, setIsSearched] = React.useState<boolean>(false);
  const [leads, setLeads] = useState<object[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [countData, setCountData] = useState<any>({});
  const [mortgageSize, setMortgageSize] = useState<any>([]);

  const handlePageState = (newStates: any) => {
    setFormState((prevStates: any) => {
      return {
        ...prevStates,
        ...newStates,
      };
    });
  };
  useEffect(() => {
    getMortgageSizeData();
    getServices();
    getCount();
    
  }, []);
  const getServices = async () => {
    setIsLoading(true);
    const res = await getServiceType();
    if (res?.success || res.status) {
      setService(res.data);
    }
    setIsLoading(false);
  };


  const getMortgageSizeData = async () => {
    const res = await getMortgageSize();
    if (res?.success || res.status) {
      setMortgageSize(res.data);
    }
  };

  const getCount = async () => {
    setIsLoading(true);
    const res = await getCountFilter();
    if (res?.success || res.status) {
      setCountData(res.data);
      handlePageState({
        unread : res.data.unread
      });
    }
    setIsLoading(false);
  };

  const handleChangeCheckbox = (event: { target: HTMLInputElement } | any) => {
    const { name, value, checked } = event.target;
    if (["local_advisor"].includes(name)) {
      handlePageState({ [name]: +checked });
    } else {
      setFormState((prevStates: any) => {
        const item = prevStates[name];
        return {
          ...prevStates,
          [name]: checked
            ? [...item, value]
            : item.filter((item: string) => item !== value),
        };
      });
    }
  };

  const handleChange = (event: { target: HTMLInputElement } | any) => {
    const { name, value } = event.target;
    handlePageState({ [name]: value });
  };

  const handleClearFilters = () => {
    setFormState(initialState);
    $('.status').prop('checked', false);
    $('.promotion').prop('checked', false);
    $('.advice_area').prop('checked', false);
    $('.mortgage_value').prop('checked', false);
    $('.lead_submitted').prop('checked', false);
    $('.fees_preference').prop('checked', false);
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    setIsSearched(true);
    setIsLoading(true);
    const res = await searchMortgageNeeds(formState);
    if (res.status) {
      setLeads(res.data);
      setIsLoading(false);
      setIsSearched(false);

      history.push({
        pathname: ADVISER_LEADS_MATCHES,
        state: { filteredLeads: res.data },
      });
    } else {
      setLeads([]);
      setIsLoading(false);
      setIsSearched(false);
    }
  };

  return (
   
    <>
      <main className="dashboard">
        <section className="page-title leadmatch">
          <div className="container">
            <div className="row">
              <div className="col-md-12 mb-5 mb-md-0"></div>
            </div>
          </div>
        </section>
        <section className="recent-sec advice-area-sec profile-sec advice-lead-matches advice-lead-filter pb-5">
          <div className="container">
            <div className="row">
              <div className="col-12 back-btn mt-lg-4 mb-lg-3 mt-md-4 mt-4 mb-0">
                <Link
                  to={ADVISER_LEADS_MATCHES}
                  className="back-btn filter-backbtn"
                >
                  <i className="fa fa-angle-left" aria-hidden="true"></i> Back
                </Link>
              </div>
            </div>
          </div>
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-12">
                <div className="recent-box">
                  <div className="row align-items-center justify-content-center mb-4">
                    <div className="col-lg-12">
                      <div className="row">
                        <div className="col-xl-4 col-lg-5 col-md-12 mb-4 pr-xl-3 pl-xl-3 left-right-acc">
                          <div id="accordion2" className="accordion2">
                            <div className="address-acc">
                              <div id="left_side" className="mt-lg-0 mt-3">
                                <div className="left-sidebar">
                                  <form onSubmit={handleSubmit}>
                                    <div className="leads-data p-0">
                                      <h4 className="filter-backbtn">
                                        Filter{" "}
                                        <Link
                                          to={ADVISER_LEADS_MATCHES}
                                          className="back-btn text-right"
                                        >
                                          <i
                                            className="fa fa-angle-left"
                                            aria-hidden="true"
                                          ></i>{" "}
                                          Back
                                        </Link>
                                      </h4>
                                      <p>Keyword search </p>
                                      <div className="form-group">
                                        <div className="row">
                                          <div className="col-12 search-bar">
                                            <i
                                              className="fa fa-search"
                                              aria-hidden="true"
                                            ></i>
                                            <input
                                              type="search"
                                              id="search_filter"
                                              placeholder="Search"
                                              name="search"
                                              value={formState.search}
                                              onChange={handleChange}
                                            />
                                          </div>
                                        </div>
                                      </div>
                                    </div>

                                    <div className="directory-section">
                                      <div id="accordion" className="accordion">
                                        <div className="accordion-directory">
                                          <div
                                            className="ques collapsed mb-4"
                                            data-toggle="collapse"
                                            aria-expanded="true"
                                            data-href="#collapse-1"
                                            data-target="#collapse-1"
                                          >
                                            <p>Status</p>
                                          </div>
                                          <div
                                            id="collapse-1"
                                            className="collapse show "
                                            data-parent="#accordion"
                                          >
                                            <div className="answers">
                                              <div className="row">
                                                <div className="col-12 col-md-6 col-6">
                                                  <div className="form-group checkboxes-data mb-0">
                                                    <input
                                                      type="checkbox"
                                                      id="unread"
                                                      name="status"
                                                      className ="status"
                                                      value={"unread"}
                                                      onChange={
                                                        handleChangeCheckbox
                                                      }
                                                    />
                                                    <label htmlFor="unread">
                                                      Unread ({countData?.unread})
                                                    </label>
                                                  </div>
                                                  <div className="form-group checkboxes-data mb-0">
                                                    <input
                                                      type="checkbox"
                                                      id="read"
                                                      name="status"
                                                      className ="status"
                                                      value={"read"}
                                                      onChange={
                                                        handleChangeCheckbox
                                                      }
                                                    />
                                                    <label htmlFor="read">
                                                      Read ({countData?.read})
                                                    </label>
                                                  </div>
                                                </div>
                                                <div className="col-12 col-md-6 col-6">
                                                  <div className="form-group checkboxes-data mb-0">
                                                    <input
                                                      type="checkbox"
                                                      id="not_interested"
                                                      name="status"
                                                      className ="status"
                                                      value={"not-interested"}
                                                      onChange={
                                                        handleChangeCheckbox
                                                      }
                                                    />
                                                    <label htmlFor="not_interested">
                                                      Not Interested ({countData?.not_intrest})
                                                    </label>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>

                                        <span className="border"></span>
                                        <div className="accordion-directory">
                                          <div
                                            className="ques collapsed mb-4"
                                            data-toggle="collapse"
                                            data-href="#collapse-2"
                                            data-target="#collapse-2"
                                            aria-expanded="true"
                                          >
                                            <p>Promotion</p>
                                          </div>
                                          <div
                                            id="collapse-2"
                                            className="collapse"
                                            data-parent="#accordion"
                                          >
                                            <div className="answers">
                                              <div className="row">
                                                <div className="col-12 col-md-6 col-6">
                                                  {/* <div className="form-group checkboxes-data mb-0">
                                                    <input
                                                      type="checkbox"
                                                      id="early_bids"
                                                      name="promotion"
                                                      value={"early-bird"}
                                                      onChange={
                                                        handleChangeCheckbox
                                                      }
                                                    />
                                                    <label htmlFor="early_bids">
                                                      Early bird
                                                    </label>
                                                  </div> */}
                                                  <div className="form-group checkboxes-data mb-0">
                                                    <input
                                                      type="checkbox"
                                                      id="none"
                                                      name="promotion"
                                                      className ="promotion"
                                                      value={"none"}
                                                      onChange={
                                                        handleChangeCheckbox
                                                      }
                                                    />
                                                    <label htmlFor="none">
                                                      None ({countData?.none})
                                                    </label>
                                                  </div>
                                                  <div className="form-group checkboxes-data mb-0">
                                                    <input
                                                      type="checkbox"
                                                      id="50_off"
                                                      name="promotion"
                                                      value={"50-off"}
                                                      className ="promotion"
                                                      onChange={
                                                        handleChangeCheckbox
                                                      }
                                                    />
                                                    <label htmlFor="50_off">
                                                      50% off ({countData?.half})
                                                    </label>
                                                  </div>
                                                </div>
                                                <div className="col-12 col-md-6 col-6">
                                                  <div className="form-group checkboxes-data mb-0">
                                                    <input
                                                      type="checkbox"
                                                      id="75_off"
                                                      name="promotion"
                                                      className ="promotion"
                                                      value={"75-off"}
                                                      onChange={
                                                        handleChangeCheckbox
                                                      }
                                                    />
                                                    <label htmlFor="75_off">
                                                      75% off ({countData?.third})
                                                    </label>
                                                  </div>
                                                  <div className="form-group checkboxes-data mb-0">
                                                    <input
                                                      type="checkbox"
                                                      id="free"
                                                      name="promotion"
                                                      className ="promotion"
                                                      value={"free"}
                                                      onChange={
                                                        handleChangeCheckbox
                                                      }
                                                    />
                                                    <label htmlFor="free">
                                                      Free ({countData?.free})
                                                    </label>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>

                                        <span className="border"></span>
                                        <div className="accordion-directory">
                                          <div
                                            className="ques collapsed mb-4"
                                            data-toggle="collapse"
                                            data-href="#collapse-3"
                                            data-target="#collapse-3"
                                            aria-expanded="true"
                                          >
                                            <p>Advise area</p>
                                          </div>
                                          <div
                                            id="collapse-3"
                                            className="collapse  "
                                            data-parent="#accordion"
                                          >
                                            {/* show */}
                                            <div className="answers">
                                              <div className="row">
                                                <div className="col-12 col-md-6 col-6">
                                                  {serviceType.slice(
                                                    0,
                                                    6
                                                  ).map((adv_area: any) => {
                                                    return (
                                                      <>
                                                        <div
                                                          key={adv_area.id}
                                                          className="form-group checkboxes-data mb-0"
                                                        >
                                                          <input
                                                            type="checkbox"
                                                            id={adv_area.id}
                                                            name="advice_area"
                                                            className ="advice_area"
                                                            value={
                                                              adv_area.id
                                                            }
                                                            onChange={
                                                              handleChangeCheckbox
                                                            }
                                                          />
                                                          <label
                                                            htmlFor={
                                                              adv_area.id
                                                            }
                                                          >
                                                            {adv_area.name} ({adv_area.service_count})
                                                          </label>
                                                        </div>
                                                      </>
                                                    );
                                                  })}
                                                </div>
                                                <div className="col-12 col-md-6 col-6">
                                                  {serviceType.slice(
                                                    6,
                                                    12
                                                  ).map((adv_area: any) => {
                                                    return (
                                                      <>
                                                        <div
                                                          key={adv_area.id}
                                                          className="form-group checkboxes-data mb-0"
                                                        >
                                                          <input
                                                            type="checkbox"
                                                            id={adv_area.id}
                                                            name="advice_area"
                                                            className ="advice_area"
                                                            value={
                                                              adv_area.id
                                                            }
                                                            onChange={
                                                              handleChangeCheckbox
                                                            }
                                                          />
                                                          <label
                                                            htmlFor={
                                                              adv_area.id
                                                            }
                                                          >
                                                            {adv_area.name} ({adv_area.service_count})
                                                          </label>
                                                        </div>
                                                      </>
                                                    );
                                                  })}
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>

                                        <span className="border"></span>
                                        <div className="accordion-directory">
                                          <div
                                            className="ques collapsed mb-4"
                                            data-toggle="collapse"
                                            data-href="#collapse-4"
                                            data-target="#collapse-4"
                                            aria-expanded="true"
                                          >
                                            <p>Mortgage size</p>
                                          </div>
                                          <div
                                            id="collapse-4"
                                            className="collapse"
                                            data-parent="#accordion"
                                          >
                                            <div className="answers">
                                              <div className="row">
                                                <div className="col-12 col-md-6 col-6">
                                                  {mortgageSize.slice(
                                                    0,
                                                    3
                                                  ).map((adv_price: any) => {
                                                    return (
                                                      <>
                                                        <div
                                                          key={adv_price.value}
                                                          className="form-group checkboxes-data mb-0"
                                                        >
                                                          <input
                                                            type="checkbox"
                                                            id={adv_price.key}
                                                            name="mortgage_value"
                                                            className ="mortgage_value"
                                                            value={
                                                              adv_price.key
                                                            }
                                                            onChange={
                                                              handleChangeCheckbox
                                                            }
                                                          />
                                                          <label
                                                            htmlFor={
                                                              adv_price.key
                                                            }
                                                          >
                                                            {adv_price.name} ({adv_price.size_count})
                                                          </label>
                                                        </div>
                                                      </>
                                                    );
                                                  })}
                                                </div>
                                                <div className="col-12 col-md-6 col-6">
                                                  {mortgageSize.slice(
                                                    3,
                                                    5
                                                  ).map((adv_price: any) => {
                                                    return (
                                                      <>
                                                        <div
                                                          key={adv_price.key}
                                                          className="form-group checkboxes-data mb-0"
                                                        >
                                                          <input
                                                            type="checkbox"
                                                            id={adv_price.key}
                                                            name="mortgage_value"
                                                            className ="mortgage_value"
                                                            value={
                                                              adv_price.key
                                                            }
                                                            onChange={
                                                              handleChangeCheckbox
                                                            }
                                                          />
                                                          <label
                                                            htmlFor={
                                                              adv_price.key
                                                            }
                                                          >
                                                            {adv_price.name} ({adv_price.size_count})
                                                          </label>
                                                        </div>
                                                      </>
                                                    );
                                                  })}
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>

                                        <span className="border"></span>
                                        <div className="accordion-directory">
                                          <div
                                            className="ques collapsed mb-4"
                                            data-toggle="collapse"
                                            data-href="#collapse-5"
                                            data-target="#collapse-5"
                                            aria-expanded="true"
                                          >
                                            <p>Lead submitted</p>
                                          </div>
                                          <div
                                            id="collapse-5"
                                            className="collapse"
                                            data-parent="#accordion"
                                          >
                                            <div className="answers">
                                              <div className="row">
                                                <div className="col-12 col-md-6 col-6">
                                                  <div className="form-group checkboxes-data mb-0">
                                                    <input
                                                      type="checkbox"
                                                      id="anytime"
                                                      name="lead_submitted"
                                                      className ="lead_submitted"
                                                      value={"anytime"}
                                                      onChange={
                                                        handleChangeCheckbox
                                                      }
                                                    />
                                                    <label htmlFor="anytime">
                                                      Anytime ({countData?.anytime})
                                                    </label>
                                                  </div>
                                                  <div className="form-group checkboxes-data mb-0">
                                                    <input
                                                      type="checkbox"
                                                      id="last_1_hour"
                                                      name="lead_submitted"
                                                      className ="lead_submitted"
                                                      value={"last_hour"}
                                                      onChange={
                                                        handleChangeCheckbox
                                                      }
                                                    />
                                                    <label htmlFor="last_1_hour">
                                                      Last 1 hour ({countData?.last_hour})
                                                    </label>
                                                  </div>
                                                  <div className="form-group checkboxes-data mb-0">
                                                    <input
                                                      type="checkbox"
                                                      id="today"
                                                      name="lead_submitted"
                                                      className ="lead_submitted"
                                                      value={"today"}
                                                      onChange={
                                                        handleChangeCheckbox
                                                      }
                                                    />
                                                    <label htmlFor="today">
                                                      Today ({countData?.today})
                                                    </label>
                                                  </div>
                                                </div>
                                                <div className="col-12 col-md-6 col-6">
                                                  <div className="form-group checkboxes-data mb-0">
                                                    <input
                                                      type="checkbox"
                                                      id="Yesterday"
                                                      name="lead_submitted"
                                                      className ="lead_submitted"
                                                      value={"yesterday"}
                                                      onChange={
                                                        handleChangeCheckbox
                                                      }
                                                    />
                                                    <label htmlFor="Yesterday">
                                                      Yesterday ({countData?.yesterday})
                                                    </label>
                                                  </div>
                                                  <div className="form-group checkboxes-data mb-0">
                                                    <input
                                                      type="checkbox"
                                                      id="less_3"
                                                      name="lead_submitted"
                                                      className ="lead_submitted"
                                                      value={"less_3_days"}
                                                      onChange={
                                                        handleChangeCheckbox
                                                      }
                                                    />
                                                    <label htmlFor="less_3">
                                                      &lt; 3 days ({countData?.three_days})
                                                    </label>
                                                  </div>
                                                  <div className="form-group checkboxes-data mb-0">
                                                    <input
                                                      type="checkbox"
                                                      id="less_1"
                                                      name="lead_submitted"
                                                      className ="lead_submitted"
                                                      value={"less_1_week"}
                                                      onChange={
                                                        handleChangeCheckbox
                                                      }
                                                    />
                                                    <label htmlFor="less_1">
                                                      &lt; 1 week ({countData?.one_week})
                                                    </label>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>

                                        <span className="border"></span>
                                        <div className="accordion-directory">
                                          <div
                                            className="ques collapsed mb-4"
                                            data-toggle="collapse"
                                            data-href="#collapse-6"
                                            data-target="#collapse-6"
                                            aria-expanded="true"
                                          >
                                            <p>Customer fee preference</p>
                                          </div>
                                          <div
                                            id="collapse-6"
                                            className="collapse"
                                            data-parent="#accordion"
                                          >
                                            <div className="answers">
                                              <div className="row">
                                                <div className="col-12 col-md-6 col-6">
                                                  <div className="form-group checkboxes-data mb-0">
                                                    <input
                                                      type="checkbox"
                                                      id="no_fee"
                                                      name="fees_preference"
                                                      className ="fees_preference"
                                                      value={"no_fee"}
                                                      onChange={
                                                        handleChangeCheckbox
                                                      }
                                                    />
                                                    <label htmlFor="no_fee">
                                                      No fee ({countData?.preference_no})
                                                    </label>
                                                  </div>
                                                </div>
                                                <div className="col-12 col-md-6 col-6">
                                                  <div className="form-group checkboxes-data mb-0">
                                                    <input
                                                      type="checkbox"
                                                      id="would_consider"
                                                      name="fees_preference"
                                                      className ="fees_preference"
                                                      value={"would_consider"}
                                                      onChange={
                                                        handleChangeCheckbox
                                                      }
                                                    />
                                                    <label htmlFor="would_consider">
                                                      Would consider ({countData?.preference})
                                                    </label>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>

                                        <span className="border"></span>
                                        <div className="">
                                          <div className="form-row">
                                            <div className="col-xl-6 col-lg-6 col-md-6 col-6 mb-3 text-center">
                                              <button
                                                type="submit"
                                                className="theme-btn"
                                              >
                                                APPLY 
                                              </button>
                                            </div>
                                            <div className="col-xl-6 col-lg-6 col-md-6 col-6 mb-3 text-center">
                                              <button className="theme-btn-border" onClick={handleClearFilters}>
                                                CLEAR
                                              </button>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </form>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="profile-data col-xl-8 col-lg-7 col-md-12 left-right-acc">
                          <div id="accordion" className="accordion">
                            <div className="address-acc">
                              <div id="right_side" className="mt-lg-0 mt-3">
                                {leads.length ? (
                                  leads.map((lead: any) => (
                                    <>
                                      <MortgageCardDetails
                                        key={`${lead.id}`}
                                        isCollapse={true}
                                        {...lead}
                                      />
                                      <span className="border-bottom pt-1 mt-1 mb-1 pb-1"></span>
                                    </>
                                  ))
                                ) : isLoading ? (
                                  <LoadingSpinner loading={isLoading} />
                                ) : (
                                  <div className="col-md-12 pt-3 text-center">
                                    <div className="view-btn text-center font-weight-bold">
                                      {NO_DATA_FOUND}
                                    </div>
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  );
};

export default AdviserLeadsFilter;
