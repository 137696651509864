import React from "react";

const InlineAlert = (props: { isError: boolean; message: string }) => {
  return (
    <>
      <div
        style={{ width: "100%" }}
        className={`alert ${props.isError ? "alert-danger" : "alert-success"}`}
      >
        <strong>{props.isError ? "" : ""}</strong> {props.message}
      </div>
    </>
  );
};
export default InlineAlert;
