import React, { useEffect, useRef, useState } from "react";
import { Link,useHistory } from "react-router-dom";
import { seoPlaces } from "../Constants/seoPlaces";
import useLoggedInUserData from "../utility/useLoggedInUserData";
const currentUser = JSON.parse(localStorage.getItem('loggedIn_user') || '{}');

const Sitemap = () => {
  const [seoPlaceLists, setSeoPlaceLists] = useState<any>([]);
  //const [faqCategory, setFaqCategory] = useState<any>([]);
  //const [isAdviser, setIsAdviser] = useState(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const imageUrl = `${process.env.REACT_APP_IMAGE_URL}faq_category/original/`;
  const user = useLoggedInUserData();
  const history = useHistory();

  useEffect(() => {
    ContentPagesCall();
    localStorage.removeItem('faqList');
  }, []);

  const ContentPagesCall = async () => {
    setIsLoading(true);
    //const post:any = { 'audience' : currentUser!=''?currentUser.user_role:0};
    //let userType:any = currentUser!=''?currentUser.user_role:0;
    // if(userType==1){
    //   setIsAdviser(true);
    // }
    // else{
    //   setIsAdviser(false);
    // }
    // const res = await FaqData(post);
    // if (res?.success || res.status) {
    //   setFaq(res.data.list);
    //   setFaqCategory(res.data.category);
    // }
    setSeoPlaceLists(seoPlaces);
    setIsLoading(false);
  };
  // function setFaqLocalStorage(faq: any) {
  //   localStorage.setItem('faqList',JSON.stringify(faq));
  //   history.push("/faq-inner");
  // }
  return (
    <>
      <section className="page-title">
        <div className="container">
          <div className="row">
            <div className="col-md-12 text-center">
              <div className="heading">
                <h2>Sitemap</h2>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="position-relative pt-5 pb-5">
        <img
          src="assets/images/left-shape.png"
          alt="help-1"
          className="img-fluid-left after-img"
        />
        <div className="container">
          <div className="row mb-5">
            <div className="col-lg-12 text-left mb-4">
              <div className="heading">
                <h2 className="text-uppercase">Sitemap</h2>
                <span className="left-divider"></span>
              </div>
            </div>
            {/* {seoPlaceLists.length?
            ( seoPlaceLists.map((row: any, index: any) =>
            <div className="col-lg-4">
              <ul>
                <li className="card">
                  <Link to={`/directory`}>Mortgage advisers and brokers in {row.Country}</Link>
                </li>
                <li className="card">
                    <ul>
                    {row.county.length?
                      (row.county.map((rowCounty: any, index2: any) =>
                      <li className="card">
                          <Link to={`/directory/${rowCounty.County}`}>Mortgage advisers and brokers in {rowCounty.County}</Link>
                          <ul>
                            {rowCounty.LocalAuthority.map((rowLocalAuthority: any, index2: any) =>
                            <li className="card">
                              <a href={`/directory/${rowCounty.County}/${rowLocalAuthority.LocalAuthority}`}>Mortgage advisers and brokers in {rowLocalAuthority.LocalAuthority}</a>
                              <ul>
                                {rowLocalAuthority.Place.map((rowPlace: any, index2: any) =>
                                <li className="card">
                                  <a href={`/directory/${rowCounty.County}/${rowLocalAuthority.LocalAuthority}/${rowPlace.Place}`}>Mortgage advisers and brokers in {rowPlace.Place}</a>
                                </li>        
                                )}
                              </ul>
                            </li>        
                            )}
                          </ul>
                      </li>
                      )):null}
                    </ul>
                </li>              
              </ul>
            </div>
            )):
            null
            } */}

            {seoPlaceLists.length?
            ( seoPlaceLists.map((row: any, index: any) =>
              <div className="col-lg-4">
              <ul style={{ paddingInlineStart: 20 }}>
                <li className="card">
                <a href={`/directory/${row.County}`} style={{"fontSize":"12px"}}>Mortgage advisers and brokers in {row.County}</a>
                </li>
                <li className="card">
                    <ul style={{ paddingInlineStart: 20 }}>
                    {row.LocalAuthority.length?
                      (row.LocalAuthority.map((rowLocalAuthority: any, index2: any) =>
                      <li className="card">
                          <a href={`/directory/${row.County}/${rowLocalAuthority.LocalAuthority}`}  style={{"fontSize":"12px"}}>Mortgage advisers and brokers in {rowLocalAuthority.LocalAuthority}</a>
                          <ul style={{ paddingInlineStart: 20 }}>
                            {rowLocalAuthority.Place.map((rowPlace: any, index2: any) =>
                            <li className="card">
                              <a href={`/directory/${row.County}/${rowLocalAuthority.LocalAuthority}/${rowPlace.Place}`} style={{"fontSize":"12px"}}>Mortgage advisers and brokers in {rowPlace.Place}</a>
                              {/* <ul>
                                {rowLocalAuthority.Place.map((rowPlace: any, index2: any) =>
                                <li className="card">
                                  <a href={`/directory/${rowCounty.County}/${rowLocalAuthority.LocalAuthority}/${rowPlace.Place}`}>Mortgage advisers and brokers in {rowPlace.Place}</a>
                                </li>        
                                )}
                              </ul> */}
                            </li>        
                            )}
                          </ul>
                      </li>
                      )):null}
                    </ul>
                </li>              
              </ul>
            </div>
            )):
            null
            }
          </div>
        </div>
        <img
          src="assets/images/right-shape.png"
          alt="help-1"
          className="img-fluid before-img"
        />
      </section>
    </>
  );
};

export default Sitemap;
