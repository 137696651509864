import React from "react";

const FirstMessageExampleModal = () => {
  return (
    <>
      <div
        className="mail-popup modal fade popup-model nutshell_popup about_company_popup firstmessage_popup"
        id="firstmessage_example"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-body">
              <div className="row">
                <div className="col-md-12">
                  <div className="heading mb-4">
                    <h4 className="text-uppercase text-center theme-color mb-3">
                      Example introductory message
                    </h4>
                    <p>Hello, I'm John at Bercrow & Wip mortgage advisers,</p>
                    <p>
                      Thank you for taking the time to contact me regarding your
                      mortgage.
                    </p>
                    <p>
                      Mortgagebox is a superb tool and here at Bercrow & Wip
                      mortgage advisors, we recognise the benefits so much that
                      I'm solely dedicated to dealing with your mortgage needs!
                    </p>
                    <p>What are the next steps?</p>
                    <ul className="ml-0">
                      <li>
                        That's nice and easy. Simply pick up the phone and I
                        will talk you through the mortgage options and answer
                        any questions you have. Alternately feel free to message
                        me with your questions
                      </li>
                      <li>
                        Once you confirm you wish to proceed I will then make
                        you an appointment to come down and run through the
                        whole deal including a step by step breakdown.
                      </li>
                    </ul>
                    <p>
                      No question is a silly question so please don't hesitate
                      to call or message me.
                    </p>
                    <p>Kind Regards</p>
                    <p>
                      John Bercrow <br />
                      Bercrow & Wip mortgage advisors <br />
                      Dedicated mortgagebox expert <br />
                    </p>
                  </div>
                </div>
                <div className="col-md-4 offset-md-4 mb-md-0 mb-3 text-center">
                  <button
                    type="button"
                    className="theme-btn"
                    data-dismiss="modal"
                  >
                    OK
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default FirstMessageExampleModal;
