import React, { useEffect, useState } from "react";
import $ from "jquery";
import "bootstrap";
import MortgageCard from "../../components/adviser/MortgageCard";
import MortgageCardDetails from "../../components/adviser/MortgageCardDetails";
import LoadingSpinner from "../../components/common/LoadingSpinner";
import {
  advisorMatchedLeads,
  getServiceType,
  getAllAdvisor,
  searchMortgageNeeds,
  searchAcceptedLeads,
  getCountAcceptedFilter,
} from "../../components/common/service";
import { prettyTimeAgo } from "../../utility/commonUtils";
import {
  ADVISER_LEADS_MATCHES,
  MORTGAGE_ADVICE_ARR,
  MORTGAGE_PRICE_ARR,
  NO_DATA_FOUND,
  ADVISER_LEADS_ACCEPTED,
} from "../../Constants";
import { Link, useHistory } from "react-router-dom";
import useLoggedInUserData from "../../utility/useLoggedInUserData";

const initialState = {
  search: "",
  status: [],
  message: [],
  advice_area: [],
  lead_submitted: [],
  prospect: [],
};

const AdviserLeadsAcceptedFilter = () => {
  const user = useLoggedInUserData();
  const history = useHistory();
  const [serviceType, setService] = useState<any>([]);
  const [advisor, setAdvisor] = useState<any>([]);

  const [formState, setFormState] = useState(initialState);
  const [isSearched, setIsSearched] = React.useState<boolean>(false);
  const [leads, setLeads] = useState<object[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [countData, setCountData] = useState<any>({});

  const handlePageState = (newStates: any) => {
    setFormState((prevStates: any) => {
      return {
        ...prevStates,
        ...newStates,
      };
    });
  };
  useEffect(() => {
    getAdvisors();
    getCount();
  }, []);
  const getAdvisors = async () => {
    setIsLoading(true);
    const res = await getAllAdvisor();
    if (res?.success || res.status) {
      setAdvisor(res.data);
    }
    setIsLoading(false);
  };

  const getCount = async () => {
    setIsLoading(true);
    const res = await getCountAcceptedFilter('duration','month');
    if (res?.success || res.status) {
      setCountData(res.data);
    }
    setIsLoading(false);
  };
  const handleChangeCheckbox = (event: { target: HTMLInputElement } | any) => {
    const { name, value, checked } = event.target;
    if (["local_advisor"].includes(name)) {
      handlePageState({ [name]: +checked });
    } else {
      setFormState((prevStates: any) => {
        const item = prevStates[name];
        return {
          ...prevStates,
          [name]: checked
            ? [...item, value]
            : item.filter((item: string) => item !== value),
        };
      });
    }
  };

  const handleChange = (event: { target: HTMLInputElement } | any) => {
    const { name, value } = event.target;
    handlePageState({ [name]: value });
  };

  const handleClearFilters = () => {
    setFormState(initialState);
    $('.status').prop('checked', false);
    $('.prospect').prop('checked', false);
    $('.advice_area').prop('checked', false);
    $('.message').prop('checked', false);
    $('.lead_submitted').prop('checked', false);
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    setIsSearched(true);
    setIsLoading(true);
    const res = await searchAcceptedLeads(formState);
    if (res.status) {
      setLeads(res.data);
      setIsLoading(false);
      setIsSearched(false);

      history.push({
        pathname: ADVISER_LEADS_ACCEPTED,
        state: { filteredLeads: res.data },
      });
    } else {
      setLeads([]);
      setIsLoading(false);
      setIsSearched(false);
    }
  };

  return (
    <>
      <main className="dashboard">
        <section className="page-title">
          <div className="container">
            <div className="row">
              <div className="col-md-12 mb-5 mb-md-0"></div>
            </div>
          </div>
        </section>
        <section className="recent-sec advice-area-sec profile-sec advice-lead-matches advice-lead-filter pb-5">
          <div className="container">
            <div className="row">
              <div className="col-12 back-btn mt-lg-4 mb-lg-3 mt-md-4 mt-4 mb-0">
                <Link
                  to={ADVISER_LEADS_ACCEPTED}
                  className="back-btn filter-backbtn"
                >
                  <i className="fa fa-angle-left" aria-hidden="true"></i> Back
                </Link>
              </div>
            </div>
          </div>
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-12">
                <div className="recent-box">
                  <div className="row align-items-center justify-content-center mb-4">
                    <div className="col-lg-12">
                      <div className="row">
                        <div className="col-xl-4 col-lg-5 col-md-12 mb-4 pr-xl-3 pl-xl-3 left-right-acc">
                          <div id="accordion2" className="accordion2">
                            <div className="address-acc">
                              <div id="left_side" className="mt-lg-0 mt-3">
                                <div className="left-sidebar">
                                  <form onSubmit={handleSubmit}>
                                    <div className="leads-data p-0">
                                      <h4 className="filter-backbtn">
                                        Filter{" "}
                                        <Link
                                          to={ADVISER_LEADS_ACCEPTED}
                                          className="back-btn text-right"
                                        >
                                          <i
                                            className="fa fa-angle-left"
                                            aria-hidden="true"
                                          ></i>{" "}
                                          Back
                                        </Link>
                                      </h4>
                                      <p>Keyword search</p>
                                      <div className="form-group">
                                        <div className="row">
                                          <div className="col-12 search-bar">
                                            <i
                                              className="fa fa-search"
                                              aria-hidden="true"
                                            ></i>
                                            <input
                                              type="search"
                                              id="search_filter"
                                              placeholder="Search"
                                              name="search"
                                              value={formState.search}
                                              onChange={handleChange}
                                            />
                                          </div>
                                        </div>
                                      </div>
                                    </div>

                                    <div className="directory-section">
                                      <div id="accordion" className="accordion">
                                     
                                        <div className="accordion-directory">
                                          <div
                                            className="ques collapsed mb-4"
                                            data-toggle="collapse"
                                            aria-expanded="true"
                                            data-href="#collapse-1"
                                            data-target="#collapse-1"
                                          >
                                            <p>Messages</p>
                                          </div>
                                          <div
                                            id="collapse-1"
                                            className="collapse show "
                                            data-parent="#accordion"
                                          >
                                            <div className="answers">
                                              <div className="row">
                                                <div className="col-md-6 col-6">
                                                  <div className="form-group checkboxes-data mb-0">
                                                    <input
                                                      type="checkbox"
                                                      id="unread"
                                                      name="message"
                                                      className="message"
                                                      value={"unread"}
                                                      onChange={
                                                        handleChangeCheckbox
                                                      }
                                                    />
                                                    <label htmlFor="unread">
                                                      Unread ({countData?.unread})
                                                    </label>
                                                  </div>                                                  
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                        <span className="border"></span>
                                        <div className="accordion-directory">
                                          <div
                                            className="ques collapsed mb-4"
                                            data-toggle="collapse"
                                            data-href="#collapse-2"
                                            data-target="#collapse-2"
                                            aria-expanded="true"
                                          >
                                            <p>Status</p>
                                          </div>
                                          <div
                                            id="collapse-2"
                                            className="collapse"
                                            data-parent="#accordion"
                                          >
                                            <div className="answers">
                                              <div className="row">
                                                <div className="col-md-6 col-6">
                                                  <div className="form-group checkboxes-data mb-0">
                                                    <input
                                                      type="checkbox"
                                                      id="accepted"
                                                      name="status"
                                                      className="status"
                                                      value={"accepted"}
                                                      onChange={
                                                        handleChangeCheckbox
                                                      }
                                                    />
                                                    <label htmlFor="accepted">
                                                      Accepted ({countData?.accepted})
                                                    </label>
                                                  </div>
                                                  <div className="form-group checkboxes-data mb-0">
                                                    <input
                                                      type="checkbox"
                                                      id="hired"
                                                      name="status"
                                                      className="status"
                                                      value={"hired"}
                                                      onChange={
                                                        handleChangeCheckbox
                                                      }
                                                    />
                                                    <label htmlFor="hired">
                                                      Hired ({countData?.hired})
                                                    </label>
                                                  </div>
                                                  <div className="form-group checkboxes-data mb-0">
                                                    <input
                                                      type="checkbox"
                                                      id="completed"
                                                      name="status"
                                                      className="status"
                                                      value={"completed"}
                                                      onChange={
                                                        handleChangeCheckbox
                                                      }
                                                    />
                                                    <label htmlFor="completed">
                                                      Completed ({countData?.completed})
                                                    </label>
                                                  </div>
                                                  <div className="form-group checkboxes-data mb-0">
                                                    <input
                                                      type="checkbox"
                                                      id="lost"
                                                      name="status"
                                                      className="status"
                                                      value={"lost"}
                                                      onChange={
                                                        handleChangeCheckbox
                                                      }
                                                    />
                                                    <label htmlFor="lost">
                                                      Lost ({countData?.lost})
                                                    </label>
                                                  </div>
                                                  <div className="form-group checkboxes-data mb-0">
                                                    <input
                                                      type="checkbox"
                                                      id="no_response"
                                                      name="status"
                                                      className="status"
                                                      value={"no_response"}
                                                      onChange={
                                                        handleChangeCheckbox
                                                      }
                                                    />
                                                    <label htmlFor="no_response">
                                                      No Response ({countData?.no_response})
                                                    </label>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>

                                        {/* <span className="border"></span>
                                        <div className="accordion-directory">
                                          <div
                                            className="ques collapsed mb-4"
                                            data-toggle="collapse"
                                            data-href="#collapse-3"
                                            data-target="#collapse-3"
                                            aria-expanded="true"
                                          >
                                            <p>Prospect</p>
                                          </div>
                                          <div
                                            id="collapse-3"
                                            className="collapse show"
                                            data-parent="#accordion"
                                          >
                                            <div className="answers">
                                              <div className="row">
                                                <div className="col-md-6 col-6">
                                                  <div className="form-group checkboxes-data mb-0">
                                                    <input
                                                      type="checkbox"
                                                      id="anytime_pros"
                                                      name="prospect"
                                                      className="prospect"
                                                      value={"anytime_pros"}
                                                      onChange={
                                                        handleChangeCheckbox
                                                      }
                                                    />
                                                    <label htmlFor="anytime_pros">
                                                      Anytime
                                                    </label>
                                                  </div>
                                                  <div className="form-group checkboxes-data mb-0">
                                                    <input
                                                      type="checkbox"
                                                      id="today"
                                                      name="prospect"
                                                      className="prospect"
                                                      value={"today"}
                                                      onChange={
                                                        handleChangeCheckbox
                                                      }
                                                    />
                                                    <label htmlFor="today">
                                                      Today
                                                    </label>
                                                  </div>
                                                  <div className="form-group checkboxes-data mb-0">
                                                    <input
                                                      type="checkbox"
                                                      id="less_one_week"
                                                      name="prospect"
                                                      className="prospect"
                                                      value={"less_one_week"}
                                                      onChange={
                                                        handleChangeCheckbox
                                                      }
                                                    />
                                                    <label htmlFor="less_one_week">
                                                      Less than 1 weeks
                                                    </label>
                                                  </div>
                                                  <div className="form-group checkboxes-data mb-0">
                                                    <input
                                                      type="checkbox"
                                                      id="less_two_week"
                                                      name="prospect"
                                                      className="prospect"
                                                      value={"less_two_week"}
                                                      onChange={
                                                        handleChangeCheckbox
                                                      }
                                                    />
                                                    <label htmlFor="less_two_week">
                                                      Less than 2 weeks
                                                    </label>
                                                  </div>
                                                </div>
                                                <div className="col-md-6 col-6">
                                                  <div className="form-group checkboxes-data mb-0">
                                                    <input
                                                      type="checkbox"
                                                      id="yesterday"
                                                      name="prospect"
                                                      className="prospect"
                                                      value={"yesterday"}
                                                      onChange={
                                                        handleChangeCheckbox
                                                      }
                                                    />
                                                    <label htmlFor="yesterday">
                                                      Yesterday
                                                    </label>
                                                  </div>
                                                  <div className="form-group checkboxes-data mb-0">
                                                    <input
                                                      type="checkbox"
                                                      id="less_four_week"
                                                      name="prospect"
                                                      className="prospect"
                                                      value={"less_four_week"}
                                                      onChange={
                                                        handleChangeCheckbox
                                                      }
                                                    />
                                                    <label htmlFor="less_four_week">
                                                      Less than 4 week
                                                    </label>
                                                  </div>
                                                  <div className="form-group checkboxes-data mb-0">
                                                    <input
                                                      type="checkbox"
                                                      id="less_three_days"
                                                      name="prospect"
                                                      className="prospect"
                                                      value={"less_three_days"}
                                                      onChange={
                                                        handleChangeCheckbox
                                                      }
                                                    />
                                                    <label htmlFor="less_three_days">
                                                      Less than 3 days
                                                    </label>
                                                  </div>
                                                  
                                                  
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div> */}
                                        <span className="border"></span>
                                        <div className="accordion-directory">
                                          <div
                                            className="ques collapsed mb-4"
                                            data-toggle="collapse"
                                            data-href="#collapse-4"
                                            data-target="#collapse-4"
                                            aria-expanded="true"
                                          >
                                            <p>When</p>
                                          </div>
                                          <div
                                            id="collapse-4"
                                            className="collapse"
                                            data-parent="#accordion"
                                          >
                                            <div className="answers">
                                              <div className="row">
                                                <div className="col-md-6 col-6">
                                                  <div className="form-group checkboxes-data mb-0">
                                                    <input
                                                      type="checkbox"
                                                      id="anytime"
                                                      name="lead_submitted"
                                                      className="lead_submitted"
                                                      value={"anytime"}
                                                      onChange={
                                                        handleChangeCheckbox
                                                      }
                                                    />
                                                    <label htmlFor="anytime">
                                                      Anytime ({countData?.anytime})
                                                    </label>
                                                  </div>
                                                  <div className="form-group checkboxes-data mb-0">
                                                    <input
                                                      type="checkbox"
                                                      id="three_month"
                                                      name="lead_submitted"
                                                      className="lead_submitted"
                                                      value={"three_month"}
                                                      onChange={
                                                        handleChangeCheckbox
                                                      }
                                                    />
                                                    <label htmlFor="three_month">
                                                      Last 3 months ({countData?.three_month})
                                                    </label>
                                                  </div>
                                                  <div className="form-group checkboxes-data mb-0">
                                                    <input
                                                      type="checkbox"
                                                      id="six_month"
                                                      name="lead_submitted"
                                                      className="lead_submitted"
                                                      value={"six_month"}
                                                      onChange={
                                                        handleChangeCheckbox
                                                      }
                                                    />
                                                    <label htmlFor="six_month">
                                                      Last 6 months ({countData?.six_month})
                                                    </label>
                                                  </div>
                                                </div>
                                                <div className="form-group checkboxes-data mb-0">
                                                    <input
                                                      type="checkbox"
                                                      id="this_year"
                                                      name="lead_submitted"
                                                      className="lead_submitted"
                                                      value={"this_year"}
                                                      onChange={
                                                        handleChangeCheckbox
                                                      }
                                                    />
                                                    <label htmlFor="this_year">
                                                      This year ({countData?.this_year})
                                                    </label>
                                                  </div>
                                                <div className="col-md-6 col-6">
                                                  <div className="form-group checkboxes-data mb-0">
                                                    <input
                                                      type="checkbox"
                                                      id="last_year"
                                                      name="lead_submitted"
                                                      className="lead_submitted"
                                                      value={"last_year"}
                                                      onChange={
                                                        handleChangeCheckbox
                                                      }
                                                    />
                                                    <label htmlFor="last_year">
                                                      Last year ({countData?.last_year})
                                                    </label>
                                                  </div>
                                                  
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                        {user.is_admin==1?
                                        <span className="border"></span>:""}
                                        {user.is_admin==1?
                                        <div className="accordion-directory">
                                          <div
                                            className="ques collapsed mb-4"
                                            data-toggle="collapse"
                                            data-href="#collapse-5"
                                            data-target="#collapse-5"
                                            aria-expanded="true"
                                          >
                                            <p>Adviser</p>
                                          </div>
                                          <div
                                            id="collapse-5"
                                            className="collapse"
                                            data-parent="#accordion"
                                          >
                                            <div className="answers">
                                              <div className="row">
                                                <div className="col-md-6 col-6">
                                                  {advisor.map((adv_area: any) => {
                                                    return (
                                                      <>
                                                        <div
                                                          key={adv_area.id}
                                                          className="form-group checkboxes-data mb-0"
                                                        >
                                                          <input
                                                            type="checkbox"
                                                            id={adv_area.id}
                                                            name="advice_area"
                                                            className="advice_area"
                                                            value={
                                                              adv_area.id
                                                            }
                                                            onChange={
                                                              handleChangeCheckbox
                                                            }
                                                          />
                                                          <label
                                                            htmlFor={
                                                              adv_area.id
                                                            }
                                                          >
                                                            {adv_area.advisor_data.display_name} ({adv_area.adviser_count})
                                                          </label>
                                                        </div>
                                                      </>
                                                    );
                                                  })}
                                                </div>
                                                {/* <div className="col-md-6 col-6">
                                                  {advisor.slice(
                                                    6,
                                                    12
                                                  ).map((adv_area: any) => {
                                                    return (
                                                      <>
                                                        <div
                                                          key={adv_area.id}
                                                          className="form-group checkboxes-data mb-0"
                                                        >
                                                          <input
                                                            type="checkbox"
                                                            id={adv_area.id}
                                                            name="advice_area"
                                                            value={
                                                              adv_area.id
                                                            }
                                                            onChange={
                                                              handleChangeCheckbox
                                                            }
                                                          />
                                                          <label
                                                            htmlFor={
                                                              adv_area.id
                                                            }
                                                          >
                                                            {adv_area.advisor_data.display_name}
                                                          </label>
                                                        </div>
                                                      </>
                                                    );
                                                  })}
                                                </div> */}
                                              </div>
                                            </div>
                                          </div>
                                        </div>:""}
                                        <span className="border"></span>
                                        <div className="">
                                          <div className="form-row">
                                            <div className="col-xl-6 col-lg-6 col-md-6 col-6 mb-3 text-center">
                                              <button
                                                type="submit"
                                                className="theme-btn"
                                              >
                                                APPLY
                                              </button>
                                            </div>
                                            <div className="col-xl-6 col-lg-6 col-md-6 col-6 mb-3 text-center">
                                              <button className="theme-btn-border" onClick={handleClearFilters}>
                                                CLEAR
                                              </button>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </form>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="profile-data col-xl-8 col-lg-7 col-md-12 left-right-acc">
                          <div id="accordion" className="accordion">
                            <div className="address-acc">
                              <div id="right_side" className="mt-lg-0 mt-3">
                                {leads.length ? (
                                  leads.map((lead: any) => (
                                    <>
                                      <MortgageCardDetails
                                        key={`${lead.id}`}
                                        isCollapse={true}
                                        {...lead}
                                      />
                                      <span className="border-bottom pt-1 mt-1 mb-1 pb-1"></span>
                                    </>
                                  ))
                                ) : isLoading ? (
                                  <LoadingSpinner loading={isLoading} />
                                ) : (
                                  <div className="col-md-12 pt-3 text-center">
                                    <div className="view-btn text-center font-weight-bold">
                                      {NO_DATA_FOUND}
                                    </div>
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  );
};

export default AdviserLeadsAcceptedFilter;