import React, { useState, useRef } from "react";
import InviteNow from "../../pages/public/InviteNow";
import { getStarCls } from "../../utility/commonUtils";
import MarkAsSpamModal from "../common/modals/advisorModals/MarkAsSpamModal";
import ReplyReviewModal from "../common/modals/advisorModals/ReplyReviewModal";
import AdviserProfileReviewsSingle from "./AdviserProfileReviewsSingle";
import { useToasts } from "react-toast-notifications";

import {
  FacebookShareButton,
  FacebookIcon,
  TwitterShareButton,
  TwitterIcon,
  LinkedinShareButton,
  LinkedinIcon,
  WhatsappShareButton,
  WhatsappIcon,
} from "react-share";
import useLoggedInUserData from "../../utility/useLoggedInUserData";

const AdviserProfileReviews = (props: any) => {
  const user = useLoggedInUserData();
  const [parentReviewId, setparentReviewId] = useState(0);
  const { addToast } = useToasts();
  let ratingCounts: any = { 1: 0, 2: 0, 3: 0, 4: 0, 5: 0 };
  const textAreaRef = useRef<any>(null);
  const shareTitle = "Please check this awesome mortgage website";
  let shareUrl = `${process.env.REACT_APP_FRONTEND_URL}review/${user.slug}`;
  const reviews = props.reviewRatingData;
  if (reviews.data && reviews.data.length) {
    [...reviews.data].forEach((r: any) => {
      ratingCounts[r.rating] = (ratingCounts[r.rating] || 0) + 1;
    });
  }

  const copyToClipboard = (e: { target: HTMLInputElement } | any) => {
    textAreaRef.current.select();
    document.execCommand("copy");
    e.target.focus();
    addToast(`URL ${shareUrl} copied to clipboard.`, { appearance: "info" });
  };

  const getRatingPercentage = (ratingCount: number) => {
    return reviews.avarageRatingTotal
      ? `${(ratingCount / reviews.avarageRatingTotal) * 100}%`
      : "";
  };

  const handleReplySpam = (parentReviewId: number) => {
    $("#description ").val('');
    $(".alert").hide();
    setparentReviewId(parentReviewId);
  };

  const [ariaExpanded, setAriaExpanded] = useState(false);

  return (
    <>
      <div className="tab-pane container active">
        <div className="row">
          <div className="col-xl-12">
            <form>
              <div className="pl-xl-5 pl-0 pr-xl-0 pr-0 mt-xl-3 mt-0 profile-datas offers-data">
                <h5 className="mb-3">Overall rating</h5>

                <div className="row text-center ratting">
                  <div className="col-lg-3 col-md-4">
                    <h4>{reviews.avarageRating}</h4>
                    <div
                      className={
                        Math.round(Number(reviews.avarageRating)) > 0
                          ? `mb-1 text-center review-star-rating-${Math.round(
                              Number(reviews.avarageRating)
                            )}`
                          : "mb-1 text-center review-star-rating-0"
                      }
                    >
                      <p
                        className={getStarCls(1, reviews.avarageRating)}
                        aria-hidden="true"
                      ></p>
                      <p
                        className={getStarCls(2, reviews.avarageRating)}
                        aria-hidden="true"
                      ></p>
                      <p
                        className={getStarCls(3, reviews.avarageRating)}
                        aria-hidden="true"
                      ></p>
                      <p
                        className={getStarCls(4, reviews.avarageRating)}
                        aria-hidden="true"
                      ></p>
                      <p
                        className={getStarCls(5, reviews.avarageRating)}
                        aria-hidden="true"
                      ></p>
                    </div>
                    <p>({reviews.avarageRatingTotal} Reviews)</p>
                  </div>
                  <div className="col-lg-9 col-md-8 text-left">
                    <div className="progress">
                      <label>Excellent</label>
                      <div
                        className="progress-bar"
                        style={{
                          backgroundColor: "#5dc591",
                          width: getRatingPercentage(ratingCounts[5]),
                        }}
                      ></div>
                    </div>
                    <div className="progress">
                      <label>Great</label>
                      <div
                        className="progress-bar"
                        style={{
                          backgroundColor: "#9ccd70",
                          width: getRatingPercentage(ratingCounts[4]),
                        }}
                      ></div>
                    </div>
                    <div className="progress">
                      <label>Average</label>
                      <div
                        className="progress-bar"
                        style={{
                          backgroundColor: "#ffd935",
                          width: getRatingPercentage(ratingCounts[3]),
                        }}
                      ></div>
                    </div>
                    <div className="progress">
                      <label>Poor</label>
                      <div
                        className="progress-bar"
                        style={{
                          backgroundColor: "#ffb235",
                          width: getRatingPercentage(ratingCounts[2]),
                        }}
                      ></div>
                    </div>
                    <div className="progress">
                      <label>Bad</label>
                      <div
                        className="progress-bar"
                        style={{
                          backgroundColor: "#ff4141",
                          width: getRatingPercentage(ratingCounts[1]),
                        }}
                      ></div>
                    </div>
                  </div>
                </div>
                <span className="borders text-center"></span>

                <div id="accordion" className="accordion invite_acc">
                  <div className="accordion-ques">
                    <div
                      className="ques collapsed"
                      data-toggle="collapse"
                      // data-href="#collapse-111"
                      // data-target="#collapse-111"
                      aria-expanded={ariaExpanded}
                      onClick={() =>
                        setAriaExpanded((preAriaExpanded) => !preAriaExpanded)
                      }
                    >
                      <h5 className="mb-3">
                        Invite your customers to leave reviews
                      </h5>
                    </div>
                    <div
                      id="collapse-111"
                      className={`collapse ${ariaExpanded ? "show" : ""}`}
                      data-parent="#accordion"
                    >
                      <div className="answers">
                        <p>
                          Reviews help you win new business. They don't have to
                          just come from customers you found through
                          mortgagebox, they can come from your existing
                          customers.
                        </p>
                        {/* <InviteNow page="advisorReviews" /> */}
                        <form>
                          <div className="form-group mt-3">
                            <div className="row">
                              <div className="col-lg-12 col-md-12 col-12">
                                <input
                                  type="text"
                                  id="refere_link"
                                  className="refere_link w-100"
                                  value={shareUrl}
                                  ref={textAreaRef}
                                  readOnly
                                />
                              </div>
                              <div className="col-lg-12 col-md-12 col-12 copy_share_social">
                                <ul className="pl-0 ml-0 mb-0 share_links display-inline-block">
                                  <li>
                                    <FacebookShareButton url={shareUrl} quote={shareTitle}>
                                      <FacebookIcon size={32} round={true} />
                                    </FacebookShareButton>
                                  </li>
                                  <li>
                                    <TwitterShareButton url={shareUrl} title={shareTitle}>
                                      <TwitterIcon size={32} round={true} />
                                    </TwitterShareButton>
                                  </li>
                                  <li>
                                    <LinkedinShareButton url={shareUrl} title={shareTitle}>
                                      <LinkedinIcon size={32} round={true} />
                                    </LinkedinShareButton>
                                  </li>
                                  <li>
                                    <WhatsappShareButton url={shareUrl} title={shareTitle}>
                                      <WhatsappIcon size={32} round={true} />
                                    </WhatsappShareButton>
                                  </li>
                                </ul>
                                <button
                                  type="button"
                                  className="copy_btn theme-btn text-center"
                                  onClick={copyToClipboard}
                                >
                                  Copy Link
                                </button>
                              </div>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>

                <span className="borders text-center"></span>

                <div className="row">
                  <div className="col-12">
                    <h5 className="mb-3">Reviews on Mortgagebox.co.uk</h5>
                  </div>
                </div>

                <div className="reviwes-datas">
                  {reviews.data && reviews.data.length
                    ? reviews.data.map((review: any) => (
                      (review.spam_status!=1?
                        <AdviserProfileReviewsSingle
                          key={review.id}
                          review={review}
                          handleReplySpam={handleReplySpam}
                        />
                        :"")
                      ))
                    : <div>
                        <p className="error-text">Reviews not found.</p>
                      </div>}
                </div>

                <div className="row">
                  <div className="col-12 text-center">
                    {/* <div className="pagination mr-pagination mt-md-5 mt-3">
                      <ul className="pl-0 ml-0 w-100">
                        <li>
                          <a href="javascript:void(0)">
                            <i
                              className="fa fa-angle-left"
                              aria-hidden="true"
                            ></i>
                          </a>
                        </li>
                        <li>
                          <a href="javascript:void(0)" className="active">
                            1
                          </a>
                          <a href="javascript:void(0)">2</a>
                          <a href="javascript:void(0)">3</a>
                        </li>
                        <li>
                          <a href="javascript:void(0)">
                            <i
                              className="fa fa-angle-right"
                              aria-hidden="true"
                            ></i>
                          </a>
                        </li>
                      </ul>
                    </div> */}
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      <MarkAsSpamModal parentReviewId={parentReviewId} />
      <ReplyReviewModal parentReviewId={parentReviewId} />
    </>
  );
};

export default AdviserProfileReviews;
