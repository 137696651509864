import React from "react";

const AboutTheCompanyModal = () => {
  return (
    <>
      <div
        className="mail-popup modal fade popup-model nutshell_popup about_company_popup"
        id="about_company_example"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-body">
              <div className="row">
                <div className="col-md-12">
                  <div className="heading text-center mb-4">
                    <h4 className="text-uppercase theme-color">
                      ABOUT THE COMPANY EXAMPLE
                    </h4>
                    <p>
                      Bercrow & Wip mortgage advisers is an independent mortgage
                      broker with strong relationships with High Street lenders
                      and accredited with specialist lenders not found on the
                      High Street.
                    </p>
                    <p>
                      We offer face to face advice in your home including
                      evenings and weekends. Alternatively, we can provide the
                      same expert advice over the phone, video, chat or email.
                      We like to keep the advice process simple and
                      straightforward, just tell us what you want to achieve and
                      we'll tell if it's doable.
                    </p>
                    <p>
                      We are passionate about taking the stress out of choosing
                      the right mortgage and providing professional and
                      individual mortgage advice. We do not charge broker fees
                      on standard mortgage applications! Voted Mortgage Adviser
                      of the Year.
                    </p>
                    <p>
                      Our friendly advisors will make sure you receive expert
                      advice and get the best deal possible.
                    </p>
                    <p>
                      ** You could lose your home if you don’t keep up your
                      mortgage repayments **
                    </p>
                  </div>
                </div>
                <div className="col-md-4 offset-md-4 mb-md-0 mb-3 text-center">
                  <button
                    type="button"
                    className="theme-btn"
                    data-dismiss="modal"
                  >
                    OK
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AboutTheCompanyModal;
