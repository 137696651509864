export const USER_LOGIN: string = "login";
export const FORGOT_PASSWORD: string = "forgotPassword";
export const USER_GET_PROFILE: string = "get_user";
export const GET_PROFILE_USER: string = "get_user_profile";
export const USERS_CHANGE_PASSWORD: string = "changePassword";
export const USERS_UPDATE_ACCOUNT: string = "updateAccount";
export const USERS_REGISTER_INTEREST: string = "addInterest";
export const USER_REGISTER: string = "addAdviceArea";
export const USER_ADD_ADVICE_AREA: string = "addNewAdviceArea";
export const SEND_MAIL_USER_MATCHED_LEADS: string = "send-mail-matched-lead";
export const USER_GET_ADVICE_AREA: string = "getUsersAdviceArea";
export const USER_GET_SINGLE_ADVICE_AREA: string = "getAdviceAreaById";
export const USER_GET_NOTES = "getAdviceNotesByAdviceId";
export const USER_ADVICE_GET_NOTES = "getAdviceNotesOfUserByAdviceId";
export const MARK_AREA_READ = "markAreaAsRead";
export const USER_ACCEPT_DECLINE_OFFER = "selectOrDeclineOffer";
export const ADD_ADVICE_NOTES = "addNotes";
export const UPDATE_ADVICE_NOTES = "updateNotes";
export const CLOSE_ADVICE_NEED = "closeAdviceAreaNeed";
export const RESEND_ACTIVATION_EMAIL = "resendActivationMail";

export const ADVISOR_REGISTER = "advisorRegister";
export const INVITE_USERS = "inviteUsers";
export const ADVISOR_MATCHED_LEADS = "matchLeads";
export const SEARCH_MORTGAGE_NEEDS = "searchMortgageNeeds";
export const SEARCH_ACCEPTED_NEEDS = "searchAcceptedNeeds";

export const ACCEPT_RJECT_BID = "acceptRejectBid";
export const GET_ADVICE_AREA_BID = "getAdviseAreaBid";
export const START_CHAT = "startChat";
export const SEND_MESSAGE = "sendMessage";
export const GET_ADVISOR_ACCEPTED_LEADS = "advisorAcceptedLeads";
export const GET_ALL_ADVISOR = "getAllAdviser";

export const GET_RECENT_MESSAGES = "getRecentMessages";
export const GET_RECENT_NOTIFICATIONS = "getNotification";
export const READ_RECENT_NOTIFICATIONS = "updateReadNotification";
export const SEND_FILE_TO_CHAT = "sendAttachment";
export const GET_ADVISOR_PROFILE = "getAdvisorProfileByAdvisorId";
export const GET_ADVISOR_PROFILE_BY_ID = "getAdvisorProfileById";

export const SET_RECENT_MESSAGES_OF_CHAT_TO_READ =
  "setRecentMessagesOfChatToRead";
export const ADVISOR_ADD_OFFER = "addOffer";
export const ADVISOR_DELETE_OFFER = "deleteOffer";
export const ADVISOR_EDIT_OFFER = "editOffer";
export const GET_ADVISOR_LINKS = "getAdvisorLinks";
export const SET_ADVISOR_LINKS = "setAdvisorLinks";
export const ADVISOR_ADD_REVIEW = "addReview";
export const REPORT_NEED_SPAM = "accepted-lead-spam";
export const REPORT_REVIEW_SPAM = "review-spam";

export const ADVISOR_UPDATE_GENERAL_INFO = "updateAdvisorGeneralInfo";
export const ADVISOR_UPDATE_ABOUT = "updateAdvisorAboutUs";
export const ADVISOR_GET_NOTIFICATION_PREFERENCES =
  "getNotificationPreferences";
export const ADVISOR_UPDATE_NOTIFICATION_PREFERENCES =
  "updateNotificationPreferences";
export const ADVISOR_GET_PRODUCT_PREFERENCES = "getAdvisorProductPreference";
export const ADVISOR_UPDATE_PRODUCT_PREFERENCES =
  "updateAdvisorProductPreference";
export const ADVISOR_GET_CUSTOMER_PREFERENCES = "getAdvisorCustomerPreference";
export const ADVISOR_UPDATE_CUSTOMER_PREFERENCES =
  "updateAdvisorCustomerPreference";
export const ADVISOR_GET_DEFAULT_PREFERENCES = "getAdvisorDefaultPreference";
export const ADVISOR_UPDATE_DEFAULT_PREFERENCES =
  "updateAdvisorDefaultPreference";
export const ADVISOR_GET_FIRSTMESSAGE_PREFERENCES = "getAdvisorFirstMessage";
export const ADVISOR_UPDATE_FIRSTMESSAGE_PREFERENCES = "updateFirstMessage";
export const ADVISOR_GET_LOCATION_PREFERENCES = "getAdvisorLocationPreference";
export const ADVISOR_UPDATE_LOCATION_PREFERENCES =
  "updateAdvisorLocationPreference";

export const ADVISOR_GET_BILLING_ADDRESS = "getAdvisorBillingAddress";
export const ADVISOR_UPDATE_BILLING_ADDRESS = "updateAdvisorBillingAddress";

export const ADVISOR_GET_TEAMS = "getAdvisorTeam";
export const ADVISOR_ADD_TEAMS = "advisorTeam";
export const ADVISOR_UPDATE_TEAMS = "updateTeam";
export const ADVISOR_DELETE_TEAMS = "deleteTeam";

export const ADVISOR_SEARCH = "searchAdvisor";
export const ADVISOR_DASHBOARD_DATA = "advisorDashboard";
export const ADVISOR_MAKE_ENQUIRY = "makeEnquiry";

export const ADVISOR_STRIPE_SAVE_CARD = "saveCard";
export const ADVISOR_STRIPE_GET_ALL_CARD = "getAllCardByCustomer";
export const ADVISOR_STRIPE_DELETE_CARD = "deleteCard";

export const SEARCH_POST_CODE = "searchPostalCode";
export const SEARCH_LOCATION_AUTO_SUGGESTION = "searchLocationAutoSuggestion";
export const CONTENT_PAGES = "getCMSData";
export const FAQ_LIST = "faq-list";
export const CONTACT_US = "contact-us";
export const SERVICE_TYPE = "getAllServiceType";
export const GET_TEAM_MEMBER = "getTeamMember";
export const UPDATE_COMPANY_ADMIN = "updateCompanyAdmin";
export const SERVICE_TYPE_AUTH = "getAllServiceTypeWithAuth";
export const MARK_PROJECT_COMPLETED = "markProjectCompleted";
export const SERVICES_WITH_PRODUCT_PREFRENCES = "getAllServiceTypeWithPreferences";
export const INVOICE_DATA = "invoice-display";
export const SEEN_MESSAGES = "seenMessages";
export const GET_COUNT_FILTER = "getCountFilter";
export const GET_COUNT_ACCEPTED_FILTER = "getCountAcceptedFilter";
export const GET_MORTGAGE_SIZE = "getMortgageSize";
export const ADD_CHATGPT_RESPONSE = "saveChatGptResponse";


export const DOWNLOAD_INVOICE_DATA = "downloadInvoice";