import React from "react";
import { useToasts } from "react-toast-notifications";
import { capitalize } from "../../utility/commonUtils";
import LoadingSpinner from "../common/LoadingSpinner";
import AddOfferModal from "../common/modals/AddOfferModal";
import DeleteConfirmModal from "../common/modals/DeleteConfirmModal";
import { advisorDeleteOffer } from "../common/service";
import $ from "jquery";
import "bootstrap";
import AboutInaNutshellModal from "../common/modals/advisorModals/AboutInaNutshellModal";
import OfferInaNutshellModal from "../common/modals/advisorModals/OfferInaNutshellModal";

const AdviserProfileOffers = (props: any) => {
  const { addToast } = useToasts();
  const [isLoading, setIsLoading] = React.useState<boolean>(true);
  const [offersData, setOffersData] = React.useState<any>([]);
  const [deleteItemId, setDeleteItemId] = React.useState();
  const [editItem, setEditItem] = React.useState({
    offer_id: 0,
    offer: "",
    description: "",
  });

  React.useEffect(() => {
    setOffersData(props.offersData);
    setIsLoading(false);
  }, [props.offersData]);

  const updateItem = (updatedOffer: any) => {
    const updatedOffers = offersData.map((offer: any) =>
      offer.offer_id === updatedOffer.offer_id ? updatedOffer : offer
    );
    setOffersData(updatedOffers);
  };

  const handleAddResponse = (type: boolean) => {
    if (type) {
      props.refechData();
    }
  };

  const handleDeleteResponse = async (id: any) => {
    const res = await advisorDeleteOffer(id);
    if (res.status) {
      setOffersData(() =>
        offersData.filter((offer: any) => offer.offer_id !== id)
      );
      $("#delete_modal").modal("hide");
      $("body").removeClass("modal-open");
      $(".modal-backdrop").remove();
      addToast(res.message, { appearance: "success" });
    } else {
      addToast(res.message, { appearance: "error" });
    }
  };

  return (
    <>
      <div className="tab-pane container active">
        <div className="row">
          <div className="col-xl-12">
            <form>
              <div className="pl-xl-5 pl-0 pr-xl-5 pr-0 mt-xl-3 mt-0 profile-datas offers-data">
                <h5 className="mb-3">Offers</h5>
                <p>
                  A great way to let your potential customers know your offers
                  and services in a concise format that will appear in your
                  profile. We have pre-populated this section with some common
                  offers, however, this is your profile so please add, edit or
                  remove as appropriate.
                </p>
                <div className="row mt-1">
                  <div className="col-lg-12 text-center">
                    <a
                      href="javascript:void(0)"
                      data-toggle="modal"
                      data-target="#nutshell_example"
                      className="example_btn"
                    >
                      Here's our example of 'Offers!'
                    </a>
                  </div>
                </div>
                <span className="borders text-center"></span>
              </div>

              <div className="pl-xl-5 pl-0 pr-xl-5 pr-0 mt-xl-3 mt-0 profile-datas offers-data">
                <LoadingSpinner loading={isLoading} />
                {offersData.length
                  ? offersData.map((offer: any) => (
                      <div className="offers" key={offer.offer_id}>
                        <h5 className="mb-3">{capitalize(offer.offer)}</h5>
                        <p>{offer.description}</p>
                        <div className="del-edit-but">
                          <a
                            href="javascript:void(0)"
                            data-toggle="modal"
                            data-target="#delete_modal"
                            className="delete-btn"
                            onClick={() => setDeleteItemId(offer.offer_id)}
                          >
                            DELETE
                          </a>
                          <a
                            href="javascript:void(0)"
                            data-toggle="modal"
                            data-target="#add_offer"
                            className="edit-btn"
                            onClick={() => setEditItem(offer)}
                          >
                            EDIT
                          </a>
                        </div>
                      </div>
                    ))
                  : null}
              </div>

              <div className="add-offer-btn mt-5">
                <a
                  href="javascript:void(0)"
                  data-toggle="modal"
                  data-target="#add_offer"
                  className="theme-btn"
                >
                  + ADD OFFER
                </a>
              </div>
            </form>
          </div>
        </div>
      </div>
      <AddOfferModal
        editItem={editItem}
        updateItem={updateItem}
        handleResponse={handleAddResponse}
      />
      <DeleteConfirmModal
        deleteItemId={deleteItemId}
        handleResponse={handleDeleteResponse}
      />
      <OfferInaNutshellModal />
    </>
  );
};

export default AdviserProfileOffers;
