import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useToasts } from "react-toast-notifications";
import {
  getAdvisorBillingAddress,
  searchPostalCode,
  updateAdvisorBillingAddress,
} from "../../components/common/service";
import { ADVISER_MY_ACCOUNT } from "../../Constants";
import { AsyncTypeahead } from "react-bootstrap-typeahead";

const initialState = {
  contact_name: "",
  invoice_name: "",
  address_one: "",
  address_two: "",
  city: "",
  post_code: "",
  contact_number: "",
  is_vat_registered: 0,
};

const AdviserBillingDetails = () => {
  const { addToast } = useToasts();
  const [formState, setFormState] = useState(initialState);

  const [isSeachLoading, setIsSeachLoading] = useState<boolean>(false);
  const [options, setOptions] = useState<any>([]);
  const [selectedOption, setselectedOption] = useState<any>([]);

  useEffect(() => {
    getAdvisorBillingAddressCall();
  }, []);

  useEffect(() => {
    setTimeout(() => {
      $(".rbt-input-main").val(formState.post_code);
    },50);
  }, [formState]);

  const getAdvisorBillingAddressCall = async () => {
    const res = await getAdvisorBillingAddress();
    if (res.data) {
      delete res.data.id;
      delete res.data.advisor_id;
      delete res.data.updated_at;
      delete res.data.created_at;
      setFormState(res.data);
    }
  };

  const handlePageState = (newStates: any) => {
    setFormState((prevStates: any) => {
      return {
        ...prevStates,
        ...newStates,
      };
    });
  };

  const handleChangeCheckbox = (event: { target: HTMLInputElement } | any) => {
    const { name, checked } = event.target;
    handlePageState({ [name]: +checked });
  };

  const handleChange = (event: { target: HTMLInputElement } | any) => {
    const { name, value } = event.target;
    handlePageState({ [name]: value });
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    const res = await updateAdvisorBillingAddress(formState);
    if (res.status) {
      addToast(res.message, { appearance: "success" });
    } else {
      addToast(res.message, { appearance: "error" });
    }
  };

  const showSelectedValue = (event: any) => {
    setTimeout(() => {
      $(".rbt-input-main").val(formState.post_code);
    },50);
  };

  const showSameName = (event: any) => {
    setTimeout(() => {
      var nameval = $("#name").val();
      handlePageState({ invoice_name: nameval });
    },50);
  };

  const handleSearch = async (query: any) => {
    setIsSeachLoading(true);
    const res = await searchPostalCode({ postal_code: query });
    if (res.status) {
      setOptions(res.data);
    }
    setIsSeachLoading(false);
  };

  const handleChangeSearch = (event: any) => {
    if (event.length) {
      setselectedOption([event[0]]);
      handlePageState({ post_code: event[0].Postcode });
    } else {
      setselectedOption([]);
    }
  };

  const filterBy = () => true;

  return (
    <>
      <main className="need-offers">
        <section className="page-title my-account-title">
          <div className="container">
            <div className="row">
              <div className="col-md-12 mb-5 mb-md-0"></div>
            </div>
          </div>
        </section>

        <section className="recent-sec directory-sec advice-area-sec register-final-sec pt-4 pb-5 myaccount-sec preference-product-sec billing-details-sec">
          <div className="container">
            <div className="row">
              <div className="col-12 back-btn mt-lg-4 mb-lg-3 mt-md-5 mt-5 mb-0">
                <Link to={ADVISER_MY_ACCOUNT} className="back-btn">
                  <i className="fa fa-angle-left" aria-hidden="true"></i>
                  My Account
                </Link>
              </div>
            </div>
          </div>

          <div className="container">
            <div className="row">
              <div className="col-12 mb-3 small-breadcumbs">
                <h5 className="text-white">Billing Details</h5>
              </div>
            </div>
            <div className="recent-box settings-data mb-lg-4 mb-0">
              <div className="row justify-content-center">
                <div className="col-xl-10 col-lg-12 mb-lg-4 mb-0">
                  <div className="tabbing">
                    <div className="tab-content mt-lg-4 mt-2">
                      <div className="row">
                        <div className="col-12">
                          <div className="profile-datas">
                            <h5 className="theme-color mb-3">
                              Billing address and contact details
                            </h5>
                            <p>
                              We’ll use these account details to contact you but
                              we won’t share them with customers.
                            </p>
                          </div>
                        </div>
                      </div>

                      <div className="billing-detais mt-3">
                        <form onSubmit={handleSubmit}>
                          <div className="team-memebers-data">
                            <div className="row">
                              <div className="col-12">
                                <div className="form-group">
                                  <label>Contact name</label>
                                  <input
                                    type="text"
                                    id="name"
                                    name="contact_name"
                                    value={formState.contact_name}
                                    onChange={handleChange}
                                    placeholder=""
                                    onFocus={(e) => (e.target.placeholder = "")}
                                    onBlur={(e) => (e.target.placeholder = "")}
                                    required
                                  />
                                </div>
                              </div>
                              <div className="col-12">
                                <div className="form-group">
                                  <label>Invoice <a onClick={showSameName} className="anchor-design"><p >Same as Contact Name</p></a></label>
                                  <input
                                    type="text"
                                    id="invoice_name"
                                    name="invoice_name"
                                    value={formState.invoice_name}
                                    onChange={handleChange}
                                    placeholder=""
                                    onFocus={(e) => (e.target.placeholder = "")}
                                    onBlur={(e) => (e.target.placeholder = "")}
                                    required
                                  />
                                </div>
                              </div>
                              <div className="col-12">
                                <div className="form-group">
                                  <label>Address line 1</label>
                                  <input
                                    type="text"
                                    id="address1"
                                    name="address_one"
                                    value={formState.address_one}
                                    onChange={handleChange}
                                    placeholder=""
                                    onFocus={(e) => (e.target.placeholder = "")}
                                    onBlur={(e) => (e.target.placeholder = "")}
                                    required
                                  />
                                </div>
                              </div>
                              <div className="col-12">
                                <div className="form-group">
                                  <label>Address line 2</label>
                                  <input
                                    type="text"
                                    id="address2"
                                    name="address_two"
                                    value={formState.address_two}
                                    onChange={handleChange}
                                    placeholder=""
                                    onFocus={(e) => (e.target.placeholder = "")}
                                    onBlur={(e) => (e.target.placeholder = "")}
                                  />
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="form-group">
                                  <label>City</label>
                                  <input
                                    type="text"
                                    id="city"
                                    name="city"
                                    value={formState.city}
                                    onChange={handleChange}
                                    placeholder=""
                                    onFocus={(e) => (e.target.placeholder = "")}
                                    onBlur={(e) => (e.target.placeholder = "")}
                                    required
                                  />
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="form-group">
                                  <label>Postcode</label>
                                  {/* <input
                                    type="text"
                                    id="postcode"
                                    name="post_code"
                                    value={formState.post_code}
                                    onChange={handleChange}
                                    placeholder=""
                                    onFocus={(e) => (e.target.placeholder = "")}
                                    onBlur={(e) => (e.target.placeholder = "")}
                                    required
                                  /> */}
                                  <AsyncTypeahead
                                    filterBy={filterBy}
                                    id="async-example"
                                    isLoading={isSeachLoading}
                                    labelKey="Postcode"
                                    minLength={2}
                                    onSearch={handleSearch}
                                    options={options}
                                    defaultInputValue={formState.post_code}
                                    // defaultInputValue={formState?.postcode}
                                    onChange={handleChangeSearch}
                                    onBlur={showSelectedValue}
                                    selected={selectedOption}
                                    placeholder="TW12 1AA"
                                    className="postCodeSearchInnerAccount"
                                    renderMenuItemChildren={(option: any, props: any) => (
                                      <React.Fragment>
                                        <span>{option?.Postcode}</span>
                                      </React.Fragment>
                                    )}
                                  />
                                </div>
                              </div>
                              <div className="col-12">
                                <div className="form-group">
                                  <label>Contact number</label>
                                  <input
                                    type="text"
                                    id="contact_number"
                                    name="contact_number"
                                    value={formState.contact_number}
                                    onChange={handleChange}
                                    placeholder=""
                                    onFocus={(e) => (e.target.placeholder = "")}
                                    onBlur={(e) => (e.target.placeholder = "")}
                                    required
                                  />
                                </div>
                              </div>
                              <div className="col-12 mt-3">
                                <div className="form-group checkboxes-data mb-0">
                                  <input
                                    type="checkbox"
                                    id="vat_register"
                                    name="is_vat_registered"
                                    value={1}
                                    onChange={handleChangeCheckbox}
                                    checked={formState.is_vat_registered === 1}
                                  />
                                  <label htmlFor="vat_register">
                                    VAT Registered
                                  </label>
                                </div>
                              </div>
                              <div className="col-12 mt-3 text-center">
                                <div className="form-group">
                                  <button type="submit" className="theme-btn">
                                    Save
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <img
            src="assets/images/business_right.png"
            alt="help-1"
            className="img-fluid before-img"
          />
          <img
            src="assets/images/business_left.png"
            alt="help-1"
            className="img-fluid after-img"
          />
        </section>
      </main>
    </>
  );
};

export default AdviserBillingDetails;
