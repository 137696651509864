import React from "react";
import { Link } from "react-router-dom";

const CreateMortgageNeedNotice = () => {
  return (
    <>
      <div className="datas mb-md-5 mb-3">
        <div className="block">
          <h5>There is an easier way to find your mortgage adviser ...</h5>
          <p>
            Enter your mortgage need just once and let expert advisers matched
            to your mortgage need one preference come to you - no need to need
            to spend hours browsing / ringing around
          </p>
          <Link to={`/advice-area`} className="theme-btn text-uppercase">
            find me an expert
          </Link>
        </div>
      </div>
    </>
  );
};

export default CreateMortgageNeedNotice;
