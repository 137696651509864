import React, { useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";

const InviteAdvisor = () => {
  const urlParams: { id: any } = useParams();
  const { id } = urlParams;
  const history = useHistory();
  const queryParams = new URLSearchParams(window.location.search);
  const invitedToEmail = queryParams.get("invitedToEmail");

  useEffect(() => {
    if(invitedToEmail!=null){
      const email = encodeURI(invitedToEmail);
      history.push(`/register?invitedBy=${id}&invitedToEmail=${email}`, { from: `/invite/${id}` });
    }else{
      history.push(`/register?invitedBy=${id}`, { from: `/invite/${id}` });
    }
  }, []);

  return <></>;
};

export default InviteAdvisor;
