import React, { useEffect, useState } from "react";
import InlineAlert from "../../InlineAlert";
import LoadingSpinner from "../../../common/LoadingSpinner";
import { openAddReview } from "../../service";
import $ from "jquery";
import "bootstrap";
import { useHistory } from "react-router-dom";

const initialState = {
  status: 0,
  rating: 0,
  review_title: "",
  reviews: "",
  advisor_id: 0,
  reviewer_name: "",
  user_id: 0,
  isError: false,
  responseMessage: "",
};

const LeaveReviewWithoutLoginModal = (props: any) => {
  const history = useHistory();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [formState, setFormState] = useState({
    ...initialState,
  });

  useEffect(() => {
    handlePageState({
      advisor_id: props.advisor.advisor_data.advisorId,
    });
  }, [props.advisor.advisor_data.advisorId]);

  const handlePageState = (newStates: any) => {
    setFormState((prevStates) => {
      return {
        ...prevStates,
        ...newStates,
      };
    });
  };

  const onSubmitHandler = async (e: any) => {
    e.preventDefault();
    if(formState.rating==0){
      handlePageState({
        responseMessage: "Please select star rating.",
        isError: true,
      });
    }else{
      setIsLoading(true);
      const res = await openAddReview(formState);
      setIsLoading(false);
      if (res.status) {
        setFormState(initialState);
        handlePageState({ responseMessage: res.message, isError: false });
      } else {
        handlePageState({ responseMessage: res.message, isError: true });
      }
    }
  };

  const cancelReview = async (e: any) => {
    e.preventDefault();
    $("#leaveReviewModal").modal("hide");
    $("body").removeClass("modal-open");
    $(".modal-backdrop").remove();
    handlePageState({ ...initialState });
    history.push(`/`);
  };

  return (
    <>
      <div className="mail-popup modal fade popup-model pr-null" id="leaveReviewModal">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-body">
              <div className="row">
                <div className="col-md-12">
                  <div className="heading text-center mb-4">
                    <h4 className="text-uppercase theme-color">
                      LEAVE A REVIEW  FOR {props.advisor.advisor_data.display_name}
                    </h4>
                    <span></span>
                  </div>
                  <div className="mb-4 mt-lg-5 mt-2">
                    <form onSubmit={onSubmitHandler}>
                      {formState.responseMessage && (
                        <InlineAlert
                          isError={formState.isError}
                          message={formState.responseMessage}
                        />
                      )}
                      <p className="m-2 mb-lg-4 mb-3">
                        Rate your experience
                        <span
                          className={
                            Math.round(Number(formState.rating)) > 0
                              ? `form-star mt-4 mb-4 text-center ml-3 review-star-rating-${Math.round(
                                  Number(formState.rating)
                                )}`
                              : "form-star mt-4 mb-4 text-center ml-3 review-star-rating-0"
                          }
                        >
                          <span
                            aria-hidden="true"
                            className={`fa fa-star ${
                              formState.rating >= 1 && "checked"
                            }`}
                            onClick={() => handlePageState({ rating: 1 })}
                          ></span>
                          <span
                            aria-hidden="true"
                            className={`fa fa-star ${
                              formState.rating >= 2 && "checked"
                            }`}
                            onClick={() => handlePageState({ rating: 2 })}
                          ></span>
                          <span
                            aria-hidden="true"
                            className={`fa fa-star ${
                              formState.rating >= 3 && "checked"
                            }`}
                            onClick={() => handlePageState({ rating: 3 })}
                          ></span>
                          <span
                            aria-hidden="true"
                            className={`fa fa-star ${
                              formState.rating >= 4 && "checked"
                            }`}
                            onClick={() => handlePageState({ rating: 4 })}
                          ></span>
                          <span
                            aria-hidden="true"
                            className={`fa fa-star ${
                              formState.rating >= 5 && "checked"
                            }`}
                            onClick={() => handlePageState({ rating: 5 })}
                          ></span>
                        </span>
                      </p>
                      <p className="m-2">Headline of the review</p>
                      <div className="form-group d-flex align-items-center">
                        <input
                          type="text"
                          className="mb-0"
                          name="review_title"
                          value={formState.review_title}
                          onChange={(e) =>
                            handlePageState({
                              review_title: e.target.value,
                            })
                          }
                          placeholder="Whats the most important to know?"
                          required
                        />
                      </div>
                      <p className="m-2">Tell us about your experience</p>
                      <div className="form-group d-flex align-items-center mb-0">
                        <textarea
                          name="reviews"
                          value={formState.reviews}
                          required
                          onChange={(e) =>
                            handlePageState({
                              reviews: e.target.value,
                            })
                          }
                          placeholder="Share your honest experience, and help other people make better choices"
                        ></textarea>
                      </div>
                      <p className="m-2">Name</p>
                      <div className="form-group d-flex align-items-center">
                      <input
                          type="text"
                          className="mb-0"
                          name="reviewer_name"
                          value={formState.reviewer_name}
                          required
                          onChange={(e) =>
                            handlePageState({
                              reviewer_name: e.target.value,
                            })
                          }
                          placeholder=""
                        />
                      </div>
                      <div className="row align-items-center">
                        <div className="col-6 col-md-6">
                          <button
                            type="button"
                            className="normal-btn cacle-bnt text-uppercase"
                            onClick={cancelReview}
                          >
                            cancel
                          </button>
                        </div>
                        <div className="col-6 col-md-6 text-right">
                        {!isLoading?<button
                            type="submit"
                            className="theme-btn text-uppercase"
                          >
                            POST REVIEW
                          </button>:<button
                            type="button"
                            className="theme-btn text-uppercase"
                          >
                            Please Wait...
                          </button>}
                          {/* <button
                            type="submit"
                            className="theme-btn text-uppercase"
                          >
                            POST REVIEW
                          </button> */}
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default LeaveReviewWithoutLoginModal;
