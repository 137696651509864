import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";


const FaqViewingAndAcceptingALead = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  


  return (
    <>
      <main>
        <section className="page-title">
          <div className="container">
            <div className="row">
              <div className="col-md-12 text-center">
                <div className="heading">
                  <h2>Frequently Asked Questions</h2>
                  <ul className="breadcrumbs">
                    <li>
                      <Link to={`/faq`}>FAQs</Link>
                    </li>
                    <li>Viewing and accepting a prospective lead</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="single-faq pt-5 pb-5">
          <img
            src="assets/images/left-shape.png"
            alt="help-1"
            className="img-fluid-left after-img"
          />
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-8 text-left mb-4">
                <div className="heading">
                  <h2 className="text-uppercase">4. Viewing and accepting a prospective lead</h2>
                  <span className="left-divider"></span>
                </div>
              </div>
              <div className="col-lg-8">                
                {/* <div className="text-center">
                    <img src="assets/faq/faq-logo.png"  />  
                </div>
                <p style={{"marginTop":"0in","marginRight":"0in","marginBottom":"8pt","marginLeft":"0in","fontSize":"11pt","fontFamily":"'Calibri', sansSerif","textAlign":"center"}}>&nbsp;</p> */}
                
                {/* <p className="MsoNormal" style={{"textAlign":"center"}}>
                    <b>
                        <span lang="EN-GB" style={{"fontSize":"20pt","lineHeight":"107%"}}>Viewing and accepting a lead</span>
                    </b>
                </p> */}

                <p><span>Welcome to MortgageBox.co.uk, where we give customers more options, better deals, and a higher acceptance rate by putting them in contact with 5 FCA registered mortgage advisers. </span></p>

                <p className="MsoNormal">
                    <span lang="EN-GB">In the next few pages, we walk you through the steps of viewing and accepting a lead.<span></span></span>
                </p>

                <strong style={{"fontSize":"18px","lineHeight":"107%"}}><span>Viewing and accepting a prospective lead</span></strong>

                
                <ul className="decimal_type" style={{"listStyleType":"undefined","marginTop":"0.1in","paddingLeft":"0"}}>
                    <li>
                      <strong><span style={{"fontSize":"14px"}}>4.1 Pre-requisite</span></strong>
                    </li>
                </ul>
               
                <p className="MsoNormal">
                    <span lang="EN-GB"><u>Email confirmation</u> and <u>FCA validation</u> are required before you can accept a lead.<span></span></span>
                </p>

                <ul className="decimal_type" style={{"listStyleType":"undefined","marginTop":"0.25in","paddingLeft":"0"}}>
                    <li>
                      <strong><span style={{"fontSize":"14px"}}>4.2 Viewing and accepting leads</span></strong>
                    </li>
                </ul>

                <p className="MsoNormal">
                    <span>The leads page allows you to quickly see all leads that meet your preferences. </span>
                </p>
                

                <ul>
                    <li>
                        <strong><span>i) Realtime lead list</span></strong>
                    </li>
                </ul>

                <p className="MsoNormal" style={{"marginLeft":"0.5in"}}>
                    <span lang="EN-GB" style={{"color":"#002060"}}>Shows a summary of all leads that meet your filter criteria. Click on a lead and further details are shown in Area 2.</span>
                </p>

                <ul>
                    <li>
                        <strong><span>ii) Mortgage need overview</span></strong>
                    </li>
                </ul>

                <p className="MsoNormal" style={{"marginLeft":"0.5in"}}>
                    <span lang="EN-GB" style={{"color":"#002060"}}>Key nuggets of the mortgage need such as cost, size, lead value, and number of introduction slots remaining (a maximum of five advisers can be introduced).</span>
                </p>

                <ul>
                    <li>
                        <strong><span>iii) Mortgage details</span></strong>
                    </li>
                </ul>

                <p className="MsoNormal" style={{"marginLeft":"0.5in"}}>
                    <span lang="EN-GB" style={{"color":"#002060"}}>Further details on the customer’s mortgage need, including the amount required, property value, income, customer preferences, speed, and any special requirements.</span>
                </p>

                <ul>
                    <li>
                        <strong><span>iv) Cost of lead</span></strong>
                    </li>
                </ul>

                <p className="MsoNormal" style={{"marginLeft":"0.5in"}}>
                    <span lang="EN-GB" style={{"color":"#002060"}}>Shows the cost of accepting the lead, after which you will be able to message the customer.</span>
                </p>

                <p className="MsoNormal" style={{"marginLeft":"0.5in"}}>
                    <span lang="EN-GB" style={{"color":"#002060"}}>
                        The cost will vary depending on the mortgage size and time that the lead has been listed. A lead will be listed until either 5 advisers have been introduced or 14 days have passed. Each day that passes and the lead remains
                        available, the cost of the lead will halve. After three days the lead becomes free.
                    </span>
                </p>

                <p className="MsoNormal" style={{"marginLeft":"0.5in"}}>
                    <span lang="EN-GB" style={{"color":"#002060"}}>As an introductory offer to new advisers, the first 5 leads are free.</span>
                </p>

                <ul>
                    <li>
                        <strong><span>v) Accepting the lead</span></strong>
                    </li>
                </ul>

                <p className="MsoNormal" style={{"marginLeft":"0.5in"}}>
                    <span lang="EN-GB" style={{"color":"#002060"}}>A lead can be accepted until either 5 advisers have been introduced or 14 days have passed. </span>
                </p>

                <p className="MsoNormal" style={{"marginLeft":"0.5in"}}>
                    <span lang="EN-GB" style={{"color":"#002060"}}>After clicking ‘Accept lead’, you will be asked to reconfirm your lead purchase with the cost shown. </span>
                </p>
                
                <p>
                    <span style={{"float":"left","width":"100%","textAlign":"center"}}>
                    <img src="assets/faq/accepting-the-lead.png" style={{"border":"2px solid #ddd"}} />
                    </span>
                </p>

                <ul className="decimal_type" style={{"listStyleType":"undefined","marginTop":"0.25in","paddingLeft":"0","float":"left","width":"100%"}}>
                    <li>
                      <strong><span style={{"fontSize":"14px"}}>4.3 How should I decide which leads to accept?</span></strong>
                    </li>
                </ul>

                <p className="MsoNormal">
                    <span>
                        Great question. A good match to your areas of expertise and your customer preference is the starting point. After that another important consideration is how busy you are – in quiet times you will go for more as opposed to busy
                        times.
                    </span>
                </p>

                <p className="MsoNormal">
                    <span>
                        Another consideration is how much information has the customer provided and what it tells you. We show all the information provided so you can make informed decisions.
                    </span>
                </p>

                <p className="MsoNormal">
                    <span>
                        We also recommend you set your Lead Preferences as this will help you find customers best matched to your business.<i></i>
                    </span>
                </p>

                <ul className="decimal_type" style={{"listStyleType":"undefined","marginTop":"0.25in","paddingLeft":"0","float":"left","width":"100%"}}>
                    <li>
                      <strong><span style={{"fontSize":"14px"}}>4.4	How quickly should I accept my chosen lead?</span></strong>
                    </li>
                </ul>

                <p className="MsoNormal">
                    <span lang="EN-GB">
                        To stand the best chance of winning the business, respond to your chosen leads within a few hours, otherwise, you may find that other advisers have snapped them up.
                    </span>
                </p>

                <p className="MsoNormal">
                    <span lang="EN-GB">
                        We also recognise that as a lead gets older and remains available this indicates the lead is less desirable. Therefore, to address this, we reduce the cost by 50% every 24 hours. After three days the lead becomes free.
                    </span>
                </p>

                <ul className="decimal_type" style={{"listStyleType":"undefined","marginTop":"0.25in","paddingLeft":"0","float":"left","width":"100%"}}>
                    <li>
                      <strong><span style={{"fontSize":"14px"}}>4.5 What happens after I accept a lead?</span></strong>
                    </li>
                </ul>

                <p className="MsoNormal">
                    <span lang="EN-GB">The lead moves to the ‘Purchased’ tab. This tab allows you to view and filter leads along with contacting the customer through the MortgageBox messaging app.</span>
                </p>
                <p>
                    <span style={{"float":"left","width":"100%","textAlign":"center"}}>
                    <img src="assets/faq/what-happens-after-accept-a-lead.png" style={{"border":"2px solid #ddd"}} />
                    </span>
                </p>

                <ul className="decimal_type" style={{"listStyleType":"undefined","marginTop":"0.25in","paddingLeft":"0","float":"left","width":"100%"}}>
                    <li>
                      <strong><span style={{"fontSize":"14px"}}>4.6 What does a great first message look like?</span></strong>
                    </li>
                </ul>


                <p className="MsoNormal">
                    <span lang="EN-GB">Your first message should include key points about your business and your offering, including:</span>
                </p>
                <div style={{"marginTop":"0in","marginRight":"0in","marginBottom":"8pt","marginLeft":"0in"}}>
                  <ol style={{"marginBottom":"0in","listStyleType":"disc"}}>
                      <li style={{"marginTop":"0in","marginRight":"0in","marginBottom":"8pt","marginLeft":"0in"}}>
                      A short, clear description of how you can help the customer.
                      </li>
                      <li style={{"marginTop":"0in","marginRight":"0in","marginBottom":"8pt","marginLeft":"0in"}}>
                      A basic estimate of costs if possible, and your contact details.
                      </li>
                    </ol>
                </div>

                <p className="MsoNormal">
                    <span lang="EN-GB">It is also crucial that your message is well written and formatted with no spelling or grammatical errors. </span>
                </p>

                <p className="MsoNormal">
                    <span lang="EN-GB">
                        The first message can be further tailored prior to sending to a customer to ensure a personalised response. In the example below, ‘First message’ has been selected which auto-populates the message area.
                    </span>
                </p>

                <p>
                    <span style={{"float":"left","width":"100%","textAlign":"center"}}>
                    <img src="assets/faq/what-doesaa-great-first-message-look-like.png" style={{"border":"2px solid #ddd"}} />
                    </span>
                </p>

                <p className="MsoNormal">
                    <span lang="EN-GB" >Then, after sending you will see </span>
                </p>

                <p>
                    <span style={{"float":"left","width":"100%","textAlign":"center"}}>
                    <img src="assets/faq/what-happens-after-accept-a-lead2.png" style={{"border":"2px solid #ddd"}} />
                    </span>
                </p>

                <ul className="decimal_type" style={{"listStyleType":"undefined","marginTop":"0.25in","paddingLeft":"0","float":"left","width":"100%"}}>
                    <li>
                      <strong><span style={{"fontSize":"14px"}}>4.7 How do I know if I get selected?</span></strong>
                    </li>
                </ul>

                <p className="MsoNormal">
                    <span lang="EN-GB">We will let you know by:</span>
                </p>

                <div style={{"marginTop":"0in","marginRight":"0in","marginBottom":"8pt","marginLeft":"0in"}}>
                  <ol style={{"marginBottom":"0in","listStyleType":"disc"}}>
                      <li style={{"marginTop":"0in","marginRight":"0in","marginBottom":"8pt","marginLeft":"0in"}}>
                      Email
                      </li>
                      <li style={{"marginTop":"0in","marginRight":"0in","marginBottom":"8pt","marginLeft":"0in"}}>
                      Providing a notification on the Dashboard page
                      </li>
                      <li style={{"marginTop":"0in","marginRight":"0in","marginBottom":"8pt","marginLeft":"0in"}}>
                      Changing the status of the lead from ‘Prospect’ to ‘Hired’
                      </li>
                    </ol>
                </div>

                <p className="MsoNormal">
                    <span lang="EN-GB">
                        Once you are hired, your real job starts! Feel free to continue to use the MortgageBox messaging app but also consider more direct lines of communication such as email, phone, and face-to-face based on the customer's preference.
                        These details can be exchanged through the MortgageBox messaging app.
                    </span>
                </p>

                <p className="MsoNormal">
                    <span lang="EN-GB">The page below shows the ‘Purchased’ tab, with Hired selected.</span>
                </p>
                <p>
                    <span style={{"float":"left","width":"100%","textAlign":"center"}}>
                    <img src="assets/faq/do-know-if-get-selected.png" style={{"border":"2px solid #ddd"}} />
                    </span>
                </p>

                <ul className="decimal_type" style={{"listStyleType":"undefined","marginTop":"0.25in","paddingLeft":"0","float":"left","width":"100%"}}>
                    <li>
                      <strong><span style={{"fontSize":"14px"}}>4.8 How many free leads do I have?</span></strong>
                    </li>
                </ul>

                <p className="MsoNormal">
                    <span lang="EN-GB">
                        When you register for the first time, we give you five free leads. Additional free leads can be earned by working with us to improve MortgageBox. One free lead is used each time you accept a lead. To see how many free introductions
                        you have, check out the dashboard.
                    </span>
                </p>
                <p>
                    <span style={{"float":"left","width":"100%","textAlign":"center"}}>
                    <img src="assets/faq/how-many-free-leads-do-have.png" style={{"border":"2px solid #ddd"}} />
                    </span>
                </p>

                <ul className="decimal_type" style={{"listStyleType":"undefined","marginTop":"0.25in","paddingLeft":"0","float":"left","width":"100%"}}>
                    <li>
                      <strong><span style={{"fontSize":"14px"}}>4.9 Why have I not heard back from a customer?</span></strong>
                    </li>
                </ul>

                <p className="MsoNormal">
                    <span>As much as we would like to, we cannot guarantee that every customer you contact through MortgageBox will respond to you.</span>
                </p>

                <p className="MsoNormal">
                    <span lang="EN-GB">
                        Customers receive different responses from competing professionals, so it may be the case that a customer only replies to the professional(s) they want to hire or have shortlisted.
                    </span>
                </p>

                <ul className="decimal_type" style={{"listStyleType":"undefined","marginTop":"0.25in","paddingLeft":"0","float":"left","width":"100%"}}>
                    <li>
                      <strong><span style={{"fontSize":"14px"}}>4.10 Can I ask a customer for more information before responding to the lead?</span></strong>
                    </li>
                </ul>

                <p className="MsoNormal">
                    <span lang="EN-GB">
                        We ask the customers for a summary of their mortgage need, including approximate financials, to allow you to make an informed choice on whether to accept the lead. However, some customers’ responses may not be as complete as we
                        would hope for.
                    </span>
                </p>

                <p className="MsoNormal">
                    <span lang="EN-GB">
                        Should you feel that there is not enough information then we would recommend that you do not accept the lead, as it is not possible to ask the customer for more information without having first accepted the lead.
                    </span>
                </p>

                <ul className="decimal_type" style={{"listStyleType":"undefined","marginTop":"0.25in","paddingLeft":"0","float":"left","width":"100%"}}>
                    <li>
                      <strong><span style={{"fontSize":"14px"}}>4.11 Can I receive exclusive leads just for me?</span></strong>
                    </li>
                </ul>

                <p className="MsoNormal">
                    <span lang="EN-GB">Unfortunately not. MortgageBox is an online marketplace focused on giving customers choices and helping them find the best adviser for their mortgage need.</span>
                </p>

                <ul className="decimal_type" style={{"listStyleType":"undefined","marginTop":"0.25in","paddingLeft":"0","float":"left","width":"100%"}}>
                    <li>
                      <strong><span style={{"fontSize":"14px"}}>4.12 What is the estimated value of a lead and how is this calculated?</span></strong>
                    </li>
                </ul>

                <p className="MsoNormal">
                    <span lang="EN-GB">
                        The estimated value indicates what the lead will be worth if you are successful. We do not show any information on pricing to the customers.
                    </span>
                </p>

                <p className="MsoNormal">
                    <span lang="EN-GB">
                        It’s designed so that professionals can quickly see the value of a lead, assess whether to bid, and if there is any room for flexibility on pricing or customer offers. 
                    </span>
                </p>

                <p className="MsoNormal">
                    <span lang="EN-GB">The estimated value takes into account the value and type of mortgage and is configurable through settings.</span>
                </p>

                <ul className="decimal_type" style={{"listStyleType":"undefined","marginTop":"0.25in","paddingLeft":"0","float":"left","width":"100%"}}>
                    <li>
                      <strong><span style={{"fontSize":"14px"}}>4.13 What is the average amount of leads it takes to get hired on MortgageBox?</span></strong>
                    </li>
                </ul>

                <p className="MsoNormal">
                    <span lang="EN-GB">Statistically, professionals will convert 1 in 5 leads. That said, this will vary from adviser to adviser.</span>
                </p>

                <p className="MsoNormal">
                    <span lang="EN-GB">The best on MortgageBox will convert as much as 50% of the leads they go for. To help achieve this it is important to have a great profile.</span>
                </p>

                <p className="MsoNormal">
                    <span lang="EN-GB" style={{"fontSize":"12pt","lineHeight":"107%"}}></span>
                </p>

                <ul className="decimal_type" style={{"listStyleType":"undefined","marginTop":"0.25in","paddingLeft":"0","float":"left","width":"100%"}}>
                    <li>
                      <strong><span style={{"fontSize":"14px"}}>4.14 Is it free to send additional messages to a customer?</span></strong>
                    </li>
                </ul>
                <p className="MsoNormal">
                    <span lang="EN-GB">Yes, it is free to send additional messages. You only pay once when you first accept the lead.</span>
                </p>

              </div>
            </div>
          </div>
          <img
            src="assets/images/right-shape.png"
            alt="help-1"
            className="img-fluid before-img"
          />
        </section>
      </main>
    </>
  );
};

export default FaqViewingAndAcceptingALead;
