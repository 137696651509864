import React, {useEffect, useCallback, useContext, useState } from "react";
import { BrowserRouter as Router, Switch } from "react-router-dom";
import {
  ADVISER_DASHBOARD,
  ADVISER_LEADS_ACCEPTED,
  ADVISER_LEADS_FILTER,
  ADVISER_LEADS_ACCEPTED_FILTER,
  ADVISER_LEADS_MATCHES,
  ADVISER_MY_ACCOUNT,
  ADVISER_MY_PROFILE,
  ADVISER_NOTIFICATIONS,
  ADVISER_PAYMENT_BILLING_DETAILS,
  ADVISER_PAYMENT_DETAILS,
  ADVISER_PAYMENT_INVOICES,
  ADVISER_PAYMENT_PAY_INVOICE,
  ADVISER_PREFERENCES,
  ADVISER_TEAM_MEMBERS,
  JWT_TOKEN,
} from "../Constants";
import { AuthContext } from "../shared/context/auth-context";
import SignUp from "../pages/SignUp";
import UserDashboard from "../pages/UserDashboard";
import Logout from "../components/common/Logout";
import AdviceAreaDetail from "../pages/AdviceAreaDetail";
import ScrollToTop from "./ScrollToTop";
import UserAccount from "../pages/UserAccount";
import PublicRoute from "./PublicRoutes";
import PrivateRoute from "./PrivateRoute";
import Home from "../pages/Home";
import About from "../pages/About";
import PrivacyPolicy from "../pages/PrivacyPolicy";
import Press from "../pages/Press";
import Services from "../pages/Services";
import TermsConditions from "../pages/TermsConditions";
import Pricing from "../pages/Pricing";
import PostcodeSearch from "../pages/PostcodeSearch";
import CloseAdviceAreaNeed from "../pages/CloseAdviceAreaNeed";
import PricingDetails from "../pages/PricingDetails";
import AdvisorSignUp from "../pages/AdvisorSignUp";
import AdviserLeadsMatches from "../pages/adviser/AdviserLeadsMatches";
import InviteNow from "../pages/public/InviteNow";
import InviteUsers from "../pages/public/InviteUsers";
import InviteAdvisor from "../pages/public/InviteAdvisor";
import Header from "../components/Header";
import Footer from "../components/Footer";
import ChatGPT from "../components/ChatGPT";  
import Faq from "../pages/Faq";
import Sitemap from "../pages/Sitemap";
import FaqAdviserRegistrationStepByStepGuide from "../pages/public/FaqAdviserRegistrationStepByStepGuide";
import FaqCreatingAProfessionalLookingProfile from "../pages/public/FaqCreatingAProfessionalLookingProfile";
import FaqSettingCustomerAndProductPreferences from "../pages/public/FaqSettingCustomerAndProductPreferences";
import FaqViewingAndAcceptingALead from "../pages/public/FaqViewingAndAcceptingALead";
import FaqInner from "../pages/public/FaqInner";
import AdvisorProfile from "../pages/AdvisorProfile";
import AdviserMyAccount from "../pages/adviser/AdviserMyAccount";
import Directory from "../pages/private/Directory";
import DirectorySitemap from "../pages/private/DirectorySitemap";
import AdviserPaymentDetails from "../pages/adviser/AdviserPaymentDetails";
import AdviserBillingDetails from "../pages/adviser/AdviserBillingDetails";
import AdviserCreditInvoice from "../pages/adviser/AdviserCreditInvoice";
import AdviserPayInvoice from "../pages/adviser/AdviserPayInvoice";
import AdviserNotifications from "../pages/adviser/AdviserNotifications";
import AdviserTeamMember from "../pages/adviser/AdviserTeamMember";
import AdviserPreferences from "../pages/adviser/AdviserPreferences";
import AdviserMyProfile from "../pages/adviser/AdviserMyProfile";
import AdviserLeadsAccepted from "../pages/adviser/AdviserLeadsAccepted";
import AdvisorDashboard from "../pages/adviser/AdvisorDashboard";
import AdviserLeadsFilter from "../pages/adviser/AdviserLeadsFilter";
import AdviserLeadsAcceptedFilter from "../pages/adviser/AdviserLeadsAcceptedFilter";
import AdviserHome from "../pages/AdviserHome";
import GiveReviewToAdviser from "../pages/public/GiveReviewToAdviser";
import ContactUs from "../pages/ContactUs";
import AdviserFooter from "../components/AdviserFooter";
import AdviserAbout from "../pages/AdviserAbout";
import AdviserPrivacyPolicy from "../pages/AdviserPrivacyPolicy";
import AdviserPress from "../pages/AdviserPress";
import AdviserServices from "../pages/AdviserServices";
import AdviserTermsConditions from "../pages/AdviserTermsConditions";
import HomeTwo from "../pages/public/HomeTwo";
import AdvicerTwo from "../pages/public/AdvicerTwo";
import PricingTwo from "../pages/public/PricingTwo";

const Routes = () => {
  const pathname = window.location.pathname; 
  const auth = useContext(AuthContext);
  const [isLoggedIn, setIsLoggedIn] = useState<boolean>(auth.isLoggedIn);
  const [user, setUser] = useState<any>({});

  const [routeChanged, setRouteChanged] = useState(false);

  const login = useCallback((user: any) => {
    setIsLoggedIn(true);
    setUser(user);
  }, []);

  useEffect(() => {
    setTimeout(function(){
      const pathname = window.location.pathname; 
      console.log(pathname);
    },500)
    console.log(pathname);

    setRouteChanged(true);
    const timeout = setTimeout(() => setRouteChanged(false), 500); // Reset after a short delay
    return () => clearTimeout(timeout);
  }, [pathname]);

  const logout = useCallback(() => {
    localStorage.removeItem(JWT_TOKEN);
    setIsLoggedIn(false);
    setUser({});
  }, []);

  return (
    <AuthContext.Provider
      value={{
        isLoggedIn,
        user,
        login,
        logout,
      }}
    >
      <Router>
        <ScrollToTop />
        <Header />

        <Switch>
          <PublicRoute component={HomeTwo} path={"/"} exact />
          <PublicRoute component={About} path={"/about"} exact />
          <PublicRoute component={PrivacyPolicy} path={"/privacy-policy"} exact />
          <PublicRoute component={Press} path={"/press"} exact />
          <PublicRoute component={Services} path={"/services"} exact />
          <PublicRoute component={TermsConditions} path={"/terms-conditions"} exact />
          <PublicRoute component={AdviserAbout} path={"/adviser/about"} exact />
          <PublicRoute component={AdviserPrivacyPolicy} path={"/adviser/privacy-policy"} exact />
          <PublicRoute component={AdviserPress} path={"/adviser/press"} exact />
          <PublicRoute component={AdviserServices} path={"/adviser/services"} exact />
          <PublicRoute component={AdviserTermsConditions} path={"/adviser/terms-conditions"} exact />
          <PublicRoute component={Sitemap} path={"/sitemap"} exact />
          <PublicRoute component={Faq} path={"/faq"} exact />
          <PublicRoute component={FaqAdviserRegistrationStepByStepGuide} path={"/faq/adviser-registration-step-by-step-guide"} exact />
          <PublicRoute component={FaqCreatingAProfessionalLookingProfile} path={"/faq/creating-a-professional-looking-profile"} exact />
          <PublicRoute component={FaqSettingCustomerAndProductPreferences} path={"/faq/setting-customer-and-product-preferences"} exact />
          <PublicRoute component={FaqViewingAndAcceptingALead} path={"/faq/viewing-and-accepting-a-lead"} exact />
          <PublicRoute component={ContactUs} path={"/contact-us"} exact />
          <PublicRoute component={FaqInner} path={"/faq-inner"} exact />
          <PublicRoute component={PricingTwo} path={"/pricing"} exact />
          <PublicRoute component={ChatGPT} path={"/chat-gpt"} exact />
          <PublicRoute
            component={PricingDetails}
            path={"/pricing-details"}
            exact
          />
          <PublicRoute
            component={PostcodeSearch}
            path={"/postcode-search"}
            exact
          />
          <PublicRoute component={Directory} path={"/directory"} exact />
          <PublicRoute component={DirectorySitemap} path={"/directory/:county"} exact />
          <PublicRoute component={DirectorySitemap} path={"/directory/:county/:localauthority"} exact />
          <PublicRoute component={DirectorySitemap} path={"/directory/:county/:localauthority/:place"} exact />
          <PublicRoute component={SignUp} path={"/need"} exact />
          <PublicRoute component={AdvisorSignUp} path={"/register"} exact />
          <PublicRoute
            component={GiveReviewToAdviser}
            path={"/review/:id"}
            exact
          />
          <PublicRoute component={InviteUsers} path={"/invite/:id"} exact />
          <PublicRoute component={InviteAdvisor} path={"/invite-advisor/:id"} exact />
          <PublicRoute component={AdvicerTwo} path={"/adviser"} exact />

          <PublicRoute
            component={AdvisorProfile}
            path={"/adviser-profile/:id"}
            exact
          />

          {/* Private pages */}
          <PrivateRoute component={InviteNow} path={"/invite-now"} exact />

          {/* Adviser pages */}
          <PrivateRoute
            component={AdvisorDashboard}
            path={ADVISER_DASHBOARD}
            role={1}
            exact
          />
          <PrivateRoute
            component={AdviserLeadsMatches}
            path={ADVISER_LEADS_MATCHES}
            role={1}
            exact
          />
          <PrivateRoute
            component={AdviserLeadsFilter}
            path={ADVISER_LEADS_FILTER}
            role={1}
            exact
          />
          <PrivateRoute
            component={AdviserLeadsAcceptedFilter}
            path={ADVISER_LEADS_ACCEPTED_FILTER}
            role={1}
            exact
          />
          <PrivateRoute
            component={AdviserLeadsAccepted}
            path={ADVISER_LEADS_ACCEPTED}
            role={1}
            exact
          />
          <PrivateRoute
            component={AdviserMyAccount}
            path={ADVISER_MY_ACCOUNT}
            role={1}
            exact
          />
          <PrivateRoute
            component={AdviserMyProfile}
            path={ADVISER_MY_PROFILE}
            role={1}
            exact
          />
          <PrivateRoute
            component={AdviserPaymentDetails}
            path={ADVISER_PAYMENT_DETAILS}
            role={1}
            exact
          />
          <PrivateRoute
            component={AdviserBillingDetails}
            path={ADVISER_PAYMENT_BILLING_DETAILS}
            role={1}
            exact
          />
          <PrivateRoute
            component={AdviserCreditInvoice}
            path={ADVISER_PAYMENT_INVOICES}
            role={1}
            exact
          />
          <PrivateRoute
            component={AdviserPayInvoice}
            path={ADVISER_PAYMENT_PAY_INVOICE}
            role={1}
            exact
          />
          <PrivateRoute
            component={AdviserNotifications}
            path={ADVISER_NOTIFICATIONS}
            role={1}
            exact
          />
          <PrivateRoute
            component={AdviserTeamMember}
            path={ADVISER_TEAM_MEMBERS}
            role={1}
            exact
          />
          <PrivateRoute
            component={AdviserPreferences}
            path={ADVISER_PREFERENCES}
            role={1}
            exact
          />

          {/* User pages */}
          <PrivateRoute
            component={SignUp}
            path={"/advice-area"}
            role={0}
            exact
          />
          <PrivateRoute component={Logout} path={"/logout"} exact />
          
          <PrivateRoute
            component={UserDashboard}
            path={"/dashboard"}
            role={0}
            exact
          />
          <PrivateRoute
            component={UserAccount}
            path={"/account"}
            role={0}
            exact
          />
          <PrivateRoute
            component={AdviceAreaDetail}
            path={"/advice-area/:id/:tab"}
            role={0}
            exact
          />
          <PrivateRoute
            component={CloseAdviceAreaNeed}
            path={"/close-need/:id"}
            role={0}
            exact
          />
        </Switch>
        {pathname=='/adviser' || pathname=='/adviser/about' || pathname=='/adviser/privacy-policy' || pathname=='/adviser/press' || pathname=='/adviser/services' || pathname=='/adviser/terms-conditions'?
        <Footer />:<AdviserFooter />}
      </Router>
    </AuthContext.Provider>
  );
};

export default Routes;
