import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import {
  ADVISER_LEADS_ACCEPTED,
  ADVISER_LEADS_MATCHES,
  ADVISER_PAYMENT_INVOICES,
  ADVISER_TEAM_MEMBERS,
  ADVISER_MY_ACCOUNT,
} from "../../Constants";
import { prettyTimeAgo } from "../../utility/commonUtils";
import useLoggedInUserData from "../../utility/useLoggedInUserData";
import { getRecentNotification } from "../common/service";

const RecentNotifications = (props: any) => {
  const history = useHistory();
  const user = useLoggedInUserData();
  const [notifications, setNotifications] = useState<object[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    getRecentNotificationCall();
  }, []);

  const getRecentNotificationCall = async () => {
    setIsLoading(true);
    const res = await getRecentNotification();
    if (res.status) {
      setNotifications(res.data);
      props.setNotificationCount(res.unread_count);
    }
    setIsLoading(false);
  };

  const redirectToPage = (notification: any) => {
    switch (notification.type) {
      case 0:
      case 1:
        history.push(
          `${ADVISER_LEADS_ACCEPTED}?accepted_id=${notification.area_id}`,
          {
            from: `/dashboard`,
          }
        );
        break;
      case 2:
        history.push(`${ADVISER_LEADS_MATCHES}`, {
          from: `/dashboard`,
        });
        break;
      case 4:
        history.push(`/adviser-profile/${notification.advisor_id}`, {
          from: `/dashboard`,
        });
        break;
      case 5:
        history.push(`${ADVISER_PAYMENT_INVOICES}`, {
          from: `/dashboard`,
        });
        break;
      case 6:
        history.push(`${ADVISER_TEAM_MEMBERS}`, {
          from: `/dashboard`,
        });
        break;
      case 7:
          history.push(`${ADVISER_TEAM_MEMBERS}`, {
            from: `/dashboard`,
          });
          break;
      case 9:
        history.push(
          `${ADVISER_LEADS_MATCHES}?accepted_id=${notification.area_id}`,
          {
            from: `/dashboard`,
          }
        );
        break;
      case 10:
        history.push(
          `${ADVISER_MY_ACCOUNT}/general-info`,
          {
            from: `/dashboard`,
          }
        );
        break;
      default:
        break;
    }
  };

  return (
    <>
      {notifications.length ? (
        notifications.map((notification: any, index) => {
          return (
            <>
              <div
                key={notification.id}
                className={`msg-box ${
                  notification.read_unread === 0 ? "unread" : "read"
                }`}
                onClick={() => redirectToPage(notification)}
              >
                <div className="msg-content order-3 order-md-2">
                  <h6>
                    {notification?.userDetails?.name || "Received new review"}
                    {/* <sub>requires £250,000 Buy-to-let</sub> */}
                  </h6>
                  <span></span>
                  <p>{notification.message}</p>
                </div>
                <div className="msg-time order-2 order-md-3 mb-2 mb-md-0">
                  <span className="text-uppercase">
                    {prettyTimeAgo(notification?.created_at)}{" "}
                  </span>
                </div>
              </div>
            </>
          );
        })
      ) : (
        <div></div>
      )}
    </>
  );
};
export default RecentNotifications;
