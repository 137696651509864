import React from "react";
import Webcam from "react-webcam";
import { ADVISOR_DEFAULT_IMAGE } from "../../../../Constants";

const WebcamCaptureModal = (props: any) => {
  const videoConstraints = {
    width: 200,
    height: 200,
    facingMode: "user",
  };
  const [src, setSrc] = React.useState(ADVISOR_DEFAULT_IMAGE);
  const webcamRef = React.useRef<any>(null);

  const capture = React.useCallback(() => {
    const imageSrc = webcamRef.current.getScreenshot();
    setSrc(imageSrc);
  }, [webcamRef]);

  return (
    <>
      <div
        className="mail-popup modal fade popup-model nutshell_popup"
        id="webcamCaptureModalId"
      >
        <div className="modal-dialog modal-dialog-centered webcam-data">
          <div className="modal-content">
            <div className="modal-body">
              <div className="row">
                <div className="col-md-12">
                  <div className="heading text-center mb-4">
                    <h4 className="theme-color">Capture Image</h4>
                    <p>
                      <hr />
                      <div className="">
                        {src === "" ? (
                          <Webcam
                            audio={false}
                            ref={webcamRef}
                            screenshotFormat="image/jpeg"
                            videoConstraints={videoConstraints}
                            width={200}
                            height={200}
                          />
                        ) : (
                          <img src={src} alt="" />
                        )}
                      </div>

                      <p>
                        {src === "" ? (
                          <button
                            className="theme-btn text-uppercase"
                            onClick={(e) => {
                              e.preventDefault();
                              capture();
                            }}
                          >
                            Capture
                          </button>
                        ) : (
                          <button
                            className="theme-btn text-uppercase"
                            onClick={(e) => {
                              e.preventDefault();
                              setSrc("");
                            }}
                          >
                            Retake Image
                          </button>
                        )}
                      </p>
                      <hr />
                    </p>
                  </div>
                </div>
                <div className="col-md-12 text-center">
                  {src !== "" && (
                    <button
                      type="button"
                      className="theme-btn m-r-16"
                      onClick={() => props.setImageURL(src)}
                      data-dismiss="modal"
                    >
                      Use
                    </button>
                  )}
                  <button
                    type="button"
                    className="theme-btn"
                    onClick={() => setSrc(ADVISOR_DEFAULT_IMAGE)}
                    data-dismiss="modal"
                  >
                    Close
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default WebcamCaptureModal;
