/* eslint-disable react/style-prop-object */
import React from "react";
import { Link } from "react-router-dom";

const HomeTwo = () => {
  return (
    <React.Fragment>
        

      <div className="swtich-index">
        
        <section className="home-two-herosection ">
          
          <div className="container">
            <div className="container-center-hero">
              <p className="herotitleHomeTwoTitle">
                The smart way to find your
              </p>
              <h3 className="heroTitleHomeHeader">Mortgage Adviser</h3>
              <div className="heroHomeSubTitle">
                Five FCA regulated advisers compete to secure you the best deal
                possible. 100% free to use.
              </div>
              <Link to="/need">
                <img
                  alt="logo"
                  src={"assets/images/newpagesimages/adviserButton.png"}
                  className="hero-section-home"
                />
              </Link>
            </div>
          </div>
        </section>
      </div>
  
      <section className="section-three-container ">
        <div className="section-three-text-container py-5 ">
          <h1>Why Connect with Multiple Advisers?</h1>
          <div className="section-third-ul">
            <div className="section-third-ul-title">
              <div className="ul-list">
                <img
                  alt="logocheck"
                  src={"/assets/images/newpagesimages/check.png"}
                />{" "}
                <p className="li-title">More Options</p>
              </div>

              <p className="li-subtitle">
                Access a wider range of mortgage products.
              </p>
            </div>
          </div>
          <div className="section-third-ul">
            <div className="section-third-ul-title">
              <div className="ul-list">
                <img
                  alt="logocheck"
                  src={"/assets/images/newpagesimages/check.png"}
                />{" "}
                <p className="li-title">Better Deals</p>
              </div>

              <p className="li-subtitle">
                Increase your chance of competitive rate and terms.
              </p>
            </div>
          </div>
          <div className="section-third-ul">
            <div className="section-third-ul-title">
              <div className="ul-list">
                <img
                  alt="logocheck"
                  src={"/assets/images/newpagesimages/check.png"}
                />{" "}
                <p className="li-title">Higher Acceptance Rates</p>
              </div>

              <p className="li-subtitle">
                Benefit from diverse insights and expertise.
              </p>
            </div>
          </div>
          <div className="section-third-ul">
            <div className="section-third-ul-title">
              <div className="ul-list">
                <img
                  alt="logocheck"
                  src={"/assets/images/newpagesimages/check.png"}
                />{" "}
                <p className="li-title">Tailored Solutions</p>
              </div>

              <p className="li-subtitle">
                Find the perfect match for your financial situation.
              </p>
            </div>
          </div>
          <div className="section-third-ul">
            <div className="section-third-ul-title">
              <div className="ul-list">
                <img
                  alt="logocheck"
                  src={"/assets/images/newpagesimages/check.png"}
                />{" "}
                <p className="li-title">Skills and Expertise</p>
              </div>

              <p className="li-subtitle">
                Standard and specialised mortgages through to bridging loans.
              </p>
            </div>
          </div>
          <div className="find-your-adviser-button">
            <Link to="/need">
              <img
                alt="find"
                src="/assets/images/newpagesimages/findyouradviser.png"
              />
            </Link>
          </div>
        </div>
        <div className="third-logo">
          <div className="container3">
            <div className="pics">
              <img alt="logo" src="/assets/images/assets/group/6.png" />
              <img
                alt="logo"
                src="/assets/images/assets/group/1.png"
                className="anime"
                id="anime-png1"
              />
              <img
                alt="logo"
                src="/assets/images/assets/group/2.png"
                className="anime"
                id="anime-png2"
              />
              <img
                alt="logo"
                src="/assets/images/assets/group/3.png"
                className="anime"
                id="anime-png3"
              />
              <img
                alt="logo"
                src="/assets/images/assets/group/4.png"
                className="anime"
                id="anime-png4"
              />
              <img
                alt="logo"
                src="/assets/images/assets/group/5.png"
                className="anime"
                id="anime-png5"
              />

              <img
                alt="logo"
                className="text-1"
                src="/assets/images/assets/text2.png"
              />
              <img
                alt="logo"
                className="text-2"
                src="/assets/images/assets/text1.png"
              />
              <img
                alt="logo"
                className="text-3"
                src="/assets/images/assets/text3.png"
              />
            </div>
          </div>
        </div>
      </section>

      <section className="ready-to-connect">
        <p className="ready-to-connect-heading">Ready to Connect?</p>
        <p className="ready-to-connect-sub-heading">
        Maximise your chances of finding the perfect mortgage in three easy steps.
        </p>

        <div className="ready-grid">
          <div>
            <img
              alt="img1"
              src={
                "/assets/images/newpagesimages/ready-section-2.png"
              }
            />

            <h5>Outline Your Mortgage Needs</h5>

            <div className="text-center subtitle ">
            Share basic details and we'll match you to five advisers. It only takes a minute.
            </div>
          </div>
          <div>
            <img
              alt="img2"
              src={
                "/assets/images/newpagesimages/ready-section-1.png"
              }
            />

            <h5>Get the Best Offer</h5>

            <div className="text-center subtitle ">
            Compare their advice, products, and incentives to make an informed decision and discover potential savings.
            </div>
          </div>
          <div>
            <img
              alt="img2"
              src={
                "/assets/images/newpagesimages/ready-section-3.png"
              }
            />

            <h5>Complete Your Mortgage</h5>

            <div className="text-center subtitle ">
            Your selected adviser will guide you through the final steps to secure your mortgage.
            </div>
          </div>
          
        </div>
        <Link to="/need">
                <img
                  alt="logo"
                  src={"assets/images/newpagesimages/adviserButton.png"}
                  className="ready-to-connect-img"
                />
              </Link>
      </section>

      {/* <div className='container2'>
        <div className='pic' id='pic3' />
        <div className='pic' id='pic2' />
        <div className='pic' id='pic1' />
      </div> */}
      {/* fourth section */}
      {/* <section className="section-fourth">
        <p className="fourth-section-title mt-3">The MortgageBox Difference</p>

        <p className="fourth-section-subTitle">
          Simplifying your Mortgage Journey
        </p>

        <div className="container py-4">
          <div className="container ">
            <div className="row justify-content-center mb-5">
              <div className="col-xl-4 col-lg-4 col-md-4  col-sm-12 mb-5 mb-md-3  d-flex justify-content-center">
                <div className="card-custom-home  rounded shadow-sm py-4 px-4 d-flex flex-column align-items-center">
                  <img
                    src="/assets/images/newpagesimages/cardOneIcon.png"
                    alt=""
                    width="70"
                    className="img-fluid rounded-circle mb-3 img-thumbnail shadow-sm"
                  />
                  <h5 className="mb-2 text-center">Quick</h5>
                  <span className="text-center small  ">
                    Outline your mortgage needs in under 60 seconds.
                  </span>
                </div>
              </div>
              <div className="col-xl-4 col-lg-4 col-md-4  col-sm-12   mb-5 mb-md-3 d-flex justify-content-center">
                <div className="card-custom-home  rounded shadow-sm py-4 px-4 d-flex flex-column align-items-center">
                  <img
                    src="/assets/images/newpagesimages/cardThirdIcon.png"
                    alt=""
                    width="70"
                    className="img-fluid rounded-circle mb-3 img-thumbnail shadow-sm"
                  />
                  <h5 className="mb-2 text-center">In Control</h5>
                  <span className="text-center small  ">
                    Review and compare offers with no pressure or obligations.
                  </span>
                </div>
              </div>
              <div className="col-xl-4 col-lg-4 col-md-4  col-sm-12 mb-5 mb-md-3  d-flex justify-content-center">
                <div className="card-custom-home  rounded shadow-sm py-4 px-4 d-flex flex-column align-items-center">
                  <img
                    src="/assets/images/newpagesimages/cardTwoIcon.png"
                    alt=""
                    width="70"
                    className="img-fluid rounded-circle mb-3 img-thumbnail shadow-sm"
                  />
                  <h5 className="mb-2 text-center">Free</h5>
                  <span className="text-center small  ">
                    We don’t charge a penny for our service.
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section> */}

<div className="homeLogo">
          <Link to="/chat-gpt">
          <img alt="logo" src={"/assets/images/newpagesimages/homeIcon.png"} />
          </Link>
        </div>
    </React.Fragment>
  );
};

export default HomeTwo;
