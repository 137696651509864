import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useToasts } from "react-toast-notifications";
import LoadingSpinner from "../../components/common/LoadingSpinner";
import AddNewCards from "../../components/common/modals/AddNewCards";
import DeleteConfirmModal from "../../components/common/modals/DeleteConfirmModal";
import {
  stripeDeleteCard,
  stripeGetAllCards,
} from "../../components/common/service";
import {
  ADVISER_MY_ACCOUNT,
  ADVISER_PAYMENT_PAY_INVOICE,
} from "../../Constants";
import { capitalize } from "../../utility/commonUtils";
import useLoggedInUserData from "../../utility/useLoggedInUserData";

const AdviserPaymentDetails = () => {
  const user = useLoggedInUserData();
  const { addToast } = useToasts();
  const [savedCards, setSavedCards] = useState<object[]>([]);
  const [deleteItemId, setDeleteItemId] = React.useState();
  const [isLoading, setIsLoading] = React.useState<boolean>(true);

  useEffect(() => {
    stripeGetAllCardsCall();
  }, []);

  const stripeGetAllCardsCall = async () => {
    setIsLoading(true);
    const res = await stripeGetAllCards();
    if (res.data && res.data.data) {
      setSavedCards(res.data.data);
    }
    setIsLoading(false);
  };

  const handleDeleteResponse = async (id: any) => {
    const res = await stripeDeleteCard({ card_id: id });
    if (res.status) {
      setSavedCards(() => savedCards.filter((card: any) => card.id !== id));
      $("#delete_modal").modal("hide");
      $("body").removeClass("modal-open");
      $(".modal-backdrop").remove();
      addToast(res.message, { appearance: "success" });
    } else {
      addToast(res.message, { appearance: "error" });
    }
  };

  return (
    <>
      <main className="need-offers">
        <section className="page-title my-account-title">
          <div className="container">
            <div className="row">
              <div className="col-md-12 mb-5 mb-md-0"></div>
            </div>
          </div>
        </section>

        <section className="recent-sec directory-sec advice-area-sec pt-4 pb-5 preference-product-sec">
          <div className="container">
            <div className="row">
              <div className="col-12 back-btn mt-lg-4 mb-lg-3 mt-md-5 mt-5 mb-0">
                <Link to={ADVISER_MY_ACCOUNT} className="back-btn">
                  <i className="fa fa-angle-left" aria-hidden="true"></i> My
                  Account
                </Link>
              </div>
            </div>
          </div>

          <div className="container">
            {/* <div className="row">
              <div className="col-12">
                <div className="saved-card-sec">
                  <Link to={ADVISER_PAYMENT_PAY_INVOICE}>
                    <div className="alert alert-warning alert-white rounded warning-data mb-3">
                     
                      <div className="icon">
                        <i className="fa fa-warning" aria-hidden="true"></i>
                      </div>
                      <strong>Warning!</strong> Your account is at risk of being
                      suspended – pay outstanding invoice
                    </div>
                  </Link>
                </div>
              </div>
            </div> */}

            {savedCards.length === 0 ? (
              <div className="recent-box settings-data mb-lg-4 mb-0">
                <div className="row justify-content-center">
                  <div className="col-xl-10 col-lg-12 mb-lg-4 mb-0">
                    <div className="tabbing">
                      <div className="tab-content mt-lg-4 mt-2">
                        <div className="row">
                          <div className="col-12">
                            {isLoading ? (
                              <div style={{ marginBottom: "7%" }}>
                                <LoadingSpinner loading={isLoading} />
                              </div>
                            ) : (
                              <div className="profile-datas">
                                <h5 className="mb-4">My saved card</h5>
                              </div>
                            )}
                          </div>
                        </div>

                        <div className="row add-card-sec">
                          {!isLoading ? (
                            <div className="col-lg-10">
                              <div className="paymet-box">
                                <img
                                  src="assets/images/cards.png"
                                  alt="cards"
                                  className="img-fluid"
                                />
                                <p className="mb-1">
                                  <b>
                                    We don’t have any payment information for
                                    you yet.
                                  </b>
                                </p>
                                <a
                                  href="#"
                                  data-toggle="modal"
                                  data-target="#add_new_card"
                                  className="add-card"
                                >
                                  Click here to add a card
                                </a>
                              </div>
                            </div>
                          ) : null}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <div className="recent-box settings-data mb-lg-4 mb-0 saved-card-sec">
                <div className="row justify-content-center">
                  <div className="col-xl-10 col-lg-12 mb-lg-4 mb-0">
                    <div className="tabbing">
                      <div className="tab-content mt-lg-4 mt-2">
                        <div className="saved-card-sec mt-4">
                          <h5 className="mb-4 pb-1">Payment Options</h5>
                          <div className="payment-options">
                            {savedCards.map((card: any) => (
                              <div
                                key={card.id}
                                id="accordion"
                                className="accordion"
                              >
                                <div className="address-acc">
                                  <div
                                    className="cards collapsed"
                                    data-toggle="collapse"
                                    aria-expanded="false"
                                    data-href={`#card_${card.id}`}
                                    data-target={`#card_${card.id}`}
                                  >
                                    <div className="row align-items-center">
                                      <div className="col-md-6 mb-md-0 mb-2">
                                        <label className="radio-img">
                                          <input
                                            type="radio"
                                            name="card_data"
                                            value="visa_card"
                                          />
                                          <h5>
                                            {capitalize(card.card.brand)} ending{" "}
                                            {card.card.last4}
                                          </h5>
                                        </label>
                                      </div>
                                      <div className="col-md-3 col-8">
                                        <span>
                                          Expires {card.card.exp_month}/
                                          {card.card.exp_year}
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    id={`card_${card.id}`}
                                    className="collapse"
                                    data-parent="#accordion"
                                  >
                                    <div className="address mt-4 pt-3">
                                      <form>
                                        <div className="row">
                                          <div className="col-md-6">
                                            <p>
                                              <b>Name on card</b>
                                            </p>
                                            <p>
                                              {card.billing_details.name ||
                                                user?.userDetails?.display_name}
                                            </p>
                                          </div>
                                          <div className="col-md-3">
                                            <p>
                                              <b>Billing address</b>
                                            </p>
                                            <p>
                                              {card.billing_details.name ||
                                                user?.userDetails?.display_name}
                                              <br />
                                              123 High Street
                                              <br />
                                              Thames Ditton
                                              <br />
                                              Surrey, KT22 1AA 1234 56789
                                            </p>
                                          </div>
                                        </div>
                                        <div className="row mt-3">
                                          <div className="col-6">
                                            <a href="javascript:void(0)">
                                              <b>Set as default</b>
                                            </a>
                                          </div>
                                          <div className="col-6 text-right">
                                            {/* <a href="javascript:void(0)">
                                            <b>Edit</b>
                                          </a> */}
                                            <a
                                              href="#"
                                              data-toggle="modal"
                                              data-target="#delete_modal"
                                              className="ml-4"
                                              onClick={() =>
                                                setDeleteItemId(card.id)
                                              }
                                            >
                                              <b>Delete</b>
                                            </a>
                                          </div>
                                        </div>
                                      </form>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            ))}
                            <a
                              href="#"
                              data-toggle="modal"
                              data-target="#add_new_card"
                              className="add-card"
                            >
                              <b>Add payment option</b>
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </section>
      </main>
      <AddNewCards refetch={stripeGetAllCardsCall} />
      <DeleteConfirmModal
        deleteItemId={deleteItemId}
        handleResponse={handleDeleteResponse}
      />
    </>
  );
};

export default AdviserPaymentDetails;
