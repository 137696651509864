import React, { useEffect, useState } from "react";
import { useToasts } from "react-toast-notifications";
import ReactTooltip from "react-tooltip";
import { prettyTime } from "../../utility/commonUtils";
import useLoggedInUserData from "../../utility/useLoggedInUserData";
import AboutInaNutshellModal from "../common/modals/advisorModals/AboutInaNutshellModal";
import AboutTheCompanyModal from "../common/modals/advisorModals/AboutTheCompanyModal";
import { updateAdvisorAboutUs, getAdvisorProfile } from "../common/service";

const initialState = {
  short_description: "",
  description: "",
  company_about:"",
  updated_by:0,
};

const AdviserProfileAbout = (props: any) => {
  const { addToast } = useToasts();
  const user = useLoggedInUserData();

  const [formState, setFormState] = useState(initialState);
  const [profileData, setProfileData] = useState<any>({});
  useEffect(() => {
    setFormState({
      short_description: profileData.short_description,
      description: profileData.description,
      company_about: profileData.company_about,
      updated_by:profileData.advisorId,
    });
    if (user.id) {
      getAdvisorProfileCall(user.id);
    }
  }, []);
// props.profileData
  const handlePageState = (newStates: any) => {
    setFormState((prevStates) => {
      return {
        ...prevStates,
        ...newStates,
      };
    });
  };

  const onSubmitHandler = async (e: any) => {
    e.preventDefault();
    const res = await updateAdvisorAboutUs(formState);
    if (res.status) {
      addToast(res.message, { appearance: "success" });
    } else {
      addToast(res.message, { appearance: "error" });
    }
  };

  const getAdvisorProfileCall = async (advice_id: number) => {
    const res = await getAdvisorProfile(advice_id);
    if (res?.status) {
      setProfileData(res.data);
      setFormState({
        short_description: res.data.short_description,
        description: res.data.description,
        company_about: res.data.company_id!=0?res.data.company_about:res.data.about_us,
        updated_by:res.data.advisorId,
      });
      props = res.data;
    }
  };

  return (
    <>
      <div className="tab-pane container active">
        <div className="row">
          <div className="col-xl-12">
            <form onSubmit={onSubmitHandler}>
              <div className="pl-xl-5 pl-0 pr-xl-5 pr-0 mt-xl-3 mt-0 profile-datas">
                <h5>
                  In a Nutshell
                  <a
                    href="javascript:void(0)"
                    data-tip="This Is specific to you"
                  >
                    <img
                      src="assets/images/information.png"
                      alt="information"
                      className="img-fluid information"
                    />
                  </a>
                </h5>

                <div className="row mt-3">
                  <div className="col-md-8">
                    <p>Explain in a sentence why a client should select you.</p>
                  </div>
                  <div className="col-md-4 text-right dates">
                    {/* <p>
                      Last edited:{" "}
                      {props.profileData?.description_updated
                        ? prettyTime(
                            props.profileData.description_updated,
                            true,
                            true,
                            true
                          )
                        : "N/A"}
                    </p> */}
                  </div>
                </div>
                <div className="row mt-1">
                  <div className="col-lg-12">
                    <div className="form-group nutshell_form mb-0">
                      <textarea
                        id="Nutshell"
                        name="short_description"
                        value={formState.short_description}
                        onChange={(e) =>
                          handlePageState({
                            short_description: e.target.value,
                          })
                        }
                      ></textarea>
                      <img src="assets/images/my_accounts/icon10.png" alt="" />
                    </div>
                  </div>
                </div>
                <div className="row mt-1">
                  <div className="col-lg-12 text-center">
                    <a
                      href="javascript:void(0)"
                      data-toggle="modal"
                      data-target="#nutshell_example"
                      className="example_btn"
                    >
                      Here's our example of 'In a nutshell'
                    </a>
                  </div>
                </div>

                <span className="borders text-center"></span>
              </div>

              <div className="pl-xl-5 pl-0 pr-xl-5 pr-0 mt-xl-3 mt-0 profile-datas profile-datas1">
                <h5>
                  About the Company
                  <a
                    href="javascript:void(0)"
                    data-tip="This Is common to all advisers in your company"
                  >
                    <img
                      src="assets/images/information.png"
                      alt="information"
                      className="img-fluid information"
                    />
                  </a>
                </h5>

                <div className="row mt-3">
                  <div className="col-md-8">
                    <p>Provide a description of your services.</p>
                  </div>
                  <div className="col-md-4 text-right dates">
                    <p>
                      Last edited:{" "}
                      {props.profileData?.updated_by_name
                        ? props.profileData.updated_by_name
                        : "N/A"}
                    </p>
                  </div>
                </div>
                <div className="row mt-1">
                  <div className="col-lg-12">
                    <div className="form-group nutshell_form mb-0">
                      <textarea
                        id="company"
                        name="company_about"
                        value={formState.company_about}
                        onChange={(e) =>
                          handlePageState({
                            company_about: e.target.value,
                          })
                        }
                      ></textarea>
                      <img src="assets/images/my_accounts/icon10.png" alt="" />
                    </div>
                  </div>
                </div>
                <div className="row mt-1">
                  <div className="col-lg-12 text-center">
                    <a
                      href="javascript:void(0)"
                      data-toggle="modal"
                      data-target="#about_company_example"
                      className="example_btn"
                    >
                      Here's our example of 'About the company'
                    </a>
                  </div>
                </div>

                <div className="row mt-3 mb-3">
                  <div className="col-md-12 text-center mt-lg-4 mt-0">
                    <button
                      type="submit"
                      className="theme-btn text-uppercase mr-md-3 mr-1 mb-md-0 mb-3"
                    >
                      Save
                    </button>
                    {/* <button
                      type="button"
                      className="theme-btn-border text-uppercase"
                      
                    >
                      Cancel
                    </button> */}
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      <AboutInaNutshellModal />
      <AboutTheCompanyModal />
      <ReactTooltip />
    </>
  );
};

export default AdviserProfileAbout;
