import React, { useEffect, useState } from "react";
import { useToasts } from "react-toast-notifications";
import { useHistory } from "react-router-dom";
import {
  ADVISER_ROLE_1,
  ADVISER_ROLE_2,
  ADVISOR_DEFAULT_IMAGE,
  COMPANY_DEFAULT_IMAGE,
  LANGUAGES_ARR,
  LOGGEDIN_USER,
  ADVISER_PREFERENCES_URL,
} from "../../Constants";
import useLoggedInUserData from "../../utility/useLoggedInUserData";
import WebcamCaptureModal from "../common/modals/advisorModals/WebcamCaptureModal";
import ChangePassword from "../common/modals/ChangePassword";
import {
  checkIfExistInAdvisorTeam,
  searchPostalCode,
  updateAdvisorGeneralInfo,
  getAdviserUserProfile,
} from "../common/service";
import { AsyncTypeahead } from "react-bootstrap-typeahead";
var setPostcode = false;
var setemail = false;
var profile_percent = 0;
const initialState = {
  company_logo: "",
  company_name: "",
  network: "",
  image: "",
  role: ADVISER_ROLE_1,
  display_name: "",
  FCANumber: "",
  email: "",
  phone_number: "",
  city: "",
  postcode: "",
  gender: "",
  language: "",
};

const AdviserProfileGeneralInfo = (props: any) => {
  const history = useHistory();
  const backendStorageUrl =
    process.env.REACT_APP_BACKEND_URL_STORAGE_ADVISOR || "";
  const user = useLoggedInUserData();
  const { addToast } = useToasts();
  const [formState, setFormState] = useState(initialState);
  const [userData, setUserData] = useState<any>({});

  const [isSeachLoading, setIsSeachLoading] = useState<boolean>(false);
  const [options, setOptions] = useState<any>([]);
  const [selectedOption, setselectedOption] = useState<any>([]);
  
  const handleChangeSearch = (event: any) => {
    if (event.length) {
      setselectedOption([event[0]]);
      handlePageState({ postcode: event[0].Postcode });
    } else {
      setselectedOption([]);
    }
  };

  const showSelectedValue = (event: any) => {
    setTimeout(() => {
      if($(".rbt-input-main").val()==''){
          $(".rbt-input-main").val(props.profileData.postcode);
      }
    },50);
  };

  const handleSearch = async (query: any) => {
    setIsSeachLoading(true);
    const res = await searchPostalCode({ postal_code: query });
    if (res.status) {
      setOptions(res.data);
    }
    setIsSeachLoading(false);
  };
  // Bypass client-side filtering by returning `true`. Results are already
  // filtered by the search endpoint, so no need to do it again.
  const filterBy = () => true;

  const setImageURL = (base64Url: string) => {
    handlePageState({ image: base64Url });
  };
  const getUserProfile = async () => {
    // setIsLoading(true);
    const res = await getAdviserUserProfile();
    if (res?.user) {
      setUserData(res.user);
      profile_percent = res.user.userDetails.profile_percent
      handlePageState({
        company_name: res.user.userDetails.company_name,
        network: res.user.userDetails.network,
        // role: props.profileData.role,
        display_name: res.user.userDetails.display_name,
        FCANumber: res.user.userDetails.FCANumber,
        email: res.user.userDetails.email,
        phone_number: res.user.userDetails.phone_number,
        city: res.user.userDetails.city,
        postcode: res.user.userDetails.postcode,
        gender: res.user.userDetails?.gender,
        language: res.user.userDetails?.language,
        is_admin: res.user.userDetails?.is_admin,
      });
    }  
    localStorage.setItem(LOGGEDIN_USER, JSON.stringify(res.user)); 
  };
  useEffect(() => {
    getUserProfile();
  }, []);

  useEffect(() => {
    checkIfExistInAdvisorTeamCall();
    setselectedOption(user.postcode);
    setUserData(props.profileData);
    
    // getUserProfile();
  }, []);
  // user,props.profileData

  const checkIfExistInAdvisorTeamCall = async () => {
    const res = await checkIfExistInAdvisorTeam(user?.userDetails?.company_id);
    if (res.status) {
      handlePageState({
        role: res.data,
      });
    }
  };
  useEffect(() => {
    setTimeout(() => {
      if(!setPostcode){
        if(props.profileData.postcode!=undefined){
          setPostcode = true;
          setemail = true;
          $(".rbt-input-main").val(props.profileData.postcode);
          handlePageState({
            email: props.profileData.email,
          });
        }
      }      
    },500);
    
    
  }, []);
  const handlePageState = (newStates: any) => {
    setFormState((prevStates: any) => {
      return {
        ...prevStates,
        ...newStates,
      };
    });
  };

  const handleChange = (event: { target: HTMLInputElement } | any) => {
    const { name, value } = event.target;
    handlePageState({ [name]: value });
    event.preventDefault();
  };

  const handleFileChange = async (
    event: { target: HTMLInputElement } | any
  ) => {
    const { name, files } = event.target;
    const base64 = await convertBase64(files[0]);
    handlePageState({ [name]: base64 });
  };

  const convertBase64 = (file: File) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        resolve(fileReader.result);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    const formData = new FormData();
    if (formState.company_logo) {
      formData.append("company_logo", formState.company_logo);
    }
    if (formState.image) {
      formData.append("image", formState.image);
    }
    formData.append("company_name", formState.company_name);
    formData.append("network", formState.network);
    // formData.append("role", formState.role);
    formData.append("display_name", formState.display_name);
    formData.append("FCANumber", formState.FCANumber);
    formData.append("email", formState.email);
    formData.append("phone_number", formState.phone_number);
    formData.append("city", formState.city);
    formData.append("postcode", formState.postcode);
    formData.append("gender", formState.gender);
    formData.append("language", formState.language);
    const res = await updateAdvisorGeneralInfo(formData);
    if (res.status) {
      getUserProfile();
      addToast(res.message, { appearance: "success" });
    } else {
      addToast(res.message, { appearance: "error" });
    }
  };
  const addDefaultSrc = async (e: any) =>{
    e.target.src = ADVISOR_DEFAULT_IMAGE;
  }

  return (
    <>
      <div className="tab-pane container general-info active" id="general_info">
        <div className="row">
          <div className="col-xl-12">
            <form method="post" onSubmit={handleSubmit}>
              <div className="pl-xl-5 pl-0 pr-xl-5 pr-0 profile-datas">
                <h5>Company details</h5>
                
                <div className="row upload-logo mt-lg-5 mt-3 align-items-center">
                  <div className="col-md-2">
                    <img
                      src={
                        formState?.company_logo!=''?formState?.company_logo:
                        props.profileData.company_logo
                          ? `${backendStorageUrl}${props.profileData.company_logo}`
                          : COMPANY_DEFAULT_IMAGE
                      }
                      style={{width:'100px !important',height:'100px !important'}}
                      onError={addDefaultSrc}
                      className="img-fluid w-100 border-radius-50 width-hei-100"
                      alt=""
                      
                    />
                  </div>
                  {props.profileData.is_admin==1?
                  <div className="col-md-5 text-center">
                    <div className="uploaded">
                      <input
                        type="file"
                        id="logo_upload"
                        accept="image/*"
                        name="company_logo"
                        onChange={handleFileChange}
                      />
                      <label htmlFor="logo_upload">
                        <img
                          src="assets//images/my_accounts/icon6.png"
                          alt=""
                        />
                        UPLOAD A NEW PICTURE
                      </label>
                      <div id="logo_upload_filename"></div>
                    </div>
                  </div>
                  :''}
                </div>
                <div className="row mt-lg-4 mt-0 pt-3">
                  <div className="col-md-6">
                    <label>Company name </label>
                    {userData.is_admin!=0?
                    <input
                      type="text"
                      id="company_name"
                      placeholder="xyz mortgage advisers"
                      onFocus={(e) => (e.target.placeholder = "")}
                      onBlur={(e) =>
                        (e.target.placeholder =
                          "xyz mortgage advisers")
                      }
                      name="company_name"
                      value={formState?.company_name}
                      onChange={handleChange}
                      // required
                    />
                    :<input
                    type="text"
                    id="company_name"
                    placeholder="xyz mortgage advisers"
                    name="company_name"
                    value={formState?.company_name}
                    disabled
                    // required
                  />}
                  </div>
                  <div className="col-md-6">
                    <label>Firm reference number</label>
                    <input
                      type="text"
                      id="network"
                      placeholder="Firm reference number"
                      onFocus={(e) => (e.target.placeholder = "")}
                      onBlur={(e) => (e.target.placeholder = "Firm reference number")}
                      name="network"
                      value={formState?.network}
                      onChange={handleChange}
                      // required
                    />
                  </div>
                </div>
                <span className="borders text-center"></span>
              </div>

              <div className="pl-xl-5 pl-0 pr-xl-5 pr-0">
                <h5>Adviser profile</h5>
                <div className="row upload-logo mt-lg-5 mt-3 align-items-center">
                  <div className="col-md-2">
                  {props.profileData.image?
                    <img
                      src={formState?.image!=''?formState?.image:
                        props.profileData.image
                          ? `${backendStorageUrl}${props.profileData.image}`
                          : ADVISOR_DEFAULT_IMAGE
                      }
                      onError={addDefaultSrc}
                      className="img-fluid w-100 border-radius-50 width-hei-100"
                      alt=""
                      style={{width:'100px !important',height:'100px !important'}}
                    />
                    :<img
                    src={formState?.image!=''?formState?.image:
                      props.profileData.company_logo
                        ? `${backendStorageUrl}${props.profileData.company_logo}`
                        : COMPANY_DEFAULT_IMAGE
                    }
                    onError={addDefaultSrc}
                      className="img-fluid w-100 border-radius-50 width-hei-100"
                      alt=""
                      style={{width:'100px !important',height:'100px !important'}}
                    />}
                  </div>
                  <div className="col-xl-5 col-lg-5 col-md-5 ml-0 text-center">
                    <div className="uploaded">
                      <input
                        type="file"
                        id="profile_picture_upload"
                        accept="image/*"
                        name="image"
                        onChange={handleFileChange}
                      />
                      <label htmlFor="profile_picture_upload">
                        <img
                          src="assets//images/my_accounts/icon6.png"
                          alt=""
                        />
                        UPLOAD A NEW PICTURE
                      </label>
                    </div>
                  </div>
                  {/* <div className="col-xl-5 col-lg-5 col-md-5 text-center">
                    <div className="uploaded">
                      <label
                        htmlFor="web_profile_picture_upload"
                        className="upload_webcam"
                        data-toggle="modal"
                        data-target="#webcamCaptureModalId"
                        data-backdrop="static"
                        data-keyboard="false"
                      >
                        <img
                          src="assets//images/my_accounts/icon9.png"
                          alt=""
                        />
                        UPLOAD USING WEBCAM
                      </label>
                    </div>
                  </div> */}
                </div>
                <div className="row mr-lg-4 mt-0 pt-3">
                  <div className="col-lg-6">
                    <label>Role</label>
                    <div className="select-data">
                      <select className="rol" name="role">
                        {props.profileData.is_admin === 1 ? (
                          <option value='contact_admin'>
                            Contact / Administrator
                          </option>
                        ) : (
                          <option value="adviser">
                            Mortgage Adviser
                          </option>
                        )}
                      </select>
                    </div>
                  </div>
                </div>
                <div className="row mt-lg-2 mt-0">
                  <div className="col-md-6">
                    <label>Name<p className="required">*</p></label>
                    <input
                      type="text"
                      id="name"
                      placeholder="John Bercrow"
                      onFocus={(e) => (e.target.placeholder = "")}
                      onBlur={(e) => (e.target.placeholder = "John Bercrow")}
                      name="display_name"
                      value={formState?.display_name}
                      onChange={handleChange}
                      required
                    />
                  </div>
                  <div className="col-md-6">
                    {/* {formState.role === ADVISER_ROLE_1 ? ( */}
                      <>
                        <label>Individual FCA number<p className="required">*</p></label>
                        <input
                          type="text"
                          id="FCANumber"
                          placeholder="12345"
                          onFocus={(e) => (e.target.placeholder = "")}
                          onBlur={(e) => (e.target.placeholder = "12345")}
                          name="FCANumber"
                          value={formState?.FCANumber}
                          onChange={handleChange}
                          required
                        />
                      </>
                    {/* ) : null} */}
                  </div>
                </div>
                <div className="row mt-lg-2 mt-0">
                  <div className="col-md-6">
                    <label>Email<p className="required">*</p></label>
                    <input
                      type="email"
                      id="email"
                      placeholder="John@wip.com"
                      onFocus={(e) => (e.target.placeholder = "")}
                      onBlur={(e) => (e.target.placeholder = "John@wip.com")}
                      name="email"
                      value={formState?.email}
                      onChange={handleChange}
                      required
                    />
                  </div>
                  <div className="col-md-6">
                    <label>Contact number<p className="required">*</p></label>
                    <input
                      type="text"
                      id="contact_no"
                      placeholder="01234 56789"
                      onFocus={(e) => (e.target.placeholder = "")}
                      onBlur={(e) => (e.target.placeholder = "01234 56789")}
                      name="phone_number"
                      value={formState?.phone_number}
                      onChange={handleChange}
                      required
                    />
                  </div>
                </div>
                <div className="row mt-lg-2 mt-0">
                  <div className="col-md-6">
                    <label>City / Town<p className="required">*</p></label>
                    <input
                      type="text"
                      id="city"
                      placeholder="Twickenham, London"
                      onFocus={(e) => (e.target.placeholder = "")}
                      onBlur={(e) =>
                        (e.target.placeholder = "Twickenham, London")
                      }
                      name="city"
                      value={formState?.city}
                      onChange={handleChange}
                      required
                    />
                  </div>
                  <div className="col-md-6">
                    <label>Postcode<p className="required">*</p></label>
                    <input
                      type="text"
                      id="post_code"
                      placeholder="TW12 1AA"
                      onFocus={(e) => (e.target.placeholder = "")}
                      onBlur={(e) => (e.target.placeholder = "TW12 1AA")}
                      name="postcode"
                      value={formState?.postcode}
                      readOnly
                    />
                    <a
                      href={`${ADVISER_PREFERENCES_URL}/location`}
                      className="edit-btn"
                    >
                      Edit
                    </a>
                  </div>
                  {/* <div className="col-md-6">
                    <label>Postcode</label>
                    <input
                      type="text"
                      id="post_code"
                      placeholder="TW12 1AA"
                      onFocus={(e) => (e.target.placeholder = "")}
                      onBlur={(e) => (e.target.placeholder = "TW12 1AA")}
                      name="postcode"
                      value={formState?.postcode}
                      readOnly
                    />
                    <a
                      href={`${ADVISER_PREFERENCES_URL}/location`}
                      className="edit-btn"
                    >
                      Edit
                    </a> */}
                    {/* <input
                      type="text"
                      id="post_code"
                      placeholder="TW12 1AA"
                      onFocus={(e) => (e.target.placeholder = "")}
                      onBlur={(e) => (e.target.placeholder = "TW12 1AA")}
                      name="postcode"
                      value={formState?.postcode}
                      onChange={handleChange}
                      required
                    /> */}
                    {/* <AsyncTypeahead
                      filterBy={filterBy}
                      id="async-example"
                      isLoading={isSeachLoading}
                      labelKey="Postcode"
                      minLength={2}
                      onSearch={handleSearch}
                      options={options}
                      defaultInputValue={formState?.postcode}
                      // defaultInputValue={formState?.postcode}
                      onChange={handleChangeSearch}
                      onBlur={showSelectedValue}
                      onFocus={showSelectedValue}
                      selected={selectedOption}
                      placeholder="TW12 1AA"
                      className="postCodeSearchInnerAccount"
                      renderMenuItemChildren={(option: any, props: any) => (
                        <React.Fragment>
                          <span>{option?.Postcode}</span>
                        </React.Fragment>
                      )}
                    /> */}
                  {/* </div> */}
                </div>
                {formState.role === ADVISER_ROLE_1 ? (
                  <>
                    <div className="row mt-lg-2 mt-0">
                      <div className="col-md-6">
                        <label>Gender</label>
                        <div className="select-data">
                          <select
                            className="gender"
                            name="gender"
                            onChange={handleChange}
                            value={formState?.gender}
                          >
                            <option value="">Select Gender</option>
                            <option value="Male">Male</option>
                            <option value="Female">Female</option>
                          </select>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <label>Additional Language</label>
                        <div className="select-data">
                          <select
                            className="language"
                            name="language"
                            onChange={handleChange}
                            value={formState.language}
                          >
                            {LANGUAGES_ARR.map(
                              (lang: { value: string; name: string }) => {
                                return (
                                  <option value={lang.value}>
                                    {lang.name}
                                  </option>
                                );
                              }
                            )}
                          </select>
                        </div>
                      </div>
                    </div>
                  </>
                ) : null}
                <div className="row mt-3 mb-3">
                  <div className="col-md-12">
                    <a  href="javascript:void(0)" className="font-weight-bold"  data-toggle="modal"
                    data-target="#Change_Password"  data-backdrop="static"  data-keyboard="false">Change password</a>
                  </div>
                </div>
                <div className="row mt-3 mb-3">
                  <div className="col-md-12 text-center mt-lg-4 mt-0">
                    <button
                      type="submit"
                      className="theme-btn text-uppercase mr-md-3 mr-1 mb-md-0 mb-3"
                    >
                      Save
                    </button>
                    {/* <button
                      type="button"
                      className="theme-btn-border text-uppercase"
                      onClick={() => {
                        history.goBack();
                      }}
                    >
                      Cancel
                    </button> */}
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      <WebcamCaptureModal setImageURL={setImageURL} />
      <ChangePassword />
    </>
  );
};

export default AdviserProfileGeneralInfo;
