import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import {
  getAdviseAreaBid,
  getSingleAdviceArea,
} from "../components/common/service";
import { capitalize, numberWithCommas, prettyTime } from "../utility/commonUtils";
import NoBids from "../components/adviceAreaDetail/NoBids";
import AdvisorBidCard from "../components/adviceAreaDetail/AdvisorBidCard";
import AdviceDetails from "../components/adviceAreaDetail/AdviceDetails";
import AdviceNotes from "../components/adviceAreaDetail/AdviceNotes";
import { ADVICEAREA_STATUS_ARR, NO_DATA_FOUND, NO_DECLINE_OFFERS_FOUND } from "../Constants";
import SendMessageModal from "../components/common/modals/SendMessageModal";
import LoadingSpinner from "../components/common/LoadingSpinner";
import NoBidsClosed from "../components/adviceAreaDetail/NoBidsClosed";
import OnRejectedExpiredBids from "../components/adviceAreaDetail/OnRejectedExpiredBids";


const AdviceAreaDetail = (props: any) => {
  const urlParams: { id: any; tab: any } = useParams();
  const { id, tab } = urlParams;
  const [advice, setAdvice] = useState<object | any>({});
  const [acceptedBids, setAcceptedBids] = useState<object[]>([]);
  const [rejectedBids, setRejectedBids] = useState<object[]>([]);

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [messageData, setMessageData] = useState<any>({
    from_user_id: 0,
    to_user_id: 0,
    channel: [],
    data: [],
    bid: {},
  });

  useEffect(() => {
    if (id) {
      getSingleAdviceAreaCall(id);
      getAdviseAreaBidCall(id, 1);
      // getAdviseAreaBidRejectCall(id, 0);
    }
  }, []);

  const getSingleAdviceAreaCall = async (id: number) => {
    setIsLoading(true);
    const res = await getSingleAdviceArea(id);
    if (res?.success || res.status) {
      setAdvice(res.data);
    }
    setIsLoading(false);
  };

  const getAdviseAreaBidCall = async (id: number, status: number) => {
    setIsLoading(true);
    const res = await getAdviseAreaBid(id, status);
    if (res?.success || res.status) {
      setAcceptedBids(res.data.filter((bid: any) => bid.status < 3));
      setRejectedBids(res.data.filter((bid: any) => bid.status === 3));
    }
    const resReject = await getAdviseAreaBid(id, 0);
    if (resReject?.success || resReject.status) {
      setRejectedBids(resReject.data.filter((bid: any) => bid.status!=1 ));
    }
    setIsLoading(false);
  };

  // const getAdviseAreaBidRejectCall = async (id: number, status: number) => {
  //   setIsLoading(true);
  //   const res = await getAdviseAreaBid(id, 0);
  //   if (res?.success || res.status) {
  //     setRejectedBids(res.data.filter((bid: any) => bid.status!=1 ));
  //   }
  //   setIsLoading(false);
  // };

  const getTabDescription = () => {
    switch (tab) {
      case "offers":
        return acceptedBids.length ? (
          acceptedBids.map((bid: any) => (
            <AdvisorBidCard
              key={bid.id}
              bid={bid}
              advice={advice}
              setMessageData={setMessageData}
            />
          ))
        ) : isLoading ? (
          <LoadingSpinner loading={isLoading} />
        ) : (
          (advice.is_bid_rejected_expired==1?
            <OnRejectedExpiredBids/>: 
            (advice.close_type!=0?
              (advice.offer_count==0?
                <NoBidsClosed/>:
            <NoBids/>
              ):
            <NoBids/>)
          )
        );
      case "declined":  
        return rejectedBids.length ? (
          rejectedBids.map((bid: any) => (
            <AdvisorBidCard
              key={bid.id}
              bid={bid}
              advice={advice}
              declined="true"
              setMessageData={setMessageData}
            />
          ))
        ) : isLoading ? (
          <LoadingSpinner loading={isLoading} />
        ) : (
          <div className="col-md-12 pt-3 text-center">
            <div className="view-btn text-center font-weight-bold color-white">
              {NO_DECLINE_OFFERS_FOUND}
            </div>
          </div>
        );
      case "details":
        return <AdviceDetails advice={advice} />;
      case "notes":
        return <AdviceNotes advice={advice} />;

      default:
        return (
          <>
            <LoadingSpinner loading={isLoading} />
          </>
        );
    }
  };

  return (
    <>
      <main className="need-offers">
        <section className="page-title">
          <div className="bg-black">
            <div className="container">
              <div className="row align-items-center">
                <div className="col-12 col-md-8">
                  <h4 className="text-white">
                    Mortgage Adviser - {advice.service?(capitalize(advice?.service.name)):""}, £
                    {numberWithCommas(advice?.size_want)}
                  </h4>
                </div>
                {advice.advisor_id==0?<div className="col-6 col-md-4 text-right">
                <p className="text-white mb-0">Matching</p>
                </div>:null}
              </div>
              <div className="row align-items-center">
                <div className="col-12 col-md-8">
                  <div className="tabbing">
                    <ul className="pl-0 ml-0 mb-1">
                      <li className="nav-item">
                        <Link
                          to={`/advice-area/${id}/offers`}
                          className={`nav-link ${
                            tab === "offers" ? "active" : ""
                          }`}
                        >
                          Offers
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link
                          to={`/advice-area/${id}/declined`}
                          className={`nav-link ${
                            tab === "declined" ? "active" : ""
                          }`}
                        >
                          Declined offers
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link
                          to={`/advice-area/${id}/details`}
                          className={`nav-link ${
                            tab === "details" ? "active" : ""
                          }`}
                        >
                          Details
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link
                          to={`/advice-area/${id}/notes`}
                          className={`nav-link ${
                            tab === "notes" ? "active" : ""
                          }`}
                        >
                          Notes
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="col-12 col-md-4 text-right">
                  {advice.status === 1 ? (
                    <Link
                      to={`/close-need/${id}`}
                      className="text-white close-text"
                    >
                      Close mortgage need
                    </Link>
                  ) : (
                    <span style={{ color: "#fff" }}>
                      {/* {ADVICEAREA_STATUS_ARR[advice.status]} */}
                      {advice.show_status ||
                        ADVICEAREA_STATUS_ARR[advice.status]}
                    </span>
                  )}
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="recent-sec advice-area-sec need-offers-sec pt-5 pb-5">
          <img
            src="assets/images/expert-right.png"
            alt="help-1"
            className="img-fluid after-img"
          />
          <div className="container">
            <div className="row justify-content-center ">
              {getTabDescription()}
            </div>
          </div>
          <img
            src="assets/images/expert-left.png"
            alt="help-1"
            className="img-fluid before-img"
          />
        </section>
      </main>
      <SendMessageModal {...messageData} />
    </>
  );
};

export default AdviceAreaDetail;
