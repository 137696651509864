import { createContext } from "react";
import { JWT_TOKEN, LOGGEDIN_USER } from "../../Constants";
import { isExpired } from "react-jwt";

const checkIsJwtTokenValid = () => {
  const token = localStorage.getItem(JWT_TOKEN);
  const isJwtTokenExpired = token !== null ? isExpired(token) : true;
  return !isJwtTokenExpired;
};

const checkLoggedInUserData = () => {
  const loggedInUserInfo = localStorage.getItem(LOGGEDIN_USER);
  return loggedInUserInfo === null ? {} : JSON.parse(loggedInUserInfo);
};

export const AuthContext = createContext({
  isLoggedIn: checkIsJwtTokenValid(),
  user: checkLoggedInUserData(),
  login: (user: any) => {},
  logout: () => {},
});
