import React, { useEffect, useState } from "react";
import { useToasts } from "react-toast-notifications";
import { MORTGAGE_ADVICE_ARR } from "../../Constants";
import {
  numberWithCommas,
  numberWithOutCommas,
} from "../../utility/commonUtils";
import {
  getAdvisorProductPreference,
  updateAdvisorProductPreference,
  getProductPreferencesWithServices,
} from "../common/service";

const initialState = {
  // remortgage: 0,
  // first_buyer: 0,
  // next_buyer: 0,
  // but_let: 0,
  // equity_release: 0,
  // overseas: 0,
  // self_build: 0,
  // mortgage_protection: 0,
  // secured_loan: 0,
  // bridging_loan: 0,
  // commercial: 0,
  // something_else: 0,
  mortgage_min_size: "",
  mortgage_max_size: "",
};

const AdviserPrefrencesPrducts = () => {
  const { addToast } = useToasts();
  const [preferences, setPreferences] = React.useState<any>([]);
  const [formState, setFormState] = useState<any>(initialState);

  useEffect(() => {
    getAdvisorProductPreferenceCall();
  }, []);

  const getAdvisorProductPreferenceCall = async () => {
    const res = await getProductPreferencesWithServices();
    if (res.data) {
      setPreferences(res.data);
      delete res.data.id;
      delete res.data.advisor_id;
      delete res.data.updated_at;
      delete res.data.created_at;
      handlePageState({ mortgage_min_size: res.mortgage_min_size });
      handlePageState({ mortgage_max_size: res.mortgage_max_size });
      // setFormState(res.data);
    }
  };

  const handlePageState = (newStates: any) => {
    setFormState((prevStates: any) => {
      return {
        ...prevStates,
        ...newStates,
      };
    });
  };

  const handleChangeCheckbox = (event: { target: HTMLInputElement } | any) => {
    const { name, checked } = event.target;
    handlePageState({ [name]: +checked });
  };

  const handleChange = (event: { target: HTMLInputElement } | any) => {
    const { name, value } = event.target;
    if (["mortgage_min_size", "mortgage_max_size"].includes(name)) {
      const newValue = numberWithOutCommas(value.replace(/[^\d,]/g, ""));
      handlePageState({ [name]: newValue });
    } else {
      handlePageState({ [name]: value });
    }
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    if (formState.mortgage_min_size === "") {
      addToast("Min mortgage size is required.", { appearance: "error" });
      return;
    }
    if (formState.mortgage_max_size === "") {
      addToast("Max mortgage size is required.", { appearance: "error" });
      return;
    }
    const res = await updateAdvisorProductPreference(formState);
    if (res.status) {
      addToast(res.message, { appearance: "success" });
    } else {
      addToast(res.message, { appearance: "error" });
    }
  };

  return (
    <>
      <div className="profile-datas">
        <h5 className="mb-3">Product preference</h5>
        <p>
          Focus on the most relevant leads by filtering out those of less
          interest. Simply define your areas of expertise and preferences and we
          will do the rest. You can change these settings at any time.
        </p>
        <span className="border-bottom"></span>
      </div>

      <form onSubmit={handleSubmit}>
        <div className="team-memebers-data pl-xl-5 pr-xl-5">
          {preferences.map((adv_area: any,i:number) => {
            return (
              <div key={adv_area.id} className="row member-data m-0">
                <div className="col-6 text-left">
                  <p>{adv_area.name}</p>
                </div>
                <div className="col-6 text-right">
                  <label className="switch">
                    <input
                      type="checkbox"
                      name={adv_area.id}
                      value={1}
                      defaultValue={adv_area.preference_status}
                      onChange={handleChangeCheckbox}
                      defaultChecked={adv_area.preference_status==1}
                    />
                    <span className="slider round"></span>
                  </label>
                </div>
              </div>
            );
          })}
          <div className="row member-data bg-white m-0 mt-3">
            <div className="col-md-6 col-12 text-left">
              <p>Min mortgage size</p>
            </div>
            <div className="col-md-6 col-12 text-right">
              <input
                type="text"
                id="min_size"
                className="min_max_size"
                placeholder="0"
                onFocus={(e) => (e.target.placeholder = "")}
                onBlur={(e) => (e.target.placeholder = "0")}
                name="mortgage_min_size"
                onChange={handleChange}
                value={numberWithCommas(formState.mortgage_min_size) || ""}
                required
              />
            </div>
          </div>
          <div className="row member-data bg-white m-0">
            <div className="col-md-6 col-12 text-left">
              <p>Max mortgage size</p>
            </div>
            <div className="col-md-6 col-12 text-right">
              <input
                type="text"
                id="max_size"
                className="min_max_size"
                placeholder="10,000,000"
                onFocus={(e) => (e.target.placeholder = "")}
                onBlur={(e) => (e.target.placeholder = "10,000,000")}
                name="mortgage_max_size"
                onChange={handleChange}
                value={numberWithCommas(formState.mortgage_max_size) || ""}
                required
              />
            </div>
          </div>
          <div className="row m-0">
            <div className="col-md-12 text-center mt-lg-4 mt-0">
              <button
                type="submit"
                className="theme-btn text-uppercase mr-md-3 mr-1 mb-md-0 mb-3"
              >
                Save
              </button>
            </div>
          </div>
        </div>
      </form>
    </>
  );
};

export default AdviserPrefrencesPrducts;
