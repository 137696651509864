import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import AdviserProfileAbout from "../../components/adviser/AdviserProfileAbout";
import AdviserProfileGeneralInfo from "../../components/adviser/AdviserProfileGeneralInfo";
import AdviserProfileOffers from "../../components/adviser/AdviserProfileOffers";
import AdviserProfileReviews from "../../components/adviser/AdviserProfileReviews";
import AdviserProfileWebLinks from "../../components/adviser/AdviserProfileWebLinks";
import { getAdvisorProfile, getAdviserUserProfile } from "../../components/common/service";
import { ADVISER_MY_ACCOUNT, LOGGEDIN_USER } from "../../Constants";
import useLoggedInUserData from "../../utility/useLoggedInUserData";
import LoadingSpinner from "../../components/common/LoadingSpinner";
var profile_percent = 0;
const AdviserMyProfile = () => {
  const urlParams: { id: any; tab: any } = useParams();
  const { tab } = urlParams;
  const user = useLoggedInUserData();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [profileData, setProfileData] = useState<any>({});
  const [offersData, setOffersData] = useState<any>([]);
  const [reviewRatingData, setReviewRatingData] = useState<any>([]);
  const [isAPICalled, setisAPICalled] = useState<boolean>(false);

  useEffect(() => {
    if(!isAPICalled){
      if (user.id) {
        getAdvisorProfileCall(user.id);
        setIsLoading(false);
      }
      getUserProfile();
      setisAPICalled(true);
    }
    
  }, []);

  const refechData = async () => {
    getAdvisorProfileCall(user.id);
  };

  const getAdvisorProfileCall = async (advice_id: number) => {
    const res = await getAdvisorProfile(advice_id);
    if (res?.status) {
      setProfileData(res.data);
      setOffersData(res.offers);
      setReviewRatingData({
        avarageRating: res.avarageRating,
        avarageRatingTotal: res.total,
        data: res.review_rating,
      });
    }
    setIsLoading(false);
  };

  const getUserProfile = async () => {
    const res = await getAdviserUserProfile();
    if (res?.user) {
      profile_percent = res.user.userDetails.profile_percent;
      localStorage.setItem(LOGGEDIN_USER, JSON.stringify(res.user));
    }   
    setIsLoading(false);
  };
  const getTabDescription = () => {
    switch (tab) {
      case "general-info":
        return (
          <AdviserProfileGeneralInfo isLoading profileData={profileData} />
        );
      case "about":
        return <AdviserProfileAbout isLoading profileData={profileData} />;
      case "offers":
        return (
          <AdviserProfileOffers
            isLoading
            offersData={offersData}
            refechData={refechData}
          />
        );
      case "reviews":
        return (
          <AdviserProfileReviews
            isLoading
            reviewRatingData={reviewRatingData}
          />
        );
      case "web-links":
        return <AdviserProfileWebLinks />;
      default:
        return <></>;
    }
  };

  return (
    <>
      <main className="need-offers">
        <section className="page-title my-account-title">
          <div className="container">
            <div className="row">
              <div className="col-md-12 mb-5 mb-md-0"></div>
            </div>
          </div>
        </section>

        <section className="recent-sec directory-sec advice-area-sec register-final-sec pt-4 pb-5 myaccount-sec new-review-sec">
          <div className="container">
            <div className="row">
              <div className="col-12 back-btn mt-lg-4 mb-lg-3 mt-md-5 mt-5 mb-0">
                <Link to={ADVISER_MY_ACCOUNT} className="back-btn">
                  <i className="fa fa-angle-left" aria-hidden="true"></i>
                  My Account
                </Link>
              </div>
            </div>
          </div>

          <div className="container">
            <div className="row">
              <div className="col-12 mb-3 small-breadcumbs">
                <h5 className="text-white">
                  <img
                    src="assets/images/my_accounts/icon4.png"
                    className="img-fluid"
                    alt=""
                  />{" "}
                  Profile
                </h5>
              </div>
            </div>

            {!isLoading?tab === "general-info" ? (
              <div className="recent-box mb-4 general-info-box">
                <div className="row my-accoutns-data completed-data">
                  <div className="col-xl-9 col-lg-8 col-6">
                      <h2>{profile_percent}% Complete</h2>
                  </div>
                  <div className="col-xl-3 col-lg-4 col-6 mt-2 mt-lg-0 text-center text-md-right">
                    <Link
                      to={`/adviser-profile/${user.id}`}
                      className="resend-link"
                    >
                      VIEW PUBLIC PROFILE
                    </Link>
                  </div>
                  <div className="col-xl-12">
                    <p>
                      Your profile is key to attracting customers on
                      Mortgagebox. Use it to detail your services, offers and
                      explain what makes you different from your competition &
                      why people should select you.
                    </p>
                    <p>
                      See{" "}
                      <b>
                      <Link to={`/faq/creating-a-professional-looking-profile`}>our tips </Link>
                      </b>
                      for what makes a great profile and how to increase your
                      percentage.
                    </p>
                  </div>
                </div>
              </div>
            ) : null:<LoadingSpinner loading={isLoading} />}

            <div className="recent-box settings-data reviews-data mb-4">
              <div className="row justify-content-center">
                <div className="col-xl-10 col-lg-12 mb-4">
                  <div className="tabbing">
                    <ul className="setting-tabs nav nav-pills mt-mb-4 mt-lg-4 mb-2 mt-2">
                      <li className="nav-item">
                        <Link
                          to={`${ADVISER_MY_ACCOUNT}/general-info`}
                          className={`nav-link ${
                            tab === "general-info" ? "active" : ""
                          }`}
                        >
                          Account details
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link
                          to={`${ADVISER_MY_ACCOUNT}/about`}
                          className={`nav-link ${
                            tab === "about" ? "active" : ""
                          }`}
                        >
                          About
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link
                          to={`${ADVISER_MY_ACCOUNT}/offers`}
                          className={`nav-link ${
                            tab === "offers" ? "active" : ""
                          }`}
                        >
                          Offers
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link
                          to={`${ADVISER_MY_ACCOUNT}/reviews`}
                          className={`nav-link ${
                            tab === "reviews" ? "active" : ""
                          }`}
                        >
                          Reviews
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link
                          to={`${ADVISER_MY_ACCOUNT}/web-links`}
                          className={`nav-link ${
                            tab === "web-links" ? "active" : ""
                          }`}
                        >
                          Web Links
                        </Link>
                      </li>
                    </ul>

                    <div className="tab-content mt-lg-5 mt-4">
                      {getTabDescription()}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <img
            src="assets/images/business_right.png"
            alt="help-1"
            className="img-fluid before-img"
          />
          <img
            src="assets/images/business_left.png"
            alt="help-1"
            className="img-fluid after-img"
          />
        </section>
      </main>
    </>
  );
};

export default AdviserMyProfile;
