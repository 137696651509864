import React from "react";
import { Link } from "react-router-dom";
import { getAdviserProfileImage } from "../../utility/commonUtils";
import useLoggedInUserData from "../../utility/useLoggedInUserData";
import MakeAnEnquiryModal from "../common/modals/advisorModals/MakeAnEnquiryModal";

const AdviserProfileSearchedCard = (props: any) => {
  const user = useLoggedInUserData();
  const profilepicture = {
    width: "80px",
    height: "80px",
    borderRadius:"50px",
  };
  return (
    <>
      <div className="datas mb-md-5 mb-3">
        <div className="row mb-3">
          <div className="col-xl-2 col-lg-3 col-md-2">
            <img
              src={getAdviserProfileImage(props.advisor)}
              className="img-fluid dummy-img-searc"
              alt=""
              style={profilepicture}
            />
          </div>
          <div className="col-xl-7 col-lg-9 col-md-7">
            <h3>{props.advisor?.display_name}</h3>
            <p className="mb-1">{props.advisor?.company_name}</p>
            <div className="row align-items-center mt-2">
              <div className="col-md-7">
                <div className="star">
                  <span
                    aria-hidden="true"
                    className={`fa fa-star ${
                      Number(props.advisor.avarageRating) >= 1 && "checked"
                    }`}
                  ></span>
                  <span
                    aria-hidden="true"
                    className={`fa fa-star ${
                      Number(props.advisor.avarageRating) >= 2 && "checked"
                    }`}
                  ></span>
                  <span
                    aria-hidden="true"
                    className={`fa fa-star ${
                      Number(props.advisor.avarageRating) >= 3 && "checked"
                    }`}
                  ></span>
                  <span
                    aria-hidden="true"
                    className={`fa fa-star ${
                      Number(props.advisor.avarageRating) >= 4 && "checked"
                    }`}
                  ></span>
                  <span
                    aria-hidden="true"
                    className={`fa fa-star ${
                      Number(props.advisor.avarageRating) >= 5 && "checked"
                    }`}
                  ></span>
                  <p className="review-text ml-2 d-inline-block">
                    {props.advisor.rating.total
                      ? props.advisor.rating.total <= 1
                        ? `${props.advisor.rating.total} Review`
                        : `${props.advisor.rating.total} Reviews`
                      : `No Reviews`} 
                  </p>
                </div>
              </div>
              <div className="col-md-5">
                <p>{props.advisor.total_completed_bids} MB Customers</p>
              </div>
            </div>
          </div>
          <div className="col-xl-3 col-lg-12 col-md-3 view-profile mt-2">
            <Link
              to={`/adviser-profile/${props.advisor?.advisorId}`}
              className="theme-btn-border text-uppercase"
            >
              view profile
            </Link>
          </div>
        </div>
        <div className="row">
          <div className="col-6">
            <p>{props.advisor?.short_description}</p>
            <p className="location">
              <i className="fas fa-map-marker-alt mr-2" aria-hidden="true"></i>
              {props.advisor?.city ? `${props.advisor?.city}, ` : null}
              {props.advisor?.postcode}{" "}
              {props.advisor?.distance
                ? `(${props.advisor?.distance} miles away)`
                : null}
            </p>
          </div>
          <div className="col-6">
            <a
              href="javascript:void(0)"
              className="float-right d-block"
              data-toggle="modal"
              data-target={`#direcory_enquiry_${props.advisor.advisorId}`}
            >
              Make an Enquiry
            </a>
            <MakeAnEnquiryModal advisor={props.advisor} />
          </div>
        </div>
        <span className="border"></span>
      </div>
    </>
  );
};

export default AdviserProfileSearchedCard;
