import React, { useState } from "react";
import { useToasts } from "react-toast-notifications";
import NumberFormat from "react-number-format";
import {
  numberWithCommas,
  numberWithOutCommas,
} from "../../utility/commonUtils";
import { MORTGAGE_TIME_ARR } from "../../Constants";

const StepOne = (props: any) => {

  const { addToast } = useToasts();
  const [innerSteps, setInnerSteps] = useState<number>(props.innerStep || 1);

  const handleInnerStep = (newStep: number) => {
    if (newStep === 3) {
      var cal = Number(numberWithOutCommas(props.formState.property)) / Number(numberWithOutCommas(props.formState.size_want));
      if (props.formState.property === "") {
        addToast("Please fill required field.", { appearance: "error" });
        return;
      } 
      setInnerSteps(() => newStep);
    } else if (newStep === 4) {
      var cal = props.formState.size_want / props.formState.property;
      if (props.formState.size_want === "") {
        addToast("Please fill required field.", { appearance: "error" });
        return;
      } 
      if (cal>= 2.0
      ) {
        addToast(
          `We are unable to help you as your mortgage need greatly exceeds the property value`,
          { appearance: "error" }
        );
        return;
      } else {
        setInnerSteps(() => newStep);
        props.nextStep(3);
      }
    } else {
      setInnerSteps(() => newStep);
    }
  };

  const onRadioChanged = (value: string) => {
    props.setFormData("request_time", value);
  };

  const handleSubmit = (event:any) => {
    event.preventDefault(); // Prevent default form submission behavior
    // Handle form submission logic here
    console.log('Form submitted');
  };

  const handleChange = (event: { target: HTMLInputElement } | any) => {
    const { name, value } = event.target;
    if (["property", "size_want"].includes(name)) {
      const newValue = numberWithOutCommas(value).replace(/[^\d,]/g, "");
      props.setFormData([name], newValue);
    } else {
      props.setFormData([name], value);
    }
    event.preventDefault();
  };

  return (
    <>
      <section className="recent-sec advice-area-sec advice-area-hs pt-2">
        <img
          src="assets/images/expert-right.png"
          alt="help-1"
          className="img-fluid after-img"
        />
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="panel panel-default">
                <div className="panel-body">
                  <div className="stepper">
                    <div className="active-title d-md-none text-center">
                      <p className="text-white mb-3 font-weight-bold">
                        Your needs
                      </p>
                    </div>
                    <ul className="nav nav-tabs mb-3 mb-md-0" role="tablist">
                      <li role="presentation" className="completed">
                        <a
                          className="persistant-disabled d-none d-md-flex"
                          href="javascript:void(0)"
                          data-toggle="tab"
                          aria-controls="stepper-step-1"
                          role="tab"
                          title="Step 1"
                        >
                          <span className="round-tab mr-3">1</span>
                          <p>How can we help?</p>
                        </a>
                      </li>
                      <li role="presentation" className="active">
                        <a
                          className="persistant-disabled d-none d-md-flex"
                          href="javascript:void(0)"
                          data-toggle="tab"
                          aria-controls="stepper-step-2"
                          role="tab"
                          title="Step 2"
                        >
                          <span className="round-tab mr-3">2</span>
                          <p>Your needs</p>
                        </a>
                      </li>
                      <li role="presentation">
                        <a
                          href="javascript:void(0)"
                          className="persistant-disabled d-none d-md-flex"
                          data-toggle="tab"
                          aria-controls="stepper-step-3"
                          role="tab"
                          title="Step 3"
                        >
                          <span className="round-tab  mr-3">3</span>
                          <p>About you</p>
                        </a>
                      </li>
                    </ul>
                    <form  onSubmit={handleSubmit}>
                      <div className="tab-content tab-data-content text-center">
                        <div
                          className="tab-pane fade active show"
                          role="tabpanel"
                          id="stepper-step-2"
                        >
                          {innerSteps === 1 && (
                            <div id="step1-part1" className="step2-part active">
                              <h2>How soon do you need the mortgage?</h2>
                              <div className="heading mt-2">
                                <span></span>
                              </div>
                              <div className="sub-tabbing">
                                <div className="row">
                                  <div className="col-xl-10 offset-xl-1 step2-pills">
                                    <ul className="nav nav-pills mb-4">
                                      {MORTGAGE_TIME_ARR.map(
                                        (adv_time: any) => {
                                          return (
                                            <>
                                              <li
                                                key={adv_time.key}
                                                className="nav-item"
                                              >
                                                <a
                                                  className={`nav-link text-uppercase ${
                                                    props.formState
                                                      .request_time ===
                                                    adv_time.value
                                                      ? "active"
                                                      : ""
                                                  }`}
                                                  data-toggle="pill"
                                                  href={adv_time.href}
                                                  onClick={() =>
                                                    onRadioChanged(
                                                      adv_time.value
                                                    )
                                                  }
                                                >
                                                  {adv_time.name}
                                                </a>
                                              </li>
                                            </>
                                          );
                                        }
                                      )}
                                    </ul>
                                  </div>
								  <div className="col-lg-12 text-right">
									  <button
										type="button"
										className="btn-bg-blue text-uppercase float-left"
										onClick={() => props.nextStep(1)}
									  >
										<i
										  className="fa fa-chevron-left"
										  aria-hidden="true"
										></i>{" "}
										Back
									  </button>
									  <button
										type="button"
										className="btn-bg-blue text-uppercase current-next-step"
										onClick={() => handleInnerStep(2)}
									  >
										Next Step
										<i
										  className="fa fa-chevron-right"
										  aria-hidden="true"
										></i>
									  </button>
									</div>
                                </div>
                                
                              </div>
                            </div>
                          )}

                          {innerSteps === 2 && (
                            <div id="step2-part1" className="step2-part">
                              <h2>
                                What is the approximate value of the property?
                              </h2>
                              <div className="heading mt-2">
                                <span></span>
                              </div>
                              <div className="row">
                                <div className="col-lg-8 offset-lg-2 col-md-10 offset-md-1 currency_icon">
                                  <span>
                                    <select
                                      name="property_currency"
                                      value={props.formState.property_currency}
                                      onChange={handleChange}
                                    >
                                      <option value="£">£</option>
                                      <option value="$">$</option>
                                      <option value="€">€</option>
                                    </select>
                                  </span>
                                  {/* <input
                                    type="tel"
                                    name="property"
                                    id="property"
                                    placeholder="500,000"
                                    onFocus={(e) => (e.target.placeholder = "")}
                                    onBlur={(e) =>
                                      (e.target.placeholder = "500,000")
                                    }
                                    value={numberWithCommas(
                                      props.formState.property
                                    )}
                                    onChange={handleChange}
                                    required
                                  /> */}
                                  <NumberFormat
                                    thousandSeparator={true}
                                    name="property"
                                    id="property"
                                    placeholder="500,000"
                                    onFocus={(e) => (e.target.placeholder = "")}
                                    onBlur={(e) =>
                                      (e.target.placeholder = "500,000")
                                    }
                                    value={numberWithCommas(
                                      props.formState.property
                                    )}
                                    onChange={handleChange}
                                  />
                                </div>
                                <div className="col-lg-12 text-right">
                                  <button
                                    type="button"
                                    className="btn-bg-blue text-uppercase float-left"
                                    onClick={() => handleInnerStep(1)}
                                  >
                                    <i
                                      className="fa fa-chevron-left"
                                      aria-hidden="true"
                                    ></i>{" "}
                                    Back
                                  </button>
                                  <button
                                    type="button"
                                    className="btn-bg-blue text-uppercase current-next-step"
                                    onClick={() => handleInnerStep(3)}
                                  >
                                    Next Step
                                    <i
                                      className="fa fa-chevron-right"
                                      aria-hidden="true"
                                    ></i>
                                  </button>
                                </div>
                              </div>
                            </div>
                          )}
                          {innerSteps === 3 && (
                            <div id="step3-part1" className="step2-part m0">
                              <h2>What size mortgage do you want?</h2>
                              <div className="heading mt-2">
                                <span></span>
                              </div>
                              <div className="row">
                                <div className="col-lg-8 offset-lg-2 col-md-10 offset-md-1 currency_icon">
                                  <span>
                                    <select
                                      name="size_want_currency"
                                      value={props.formState.size_want_currency}
                                      onChange={handleChange}
                                    >
                                      <option value="£">£</option>
                                      <option value="$">$</option>
                                      <option value="€">€</option>
                                    </select>
                                  </span>
                                  {/* <input
                                    type="tel"
                                    min="0"
                                    name="size_want"
                                    id="size_want"
                                    placeholder="250,000"
                                    onFocus={(e) => (e.target.placeholder = "")}
                                    onBlur={(e) =>
                                      (e.target.placeholder = "250,000")
                                    }
                                    value={numberWithCommas(
                                      props.formState.size_want
                                    )}
                                    onChange={handleChange}
                                  /> */}
                                  <NumberFormat
                                    thousandSeparator={true}
                                    name="size_want"
                                    id="size_want"
                                    placeholder="250,000"
                                    onFocus={(e) => (e.target.placeholder = "")}
                                    onBlur={(e) =>
                                      (e.target.placeholder = "250,000")
                                    }
                                    value={numberWithCommas(
                                      props.formState.size_want
                                    )}
                                    onChange={handleChange}
                                  />
                                </div>
                              

                              <div className="col-lg-12 text-right">
                                <button
                                  type="button"
                                  className="btn-bg-blue text-uppercase float-left"
                                  onClick={() => handleInnerStep(2)}
                                >
                                  <i
                                    className="fa fa-chevron-left"
                                    aria-hidden="true"
                                  ></i>{" "}
                                  Back
                                </button>
                                <button
                                  type="button"
                                  className="btn-bg-blue text-uppercase"
                                  onClick={() => handleInnerStep(4)}
                                >
                                  Next Step
                                  <i
                                    className="fa fa-chevron-right"
                                    aria-hidden="true"
                                  ></i>
                                </button>
                              </div>
							  </div>
                            </div>
                          )}
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <img
          src="assets/images/expert-left.png"
          alt="help-1"
          className="img-fluid before-img"
        />
      </section>
    </>
  );
};

export default StepOne;
