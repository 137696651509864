import React, { useState ,useRef, useEffect} from "react";
import { useToasts } from "react-toast-notifications";
import $ from "jquery";
import "bootstrap";
import {
  capitalize,
  numberWithCommas,
  prettyTime,
} from "../../utility/commonUtils";
import useLoggedInUserData from "../../utility/useLoggedInUserData";
import AdviceDetails from "../adviceAreaDetail/AdviceDetails";
import BidConfirmModal from "../common/modals/BidConfirmModal";
import { acceptRejectBid, addNotes, startChat, UpdateNotes, getUserNotes, getAdvisorAcceptedLeads, getUserAdviceNotes,markProjectCompleted, seenMessages} from "../common/service";
import SendMessageModal from "../common/modals/SendMessageModal";
import { Twemoji } from "react-emoji-render";
import {
  ADVISOR_BID_STATUS_ARR,
  ADVISER_LEADS_ACCEPTED,
  DEFAULT_BLANK_DATE,
  VERIFY_TO_ACCEPT,
} from "../../Constants";
import { useHistory } from "react-router-dom";
import LoadingSpinner from "../common/LoadingSpinner";
import ReactTooltip from "react-tooltip";
import moment from "moment";
import ReportSpamModal from "../common/modals/advisorModals/ReportSpamModal";
import { MessageBox, SystemMessage } from "react-chat-elements";
const scrollToRef = (ref: any) => window.scrollTo(0, ref.current.offsetTop);
const initialState = {
  notes: "",
  advice_id: 0,
  id: 0,
};
const MortgageCardDetails = (props: any) => {
  const collapseRandomId = new Date().getTime();
  const user = useLoggedInUserData();
  const history = useHistory();
  const { addToast } = useToasts();
  const [messageData, setMessageData] = useState<any>({
    from_user_id: user.id,
    to_user_id: 0,
    channel: [],
    data: [],
    bid: {},
  });
  const [formState, setFormState] = useState({
    ...initialState,
    advice_id: props.id,
  });
  // const [leads, setLeads] = useState<object[]>([]);
  const [userNotes, setUserNotes] = useState<object[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isHiredShow, setIsHiredShow] = useState<boolean>(true);
  const myRef = useRef(null);
  const executeScroll = () => scrollToRef(myRef);
  const [selectedLead, setSelectedLead] = useState<any>({});
  
  const queryParams = new URLSearchParams(window.location.search);
  const accepted_id = queryParams.get("accepted_id");
  useEffect(() => {
    if (props.id) {
      setIsLoading(true);
      getUserAdviceNotes(props.id,user.id).then((res) => {
        if(res.success || res.status) {
          setUserNotes(res.data);
          setIsLoading(false);
        }
      });
    }
  }, [userNotes]);
  const handleAddNotes = async (e: any) => {
    e.preventDefault();
    let res;
    if (formState.id > 0) {
      res = await UpdateNotes(formState);
    } else {
      formState.advice_id = props.id;
      res = await addNotes(formState);
    }
    if (res.success || res.status) {
      addToast(res.message, { appearance: "success" });
      handlePageState({ notes: "", id: 0 });
      getUserNotesCall(props.id);
      // setLeads([...props.last_notes, res.data]);
    } else {
      addToast("Notes can not be blank", { appearance: "error" });
    }
  };

  const getUserNotesCall = (advice_id: number) => {
    setIsLoading(true);
    getUserAdviceNotes(props.id,user.id).then((res) => {
      if(res.success || res.status) {
        setUserNotes(res.data);
      }
    });
    // const res = getUserAdviceNotes(props.id,user.id);
    // if (res.success || res.status) {
    //   setUserNotes(res.data);
    // }
    setIsLoading(false);
  };

  const handlePageState = (newStates: any) => {
    setFormState((prevStates) => {
      return {
        ...prevStates,
        ...newStates,
      };
    });
  };
  const handleMessage = async (
    from_user_id: number,
    to_user_id: number,
    advicearea_id: number
  ) => {
    const res = await startChat({
      from_user_id: from_user_id,
      to_user_id: to_user_id,
      advicearea_id: advicearea_id,
    });
    if (res.status) {
      const channel = res.channel;
      setMessageData({
        from_user_id,
        to_user_id,
        channel,
        data: res.data,
        bid: { name: props?.name, company_name: props?.service_type }, //This is for show receiver info in chat window
      });
      $("#message_data").modal("show");
      await seenMessages({
        channel_id: channel,
      });
    }
  };
  const handleNotesChange = (event: { target: HTMLInputElement } | any) => {
    handlePageState({ notes: event.target.value });
    event.preventDefault();
  };
  const editNote = (note: any) => {
    handlePageState({
      notes: note.notes,
      advice_id: note.advice_id,
      id: note.id,
    });
    // executeScroll();
  };

  const handleBidAction = async (status: number) => {
    const postedData = {
      advisor_id: user.id,
      area_id: props.id,
      advisor_status: status,
    };
    const res = await acceptRejectBid(postedData);
    if (res.status) {
      $("#accept_lead").modal("hide");
      $("body").removeClass("modal-open");
      $(".modal-backdrop").remove();
      addToast(res.message, { appearance: "success" });
      if(status!=2){
        setTimeout(() => {
          history.push(ADVISER_LEADS_ACCEPTED);
        }, 1000);
      }else{
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      }
    } else {
      addToast(res.message, { appearance: "error" });
    }
  };

  const showDetails = (id: any) => {
    const btnIns = $("#btn-" + id);
    const divIns = $("#isCollapse-" + id);
    $(btnIns).toggleClass("active");
    if (!$(btnIns).hasClass("active")) {
      $(btnIns).text("See less");
      $(btnIns).addClass("see-less");
      $(divIns).show();
    } else {
      $(btnIns).text("See more");
      $(btnIns).removeClass("see-less");
      $(divIns).hide();
    }
  };

  const resetSpamForm = (id: string) => {
      $("#reason").val('');
      $(".alert").hide();
  }
  const setCompleteLead = async (status: string) => {
      if(status=='2' || status=='3' || status=='4'){
        const postedData = {
          advisor_id: user.id,
          area_id: props.id,
          advisor_status: status,
        };
        const res = await markProjectCompleted(postedData);
        if (res.status) {
          setIsHiredShow(false);
          addToast(res.message, { appearance: "success" });
          setTimeout(() => {
            // window.location.reload();
          }, 1000);
        } else {
          addToast(res.message, { appearance: "error" });
        }
      }
  }

  const getTotalBidsCount = () => {
    if (props.totalBids && props.totalBids.length) {
      return props.totalBids.length > 5 ? 5 : props.totalBids.length;
    }
    return 0;
  };

  const getBidsGraph = () => {
    return [0, 1, 2, 3, 4].map((item) => (
      <span
        className={`borders ${
          props.total_bids_count > item && item in props.totalBids
            ? props.totalBids[item] === 1
              ? "checked"
              : "unchecked"
            : ""
        }`}
      ></span>
    ));
  };
  

  const getCostOfLead = (props.cost_of_lead_with_cost || "").trim();
  const getCostOfLeadDrops = (props.cost_of_lead_drop || "").trim();
  const getCostOfLeadSummary = (props.lead_summary || "").trim();


  const fullName = props.name ? props.name.split(" ") : [];
  const leadsMatchData = (
    <>
      <div className="row">
        <div className="col-xl-9 col-lg-7 col-md-8 col-6">
          <h4>
            {capitalize(fullName.length ? fullName[0] : "")} {capitalize(fullName.length>1 ? fullName[1].charAt(0) : "")}
            {props?.user_detail?.email_status === 0 ?
            <h6 style={{ display: "inline",color: "red", fontSize: "12px" }}> &nbsp;<i className="fa fa-times-circle" aria-hidden="true"></i> Email Not Verified</h6>:
            <h6 style={{ display: "inline",color: "green", fontSize: "12px" }}> &nbsp;<i className="fa fa-check-circle" aria-hidden="true"></i> Email Verified</h6>}
          </h4>
          
          
          <p style={{display: "inline-block"}}>{props?.service?(capitalize(props?.service.name)):""}{props?.inquiry_adviser_id!=0?(props?.inquiry_adviser_id==user.id?", Direct Enquiry":"" ):""}</p>
          {/* <p style={{width: "30%",display: "inline-block"}}></p> */}
        </div>
        <div className="col-xl-3 col-lg-5 col-md-4 col-6 dates">
          <p>{prettyTime(props?.created_at, false, true)}</p>
        </div>
      </div>
      <div className="row">
        <div className="col-xl-5 col-lg-6 col-md-6 col-12 mb-md-0 mb-1">
          <h6>
            <img src="assets/images/icon1.png" alt="" /> {props?.lead_address}
          </h6>
        </div>
        <div className="col-xl-6 col-lg-6 col-md-6 col-12 mb-md-0 mb-1">
          <h6>
            <img src="assets/images/icon4.png" alt="" /> 
            {getCostOfLead}
          </h6>
        </div>
      </div>
      <div className="row">
        <div className="col-xl-5 col-lg-6 col-md-6 col-12 mb-md-0 mb-1">
          <h6>
            {props.lead_value ? (
              <>
                <img src="assets/images/icon2.png" alt="" />
                Estimated lead value {props.lead_value}
              </>
            ) : null}
          </h6>
        </div>
        {getCostOfLeadDrops != "" ? (
          <div className="col-xl-6 col-lg-5 col-md-6 col-12 mb-md-0 mb-1">
            <h6>
              <img src="assets/images/icon3.png" alt="" /> {getCostOfLeadDrops}
            </h6>
          </div>
        ) : null}
      </div>
      <div className="row align-items-center mt-4">
        <div className="col-xl-6 col-lg-12 col-md-12 col-12">
          <div className="responded text-center mb-xl-0 mb-lg-3 mb-md-3 mb-3">
            <p className="mb-0">
              {getBidsGraph()}
              <a>
                {props.total_bids_count!=undefined?props.total_bids_count:0}/5 professionals have responded{" "}
                <a
                  href="#"
                  onClick={(e) => e.preventDefault()}
                  // title={`${props.total_bids_count}/5 professionals have responded!`}
                  data-tip data-for="registerTip"

                >
                  <img
                    src="assets/images/information.png"
                    alt="information"
                    className="img-fluid information"
                  />
                  <ReactTooltip id="registerTip" place="top" effect="solid">
                  {`${props.total_bids_count}/5 professionals have responded!`}
                  </ReactTooltip>
                  {" "}
                  {/* <img
                    src="assets/images/information.png"
                    alt="information"
                    className="img-fluid information"
                  /> */}
                </a>
              </a>
            </p>
          </div>
        </div>
        {user.email_status === 1 && user?.userDetails?.FCA_verified != ""? (
          <>
            {user.status!=0 && user?.userDetails?.FCA_verified != null? (
              <>
            <div className="col-xl-3 col-lg-6 col-md-6 col-12 mb-md-0 mb-3 text-center">
              <a
                href="javascript:void(0)"
                className="theme-btn w-100"
                data-toggle="modal"
                data-target="#accept_lead"
              >
                ACCEPT LEAD
              </a>
            </div>
            <div className="col-xl-3 col-lg-6 col-md-6 col-12 text-center">
              <button
                onClick={() => handleBidAction(2)}
                className="btn-border-blue w-100"
              >
                NOT INTERESTED
              </button>
            </div> 
            </>
          ) : user?.userDetails?.FCA_verified == ""?(<div className="col-xl-6 col-lg-6 col-md-6 col-12 mb-md-0 mb-3 text-center"><p>
          We are verifying your FCA registration, typically completed in less than 24 hours. Once confirmed you will be up and running and able to accept mortgage leads
        </p> </div>
        
      ): user?.userDetails?.FCA_verified != null?(<div className="col-xl-6 col-lg-6 col-md-6 col-12 mb-md-0 mb-3 text-center"><p>
              Your account is suspended
            </p> </div>
            
          ):null}
            
          </>
        ) : (
          <div className="col-xl-12 col-lg-12 col-md-12 col-12 text-center">
            <p>
              {/* {user.email_status == 0 && user?.userDetails?.FCA_verified == null?(<>
              <p className="error-msg">Please verify email to accept mortgage leads</p>
              <p className="error-msg">We are verifying your FCA registration, typically completed in less than 24 hours. Once confirmed you will be up and running and able to accept mortgage leads</p></>):user.email_status == 0 && user?.userDetails?.FCA_verified != null?(<><p className="error-msg">Please verify email to accept mortgage leads</p></>):user.email_status != 0 && user?.userDetails?.FCA_verified == null?(<><p className="error-msg">We are verifying your FCA registration, typically completed in less than 24 hours. Once confirmed you will be up and running and able to accept mortgage leads</p></>):null
              } */}
              {/* Please verify{" "}
              {user.email_status === 0
                ? "email"
                : user?.userDetails?.FCA_verified == ''
                ? user.email_status === 0
                  ? " and "
                  : "FCA Number"
                : ""}{" "}
              {VERIFY_TO_ACCEPT} */}
            </p>
          </div>
        )}
      </div>
    </>
  );

  const leadsAcceptedData = (
    <>
      <div className="row align-items-end">
        <div className="col-12">
          <div className="row">
            <div className="col-xl-9 col-lg-7 col-md-8 col-6">
              <h4>{capitalize(props?.name)}</h4>
              
              <p>{props?.service? (capitalize(props?.service.name)):""}</p>
            </div>
            <div className="col-xl-3 col-lg-5 col-md-4 col-6 dates text-right">
              <p>{prettyTime(props.created_at, false, true)}</p>
            </div>
          </div>
        </div>
        <div className="col-xl-7 col-12">
          <div className="row">
            <div className="col-xl-12 col-lg-12 col-md-12 col-12 mb-md-0 mb-1">
              <h6>
                <img src="assets/images/icon1.png" alt="" />
                {props?.lead_address}
              </h6>
            </div>
            <div className="col-xl-12 col-lg-12 col-md-12 col-12 mb-md-0 mb-1">
              <h6>
                {props.lead_value ? (
                  <>
                    <img src="assets/images/icon2.png" alt="" />
                    Estimated lead value {props.lead_value}
                  </>
                ) : null}
              </h6>
            </div>
          </div>
          <div className="row">
            <div className="col-xl-12 col-lg-12 col-md-12 col-12 mb-md-0 mb-1">
              <h6>
                <img src="assets/images/icon4.png" alt="" />
                {getCostOfLead}
              </h6>
            </div>
          </div>
          <div className="row">
            <div className="col-md-10 col-12">
              <div className="">
                {/* responded text-center mb-xl-0 mb-lg-3 mb-md-3 mb-3 */}
                {props.show_status!='Hired'?
                <p className="mb-0">
                  {getBidsGraph()}
                  <a>
                    {props.total_bids_count}/5 professionals have responded{" "}
                    <a
                      href="javascript:void(0)"
                      data-tip={`${props.total_bids_count}/5 professionals have responded!`}
                    >
                      
                      {" "}
                      {/* {props.show_status=='Hired'?
                      <img
                        src="assets/images/information.png"
                        alt="information"
                        className="img-fluid information"
                      />:null} */}
                    </a>
                  </a>
                </p>:null}
                {isHiredShow? props.show_status=='Hired'?
                <div className="message_status select_status">
                  <div className="form-group mb-0 mt-3">
                    <label htmlFor="gender" className="mr-2 pl-0">
                      <b>Status</b>
                    </label>
                    <select onChange={(event) => setCompleteLead(event.target.value)} className="status-drop-down">
                      <option value="0">{props.show_status}</option>
                      <option value="2">Completed</option>
                      <option value="4">Not proceeding</option>
                      <option value="3">Lost</option>
                    </select>
                  </div>
                </div>:null:null}
              </div>
            </div>
          </div>
        </div>
        <div className="col-xl-5 col-12 text-right">
          
          <div className="message_status select_status">
            {props.show_status!='Lost'?
            user.status!=0 ? (
            <a
              href="javascript:void(0)"
              className="theme-btn"
              onClick={() => handleMessage(user.id, props?.user_id, props?.id)}
            >
              MESSAGE
              {props.messageCount ? <sup>{props.messageCount}</sup> : null}
            </a> ) : (<p>
              Your account is suspended
            </p>) :""}
            {props.show_status!='Hired'?<div className="form-group mb-0 mt-3">
              <label htmlFor="gender" className="mr-2 pl-0">
                <b>Status</b>
              </label>
              <span className="status-name-design" style={{ width: "150px" }}>
                {props.show_status=='Purchased'?'Prospect':props.show_status}
              </span> 
            </div>:null}
          </div>
        </div>
      </div>
    </>
  );

  return (
    <>
      {!props.id ? (
        <LoadingSpinner loading={true} />
      ) : (
        <>
          <div className="leads-data">
            {props.isAccepted ? leadsAcceptedData : leadsMatchData}
            {props.isCollapse ? (
              <div style={{ margin: "16px 0 -16px 0" }}>
                <a
                  href="javascript:void(0)"
                  className="active see-more"
                  id={`btn-${props.id}-${collapseRandomId}`}
                  onClick={() => showDetails(`${props.id}-${collapseRandomId}`)}
                >
                  See more
                </a>
              </div>
            ) : null}

            <div
              id={`isCollapse-${props.id}-${collapseRandomId}`}
              style={{ display: props.isCollapse ? "none" : "" }}
            >
              <span className="border-bottom pt-1 mt-4 mb-5 pb-1"></span>
              <h3>DETAILS</h3>
              <AdviceDetails detailsOnly advice={props} />
            </div>
          </div>
          
          {props.last_chat?
          <div className="prt-btn">
              <a
                href="#"
                data-toggle="modal"
                data-target="#report_as_spam"
                className="theme-btn width-auto reportbtn"
                onClick={() => resetSpamForm(`report_as_spam`)}
              >Report</a>
              <span className="border-bottom pt-1 mt-4 mb-5 pb-1"></span>
                <h3 className="heading-leads font-size22">
                    RECENT MESSAGES 
                </h3>
                <div className="row mt-4">
                    <div className="col-12">
                        <div className="message_desc newchangenewass">
                            {props.last_chat.map(
                            (row: any, index: any) =>
                            // <p className={`${row.show_name!='You' ?( row.to_user_id_seen==0 ?"bold":""):""
                            //       }`}>
                            //     <span className="messtxt">{row.show_name}: {row.text!=null?row.text:row.attachment}</span>
                            //     <span>
                            //     {row.date_time}
                            //     </span>
                            // </p>
                            <MessageBox
                            position="left"
                            type={"text"}
                            text={
                              <Twemoji
                                svg
                                text={row.text}
                                className="emoji-custom"
                              />
                            }
                            status="sent"
                            date={new Date(row?.created_at)}
                          />
                            )}
                        </div>
                    </div>
                </div>

             </div>:''}
            <span className="border-bottom pt-1 mt-4 mb-5 pb-1"></span>   
            {props.is_accepted?
                 
            (userNotes?                                                      
            <div className="row">
                <div className="col-12 col-sm-7">
                    <h3 className="heading-leads font-size22">
                        NOTE TO SELF
                    </h3>
                </div>
                <div className="col-12 mt-4">
                    <div className="message_desc">
                    {userNotes.length ? (
                      userNotes.map((lead: any) => (
                        <p>
                            {moment(lead.created_at).format("ll")} - {lead.notes} 
                            <i
                              onClick={() => editNote(lead)}
                              className="fal fa-pencil mr-2"
                              style={{marginLeft: '10px'}}
                            ></i>
                        </p>
                      ))
                    ) : 
                      <p>
                            Notes allow you to keep a record of discussions held along next steps.                                                                        
                        </p>}
                    </div>
                          <div className="add-form">
                  <form onSubmit={handleAddNotes}>
                    <div className="row mb-3">
                      <div className="col-12">
                        <textarea
                          id="notes-textarea"
                          name="notes"
                          value={formState.notes}
                          onChange={handleNotesChange}
                        ></textarea>
                      </div>
                    </div>
                    <div ref={myRef} className="row">
                      <div className="col-12 text-right">
                        <button className="theme-btn text-uppercase">
                          {formState.id > 0 ? (
                            <>
                              <i className="far fa-pencil mr-2" />
                              Save
                            </>
                          ) : (
                            <>
                              <i className="far fa-plus mr-2" />
                              Add
                            </>
                          )}
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
                </div>
                
            </div>:'')
            :""}
          <ReportSpamModal
            lead={props}
          />

          <BidConfirmModal
            amount={getCostOfLeadSummary}
            handleBidAction={handleBidAction}
          />
          <SendMessageModal {...messageData} />
        </>
      )}
      <ReactTooltip />
    </>
  );
};

export default MortgageCardDetails;
