import React, { useEffect, useState } from "react";
import { useToasts } from "react-toast-notifications";
import EditLocationModal from "../common/modals/EditLocationModal";
import {
  getAdvisorLocationPreference,
  updateAdvisorLocationPreference,
} from "../common/service";
import $ from "jquery";
import "bootstrap";

const initialState = {
  post_code: "",
  miles: "",
};
let locationPre = "Within RANGE km of POSTCODE";

const AdviserPrefrencesLocation = () => {
  const { addToast } = useToasts();
  const [locationInfo, setLocationInfo] = useState("");
  const [formState, setFormState] = useState(initialState);

  useEffect(() => {
    if (formState.miles !== "" && formState.post_code !== "") {
      const mapObj: any = {
        RANGE: formState.miles,
        POSTCODE: formState.post_code,
      };
      if(formState.miles=='-1'){
        setLocationInfo("Across all postcodes");
      }else{
        setLocationInfo(
          locationPre.replace(
            /\b(?:RANGE|POSTCODE)\b/,
            (matched) => mapObj[matched]
          )
        );
      }
      
    }
  }, [formState.miles, formState.post_code]);

  useEffect(() => {
    getAdvisorLocationPreferenceCall();
  }, []);

  const getAdvisorLocationPreferenceCall = async () => {
    const res = await getAdvisorLocationPreference();
    if (res.data) {
      setFormState(res.data);
    }
  };

  const handlePageState = (newStates: any) => {
    setFormState((prevStates: any) => {
      return {
        ...prevStates,
        ...newStates,
      };
    });
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    const res = await updateAdvisorLocationPreference(formState);
    if (res.status) {
      $("#edit_location").modal("hide");
      $("body").removeClass("modal-open");
      $(".modal-backdrop").remove();
      addToast(res.message, { appearance: "success" });
    } else {
      addToast(res.message, { appearance: "error" });
    }
  };

  return (
    <>
      <div className="profile-datas">
        <h5 className="mb-3">My Location</h5>
        <p>
          Tell us the area you cover so we can show you leads for your location.
          If you cover all of the UK then select 'National'
        </p>
      </div>

      <form>
        <div className="team-memebers-data preference-customer preference-location">
          <div className="member-data m-0">
            <div className="form-group">
              <div className="input-edits">
                <input
                  type="text"
                  name="edit_code"
                  id="edit_code"
                  value={locationInfo}
                  placeholder="Edit"
                  onFocus={(e) => (e.target.placeholder = "")}
                  onBlur={(e) => (e.target.placeholder = "Edit")}
                  readOnly
                />
                <a
                  href="javascript:void(0)"
                  data-toggle="modal"
                  data-target="#edit_location"
                  className="edit-btn"
                >
                  Edit
                </a>
              </div>
            </div>
            <div className="buttons text-center mt-lg-5 mt-4 pt-lg-4">
              <button
                type="button"
                className="theme-btn text-uppercase mr-md-3 mr-1 mb-md-0 mb-3"
              >
                Save
              </button>
              <button
                type="button"
                className="theme-btn-border text-uppercase"
                data-dismiss="modal"
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </form>
      <EditLocationModal
        locationInfo={locationInfo}
        formState={formState}
        handlePageState={handlePageState}
        onSubmit={handleSubmit}
      />
    </>
  );
};

export default AdviserPrefrencesLocation;
