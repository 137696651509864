import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import LoadingSpinner from "../../components/common/LoadingSpinner";
import LeaveReviewWithoutLoginModal from "../../components/common/modals/advisorModals/LeaveReviewWithoutLoginModal";
import { getAdvisorDetails } from "../../components/common/service";
import $ from "jquery";
import "bootstrap";

const GiveReviewToAdviser = () => {
  const urlParams: { id: any } = useParams();
  const { id } = urlParams;
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [profileData, setProfileData] = useState<any>({});

  useEffect(() => {
    getAdvisorDetailsCall(id);
  }, [id]);

  const getAdvisorDetailsCall = async (slug: string) => {
    setIsLoading(true);
    const res = await getAdvisorDetails(slug);
    if (res.status) {
      setProfileData(res.data);
      $("#leaveReviewModal").modal("show");
    }
    setIsLoading(false);
  };

  return (
    <>
      <main className="dashboard">
        <section className="page-title">
          <div className="container">
            <div className="row">
              <div className="col-md-12 mb-5 mb-md-0"></div>
            </div>
          </div>
        </section>

        <section className="recent-sec advice-area-sec profile-sec advice-lead-matches advice-matches-datas new-advice-matches pb-5">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-12">
                <div className="recent-box1">
                  <div className="row align-items-center justify-content-center mb-4">
                    <div className="col-lg-12">
                      <div className="row">
                        <div className="col-lg-12" style={{ height: "500px" }}>
                          <LoadingSpinner loading={isLoading} />
                          {profileData && profileData.id ? (
                            <LeaveReviewWithoutLoginModal
                              advisor={profileData}
                            />
                          ) : null}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  );
};

export default GiveReviewToAdviser;
