import axios, { AxiosResponse } from "axios";
import { JWT_TOKEN } from "../Constants";

export const sendFileWithAuth = async (
  uri: string,
  method?: any,
  data?: any
): Promise<any> => {
  const urlPrefix: string = process.env.REACT_APP_BACKEND_URL || "";

  const response: AxiosResponse<any> = await axios({
    method,
    url: `${urlPrefix}/${uri}`,
    headers: {
      Authorization: `Bearer ${localStorage.getItem(JWT_TOKEN)}`,
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "multipart/form-data",
    },
    data,
  });
  if (response.status < 300 && response.status >= 200) {
    return response.data;
  } else if (response.status === 401) {
    localStorage.clear();
    window.location.href = "/";
  }

  throw response;
};

export const fetchWithAuth = async (
  uri: string,
  method?: any,
  data?: any
): Promise<any> => {
  const urlPrefix: string = process.env.REACT_APP_BACKEND_URL || "";

  const response: AxiosResponse<any> = await axios({
    method,
    url: `${urlPrefix}/${uri}`,
    headers: {
      Authorization: `Bearer ${localStorage.getItem(JWT_TOKEN)}`,
      "Access-Control-Allow-Origin": "*",
    },
    data,
  });
  if (response.status < 300 && response.status >= 200) {
    return response.data;
  } else if (response.status === 401) {
    localStorage.clear();
    window.location.href = "/";
  }

  throw response;
};

export const fetch = async (
  uri: string,
  method: any,
  data?: any,
  Credential?: boolean
): Promise<any> => {
  const urlPrefix: string = process.env.REACT_APP_BACKEND_URL || "";
  const response: AxiosResponse<any> = await axios({
    method,
    withCredentials: Credential,
    url: `${urlPrefix}/${uri}`,
    headers: {
      'Access-Control-Allow-Origin' : '*'
    },
    data,
  });
  if (response.status < 300 && response.status >= 200) {
    return response.data;
  } else if (response.status === 401) {
    localStorage.clear();
    window.location.href = "/";
  }
  throw response;
};
