import React, { useState, useEffect } from "react";
import { stripeSaveCard } from "../service";
import { useToasts } from "react-toast-notifications";
import $ from "jquery";
import "bootstrap";
import InlineAlert from "../InlineAlert";

const initialState = {
  cardNumber: "",
  expiryDate: "",
  cvc: "",
  isError: false,
  responseMessage: "",
};

const AddNewCards = (props: any) => {
  const { addToast } = useToasts();
  const [formState, setFormState] = useState(initialState);

  const handlePageState = (newStates: any) => {
    setFormState((prevStates) => {
      return {
        ...prevStates,
        ...newStates,
      };
    });
  };

  const handleChange = (event: { target: HTMLInputElement } | any) => {
    const { name, value } = event.target;
    handlePageState({ [name]: value });
    event.preventDefault();
  };
  const makeSpaceInCard = (event: { target: HTMLInputElement } | any) =>{   
    setTimeout(() => {
      // var val = $(".cc-number").val();       

      var newval = '';         
      var val = event.target.value.replace(/\s/g, ''); 


    // if value is not blank, then test the regex

    for(var i = 0; i < val.length; i++) {             
      if(i%4 == 0 && i > 0) newval = newval.concat(' ');             
      newval = newval.concat(val[i]);         
    }        

    // format in same input field 
    $(".cc-number").val(newval); 
    },50);     
      
  }

  const onSubmitHandler = async (e: any) => {
    e.preventDefault();
    const expiryDates = formState.expiryDate.split("-");
    const postedData = {
      account_number: formState.cardNumber,
      exp_year: expiryDates[0],
      exp_month: expiryDates[1],
      cvc: formState.cvc,
    };
    const res = await stripeSaveCard(postedData);
    if (res.status) {
      handlePageState({ responseMessage: res.message, isError: false });
      setTimeout(() => {
        $("#add_new_card").modal("hide");
        $("body").removeClass("modal-open");
        $(".modal-backdrop").remove();
        handlePageState({ ...initialState });
        props.refetch();
      }, 1000);
    } else {
      handlePageState({ responseMessage: res.message, isError: true });
    }
  };

  return (
    <>
      <div
        className="mail-popup modal fade popup-model nutshell_popup about_company_popup firstmessage_popup"
        id="add_new_card"
        data-backdrop="static"
        data-keyboard="false"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-body">
              <div className="row">
                <div className="col-md-12">
                  <div className="close-btn" style={{ width: "100%" }}>
                    <a
                      href="javascript:void(0)"
                      className="close-btn"
                      data-dismiss="modal"
                      aria-label="Close"
                      style={{ float: "right" }}
                    >
                      <i className="fa fa-close" aria-hidden="true"></i>
                    </a>
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="heading text-center mb-2">
                    <h4 className="text-uppercase theme-color">Add new card</h4>
                    <span></span>
                  </div>
                  <div className="mb-4 mt-lg-4 pt-lg-2 mt-3">
                    <form onSubmit={onSubmitHandler}>
                    {formState.responseMessage && (
                      <InlineAlert
                        isError={formState.isError}
                        message={formState.responseMessage}
                      />
                    )}
                      <div id="credit" className="row">
                        <div className="col-md-12">
                          <div className="form-group">
                            <label>Card Number</label>
                            <input
                              name="cardNumber"
                              value={formState.cardNumber}
                              onChange={handleChange}
                              className="cc-number"
                              type="tel"
                              // pattern="\d*"
                              minLength={16}
                              maxLength={19}
                              placeholder="**** **** **** ****"
                              onFocus={(e) => (e.target.placeholder = "")}
                              onBlur={(e) =>
                                (e.target.placeholder = "**** **** **** ****")
                              }
                              onKeyUp={makeSpaceInCard}
                              required
                            />
                          </div>
                        </div>
                        <div className="col-md-7">
                          <div className="form-group">
                            <label>Expiry Date</label>
                            <input
                              name="expiryDate"
                              value={formState.expiryDate}
                              onChange={handleChange}
                              className="cc-expires"
                              type="month"
                              min="2021-07"
                              max="2031-07"
                              placeholder="MM / YYYY"
                              onFocus={(e) => (e.target.placeholder = "")}
                              onBlur={(e) =>
                                (e.target.placeholder = "MM / YYYY")
                              }
                              required
                            />
                          </div>
                        </div>
                        <div className="col-md-5">
                          <div className="form-group">
                            <label>CVC</label>
                            <input
                              name="cvc"
                              value={formState.cvc}
                              onChange={handleChange}
                              className="cc-cvc"
                              type="tel"
                              pattern="\d*"
                              minLength={3}
                              maxLength={4}
                              placeholder="CVC"
                              onFocus={(e) => (e.target.placeholder = "")}
                              onBlur={(e) => (e.target.placeholder = "CVC")}
                              required
                            />
                          </div>
                        </div>
                        <div className="col-md-12 text-center mt-lg-4 mt-0">
                          <button
                            type="button"
                            className="cancel-btn text-uppercase mr-md-3 mr-1 mb-md-0 mb-3"
                            data-dismiss="modal"
                          >
                            Cancel
                          </button>
                          <button
                            type="submit"
                            className="theme-btn text-uppercase"
                          >
                            Add
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddNewCards;
