import React from "react";
import { DEFAULT_CURRENCY, GENDER_ARR } from "../../Constants";
import { capitalize, numberWithCommas, prettyTime } from "../../utility/commonUtils";
// import moment from 'moment';



const AdviceDetails = (props: any) => {
  const getDetails = () => {
    return (
      <>
        <div className="row b-line mb-3">
          <div className="col-lg-4 col-md-3 mb-1 mb-md-0">
            <span className="need-title">Service</span>
          </div>
          <div className="col-lg-8 col-md-9">
            <span className="need-value">Mortage Adviser</span>
          </div>
        </div>
        <div className="row b-line mb-3">
          <div className="col-lg-4 col-md-3 mb-1 mb-md-0">
            <span className="need-title">Request</span>
          </div>
          <div className="col-lg-8 col-md-9">
            <span className="need-value">{props.advice.created_at_need}</span>
          </div>
        </div>
        <div className="row b-line mb-3">
          <div className="col-lg-4 col-md-3 mb-1 mb-md-0">
            <span className="need-title">Mortgage</span>
          </div>
          <div className="col-lg-8 col-md-9">
            <span className="need-value">
              {props.advice.size_want_currency || DEFAULT_CURRENCY}
              {numberWithCommas(props.advice.size_want)}
            </span>
          </div>
        </div>
        <div className="row b-line mb-3">
          <div className="col-lg-4 col-md-3 mb-1 mb-md-0">
            <span className="need-title">Property value</span>
          </div>
          <div className="col-lg-8 col-md-9">
            <span className="need-value">
              {props.advice.property_currency || DEFAULT_CURRENCY}
              {numberWithCommas(props.advice.property)}
            </span>
          </div>
        </div>
        <div className="row b-line mb-3">
          <div className="col-lg-4 col-md-3 mb-1 mb-md-0">
            <span className="need-title">Combined income</span>
          </div>
          <div className="col-lg-8 col-md-9">
            <span className="need-value">
              {props.advice.combined_income_currency || DEFAULT_CURRENCY}
              {numberWithCommas(props.advice.combined_income)}
            </span>
          </div>
        </div>
        <div className="row b-line mb-3">
          <div className="col-lg-4 col-md-3 mb-1 mb-md-0">
            <span className="need-title">Mortgage need</span>
          </div>
          <div className="col-lg-8 col-md-9">
            <span className="need-value">
              {props.advice.service? (capitalize(props.advice.service.name)):""}
            </span>
          </div>
        </div>
        <div className="row b-line mb-3">
          <div className="col-lg-4 col-md-3 mb-1 mb-md-0">
            <span className="need-title">Additional detail</span>
          </div>
          <div className="col-lg-8 col-md-9">
            <span className="need-value">{props.advice.description}</span>
            <div className="row mt-3">
              {props.advice.self_employed === 1 && (
                <div className="col-xl-12 col-md-12">
                  <div className="form-group checkboxes-data mb-0">
                    <input
                      type="checkbox"
                      id="self_employed"
                      checked={props.advice.self_employed === 1}
                      disabled
                    />
                    <label htmlFor="self_employed">Self Employed</label>
                  </div>
                </div>
              )}
              {props.advice.non_uk_citizen === 1 && (
                <div className="col-xl-12 col-md-12">
                  <div className="form-group checkboxes-data mb-0">
                    <input
                      type="checkbox"
                      id="non_uk_citizen"
                      checked={props.advice.non_uk_citizen === 1}
                      disabled
                    />
                    <label htmlFor="non_uk_citizen">Non-UK Citizen</label>
                  </div>
                </div>
              )}
              {props.advice.adverse_credit === 1 && (
                <div className="col-xl-12 col-md-12">
                  <div className="form-group checkboxes-data mb-0">
                    <input
                      type="checkbox"
                      id="adverse_credit"
                      checked={props.advice.adverse_credit === 1}
                      disabled
                    />
                    <label htmlFor="adverse_credit">Adverse Credit</label>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="row b-line mb-3">
          <div className="col-lg-4 col-md-3 mb-1 mb-md-0">
            <span className="need-title">How soon?</span>
          </div>
          <div className="col-lg-8 col-md-9">
            <span className="need-value">
              {capitalize(props.advice.request_time)}
            </span>
          </div>
        </div>
        <div className="row b-line mb-3">
          <div className="col-lg-4 col-md-3 mb-1 mb-md-0">
            <span className="need-title">Preferences</span>
          </div>
        </div>
        <div className="row b-line mb-3 margin-left-15">
          <div className="col-lg-4 col-md-3 mb-1 mb-md-0 mleft-padd0">
            <span className="font-weight-normal">Contact</span>
          </div>
          <div className="col-lg-8 col-md-9 padding-left-0" >
            <div className="row">

              {props.advice.contact_preference_face_to_face === 1 && (
                <div className="col-12">
                  <div className="form-group checkboxes-data mb-0">
                    <input
                      type="checkbox"
                      id="evening"
                      checked={
                        props.advice.contact_preference_face_to_face === 1
                      }
                      disabled
                    />
                    <label htmlFor="evening">Face to Face</label>
                  </div>
                </div>
              )}

              {props.advice.contact_preference_online === 1 && (
                <div className="col-12">
                  <div className="form-group checkboxes-data mb-0">
                    <input
                      type="checkbox"
                      id="evening"
                      checked={props.advice.contact_preference_online === 1}
                      disabled
                    />
                    <label htmlFor="evening">Online</label>
                  </div>
                </div>
              )}

              {props.advice.contact_preference_telephone === 1 && (
                <div className="col-12">
                  <div className="form-group checkboxes-data mb-0">
                    <input
                      type="checkbox"
                      id="evening"
                      checked={props.advice.contact_preference_telephone === 1}
                      disabled
                    />
                    <label htmlFor="evening">Telephone</label>
                  </div>
                </div>
              )}

              {props.advice.contact_preference_evening_weekend === 1 && (
                <div className="col-12">
                  <div className="form-group checkboxes-data mb-0">
                    <input
                      type="checkbox"
                      id="evening"
                      checked={
                        props.advice.contact_preference_evening_weekend === 1
                      }
                      disabled
                    />
                    <label htmlFor="evening">Evening / Weekend</label>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
        {props.advice.advisor_preference_local === 1 && (
          <div className="row b-line mb-3 margin-left-15">
          <div className="col-lg-4 col-md-3 mb-1 mb-md-0 mleft-padd0">
            <span className="font-weight-normal">Adviser Location</span>
          </div>
          <div className="col-lg-8 col-md-9 padding-left-0">
            <div className="row">
              {props.advice.contact_preference_face_to_face === 1 && (
                <div className="col-12">
                  <div className="form-group checkboxes-data mb-0">
                    <input
                      type="checkbox"
                      id="local"
                      checked={props.advice.advisor_preference_local === 1}
                      disabled
                    />
                    <label htmlFor="evening">Local</label>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>)}
        <div className="row b-line mb-3 margin-left-15">
          <div className="col-lg-4 col-md-3 mb-1 mb-md-0 mleft-padd0">
            <span className="font-weight-normal">Adviser Gender</span>
          </div>
          <div className="col-lg-8 col-md-9 padding-left-0">
            <div className="row">

              {props.advice.contact_preference_face_to_face === 1 && (
                <div className="col-12">
                  <div className="form-group checkboxes-data mb-0">
                    <input
                      type="checkbox"
                      id="gender"
                      checked
                      disabled
                    />
                    <label htmlFor="evening">{GENDER_ARR[props.advice.advisor_preference_gender]}</label>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="row b-line mb-3 margin-left-15">
          <div className="col-lg-4 col-md-3 mb-1 mb-md-0 mleft-padd0">
            <span className="font-weight-normal">Adviser Language</span>
          </div>
          <div className="col-lg-8 col-md-9 padding-left-0">
            <div className="row">
              <div className="col-12">
                <div className="form-group checkboxes-data mb-0">
                  <input
                    type="checkbox"
                    id="language"
                    checked
                    disabled
                  />
                  <label htmlFor="language">{props.advice.advisor_preference_language || "English"}</label>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row b-line mb-3 margin-left-15">
          <div className="col-lg-4 col-md-3 mb-1 mb-md-0">
            <span className="font-weight-normal">Fees</span>
          </div>
          <div className="col-lg-8 col-md-9 padding-left-0">
            <div className="row">
              <div className="col-12">
                <div className="form-group checkboxes-data mb-0">
                  <input
                    type="checkbox"
                    id="fees"
                    checked
                    disabled
                  />
                  <label htmlFor="fees">{props.advice.fees_preference === 1 ?'Fees Considered':'No Fees'}</label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };

  return (
    <>
      {props.detailsOnly ? (
        getDetails()
      ) : (
        <div className="col-lg-12 mb-5">
          <div className="recent-box need-details">
            <div className="row justify-content-center">
              <div className="col-md-11">
                <div className="row mb-md-3 mb-0">
                  <div className="col-md-12">
                    <h2 className="mb-3">My mortgage need</h2>
                  </div>
                </div>
                {getDetails()}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default AdviceDetails;
