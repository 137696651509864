import React, { useContext } from "react";
import { Route, Redirect } from "react-router-dom";
import { AuthContext } from "../shared/context/auth-context";

const PublicRoute: React.FC<{
  component: React.FC;
  path: string;
  exact: boolean;
  restricted?: boolean;
}> = (props) => {
  const { isLoggedIn } = useContext(AuthContext);

  return isLoggedIn && props.restricted ? (
    <Redirect to="/" />
  ) : (
    <Route path={props.path} exact={props.exact} component={props.component} />
  );
};
export default PublicRoute;
