import moment from "moment";
import { ADVISOR_DEFAULT_IMAGE, TIME_FORMAT } from "../Constants";

export const capitalize = (string: string): string => {
  return string ? string[0]?.toUpperCase() + string.slice(1) : string;
};

export const validateEmail = (email: string) => {
  return true;
};

export const prettyTime = (
  time: string,
  showSeconds = false,
  showDate = false,
  dateOnly = false
): string => {
  if (
    localStorage.getItem(TIME_FORMAT) === null ||
    localStorage.getItem(TIME_FORMAT) === "24"
  ) {
    if (dateOnly) return moment(time).format("DD/MM/YYYY");
    else if (showSeconds && showDate)
      return moment(time).format("DD-MMM-YYYY HH:mm:ss");
    else if (showSeconds) return moment(time).format("HH:mm:ss");
    else if (showDate) return moment(time).format("DD-MMM-YYYY HH:mm");
    else return moment(time).format("HH:mm");
  } else {
    if (showSeconds && showDate)
      return moment(time).format("DD-MMM-YYYY hh:mm:ss a");
    else if (showSeconds) return moment(time).format("hh:mm:ss a");
    else if (showDate) return moment(time).format("DD-MMM-YYYY hh:mm a");
    else return moment(time).format("hh:mm a");
  }
};

export const prettyTimeAgo = (time: any): string => {
  return moment(time).fromNow();
};

// export const prettyTimeAgo = (time: any): string => {
//   return moment(time).fromNow();
// };

export const convertMinsToHrsMins = (minutes: string | number = 0): string => {
  minutes = typeof minutes === "number" ? minutes : parseInt(minutes);
  let h = Math.floor(minutes / 60);
  let m = minutes % 60;
  const hours = h < 10 ? "0" + h : h;
  const min = m < 10 ? "0" + m : m;
  return hours + ":" + min;
};

export const numberWithCommas = function (x: string): string {
  if (x) {
    //return x.toString().replace(/\B(?=(\d{3})+(?!\d))/, ",");
    return Number(x).toLocaleString();//x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }
  return "";
};

export const numberWithOutCommas = function (x: any): string {
  if (x) {
    return x.toString().replace(/,/g, "");
  }
  return "";
};

export const findScrollHeight = (windowWidth: any) => {
  if (windowWidth < 720) return "100vh - 184px";
  if (windowWidth <= 1044) return "100vh - 120px";
  return "100vh - 72px";
};

export const getCurrentDateTime = () => {
  let ndate = new Date();
  let greet;
  let datetimestr;
  let hr = ndate.getHours();
  if (hr < 12) {
    greet = "Good Morning";
  } else if (hr >= 12 && hr <= 17) {
    greet = "Good Afternoon";
  } else if (hr >= 17 && hr <= 24) greet = "Good Evening";
  datetimestr = moment(ndate).format("dddd, D MMMM h:mma");
  return { greet, datetimestr };
};

export const getStarCls = (match: number, avarageRating: number) => {
  return match <= Math.round(avarageRating)
    ? "fa fa-star checked"
    : "fa fa-star";
};

export const getAdviserProfileImage = (advisor: any) => {
  const backendStorageUrl =
    process.env.REACT_APP_BACKEND_URL_STORAGE_ADVISOR || "";
  return advisor?.image && advisor?.image != ""
    ? `${backendStorageUrl}${advisor.image}`
    : `${backendStorageUrl}${advisor?.company_logo}`
    ? advisor?.company_logo
      ? `${backendStorageUrl}${advisor?.company_logo}`
      : ADVISOR_DEFAULT_IMAGE
    : ADVISOR_DEFAULT_IMAGE;
};
