import React, { useEffect, useState } from "react";
import { useToasts } from "react-toast-notifications";
import {
  DEFAULT_CURRENCY,
  MORTGAGE_ADVICE_ARR,
  MORTGAGE_TIME_ARR,
} from "../../../../Constants";
import useLoggedInUserData from "../../../../utility/useLoggedInUserData";
import { getServiceType, makeEnquiry } from "../../service";
import $ from "jquery";
import "bootstrap";
import NumberFormat from "react-number-format";
import {
  numberWithCommas,
  numberWithOutCommas,
} from "../../../../utility/commonUtils";

const initialState = {
  need_advice: "remortgage",
  how_soon: "as soon as possible",
  mortgage_required: "",
  prop_value: "",
  combined_income: "",
  anything_else: "",
  match_me: 0,
  advisor_id: "", //Advisor profile Id
  user_id: "", //Logged in User Id
  name: "", //Logged in User name
  email: "", //Logged in User email
  post_code: "", //Logged in User post code
};

const initialErrorState = {
  prop_value: "",
  mortgage_required: "",
  combined_income: "",
};

const MakeAnEnquiryModal = (props: any) => {
  const user = useLoggedInUserData();
  const { addToast } = useToasts();
  const [serviceType, setService] = useState<any>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [formState, setFormState] = useState({
    ...initialState,
    user_id: user.id,
    name: user.name || "",
    email: user.email || "",
    post_code: user.post_code || "",
  });

  const [errorState, setErrorState] = useState(initialErrorState);

  useEffect(() => {
    ContentPagesCall();
    handlePageState({
      advisor_id: props.advisor.advisorId,
    });
  }, [props.advisor.advisorId]);

  const handlePageState = (newStates: any) => {
    setFormState((prevStates: any) => {
      return {
        ...prevStates,
        ...newStates,
      };
    });
  };
  const ContentPagesCall = async () => {
    setIsLoading(true);
    const res = await getServiceType();
    if (res?.success || res.status) {
      setService(res.data);
    }
    setIsLoading(false);
  };

  const handleChangeCheckbox = (event: { target: HTMLInputElement } | any) => {
    const { name, checked } = event.target;
    handlePageState({ [name]: +checked });
  };

  const handleChange = (event: { target: HTMLInputElement } | any) => {
    const { name, value } = event.target;
    if (["prop_value", "mortgage_required", "combined_income"].includes(name)) {
      const newValue = numberWithOutCommas(value.replace(/[^\d,]/g, ""));
      handlePageState({ [name]: newValue });
    } else {
      handlePageState({ [name]: value });
    }
    event.preventDefault();
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    setErrorState(initialErrorState);
    if (formState.prop_value === "") {
      setErrorState((prevState) => {
        return {
          ...prevState,
          prop_value: "Please fill required field.",
        };
      });
      return;
    } else if (
      Number(numberWithOutCommas(formState.prop_value)) < 10000 ||
      Number(numberWithOutCommas(formState.prop_value)) > 10000000
    ) {
      setErrorState((prevState) => {
        return {
          ...prevState,
          prop_value: `We are unable to help you with a property value of ${DEFAULT_CURRENCY}${formState.prop_value}`,
        };
      });
      return;
    }
    if (formState.mortgage_required === "") {
      setErrorState((prevState) => {
        return {
          ...prevState,
          mortgage_required: "Please fill required field.",
        };
      });
      return;
    } else if (
      Number(numberWithOutCommas(formState.mortgage_required)) < 10000 ||
      Number(numberWithOutCommas(formState.mortgage_required)) > 5000000
    ) {
      setErrorState((prevState) => {
        return {
          ...prevState,
          mortgage_required: `We are unable to help you with a mortgage need of ${DEFAULT_CURRENCY}${formState.mortgage_required}`,
        };
      });
      return;
    }
    if (
      Number(
        Number(numberWithOutCommas(formState.mortgage_required)) /
          Number(numberWithOutCommas(formState.prop_value))
      ) >= 1.5
    ) {
      setErrorState((prevState) => {
        return {
          ...prevState,
          mortgage_required: `We are unable to help you as your loan to value exceeds limits`,
        };
      });
      return;
    }
    if (formState.combined_income === "") {
      setErrorState((prevState) => {
        return {
          ...prevState,
          combined_income: "Please fill required field.",
        };
      });
      return;
    } else if (
      Number(numberWithOutCommas(formState.combined_income)) < 0 ||
      Number(numberWithOutCommas(formState.combined_income)) > 1000000
    ) {
      setErrorState((prevState) => {
        return {
          ...prevState,
          combined_income: `We are unable to help you with a combined income of ${DEFAULT_CURRENCY}${formState.combined_income}`,
        };
      });
      return;
    }

    if (
      Number(numberWithOutCommas(formState.combined_income)) != 0 &&
      Number(
        Number(numberWithOutCommas(formState.mortgage_required)) /
          Number(numberWithOutCommas(formState.combined_income))
      ) >= 8
    ) {
      setErrorState((prevState) => {
        return {
          ...prevState,
          combined_income: `We are unable to help you as your loan to income exceeds limits`,
        };
      });
      return;
    }

    const res = await makeEnquiry(formState);
    if (res.status) {
      setTimeout(() => {
        $(".popup-model").modal("hide");
        $("body").removeClass("modal-open");
        $(".modal-backdrop").remove();
        handlePageState({ ...initialState });
      }, 1000);
      addToast(res.message, { appearance: "success" });
    } else {
      addToast(res.message, { appearance: "error" });
    }
  };

  return (
    <>
      <div className="mail-popup modal fade popup-model" id={`direcory_enquiry_${props.advisor.advisorId}`}>
        <div className="modal-dialog modal-lg directory_popup">
          <div className="modal-content">
            <div className="modal-body">
              <div className="row">
                <div className="col-md-12">
                  <div className="heading text-left mb-4">
                    <h6>Send your enquiry to</h6>
                    <h4 className="text-uppercase">
                      {props.advisor.display_name}, {props.advisor.company_name}
                    </h4>
                    <p>
                      Once you've sent your enquiry you can expect a response
                      within 24 hours. We can also optionally match you to 4
                      additional advisers to give you maximum choice.
                    </p>
                  </div>
                  <div className="mt-lg-2 mt-2">
                    <form onSubmit={handleSubmit}>
                      <div className="row">
                        <div className="form-group col-md-6 align-items-center">
                          <p className="m-2">Your name</p>
                          <input
                            type="text"
                            className="mr-3 mb-0"
                            name="name"
                            value={formState.name}
                            onChange={handleChange}
                            placeholder="Your name here"
                            required
                          />
                        </div>
                        <div className="form-group col-md-6 align-items-center mb-0">
                          <p className="m-2">Email</p>
                          <input
                            type="email"
                            className="mr-3 mb-0"
                            name="email"
                            value={formState.email}
                            onChange={handleChange}
                            placeholder="Your email here"
                            required
                          />
                        </div>
                        <div className="form-group col-md-6 align-items-center">
                          <p className="m-2">Area of advice</p>
                          <select
                            name="need_advice"
                            value={formState.need_advice}
                            onChange={handleChange}
                          >
                            <option value=''>Select Advice Area</option>
                            {serviceType.map((adv_area: any) => {
                              return (
                                <>
                                  <option
                                    key={adv_area.id}
                                    value={adv_area.id}
                                  >
                                    {adv_area.name}
                                  </option>
                                </>
                              );
                            })}
                          </select>
                          <i className="fa fa-chevron-down"></i>
                        </div>
                        <div className="form-group col-md-6 align-items-center">
                          <p className="m-2">Approx property value</p>
                          <NumberFormat
                            thousandSeparator={true}
                            name="prop_value"
                            id="prop_value"
                            placeholder="Your property value here"
                            onFocus={(e) => (e.target.placeholder = "")}
                            onBlur={(e) =>
                              (e.target.placeholder =
                                "Your property value here")
                            }
                            value={numberWithCommas(formState.prop_value)}
                            onChange={handleChange}
                          />
                          {errorState.prop_value ? (
                            <small className="formElementTxtError">
                              {errorState.prop_value}
                            </small>
                          ) : null}
                        </div>
                        <div className="form-group col-md-6 align-items-center">
                          <p className="m-2">Mortgage required</p>
                          <NumberFormat
                            thousandSeparator={true}
                            name="mortgage_required"
                            id="mortgage_required"
                            placeholder="What size mortgage do you want?"
                            onFocus={(e) => (e.target.placeholder = "")}
                            onBlur={(e) =>
                              (e.target.placeholder =
                                "What size mortgage do you want?")
                            }
                            value={numberWithCommas(
                              formState.mortgage_required
                            )}
                            onChange={handleChange}
                          />
                          {errorState.mortgage_required ? (
                            <small className="formElementTxtError">
                              {errorState.mortgage_required}
                            </small>
                          ) : null}
                        </div>
                        <div className="form-group col-md-6 align-items-center">
                          <p className="m-2">Combined income</p>
                          <NumberFormat
                            thousandSeparator={true}
                            name="combined_income"
                            id="combined_income"
                            placeholder="750,000"
                            onFocus={(e) => (e.target.placeholder = "")}
                            onBlur={(e) => (e.target.placeholder = "750,000")}
                            value={numberWithCommas(formState.combined_income)}
                            onChange={handleChange}
                          />
                          {errorState.combined_income ? (
                            <small className="formElementTxtError">
                              {errorState.combined_income}
                            </small>
                          ) : null}
                        </div>
                        <div className="form-group col-md-6 align-items-center">
                          <p className="m-2">
                            How soon do you need the mortgage?
                          </p>
                          <select
                            className="mortgage_need"
                            name="how_soon"
                            value={formState.how_soon}
                            onChange={handleChange}
                          >
                            {MORTGAGE_TIME_ARR.map((adv_time: any) => {
                              return (
                                <>
                                  <option
                                    key={adv_time.key}
                                    value={adv_time.value}
                                  >
                                    {adv_time.name}
                                  </option>
                                </>
                              );
                            })}
                          </select>
                          <i className="fa fa-chevron-down"></i>
                        </div>
                        <div className="form-group col-md-6 align-items-center">
                          <p className="m-2">Postcode</p>
                          <input
                            type="text"
                            className="mr-3 mb-0"
                            name="post_code"
                            value={formState.post_code}
                            onChange={handleChange}
                            placeholder="Your postcode here"
                            required
                          />
                        </div>
                        <div className="form-group col-md-12 align-items-center mb-0">
                          <p className="m-2">
                            Is there anything else you feel is important?
                          </p>
                          <textarea
                            id="important_desc"
                            cols={30}
                            rows={10}
                            name="anything_else"
                            value={formState.anything_else}
                            onChange={handleChange}
                            placeholder="For example, help a child to buy a property, buy a second home, rent out current house"
                          ></textarea>
                        </div>
                        <div className="form-group checkboxes-data col-md-12 align-items-center mb-0">
                          <input
                            type="checkbox"
                            className="mr-3 mb-0"
                            id="enquiry_match_mortgage"
                            name="match_me"
                            value={1}
                            onChange={handleChangeCheckbox}
                            checked={formState.match_me === 1}
                          />
                          <label htmlFor="enquiry_match_mortgage">
                            Match me to 4 additional mortgage advisers
                          </label>
                        </div>
                        <div className="form-group col-md-12 align-items-center">
                          <p>
                            By submitting an enquiry you agree to{" "}
                            <a href="javascript:void(0)">
                              terms and conditions
                            </a>{" "}
                            and <a href="javascript:void(0)">privacy policy</a>
                          </p>
                        </div>
                      </div>
                      <div className="row align-items-center text-center">
                        <div className="col-6 text-right btns">
                          <button
                            type="submit"
                            className="theme-btn text-uppercase"
                          >
                            Send
                          </button>
                        </div>
                        <div className="col-6 text-left btns">
                          <button
                            type="button"
                            className="theme-border-btn text-uppercase"
                            data-dismiss="modal"
                          >
                            cancel
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default MakeAnEnquiryModal;
