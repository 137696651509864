import React from "react";
import $ from "jquery";
import "bootstrap";
import {
  capitalize,
  getAdviserProfileImage,
  prettyTimeAgo,
} from "../../utility/commonUtils";
import useLoggedInUserData from "../../utility/useLoggedInUserData";
import { selectOrDeclineOffer, startChat } from "../common/service";
import { ADVISOR_DEFAULT_IMAGE, BID_STATUS_ARR } from "../../Constants";
import { useToasts } from "react-toast-notifications";
import { Link } from "react-router-dom";

const AdvisorBidCard = (props: any) => {
  const user = useLoggedInUserData();
  const { addToast } = useToasts();
  const handleMessage = async (
    from_user_id: number,
    to_user_id: number,
    advicearea_id: number
  ) => {
    const res = await startChat({
      from_user_id: from_user_id,
      to_user_id: to_user_id,
      advicearea_id: advicearea_id,
    });
    if (res.status) {
      const channel = res.channel;
      props.setMessageData({
        from_user_id,
        to_user_id,
        channel,
        data: res.data,
        bid: props.bid,
      });
      $("#message_data").modal("show");
    }
  };

  const handleOffer = async (bid_id: number, status: number) => {
    const res = await selectOrDeclineOffer(bid_id, status);
    if (res.status) {
      addToast(res.message, { appearance: "success" });
      window.location.reload();
    } else {
      addToast(res.message, { appearance: "error" });
    }
  };

  let lastActivity: any = props.bid.last_activity
    ? new Date(props.bid.last_activity.replace(" ", "T") + ".000000Z")
    : "";
  lastActivity = prettyTimeAgo(lastActivity);
  if (
    [
      "a few seconds ago",
      "a minute ago",
      "2 minutes ago",
      "3 minutes ago",
      "4 minutes ago",
      "5 minutes ago",
    ].includes(lastActivity)
  ) {
    lastActivity = "online";
  }

  const avarageRating = Math.round(Number(props.bid.avarageRating));

  return (
    <>
      <div className="col-lg-12 mt-5">
        <div className="recent-box offers-scheme">
          <div className="row justify-content-center">
            <div className="col-lg-1 icon-testi">
              <img
                src={getAdviserProfileImage(props.bid)}
                className="img-fluid image-border-radius"
                alt=""
              />
            </div>
            <div className="col-lg-11">
              <div className="row small-content text-center pb-2">
                <div className="col-md-4 col-12 main-title text-left">
                  <h4>
                    <Link to={`/adviser-profile/${props.bid.advisor_id}`}>
                      {capitalize(props.bid.display_name || props.bid.name)}
                    </Link>
                  </h4>
                  <p>{capitalize(props.bid.company_name)}</p>
                </div>
                <div className="col-md-2 col-6 border-data">
                  <h6>Active</h6>
                  <p>{lastActivity}</p>
                </div>
                <div className="col-md-2 col-6 border-data">
                  <h6>{props.bid.response_time}</h6>
                  <p>response time</p>
                </div>
                <div className="col-md-2 col-6 border-data">
                  <h6>{props.bid.total_completed_bids} MB</h6>
                  <p>{props.bid.total_completed_bids <= 1 ? `customer` : `customers`}</p>
                </div>
                <div className="col-md-2 col-6">
                  <h6
                    className={
                      avarageRating > 0
                        ? `review-star-rating-${avarageRating}`
                        : "review-star-rating-0"
                    }
                  >
                    <span
                      aria-hidden="true"
                      className={`fa fa-star ${
                        avarageRating >= 1 && "checked"
                      }`}
                    ></span>
                    <span
                      aria-hidden="true"
                      className={`fa fa-star ${
                        avarageRating >= 2 && "checked"
                      }`}
                    ></span>
                    <span
                      aria-hidden="true"
                      className={`fa fa-star ${
                        avarageRating >= 3 && "checked"
                      }`}
                    ></span>
                    <span
                      aria-hidden="true"
                      className={`fa fa-star ${
                        avarageRating >= 4 && "checked"
                      }`}
                    ></span>
                    <span
                      aria-hidden="true"
                      className={`fa fa-star ${
                        avarageRating >= 5 && "checked"
                      }`}
                    ></span>
                  </h6>
                  <p>
                    {props.bid.rating?.total
                      ? props.bid.rating?.total <= 1
                        ? `${props.bid.rating?.total} Review`
                        : `${props.bid.rating?.total} Reviews`
                      : `No Reviews`}
                  </p>
                </div>
              </div>
              <div className="row pt-4 paragraph text-center">
                <div className="col-12 text-left">
                  <p>{capitalize(props.bid.short_description)}</p>
                  <div className="collapse" id="data2">
                    <p>{capitalize(props.bid.short_description)}</p>
                  </div>
                  {/* <a
                    href="javascript:void(0)"
                    className="see-more"
                    data-toggle="collapse"
                    data-target="#data2"
                    aria-expanded="false"
                    aria-controls="boxmore"
                  >
                    See more
                  </a> */}
                </div>
              </div>
              
              {props.advice.area_status!=3 && props.advice.area_status!=4 ? 
              <div className="row text-center mt-md-3 align-items-center">
                <div className="col-12 col-md-5 left-btn text-left d-md-block">
                  {props.declined ? "" : props.bid.is_bided === 0  ? (
                    user.status!=0?(
                    <button
                      className="theme-btn-border text-uppercase mt-btm"
                      onClick={() => handleOffer(props.bid.id, 3)}
                    >
                      <i
                        className="fa fa-times-circle-o"
                        aria-hidden="true"
                      ></i>
                      Decline
                    </button>
                    ):(
                      ""
                    )
                  ) : null}
                </div>
                <div className="col-12 col-sm-12 col-md-7 right-btn text-right sup-container mbbtn">
                  {props.declined ? (
                    <p className="deadline-status">Offer Declined</p>
                  ) : (
                    user.status!=0 ? (
                    <>
                    {props.bid.status > 0 || props.advice.status === 1 ? 
                      <button
                        className="theme-btn-border text-uppercase mr-4"
                        onClick={() =>
                          handleMessage(
                            user.id,
                            props.bid.advisor_id,
                            props.bid.area_id
                          )
                        }
                      >
                        message{" "}
                        {props.bid?.unread_message_count ? (
                          <sup>{props.bid?.unread_message_count}</sup>
                        ) : null}
                      </button>:""}
                      {props.bid.is_bided === 0 ? (
                        <a
                          href="javascript:void(0)"
                          className="theme-btn text-uppercase d-md-inline-flex"
                          onClick={() => handleOffer(props.bid.id, 1)}
                        >
                          Select Adviser
                        </a>
                      ) : props.bid.status > 0 || props.advice.status === 1 ? (
                        <button className="theme-btn-border text-uppercase">
                          {props.bid.status_name}
                        </button>
                      ) : null}
                    </>
                    ):(
                      <p>
                        Your account is suspended
                      </p>
                    )
                  )}
                </div>
              </div>:
              <div className="row text-center mt-md-3 align-items-center">
                <div className="col-6 col-md-5 left-btn text-left d-md-block">
                  {props.declined ? "" : props.bid.is_bided === 0  ? (
                    user.status!=0?(
                    <button
                      className="theme-btn-border text-uppercase"
                      onClick={() => handleOffer(props.bid.id, 3)}
                    >
                      <i
                        className="fa fa-times-circle-o"
                        aria-hidden="true"
                      ></i>
                      Decline
                    </button>
                    ):(
                      ""
                    )
                  ) : null}
                </div>
                <div className="col-12 col-md-7 col-6 right-btn text-right sup-container">
                  {props.declined ? (
                    <p className="deadline-status">Offer Declined</p>
                  ) :( null )
                  }
                </div>
              </div>}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AdvisorBidCard;
