import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useToasts } from "react-toast-notifications";
import LoadingSpinner from "../../components/common/LoadingSpinner";
import AddTeamMemberModal from "../../components/common/modals/advisorModals/AddTeamMemberModal";
import DeleteConfirmModal from "../../components/common/modals/DeleteConfirmModal";
import {
  deleteAdvisorTeam,
  getAdvisorTeam,
  updateAdvisorTeam,
  updateCompanyAdmin,
  getAdvisorProfile,
  getAdvisorProfileById,
  getAdviserUserProfile,
} from "../../components/common/service";
import { ADVISER_MY_ACCOUNT, ADVISOR_DEFAULT_IMAGE, LOGGEDIN_USER } from "../../Constants";
import useLoggedInUserData from "../../utility/useLoggedInUserData";
import $ from "jquery";
import "bootstrap";
import { getAdviserProfileImage, prettyTime } from "../../utility/commonUtils";
const initialState = {
  company_admin: "",
  company_id: "",
  isError: false,
  responseMessage: "",
};
const AdviserTeamMember = () => {
  var company_id = 0;
  var user = useLoggedInUserData();
  const { addToast } = useToasts();
  const [isLoading, setIsLoading] = React.useState<boolean>(true);
  const [teams, setTeams] = React.useState<any>([]);
  const [teamsWhoJoined, setTeamsWhoJoined] = React.useState<any>([]);
  const [editItem, setEditItem] = React.useState({
    name: "",
    email: "",
    company_id: 0,
    team_id: 0,
  });
  const [deleteItemId, setDeleteItemId] = React.useState();
  const [formState, setFormState] = useState({
    ...initialState,
    company_admin: 0,
    company_id: user?.userDetails?.company_id || 0,
  });
  const handlePageState = (newStates: any) => {
    setFormState((prevStates) => {
      return {
        ...prevStates,
        ...newStates,
      };
    });
  };
  const handleChange = (event: { target: HTMLInputElement } | any) => {
    const { name, value } = event.target;
    handlePageState({ [name]: value });
    event.preventDefault();
  };
  useEffect(() => {
    getUserProfileOnRefresh();
  }, []);
  useEffect(() => {
    getAdvisorTeamCall();
  }, []);
  useEffect(() => {
    const joinedOnly = teams.filter(
      (team: any) => team?.advisorDetails?.id > 0
    );
    setTeamsWhoJoined(joinedOnly);
  }, [teams]);

  const getAdvisorTeamCall = async () => {
    const res = await getAdvisorTeam(user?.userDetails?.company_id);
    if (res.status) {
      setTeams(res.data);
    }
    setIsLoading(false);
  };
  const getUserProfileOnRefresh = async () => {
    const res = await getAdviserUserProfile();
    if (res?.user) {
      user = res.user;
      localStorage.setItem(LOGGEDIN_USER, JSON.stringify(res.user));
    }   
    setIsLoading(false);
  };


  const getUserProfile = async () => {
    const res = await getAdviserUserProfile();
    if (res?.user) {
      user = res.user;
      localStorage.setItem(LOGGEDIN_USER, JSON.stringify(res.user));
      window.location.reload();
    }   
    setIsLoading(false);
  };

  const handleDeleteResponse = async (id: any) => {
    const res = await deleteAdvisorTeam(id);
    if (res.status) {
      setTeams(() => teams.filter((team: any) => team.id !== id));
      $("#delete_modal").modal("hide");
      $("body").removeClass("modal-open");
      $(".modal-backdrop").remove();
      addToast(res.message, { appearance: "success" });
    } else {
      addToast(res.message, { appearance: "error" });
    }
  };

  const updateItem = (team: any) => {
    const updatedTeams = teams.map((team: any) =>
      team.id === updatedTeams.id ? { ...team, ...updatedTeams } : team
    );
    setTeams(updatedTeams);
  };

  const handleChangeCheckbox = async (
    event: { target: HTMLInputElement },
    team: any
  ) => {
    const { name, checked } = event.target;
    handlePageState({ [name]: +checked });
    const updatedTeam = { ...team, status: checked };
    const res = await updateAdvisorTeam(updatedTeam);
    if (res.status) {
      setTeams(() =>
        teams.map((pteam: any) => (pteam.id === team.id ? updatedTeam : pteam))
      );
      addToast(res.message, { appearance: "success" });
      
    } else {
      addToast(res.message, { appearance: "error" });
    }
  };

  const onSubmitHandler = async (e: any) => {
    e.preventDefault();
    if (formState.company_admin == 0) {
      handlePageState({
        responseMessage: "Please select company admin",
        isError: true,
      });
      return;
    }
    const res = await updateCompanyAdmin({company_admin:formState.company_admin,company_id:formState.company_id});
    if (res.status) {
      handlePageState({ responseMessage: res.message, isError: false });
      addToast(res.message, { appearance: "success" });
      getUserProfile();
      getAdvisorTeamCall();
      
    } else {
      handlePageState({ responseMessage: res.message, isError: true });
    }
  };

  return (
    <>
      <main className="need-offers">
        <section className="page-title my-account-title">
          <div className="container">
            <div className="row">
              <div className="col-md-12 mb-5 mb-md-0"></div>
            </div>
          </div>
        </section>

        <section className="recent-sec directory-sec advice-area-sec register-final-sec pt-4 pb-5 myaccount-sec">
          <div className="container">
            <div className="row">
              <div className="col-12 back-btn mt-lg-4 mb-lg-3 mt-md-5 mt-5 mb-0">
                <Link to={ADVISER_MY_ACCOUNT} className="back-btn">
                  <i className="fa fa-angle-left" aria-hidden="true"></i> My
                  Account
                </Link>
              </div>
            </div>
          </div>

          <div className="container">
            <div className="row">
              <div className="col-12 mb-3 small-breadcumbs">
                <h5 className="text-white text-capitalize">
                  <img
                    src="assets/images/preference2.png"
                    className="img-fluid"
                    alt=""
                  />{" "}
                  {user?.company_name}
                </h5>
              </div>
            </div>
            <div className="recent-box mb-4">
              <div className="row my-accoutns-data completed-data">
                <div className="col-xl-12">
                  <p className="mb-0">
                    Manage your company and invite new team members. Advisers
                    are treated separately from a lead and billing prospective
                    but will share the company profile. The administrator will
                    have superpowers and can view all activity, add, suspend
                    team members etc.
                  </p>
                </div>
              </div>
            </div>
            <div className="recent-box team-memebers-data mb-4 pt-lg-5 pt-3">
              <div className="row justify-content-center">
                <div className="col-xl-11 col-lg-12 mb-4">
                  <div className="row align-items-center team-datas add-content">
                    <div className="col-md-6 col-5">
                      <h5 className="mb-md-0 mb-3">Team Members</h5>
                    </div>
                    <div className="col-md-6 col-7 text-right">
                      {user.userDetails.company_id!=0?user.is_admin==1?
                      <a
                        href="#"
                        data-toggle="modal"
                        data-target="#add_team_member"
                        className="theme-btn"
                      >
                        + ADD TEAM MEMBER
                      </a>:"":""}
                    </div>
                  </div>

                  <div className="col-md-12 add-teams"></div>

                  <div className="pl-xl-4 pl-0 pr-xl-4 pr-0 mt-5">
                    <LoadingSpinner loading={isLoading} />
                    {user.userDetails.company_id!=0?teams.length ? (
                      teams.map((team: any) => (
                        <div key={team.id} className="datas">
                          <div className="row member-data">
                            <div className="col-xl-12 col-lg-12 col-md-12 team-details">
                              <div className="text-center">
                                <img
                                  src={getAdviserProfileImage(
                                    team?.advisorDetails
                                  )}
                                  onError={(event: any) =>
                                    event.target.setAttribute(
                                      "src",
                                      ADVISOR_DEFAULT_IMAGE
                                    )
                                  }
                                  alt=""
                                  width="88"
                                  height="auto"
                                  max-height="88"
                                />
                              </div>
                              <div className="row">
                                <div className="col-md-8">
                                  <h6>
                                    { team?.member_id!=0?
                                    <a href={`/adviser-profile/${team?.member_id}`}>
                                      {team?.name}
                                    </a>:
                                    <a>
                                    {team?.name}
                                  </a>
}
                                    <span>{team?.isCompanyAdmin==1?"Admin":"Mortgage adviser"}</span>
                                  </h6>
                                </div>
                                {user.is_admin==1 ?(
                                <div className="col-md-4 text-right toggle-btn-data">
                                  {team?.is_joined!= 0 ? (
                                    team?.isCompanyAdmin!=1 ? (
                                    <div className="toggle-btn">
                                      <p>Company active</p>
                                      <label className="switch">
                                        <input
                                          type="checkbox"
                                          name="team_status"
                                          value={team.id}
                                          defaultChecked={team.status==1}
                                          onChange={(e) =>  
                                            handleChangeCheckbox(e, team)
                                          }
                                        />
                                        <span className="slider round"></span>
                                      </label>
                                    </div>
                                    ) : ( null )
                                  ) : null}
                                </div>
                                ) : null}
                              </div>
                              
                              <div className="row align-items-end mt-lg-3 mt-1">
                                <div className="col-md-8">
                                {user.is_admin==1 ?(
                                  <div className="row mb-2 pb-1">
                                    <div className="col-6">
                                      <p>
                                        <b>Status</b>
                                      </p>
                                    </div>
                                    <div className="col-6">
                                      <p>
                                        <b>
                                          {team?.is_joined == 1
                                            ? "Active"
                                            : "Invite Pending"}
                                        </b>
                                      </p>
                                    </div>
                                  </div>
                                  ) : null}
                                  {user.is_admin==1 ?(
                                  (team?.is_joined > 0 ? (
                                    <>
                                      <div className="row mb-1">
                                        <div className="col-6">
                                          <p>FCA Registration confirmation</p>
                                        </div>
                                        <div className="col-6">
                                          <p>
                                            {team?.advisorDetails?.FCA_verified
                                              ? prettyTime(
                                                  team.advisorDetails
                                                    .FCA_verified,
                                                  true,
                                                  true,
                                                  true
                                                )
                                              : "N/A"}
                                          </p>
                                        </div>
                                      </div>
                                      <div className="row">
                                        <div className="col-6">
                                          <p>Email verified</p>
                                        </div>
                                        <div className="col-6">
                                          <p>
                                            {team?.advisorDetails
                                              ?.email_verified_at
                                              ? prettyTime(
                                                  team.advisorDetails
                                                    .email_verified_at,
                                                  true,
                                                  true,
                                                  true
                                                )
                                              : "N/A"}
                                          </p>
                                        </div>
                                      </div>
                                    </>
                                  ) : null)
                                  ) :null}
                                </div>
                                <div className="col-md-4 mt-md-0 mt-3 text-right">
                                  {/* <a
                                    href="#"
                                    data-toggle="modal"
                                    data-target="#delete_modal"
                                    className="delete-btn"
                                    onClick={() => setEditItem(team)}
                                  >
                                    Edit
                                  </a> */}
                                  {user.is_admin==1? (
                                  team?.isCompanyAdmin!=1 ? 
                                  <a
                                    href="#"
                                    data-toggle="modal"
                                    data-target="#delete_modal"
                                    className="delete-btn"
                                    onClick={() => setDeleteItemId(team?.id)}
                                  >
                                    Delete
                                  </a> : "" ):( "" )}
                                </div>
                              </div>
                            </div>
                          </div>
                          <span className="border-bottom"></span>
                        </div>
                      ))
                    ) : (
                      <div className="datas">
                        <div className="row">
                          <div className="col-xl-12 col-lg-12 col-md-12">
                            <div className="text-center">
                              <p>No team member added yet.</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    ): (
                      <div className="datas">
                        <div className="row">
                          <div className="col-xl-12 col-lg-12 col-md-12">
                            <div className="text-center">
                              <p>No team member added yet.</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>

                  <span className="border-bottom"></span>
                  {user.userDetails.company_id!=0?user.is_admin==1?
                  teamsWhoJoined.length ? (
                    <div className="row align-items-center add-content">
                      <div className="col-md-12">
                        <h5 className="mb-md-3 mb-3">
                          Contact / Administrator
                        </h5>
                      </div>
                      <div className="col-md-12">
                        <p>
                          The contact/administrator has a total company view of
                          all activity and the ability to suspend team members.
                          The current contact/administrator can pass this role
                          to another team member.
                        </p>
                      </div>
                      <form onSubmit={onSubmitHandler} className="width-full mobile-f-width">
                        <div className="col-md-12 settings-data mt-lg-4 mt-2">
                          <div className="form-group form-row general-info">
                            <div className="col-12 col-lg-4 col-md-5">
                              <div className="select-data">
                                <select className="contact_info_member" name="company_admin" id="company_admin" value={formState?.company_admin} onChange={handleChange}>
                                  {teamsWhoJoined.map((team: any) => (
                                    <option key={team.advisorDetails.advisorId} value={team.advisorDetails.advisorId}>
                                      {team.name}
                                    </option>
                                  ))}
                                </select>
                              </div>
                            </div>
                            <div className="col-lg-4 col-md-5">
                              <button type="submit" className="theme-btn text-uppercase mr-md-3 mr-1 mb-md-0 mb-3">
                                Update
                              </button>
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                  ) : null
                  :"":""}
                </div>
              </div>
            </div>
          </div>
          <img
            src="assets/images/business_right.png"
            alt="help-1"
            className="img-fluid before-img"
          />
          <img
            src="assets/images/business_left.png"
            alt="help-1"
            className="img-fluid after-img"
          />
        </section>
      </main>
      <AddTeamMemberModal
        editItem={editItem}
        updateItem={updateItem}
        refetch={getAdvisorTeamCall}
      />
      <DeleteConfirmModal
        deleteItemId={deleteItemId}
        handleResponse={handleDeleteResponse}
      />
    </>
  );
};

export default AdviserTeamMember;
