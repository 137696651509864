import React, { useState,useEffect, useRef  } from 'react';
import { NavLink } from "react-router-dom";
import {
    saveChatgptResponse,
  } from "../components/common/service";
import useLoggedInUserData from '../utility/useLoggedInUserData';
import InlineAlert from "../components/common/InlineAlert";

interface ChatEntry {
    question: string;
    response: string;
}

const ChatGPT = () => {
    const [chatHistory, setChatHistory] = useState<ChatEntry[]>([]);
    const user = useLoggedInUserData();
    const [currentQuestion, setCurrentQuestion] = useState('');
    const [currentResponse, setCurrentResponse] = useState('');
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isFirstPage, setIsFirstPage] = useState<boolean>(true);
    const [isError, setIsError] = useState<boolean>(false);
    const [isStopOrRegenerate, setIsStopOrRegenerate] = useState<boolean>(false);
    const chatMessagesContainerRef = useRef<HTMLDivElement | null>(null);
    const [lastQuestionAnswer, setLastQuestionAnswer] = useState('');
    const handlePrefillClick = async (value:any) => {
      setCurrentQuestion(value);
      setIsLoading(true);
      setIsStopOrRegenerate(true);
      setIsFirstPage(false);
      const newEntry = {
        question: "",
        response: "",
      };
      const res = await saveChatgptResponse('This question is about UK Mortgages. '+value,user?.id);
      if (res?.success || res?.status) {
        const newEntry = {
          question: value,
          response: res.data.replace(/\n/g, '<br>'),
        };
        setChatHistory([...chatHistory, newEntry]);
        localStorage.setItem('chatHistory', JSON.stringify([...chatHistory, newEntry]));
        setLastQuestionAnswer(res.data.replace(/\n/g, '<br>'));
      }
      setIsLoading(false);
      setIsStopOrRegenerate(false);
      setCurrentQuestion('');
      setCurrentResponse('');
    };

    const checkEligibillityToAskQuestion = (event:any) => {      
      setIsError(true);
    };

    const handleQuestionChange = (event:any) => {
      setCurrentQuestion(event.target.value);      
    };

    const goBack = (event:any) => {      
      setIsFirstPage(true);
    };

    const regenerateResponse = async (event:any) => {
      setIsStopOrRegenerate(true);
      const lastQuestion = chatHistory[chatHistory.length - 1]?.question;
      setIsLoading(true);
      const res = await saveChatgptResponse(lastQuestion,user?.id);
      if (res?.success || res?.status) {
        setLastQuestionAnswer(res.data.replace(/\n/g, '<br>'));
        const newEntry = {
            question: lastQuestion,
            response: res.data.replace(/\n/g, '<br>'),
          };
        setChatHistory([...chatHistory, newEntry]);
        localStorage.setItem('chatHistory', JSON.stringify([...chatHistory, newEntry]));
      }
      setIsLoading(false);
      setIsStopOrRegenerate(false);
      setCurrentQuestion('');
      setCurrentResponse('');
    };

    useEffect(() => {
      const container = chatMessagesContainerRef.current;
      if (container) {
        container.scrollTop = container.scrollHeight;
      }
    }, [chatHistory]);
  
    const handleSubmit = async (event:any) => {
      event.preventDefault();
      setIsLoading(true);
      setIsStopOrRegenerate(true);
      setIsFirstPage(false);
      const newEntry = {
        question: "",
        response: "",
      };
      const res = await saveChatgptResponse('This question is about UK Mortgages. '+currentQuestion,user?.id);
      if (res?.success || res?.status) {
        const newEntry = {
          question: currentQuestion,
          response: res.data.replace(/\n/g, '<br>'),
        };
        setChatHistory([...chatHistory, newEntry]);
        localStorage.setItem('chatHistory', JSON.stringify([...chatHistory, newEntry]));
        setLastQuestionAnswer(res.data.replace(/\n/g, '<br>'));
      }
      setIsLoading(false);
      setIsStopOrRegenerate(false);
      setCurrentQuestion('');
      setCurrentResponse('');
      // setIsLoading(true);
      // setIsStopOrRegenerate(true);      
      // const newEntry = {
      //   question: "",
      //   response: "",
      // };
      // const res = await saveChatgptResponse('This question is about UK Mortgages. '+currentQuestion,user?.id);
      // if (res?.success || res?.status) {
      //   setLastQuestionAnswer(res.data);
      //   const newEntry = {
      //     question: currentQuestion,
      //     response: res.data,
      //   };
      //   setChatHistory([...chatHistory, newEntry]);
      //   localStorage.setItem('chatHistory', JSON.stringify([...chatHistory, newEntry]));
      // }
      // setIsLoading(false);
      // setIsStopOrRegenerate(false);
      // setIsFirstPage(false);
      // setCurrentQuestion('');
      // setCurrentResponse('');      
    };
  
    useEffect(() => {
      localStorage.removeItem('chatHistory');
    }, []);

    useEffect(() => {
      let lastResponse = chatHistory[chatHistory.length - 1];
      if (lastResponse) {
        const typewriterElement = document.getElementById(`typewriter_${chatHistory.length - 1}`);
        const responseText = lastResponse.response || '';
        //let typewriterIndex = 0;
        if (typewriterElement) {
          typewriterElement.innerHTML = responseText;
        }
        // const type = () => {
        //   if (typewriterElement) {
        //     // Replace '\n' with '<br>' in the response text
        //     const formattedText = responseText.slice(0, typewriterIndex).replace(/\n/g, '<br>');
            
        //     if (typewriterIndex <= responseText.length) {
        //       typewriterElement.innerHTML = formattedText;
        //       typewriterIndex++;
        //       setTimeout(type, Math.random() * 20 + 25);
        //     }
        //   }
        // };
    
        // setTimeout(type, Math.random() * 5 + 45);
      }
    }, [chatHistory]);
    
  return (
    <main style={{
      paddingTop: "140px"
    }}>
      <div className="saction-one-screen">
          <div className="container">
              <div className="saction-all">
                  {isFirstPage?
                  <div className="saction-one">
                      <div className="rebort-mobile"><img src="assets/images/icon-rbort.png" /></div>
                      <p>
                          Looking for answers to your mortgage questions? Look no further than Einstein, our<br />
                          <span>ChatGPT-powered mortgage chatbot,</span> available to assist you 24/7. Using awesome<br />
                          artifical intelligence and a vast amounts of data, Einstein can provide quick responses to<br />
                          your mortgage inquiries.
                      </p>
                      <div className="box-saction">
                          <div className="row">
                              <div className="col-md-6">
                                  <div className="box-saction-inner">
                                      <div className="box-hading">
                                          <h3><img src="assets/images/1.png" alt="#" />Examples</h3>
                                      </div>
                                      <div className="peragreaph">
                                          <p style={{ cursor: 'pointer', lineHeight: '19px', margin: '0 0 10.4px 0' }} onClick={() => handlePrefillClick("What are the top 10 questions I should ask a mortgage adviser?")}>
                                            What are the top 10 questions I should ask a mortgage adviser?
                                          </p>
                                          <p style={{cursor: 'pointer', lineHeight: '19px', margin: '0 0 10.4px 0'}} onClick={() => handlePrefillClick("What is the maximum amount I can borrow if I earn £40,000")}>What is the maximum amount I can borrow if I earn £40,000</p>
                                          <p style={{cursor: 'pointer', lineHeight: '19px', margin: '0 0 10.4px 0'}} onClick={() => handlePrefillClick("Is now a good time to buy?")}>Is now a good time to buy?</p>
                                          <p style={{cursor: 'pointer', lineHeight: '19px', margin: '0 0 10.4px 0'}} onClick={() => handlePrefillClick("How much deposit do I need to put down on a house costing £300k")}>How much deposit do I need to put down on a house costing £300k</p>
                                          <p style={{cursor: 'pointer', lineHeight: '19px', margin: '0 0 10.4px 0'}} onClick={() => handlePrefillClick("How much will monthly payments be if I borrowed £280k over 14 years at 5.9%? How does this compare if I extended the mortgage term to 20 years?")}>How much will monthly payments be if I borrowed £280k over 14 years at 5.9%? How does this compare if I extended the mortgage term to 20 years?</p>
                                      </div>
                                  </div>
                              </div>
                              <div className="col-md-6">
                                  <div className="box-saction-inner">
                                      <div className="box-hading">
                                          <h3><img src="assets/images/2.png" alt="#" />Capabilities and Limitations</h3>
                                      </div>
                                      <div className="peragreaph">
                                          <p>Remembers conversation and allows follow-up questions</p>
                                          <p>May occasionally generate incorrect information</p>
                                          <p>
                                              For personalised advice, we recommend speaking to a real FCA registered mortgage adviser.<br />
                                              <a href="#">Find your adviser</a>
                                          </p>
                                      </div>
                                  </div>
                              </div>
                          </div>
                      </div>
                      <div className="rebort-icon"><img src="assets/images/icon-rbort.png" /></div>
                  </div>
                  :
                  <div className="saction-tow" >
                    {/* style="display: none;" */}
                      <div className="find-your-right go-back">
                        <button className="find-your-right-button" onClick={goBack}>Go Back</button>
                      </div>
                      <div className="saction-one-screen saction-two-screen" style={{marginTop: "30px"}}>
                          <div className="find-your">
                              <div className="find-your-left"><span>Information provided for guidance only. Always speak with an FCA-approved mortgage adviser.</span></div>
                              <div className="find-your-right">
                                  <NavLink
                                    to={`/need`}
                                  >
                                    <button className="find-your-right-button">
                                    Find your Adviser</button>
                                  </NavLink>
                              </div>
                          </div>
                          <div className="scroll-chate" ref={chatMessagesContainerRef}>
                         
                             {chatHistory.map((row, index) => (
                                <React.Fragment key={index}>
                                  <div className="parpul-row">
                                    <span>Q</span>
                                    <p>{row.question}</p>
                                  </div>
                                  <div className="green-row">
                                    <div className="green-left">
                                      <span>
                                        <img src="assets/images/pic.png" alt="Profile" />
                                      </span>
                                    </div>
                                    <div className="green-right">
                                      {chatHistory.length>1?chatHistory.length - 1 == index?
                                        <p id={`response_${index}`} style={{ display: "none"}} dangerouslySetInnerHTML={{ __html: row.response }}>
                                        
                                      </p>
                                      
                                      :<p id={`response_${index}`} style={{ display: lastQuestionAnswer === row.response ? "none" : "" }} dangerouslySetInnerHTML={{ __html: row.response }}>
                                      
                                    </p>:<p id={`response_${index}`} style={{ display: lastQuestionAnswer === row.response ? "none" : "" }} dangerouslySetInnerHTML={{ __html: row.response }}>
                                      
                                    </p>
                                      }
                                      {/* <p id={`response_${index}`} style={{ display: lastQuestionAnswer === row.response ? "none" : "" }}>
                                        {row.response}
                                      </p> */}
                                      <p id={`typewriter_` + index} style={{ display: lastQuestionAnswer === row.response ? "block" : "none" }}></p>
                                    </div>
                                  </div>
                                </React.Fragment>
                              ))}


                              {!isStopOrRegenerate?
                                chatHistory.length >= 3 && !user?.id ? null : 
                                <div className="regenerate-response" onClick={regenerateResponse}>
                                  <button><img src="assets/images/btn-pic.png" />Regenerate Response</button>
                                </div>:
                                <div className="regenerate-response">
                                <button><img src="assets/images/btn-pic.png" />Stop Response</button>
                            </div>
                              }
                              
                              
                          </div>
                      </div>
                  </div>
                  }
                  <div className="input-search">
                  {isError && (
                      <InlineAlert
                        isError={isError}
                        message="Thanks for using MortgageBox AI Chat, and we hope it has been helpful. Regrettably, you've hit today's usage limit. To keep asking questions, please log in, register your mortgage requirement, or return tomorrow."
                      />
                    )}
                    <form onSubmit={chatHistory.length >= 10 && !user?.id ? undefined : handleSubmit}>
                      <div className="inpit-search-bar">
                        <div className="input-row">
                          <input type="text" placeholder="Ask me anything" value={currentQuestion} onChange={handleQuestionChange}/>
                        </div>
                        <div className="search-btn">
                          <button type={chatHistory.length >= 10 && !user?.id ? 'button' : 'submit' } onClick={chatHistory.length >= 10 && !user?.id ? checkEligibillityToAskQuestion : undefined }><img src="assets/images/seacrhbtn.png"/></button>
                        </div>
                      </div>
                    </form>
                  </div>
              </div>
          </div>
      </div>
  </main>
  );
};





export default ChatGPT;