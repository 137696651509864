import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { searchPostalCode } from "../components/common/service";
import { AsyncTypeahead } from "react-bootstrap-typeahead";
import InlineAlert from "../components/common/InlineAlert";

const initialState = {
  isError: false,
  responseMessage: "",
};

const PostcodeSearch = () => {
  const history = useHistory();
  const [postcodeState, setPostcodeState] = useState("");
  const [formState, setFormState] = useState(initialState);

  const [isSeachLoading, setIsSeachLoading] = useState<boolean>(false);
  const [options, setOptions] = useState<any>([]);
  const [selectedOption, setselectedOption] = useState<any>([]);

  const handleChangeSearch = (event: any) => {
    if (event.length) {
      setselectedOption([event[0]]);
      setPostcodeState(event[0].Postcode);
    } else {
      setselectedOption([]);
    }
  };

  const handleSearch = async (query: any) => {
    setIsSeachLoading(true);
    const res = await searchPostalCode({ postal_code: query });
    if (res.status) {
      setOptions(res.data);
    }
    setIsSeachLoading(false);
  };
  // Bypass client-side filtering by returning `true`. Results are already
  // filtered by the search endpoint, so no need to do it again.
  const filterBy = () => true;

  const handlePageState = (newStates: any) => {
    setFormState((prevStates) => {
      return {
        ...prevStates,
        ...newStates,
      };
    });
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    if (postcodeState === "") {
      handlePageState({
        responseMessage: "Postcode is required.",
        isError: true,
      });
      return;
    } else {
      const existingOptions = options.map((option: any) => option.Postcode);
      if (!existingOptions.includes(postcodeState)) {
        handlePageState({
          responseMessage: "Postcode is invalid. Please use valid postcode",
          isError: true,
        });
        return;
      }
    }

    history.push({
      pathname: "/directory",
      state: { post_code: postcodeState },
    });
  };

  return (
    <>
      <section className="postcode-bg pt-5 pb-5">
        <div className="container pt-5">
          <div className="recent-box pt-5 pb-5 mt-5">
            <div className="row justify-content-center">
              <div className="col-lg-12 text-center mb-4">
                <div className="heading">
                  <h2 className="text-uppercase">
                    Find Mortgage advisers near you
                  </h2>
                  <span></span>
                </div>
              </div>
            </div>
            <div className="row justify-content-center">
              <div className="col-lg-6 text-center text-md-left">
                <form className="post-code" onSubmit={handleSubmit}>
                  <p className="m-2">
                    <strong>Postcode</strong>
                  </p>
                  {formState.responseMessage && (
                    <InlineAlert
                      isError={formState.isError}
                      message={formState.responseMessage}
                    />
                  )}
                  <div className="form-group d-md-flex align-items-center">
                    {/* <input
                      type="text"
                      className="mr-3 mb-3 mb-md-0"
                      name="Postcode"
                      placeholder="TW12 1AA"
                      onFocus={(e) => (e.target.placeholder = "")}
                      onBlur={(e) => (e.target.placeholder = "TW12 1AA")}
                      onChange={(e) => setPostcodeState(e.target.value)}
                      required
                    /> */}
                    <AsyncTypeahead
                      filterBy={filterBy}
                      id="async-example"
                      isLoading={isSeachLoading}
                      labelKey="Postcode"
                      minLength={2}
                      onSearch={handleSearch}
                      options={options}
                      onChange={handleChangeSearch}
                      selected={selectedOption}
                      placeholder="TW12 1AA"
                      className="postCodeSearchInnerSearch postCodeSearchInnerAccount"
                      renderMenuItemChildren={(option: any, props: any) => (
                        <React.Fragment>
                          <span>{option?.Postcode}</span>
                        </React.Fragment>
                      )}
                    />
                    <button type="submit" className="theme-btn text-uppercase">
                      SEARCH <i className="far fa-search ml-2" />
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default PostcodeSearch;
