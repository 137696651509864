import React, { useEffect, useState } from "react";
interface Props {
  deleteItemId?: any;
  text?: string;
  handleResponse: (id: string) => void;
}
const DeleteConfirmModal = (props: Props) => {
  const [disabledButton, setDisabledButton] = useState(false);
  useEffect(() => {
    setDisabledButton(false);
  }, []);

  return (
    <>
      <div
        className="mail-popup modal fade popup-model add_offeres"
        id="delete_modal"
        data-backdrop="static"
        data-keyboard="false"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-body">
              <form>
                <div className="row">
                  <div className="col-md-12">
                    <div className="text-center mb-3">
                      <h4 className="mb-0 pb-0">
                        {props.text || "Are you really sure ?"}
                      </h4>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12 text-center mt-lg-3 mt-0 settings-data">
                    <button
                      type="button"
                      className="theme-btn text-uppercase mr-md-3 mr-1 mb-md-0 mb-3"
                      onClick={() => {
                        setDisabledButton(true);
                        props.handleResponse(props.deleteItemId);
                      }}
                      disabled={disabledButton}
                    >
                      Yes
                    </button>
                    <button
                      type="button"
                      className="theme-btn-border text-uppercase"
                      data-dismiss="modal"
                      onClick={() => setDisabledButton(false)}
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DeleteConfirmModal;
