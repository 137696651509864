import React, { useContext, useState, useEffect } from "react";
import { validateEmail } from "../../../utility/commonUtils";
import { resendActivationEmail, getAdviserUserProfile } from "../service";
import $ from "jquery";
import "bootstrap";
import InlineAlert from "../InlineAlert";
import { AuthContext } from "../../../shared/context/auth-context";
import { LOGGEDIN_USER } from "../../../Constants";

const ResendEmail = () => {
  let { user } = useContext(AuthContext);
  const loggedInUserInfo = JSON.parse(
    localStorage.getItem(LOGGEDIN_USER) || ""
  );
  user = user?.id ? user : loggedInUserInfo;
  const initialState = {
    email: user.email,
    isError: false,
    responseMessage: "",
  };
  const [formState, setFormState] = useState(initialState);

  const getUserProfile = async () => {
    const res = await getAdviserUserProfile();
    if (res?.user) {
      formState.email = res.user.email;
      localStorage.setItem(LOGGEDIN_USER, JSON.stringify(res.user));
    }   
  };

  const handlePageState = (newStates: any) => {
    setFormState((prevStates) => {
      return {
        ...prevStates,
        ...newStates,
      };
    });
  };
  useEffect(() => {
    getUserProfile();
        
    // getUserProfile();
  }, []);

  const handleChange = (event: { target: HTMLInputElement } | any) => {
    const { name, value } = event.target;
    handlePageState({ [name]: value });
    event.preventDefault();
  };
  const onSubmitHandler = async (e: any) => {
    e.preventDefault();
    if (!validateEmail(formState.email)) {
      handlePageState({ responseMessage: "Email is required.", isError: true });
      return;
    }
    const res = await resendActivationEmail(formState);
    if (res.status) {
      handlePageState({ responseMessage: res.message, isError: false });
      // $("#Resend-mail").modal("hide");
      // $("body").removeClass("modal-open");
      // $(".modal-backdrop").remove();
    } else {
      handlePageState({ responseMessage: res.message, isError: true });
    }
  };

  return (
    <>
      <div className="mail-popup modal fade" id="Resend-mail">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-body">
              <div className="row">
                <div className="col-md-12">
                  <div className="heading text-center mb-4">
                    <h4 className="text-uppercase theme-color">
                      resend activation mail
                    </h4>
                    <span></span>
                  </div>
                  <div className="mb-4">
                    <form onSubmit={onSubmitHandler}>
                      {formState.responseMessage && (
                        <InlineAlert
                          isError={formState.isError}
                          message={formState.responseMessage}
                        />
                      )}
                      <p className="m-2">
                        <strong>E-mail</strong>
                      </p>
                      <div className="form-group d-flex align-items-center">
                        <input
                          type="email"
                          className="mr-3 mb-0"
                          name="email"
                          placeholder="Email Address"
                          readOnly
                          onFocus={(e) => (e.target.placeholder = "")}
                          onBlur={(e) =>
                            (e.target.placeholder = "Email Address")
                          }
                          value={formState.email}
                          onChange={handleChange}
                          required
                        />
                        <button
                          type="submit"
                          className="theme-btn text-uppercase"
                        >
                          Send
                        </button>
                      </div>
                    </form>
                  </div>
                  <div className="note">
                    <p className="mb-2">
                      <strong>Need help?</strong>
                    </p>
                    <p>
                      Didn&#39;t receive the verification email? Please check
                      your spam folders and confirm that your email address
                      above is correct.
                    </p>
                  </div>
                  <div className="col-md-12 text-center mt-lg-3 mt-0 settings-data">
                    <button
                      type="button"
                      className="theme-btn-border text-uppercase"
                      data-dismiss="modal"
                    >
                      Close
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default ResendEmail;
