import React, { useEffect, useState } from "react";
import { useToasts } from "react-toast-notifications";
import ReactTooltip from "react-tooltip";
import {
  getAdvisorCustomerPreference,
  updateAdvisorCustomerPreference,
} from "../common/service";

const initialState = {
  self_employed: 0,
  non_uk_citizen: 0,
  adverse_credit: 0,
  ltv_max: "150%",
  lti_max: "8x",
  asap: 0,
  next_3_month: 0,
  more_3_month: 0,
  fees_preference: 0,
};

const AdviserPrefrencesCustomer = () => {
  const { addToast } = useToasts();
  const [formState, setFormState] = useState(initialState);

  useEffect(() => {
    getAdvisorCustomerPreferenceCall();
  }, []);

  const getAdvisorCustomerPreferenceCall = async () => {
    const res = await getAdvisorCustomerPreference();
    if (res.data) {
      delete res.data.id;
      delete res.data.advisor_id;
      delete res.data.updated_at;
      delete res.data.created_at;
      setFormState(res.data);
    }
  };

  const handlePageState = (newStates: any) => {
    setFormState((prevStates: any) => {
      return {
        ...prevStates,
        ...newStates,
      };
    });
  };

  const handleChangeCheckbox = (event: { target: HTMLInputElement } | any) => {
    const { name, checked } = event.target;
    handlePageState({ [name]: +checked });
  };

  const handleChange = (event: { target: HTMLInputElement } | any) => {
    const { name, value } = event.target;
    handlePageState({ [name]: value });
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    const res = await updateAdvisorCustomerPreference(formState);
    if (res.status) {
      addToast(res.message, { appearance: "success" });
    } else {
      addToast(res.message, { appearance: "error" });
    }
  };

  const imgDesign = {
    width: "15px",
    height: "15px",
  };
  const imgDesign2 = {
    width: "16px",
    height: "15px",
  };

  return (
    <>
      <div className="profile-datas">
        <h5 className="mb-3">Customer preference</h5>
        <p>
          Focus on the most relevant leads by filtering out those of less
          interest. Simply define your areas of expertise and preferences and we
          will do the rest. You can change these settings at any time.
        </p>
        <span className="border-bottom"></span>
      </div>

      <form onSubmit={handleSubmit}>
        <div className="team-memebers-data preference-customer pl-xl-3 pr-xl-3">
          <div className="row member-data m-0">
            <div className="col-6 text-left">
              <p>Self employed</p>
            </div>
            <div className="col-6 text-right">
              <label className="switch">
                <input
                  type="checkbox"
                  name="self_employed"
                  value={1}
                  onChange={handleChangeCheckbox}
                  checked={formState.self_employed === 1}
                />
                <span className="slider round"></span>
              </label>
            </div>
          </div>
          <div className="row member-data m-0">
            <div className="col-6 text-left">
              <p>Non UK citizen</p>
            </div>
            <div className="col-6 text-right">
              <label className="switch">
                <input
                  type="checkbox"
                  name="non_uk_citizen"
                  value={1}
                  onChange={handleChangeCheckbox}
                  checked={formState.non_uk_citizen === 1}
                />
                <span className="slider round"></span>
              </label>
            </div>
          </div>
          <div className="row member-data m-0">
            <div className="col-6 text-left">
              <p>Adverse credit</p>
            </div>
            <div className="col-6 text-right">
              <label className="switch">
                <input
                  type="checkbox"
                  name="adverse_credit"
                  value={1}
                  onChange={handleChangeCheckbox}
                  checked={formState.adverse_credit === 1}
                />
                <span className="slider round"></span>
              </label>
            </div>
          </div>
          <div className="row member-data m-0">
            <div className="col-md-3 col-5 text-left">
              <p>
                LTV max
                <a href="javascript:void(0)" data-tip="LTV max">
                  <img
                    src="assets/images/preference3.png"
                    alt="preference-icon"
                    className="img-fluid smallicon"
                    style={imgDesign}
                  />
                </a>
              </p>
            </div>
            <div className="col-md-9 col-7 text-right">
              <div className="select-data">
                <select
                  name="ltv_max"
                  value={formState.ltv_max}
                  onChange={handleChange}
                >
                  <option value="75%">75%</option>
                  <option value="90%">90%</option>
                  <option value="100%">100%</option>
                  {/* <option value="unlimited">Unlimited</option> */}
                  <option value="150%">150%</option>
                </select>
              </div>
            </div>
          </div>
          <div className="row member-data m-0">
            <div className="col-md-3 col-5 text-left">
              <p>
                LTI max
                <a href="javascript:void(0)" data-tip="LTI max">
                  <img
                    src="assets/images/preference3.png"
                    alt="preference-icon"
                    className="img-fluid smallicon"
                    style={imgDesign2}
                  />
                </a>
              </p>
            </div>
            <div className="col-md-9 col-7 text-right">
              <div className="select-data">
                <select
                  name="lti_max"
                  value={formState.lti_max}
                  onChange={handleChange}
                >
                  <option value="3x">3x</option>
                  <option value="4x">4x</option>
                  <option value="5x">5x</option>
                  <option value="6x">6x</option>
                  {/* <option value="unlimited">Unlimited</option> */}
                  <option value="8x">8x</option>
                </select>
              </div>
            </div>
          </div>
          <div className="row member-data m-0 mt-2">
            <div className="col-lg-3 col-md-4 col-12 text-left">
              <p>Mortgage urgency</p>
            </div>
            <div className="col-lg-9 col-md-8 col-12 text-right">
              <div className="row selection-box">
                <div className="col-xl-2 col-lg-4 col-md-4">
                  <div className="form-group checkboxes-data mb-0">
                    <input
                      type="checkbox"
                      id="ASAP"
                      name="asap"
                      value={1}
                      onChange={handleChangeCheckbox}
                      checked={formState.asap === 1}
                    />
                    <label htmlFor="ASAP">ASAP</label>
                  </div>
                </div>
                <div className="col-xl-5 col-lg-4 col-md-4">
                  <div className="form-group checkboxes-data mb-0">
                    <input
                      type="checkbox"
                      id="next_3_month"
                      name="next_3_month"
                      value={1}
                      onChange={handleChangeCheckbox}
                      checked={formState.next_3_month === 1}
                    />
                    <label htmlFor="next_3_month">In the next 3 months</label>
                  </div>
                </div>
                <div className="col-xl-5 col-lg-4 col-md-4">
                  <div className="form-group checkboxes-data mb-0">
                    <input
                      type="checkbox"
                      id="more_3_month"
                      name="more_3_month"
                      value={1}
                      onChange={handleChangeCheckbox}
                      checked={formState.more_3_month === 1}
                    />
                    <label htmlFor="more_3_month">In more than 3 months</label>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row member-data m-0">
            <div className="col-6 text-left">
              <p>Adviser fees?</p>
            </div>
            <div className="col-6 text-right">
              <label className="switch">
                <input
                  type="checkbox"
                  name="fees_preference"
                  value={1}
                  onChange={handleChangeCheckbox}
                  checked={formState.fees_preference === 1}
                />
                <span className="slider round"></span>
              </label>
            </div>
          </div>
          <div className="row member-data m-0 mt-2">
            <div className="col-12">
              <div className="last-para">
                <p>
                Please note that customers can specify during registration whether they prefer to match with advisers who charge fees or those who do not. Advisers who charge fees should communicate this to the customer as soon as possible.
                </p>
              </div>
            </div>
          </div>
          <div className="row m-0">
            <div className="col-md-12 text-center mt-lg-4 mt-0">
              <button
                type="submit"
                className="theme-btn text-uppercase mr-md-3 mr-1 mb-md-0 mb-3"
              >
                Save
              </button>
            </div>
          </div>
        </div>
      </form>
      <ReactTooltip />
    </>
  );
};

export default AdviserPrefrencesCustomer;
