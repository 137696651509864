import React, { useEffect, useState } from "react";
import ResendEmail from "../../components/common/modals/ResendEmail";
import {
  getAdvisorDashboardData,
  readRecentNotifications,
  setRecentMessagesOfAllChatToRead,
  setRecentMessagesOfChatToRead,
  startChat,
  seenMessages,
} from "../../components/common/service";
import RecentMessages from "../../components/dashboardPage/RecentMessages";
import RecentNotifications from "../../components/dashboardPage/RecentNotifications";
import useLoggedInUserData from "../../utility/useLoggedInUserData";
import ReactTooltip from "react-tooltip";
import { Link } from "react-router-dom";
import {
  ADVISER_PAYMENT_INVOICES,
  ADVISER_DASHBOARD,
  ADVISER_LEADS_ACCEPTED,
  ADVISER_LEADS_MATCHES,
  ADVISER_MY_ACCOUNT,
  LOGGEDIN_USER,
} from "../../Constants";
var offer = 0;
var profile_percent = 0;
var margin = '';
interface AdvisorData {
  matched_card_one: {
    last_hour: number;
    today: number;
    yesterday: number;
    less_than_3_days: number;
  };
  matched_card_two: {
    early_bid: number | string;
    "50_off": number | string;
    fifty_off?: number | string;
    "70_off": number | string;
    seventy_off?: number | string;
    free: number | string;
  };
  matched_card_three: {
    remortgage: number;
    next_time_buyer: number;
    first_time_buyer: number;
    buy_to_let: number;
  };
  accepted_card_one: {
    live_leads: number;
    hired: number;
    completed: number;
    lost: number;
  };
  accepted_card_two: {
    live_leads: number;
    hired: number;
    completed: number;
    lost: number;
  };
  accepted_card_three: {
    live_leads: number;
    hired: number;
    completed: number;
    lost: number;
  };
  performance: {
    conversion_rate: string;
    cost_of_leads: string;
    estimated_revenue: string;
  };
  promotions: number;
  total_invoice:number;
  userDetails:{},
}
const initialState = {
  matched_card_one: {
    last_hour: 0,
    today: 0,
    yesterday: 0,
    less_than_3_days: 0,
  },
  matched_card_two: {
    early_bid: "0",
    "50_off": "0",
    fifty_off: 0,
    "70_off": "0",
    seventy_off: 0,
    free: "0",
  },
  matched_card_three: {
    remortgage: 0,
    next_time_buyer: 0,
    first_time_buyer: 0,
    buy_to_let: 0,
  },
  accepted_card_one: { live_leads: 0, hired: 0, completed: 0, lost: 0 },
  accepted_card_two: { live_leads: 0, hired: 0, completed: 0, lost: 0 },
  accepted_card_three: { live_leads: 0, hired: 0, completed: 0, lost: 0 },
  performance: {
    conversion_rate: "",
    cost_of_leads: "",
    estimated_revenue: "",
  },
  promotions: 0,
  total_invoice:0,
  userDetails:{},
};

const AdvisorDashboard = () => {
  const user = useLoggedInUserData();
  const [advisorData, setAdvisorData] = useState<AdvisorData>(initialState);
  const [messageCount, setMessageCount] = useState<number>(0);
  const [notificationCount, setNotificationCount] = useState<number>(0);
  const [serviceType, setServiceType] = useState<any>([]);
  const [messageData, setMessageData] = useState<any>({
    from_user_id: user.id,
    to_user_id: 0,
    channel: [],
    data: [],
    bid: {},
  });
  useEffect(() => {
    getAdvisorDashboardDataCall();
    if(user.email_status === 1 && user?.userDetails?.FCA_verified!=null){
      margin = '-340px';
    }else if(user.email_status == 1 && user?.userDetails?.FCA_verified==null){
      margin = '-200px';
    }else{
      margin = '-230px';
    }
      // user.email_status === 1 ? "-422px" :"", marginTop: user?.userDetails?.FCA_verified != null ? "-320px" : "" }}
  }, []);
  //advisorData

  const getAdvisorDashboardDataCall = async () => {
    const res = await getAdvisorDashboardData();
    if (res.status) {
      offer = res.data.userDetails.offer;
      profile_percent = res.data.userDetails.profile_percent;

      res.data.matched_card_two.fifty_off = res.data.matched_card_two["50_off"];
      res.data.matched_card_two.seventy_off =
        res.data.matched_card_two["70_off"];
        setServiceType(res.data.matched_card_three);
        setMessageCount(res.data.message_unread_count);
      setAdvisorData(res.data);
    }
  };

  const readRecentNotificationsCall = async () => {
    setNotificationCount(0);
    const res = await readRecentNotifications();
    if (res.status) {
    }
  };

  const setRecentMessagesOfAllChatToReadCall = async () => {
    setMessageCount(0);
    //Call api for set messsage seen
    const res = await setRecentMessagesOfAllChatToRead();
    if (res.status) {
      setMessageCount(0);
    }
  };

  const handleMessage = async (
    from_user_id: number,
    to_user_id: number,
    advicearea_id: number
  ) => {
    const res = await startChat({
      from_user_id: from_user_id,
      to_user_id: to_user_id,
      advicearea_id: advicearea_id,
    });
    if (res.status) {
      const channel = res.channel;
      setMessageData({
        from_user_id,
        to_user_id,
        channel,
        data: res.data, //This is for show receiver info in chat window
      });
      $("#message_data").modal("show");
      await seenMessages({
        channel_id: channel,
      });
    }
  };

  return (
    <>
      <main className="dashboard">
      
        <section
          className="page-title adviser-dashboard newheigh-advisor"
          style={{ height: user.email_status === 1 || user?.userDetails?.FCA_verified != null ? "440px" : "641px" }}
        >
          {user.email_status === 0 || user?.userDetails?.FCA_verified == null ? (
            <div className="container">
              <div className="recent-box mb-0 warning-header dashboard-advisor-nir">
                <div className="row align-items-center">
                  <div className="col-lg-8">
                    <div className="required align-items-center">
                      <i className="fas fa-exclamation-triangle mr-3 mb-2 mb-md-0 text-center"></i>
                      {user.email_status === 0?
                      <p style={{"display": "inline-block","color": "red"}}>Please activate your account so we can connect you with
                      Mortgage Advisers.</p>:null}

                      {user?.userDetails?.FCA_verified == null && (
                      <p style={{"color": "red"}}>We are verifying your FCA registration, typically completed in less than 24 hours. Once confirmed you will be up and running and able to accept mortgage leads</p>
                      )} 
                    </div>
                  </div>
                  {user.email_status === 0?
                  <div className="col-lg-4 mt-2 mt-lg-0 text-center text-md-right">
                    <a
                      href="javascript:void(0)"
                      data-toggle="modal"
                      data-target="#Resend-mail"
                      className="resend-link"
                    >
                      Resend activation mail
                    </a>
                  </div>:null}
                </div>
              </div>
            </div>
            ):null}
            
           
             
        </section>
              {/* {user?.userDetails?.FCA_verified == null && ( */}
        {/* <section
          className="page-title adviser-dashboard"
          style={{ height: user?.userDetails?.FCA_verified != null ? "520px" : "641px" }}
        >
          
            <div className="container">
              <div className="recent-box mb-0 warning-header">
                <div className="row align-items-center">
                  <div className="col-lg-8">
                    <div className="required align-items-center">
                      <i className="fas fa-exclamation-triangle mr-3 mb-2 mb-md-0 text-center"></i>
                      We are verifying your FCA registration, typically completed in less than 24 hours. Once confirmed you will be up and running and able to accept mortgage leads
                    </div>
                  </div>
                </div>
              </div>
            </div>
        </section> */}
          {/* )} */}

        <section className="recent-sec adviser-dashboard-sec pt-5 pb-5 nir-advisor-dashboard" 
        style={{ marginTop: margin }}
        >
          <img
            src="assets/images/expert-left.png"
            alt="help-1"
            className="img-fluid before-img"
          />
          <img
            src="assets/images/left-shape.png"
            alt="help-1"
            className="img-fluid after-img"
          />
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-xl-8 col-lg-7 mt-2 table-strucure">
                <div className="row text-uppercase1 advicer-leads-data">
                  <div className="col-xl-6 col-lg-12 col-md-6 mb-xl-0 mb-lg-3 mb-0">
                    <div className="datas">
                      <div className="row">
                        <div className="col-12">
                          <div className="dashboard-data pb-3 mb-1">
                            <div className="row">
                              <div className="col-md-4 data">
                                <h6 className="text-capitalize mt--20">
                                  Matched Leads
                                  <a
                                    href="#"
                                    onClick={(e) => e.preventDefault()}
                                    data-tip="Summary of the leads matched to you based on your preferences"
                                  >
                                    <img
                                      src="assets/images/information.png"
                                      alt="information"
                                      className="img-fluid information"
                                    />
                                  </a>
                                </h6>
                              </div>
                              <div className="col-md-8">
                                <div className="times">
                                  <ul className="pl-0 ml-0 text-center nav nav-pills">
                                    <li className="nav-item">
                                      <a
                                        className="nav-link active"
                                        data-toggle="pill"
                                        href="#time"
                                      >
                                        Time
                                      </a>
                                    </li>
                                    <li className="nav-item">
                                      <a
                                        className="nav-link"
                                        data-toggle="pill"
                                        href="#discount"
                                      >
                                        Discount
                                      </a>
                                    </li>
                                    <li className="nav-item">
                                      <a
                                        className="nav-link"
                                        data-toggle="pill"
                                        href="#type"
                                      >
                                        Type
                                      </a>
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="tab-content mt-4">
                        <div className="tab-pane container active" id="time">
                          <div className="row main-data mt-4 p-0">
                            <div className="col-12 p-0">
                              <div className="dashboard-data">
                                <div className="row">
                                  <div className="col-8 data">
                                    <a href={`${ADVISER_LEADS_MATCHES}?time=last_hour`} className="text-capitalize">Last Hour</a>
                                  </div>
                                  <div className="col-4 data text-right">
                                    <span>
                                      {advisorData.matched_card_one.last_hour}
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-12 p-0">
                              <div className="dashboard-data">
                                <div className="row">
                                  <div className="col-8 data">
                                    <a href={`${ADVISER_LEADS_MATCHES}?time=today`} className="text-capitalize">Today</a>
                                  </div>
                                  <div className="col-4 data text-right">
                                    <span>
                                      {advisorData.matched_card_one.today}
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-12 p-0">
                              <div className="dashboard-data">
                                <div className="row">
                                  <div className="col-8 data">
                                    <a href={`${ADVISER_LEADS_MATCHES}?time=yesterday`} className="text-capitalize">Yesterday</a>
                                  </div>
                                  <div className="col-4 data text-right">
                                    <span>
                                      {advisorData.matched_card_one.yesterday}
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-12 p-0">
                              <div className="dashboard-data">
                                <div className="row">
                                  <div className="col-8 data">
                                    <a href={`${ADVISER_LEADS_MATCHES}?time=less_than_3_days`} className="text-capitalize">
                                      Less Than 3 Days
                                    </a>
                                  </div>
                                  <div className="col-4 data text-right">
                                    <span>
                                      {
                                        advisorData.matched_card_one
                                          .less_than_3_days
                                      }
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="tab-pane container" id="discount">
                          <div className="row main-data mt-4 p-0">
                            {/* <div className="col-12 p-0">
                              <div className="dashboard-data">
                                <div className="row">
                                  <div className="col-8 data">
                                    <a className="text-capitalize">
                                      Early bird
                                    </a>
                                  </div>
                                  <div className="col-4 data text-right">
                                    <span>
                                      {advisorData.matched_card_two.early_bid}
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </div> */}
                            <div className="col-12 p-0">
                              <div className="dashboard-data">
                                <div className="row">
                                  <div className="col-8 data">
                                    <a href={`${ADVISER_LEADS_MATCHES}?discount=50%_off`} className="text-capitalize">50% off</a>
                                  </div>
                                  <div className="col-4 data text-right">
                                    <span>
                                      {advisorData.matched_card_two.fifty_off}
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-12 p-0">
                              <div className="dashboard-data">
                                <div className="row">
                                  <div className="col-8 data">
                                    <a href={`${ADVISER_LEADS_MATCHES}?discount=75%_off`} className="text-capitalize">75% off</a>
                                  </div>
                                  <div className="col-4 data text-right">
                                    <span>
                                      {advisorData.matched_card_two.seventy_off}
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-12 p-0">
                              <div className="dashboard-data">
                                <div className="row">
                                  <div className="col-8 data">
                                    <a href={`${ADVISER_LEADS_MATCHES}?discount=free`} className="text-capitalize">Free</a>
                                  </div>
                                  <div className="col-4 data text-right">
                                    <span>
                                      {advisorData.matched_card_two.free}
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="tab-pane container" id="type">
                          <div className="row main-data mt-4 p-0">
                          {serviceType.length?
                      ( serviceType.map(
                (row: any, index: any) =>
                            <div className="col-12 p-0">
                              <div className="dashboard-data">
                                <div className="row">
                                  <div className="col-8 data">
                                    <a href={`${ADVISER_LEADS_MATCHES}?service_id=${row.id}`} className="text-capitalize">
                                      {row.name}
                                    </a>
                                  </div>
                                  <div className="col-4 data text-right">
                                    <span>
                                      {row.service_data_count}
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </div>
                            )):
                              <div className="col-12 p-0">
                                  <p>Services not found</p>
                                </div>
                            }
                            {/* <div className="col-12 p-0">
                              <div className="dashboard-data">
                                <div className="row">
                                  <div className="col-8 data">
                                    <a className="text-capitalize">
                                      Next time buyer
                                    </a>
                                  </div>
                                  <div className="col-4 data text-right">
                                    <span>
                                      {
                                        advisorData.matched_card_three
                                          .next_time_buyer
                                      }
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-12 p-0">
                              <div className="dashboard-data">
                                <div className="row">
                                  <div className="col-8 data">
                                    <a className="text-capitalize">
                                      First time buyer
                                    </a>
                                  </div>
                                  <div className="col-4 data text-right">
                                    <span>
                                      {
                                        advisorData.matched_card_three
                                          .first_time_buyer
                                      }
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-12 p-0">
                              <div className="dashboard-data">
                                <div className="row">
                                  <div className="col-8 data">
                                    <a className="text-capitalize">
                                      Buy to let
                                    </a>
                                  </div>
                                  <div className="col-4 data text-right">
                                    <span>
                                      {
                                        advisorDataed_card_three
                                          .buy_to_let
                                      }
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </div> */}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-xl-6 col-lg-12 col-md-6 mb-xl-0 mb-lg-3 mb-0">
                    <div className="datas">
                      <div className="row">
                        <div className="col-12">
                          <div className="dashboard-data pb-3 mb-1">
                            <div className="row">
                              <div className="col-md-4 data">
                                <h6 className="text-capitalize mt--20">
                                  Purchased Leads
                                  {/* Accepted Leads */}
                                  <a
                                    href="#"
                                    onClick={(e) => e.preventDefault()}
                                    data-tip="Summary of the leads you have purchased"
                                  >
                                    <img
                                      src="assets/images/information.png"
                                      alt="information"
                                      className="img-fluid information"
                                    />
                                  </a>
                                </h6>
                              </div>
                              <div className="col-md-8">
                                <div className="times monthly_datas">
                                  <ul className="pl-0 ml-0 text-center nav nav-pills">
                                    <li className="nav-item">
                                      <a
                                        className="nav-link month_data active"
                                        data-toggle="pill"
                                        href="#Month"
                                      >
                                        Month
                                      </a>
                                    </li>
                                    <li className="nav-item">
                                      <a
                                        className="nav-link quarter_data"
                                        data-toggle="pill"
                                        href="#Quarter"
                                      >
                                        Quarter
                                      </a>
                                    </li>
                                    <li className="nav-item">
                                      <a
                                        className="nav-link year_data"
                                        data-toggle="pill"
                                        href="#Year"
                                      >
                                        Year
                                      </a>
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="tab-content mt-4">
                        <div className="tab-pane container active" id="Month">
                          <div className="row main-data mt-4 p-0">
                            <div className="col-12 p-0">
                              <div className="dashboard-data">
                                <div className="row">
                                  <div className="col-8 data">
                                    <a href={`${ADVISER_LEADS_ACCEPTED}?lead=live_leads&time=this_month`} className="text-capitalize">
                                      Prospect
                                    </a>
                                  </div>
                                  <div className="col-4 data text-right">
                                    <span>
                                      {advisorData.accepted_card_one.live_leads}
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-12 p-0">
                              <div className="dashboard-data">
                                <div className="row">
                                  <div className="col-8 data">
                                    <a href={`${ADVISER_LEADS_ACCEPTED}?lead=hired&time=this_month`} className="text-capitalize">Hired</a>
                                  </div>
                                  <div className="col-4 data text-right">
                                    <span>
                                      {advisorData.accepted_card_one.hired}
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-12 p-0">
                              <div className="dashboard-data">
                                <div className="row">
                                  <div className="col-8 data">
                                    <a href={`${ADVISER_LEADS_ACCEPTED}?lead=completed&time=this_month`} className="text-capitalize">Completed</a>
                                  </div>
                                  <div className="col-4 data text-right">
                                    <span>
                                      {advisorData.accepted_card_one.completed}
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-12 p-0">
                              <div className="dashboard-data">
                                <div className="row">
                                  <div className="col-8 data">
                                    <a href={`${ADVISER_LEADS_ACCEPTED}?lead=lost&time=this_month`} className="text-capitalize">Lost</a>
                                  </div>
                                  <div className="col-4 data text-right">
                                    <span>
                                      {advisorData.accepted_card_one.lost}
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="tab-pane container" id="Quarter">
                          <div className="row main-data mt-4 p-0">
                            <div className="col-12 p-0">
                              <div className="dashboard-data">
                                <div className="row">
                                  <div className="col-8 data">
                                    <a href={`${ADVISER_LEADS_ACCEPTED}?lead=live_leads&time=quarter`} className="text-capitalize">
                                    Prospect
                                    </a>
                                  </div>
                                  <div className="col-4 data text-right">
                                    <span>
                                      {advisorData.accepted_card_two.live_leads}
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-12 p-0">
                              <div className="dashboard-data">
                                <div className="row">
                                  <div className="col-8 data">
                                    <a href={`${ADVISER_LEADS_ACCEPTED}?lead=hired&time=quarter`} className="text-capitalize">Hired</a>
                                  </div>
                                  <div className="col-4 data text-right">
                                    <span>
                                      {advisorData.accepted_card_two.hired}
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-12 p-0">
                              <div className="dashboard-data">
                                <div className="row">
                                  <div className="col-8 data">
                                    <a href={`${ADVISER_LEADS_ACCEPTED}?lead=completed&time=quarter`} className="text-capitalize">Completed</a>
                                  </div>
                                  <div className="col-4 data text-right">
                                    <span>
                                      {advisorData.accepted_card_two.completed}
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-12 p-0">
                              <div className="dashboard-data">
                                <div className="row">
                                  <div className="col-8 data">
                                    <a href={`${ADVISER_LEADS_ACCEPTED}?lead=lost&time=quarter`} className="text-capitalize">Lost</a>
                                  </div>
                                  <div className="col-4 data text-right">
                                    <span>
                                      {advisorData.accepted_card_two.lost}
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="tab-pane container" id="Year">
                          <div className="row main-data mt-4 p-0">
                            <div className="col-12 p-0">
                              <div className="dashboard-data">
                                <div className="row">
                                  <div className="col-8 data">
                                    <a href={`${ADVISER_LEADS_ACCEPTED}?lead=live_leads&time=year`} className="text-capitalize">
                                    Prospect
                                    </a>
                                  </div>
                                  <div className="col-4 data text-right">
                                    <span>
                                      {
                                        advisorData.accepted_card_three
                                          .live_leads
                                      }
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-12 p-0">
                              <div className="dashboard-data">
                                <div className="row">
                                  <div className="col-8 data">
                                    <a href={`${ADVISER_LEADS_ACCEPTED}?lead=hired&time=year`} className="text-capitalize">Hired</a>
                                  </div>
                                  <div className="col-4 data text-right">
                                    <span>
                                      {advisorData.accepted_card_three.hired}
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-12 p-0">
                              <div className="dashboard-data">
                                <div className="row">
                                  <div className="col-8 data">
                                    <a href={`${ADVISER_LEADS_ACCEPTED}?lead=completed&time=year`} className="text-capitalize">Completed</a>
                                  </div>
                                  <div className="col-4 data text-right">
                                    <span>
                                      {
                                        advisorData.accepted_card_three
                                          .completed
                                      }
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-12 p-0">
                              <div className="dashboard-data">
                                <div className="row">
                                  <div className="col-8 data">
                                    <a href={`${ADVISER_LEADS_ACCEPTED}?lead=lost&time=year`} className="text-capitalize">Lost</a>
                                  </div>
                                  <div className="col-4 data text-right">
                                    <span>
                                      {
                                        advisorData.accepted_card_three
                                          .lost
                                      }
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-12">
                    <div className="datas message-notification-data mt-4">
                      <div className="row justify-content-center">
                        <div className="col-md-12 mb-4">
                          <ul className="pl-0 ml-0 text-center sec-title nav nav-pills">
                            <li className="nav-item">
                              <a
                                className="nav-link message-link active p-0"
                                data-toggle="pill"
                                href="#message"
                                onClick={() =>
                                  messageCount > 0
                                    ? setRecentMessagesOfAllChatToReadCall()
                                    : null
                                }
                              >
                                <h5 className="m-0 theme-color d-inline-block">
                                  MESSAGES
                                  {messageCount ? (
                                    <sup>{messageCount}</sup>
                                  ) : null}
                                </h5>
                              </a>
                            </li>
                            <li className="nav-item">
                              <a
                                className="nav-link p-0"
                                data-toggle="pill"
                                href="#notification"
                                style={{ display: 'inline-block' }}
                                onClick={() =>
                                  notificationCount > 0
                                    ? readRecentNotificationsCall()
                                    : null
                                }
                              >
                                <h5 className="m-0 theme-color d-inline-block">
                                  NOTIFICATIONS                                
                                </h5>
                              </a>
                              {notificationCount ? (
                                <sup className="supClass">{notificationCount}</sup>
                              ) : null}
                            </li>
                          </ul>
                        </div>
                        <div className="tab-content" style={{ width: "100%" }}>
                          <div
                            className="tab-pane p-0 container active"
                            id="message"
                          >
                            <div className="col-md-12">
                              <RecentMessages
                                setMessageCount={setMessageCount}
                                advisorDashboard={true}
                              />

                              {/* <div className="col-12 text-center">
                                <a
                                  href="javascript:void(0)"
                                  className="view-all text-center text-capitalize"
                                >
                                  View All
                                </a>
                              </div> */}
                            </div>
                          </div>
                          <div
                            className="tab-pane p-0 container"
                            id="notification"
                          >
                            <div className="col-md-12">
                              <RecentNotifications
                                setNotificationCount={setNotificationCount}
                              />
                              {/* <div className="col-12 text-center">
                                <a
                                  href="javascript:void(0)"
                                  className="view-all text-center text-capitalize"
                                >
                                  View All
                                </a>
                              </div> */}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* <div className="col-12">
                    <div className="datas message-notification-data mt-4">
                      <div className="row justify-content-center">
                        <div className="col-md-12 mb-4">
                          <ul className="pl-0 ml-0 text-center sec-title nav nav-pills">
                            <li className="nav-item">
                                <h5 className="m-0 theme-color d-inline-block heading-leads">
                                  REFER A FRIEND
                                </h5>
                            </li>
                          </ul>
                        </div>
                        <div className="tab-content" style={{ width: "100%" }}>
                          <div
                            className="tab-pane p-0 container active"
                            id="message"
                          >
                            <div className="col-md-12">
                            <p>
                              If you had a great experience using mortgagebox then let
                              your friends know. We don't offer any rewards but do promise
                              your friends the same great service.
                            </p>
                            <div className="text-right mt-4">
                              <Link
                                to={`/invite-now`}
                                className="theme-btn text-uppercase"
                              >
                                invite now
                              </Link>
                            </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div> */}
                </div>
              </div>
              <div className="col-xl-4 col-lg-5 mt-2 table-strucure dashboard-right-content">
                <div className="right-content">
                  <div className="row">
                    <div className="col-12">
                      <div className="profile1 datas mb-4 dashboard-right-data">
                        <h3>{user.name}</h3>
                        <p>Mortgage Adviser</p>
                        <span className="border-bottom"></span>
                        <h5>Your profile is {profile_percent}% complete</h5>
                        <div className="progress">
                          <div className="progress-bar" style={{width: user.userDetails.profile_percent+"%"}}></div>
                        </div>
                        {user.userDetails.image==null?
                        <div className="profile-picture">
                          <a href={`${ADVISER_MY_ACCOUNT}/general-info`}><div className="row">
                            <div className="col-8">
                              <h6>Add a profile picture</h6>
                            </div>
                            <div className="col-4 text-right">
                              <span>+20%</span>
                            </div>
                          </div></a>
                        </div>:null}
                        {user.userDetails.short_description==null && user.userDetails.company_about==null?
                        <div className="profile-picture">
                          <a href={`${ADVISER_MY_ACCOUNT}/about`}><div className="row">
                            <div className="col-8">
                              <h6>Add about</h6>
                            </div>
                            <div className="col-4 text-right">
                              <span>+15%</span>
                            </div>
                          </div></a>
                        </div>:null}
                        {offer==0?
                        <div className="profile-picture">
                          <a href={`${ADVISER_MY_ACCOUNT}/offers`}><div className="row">
                            <div className="col-8">
                              <h6>Add offers</h6>
                            </div>
                            <div className="col-4 text-right">
                              <span>+30%</span>
                            </div>
                          </div></a>
                        </div>:null}
                        {user.userDetails.web_address==null?
                        <div className="profile-picture">
                          <a href={`${ADVISER_MY_ACCOUNT}/web-links`}><div className="row">
                            <div className="col-8">
                              <h6>Add a web links</h6>
                            </div>
                            <div className="col-4 text-right">
                              <span>+20%</span>
                            </div>
                          </div></a>
                        </div>:null}
                        <span className="border-bottom"></span>
                        <div className="current-data">
                          <div className="row align-items-center">
                            <div className="col-8">
                              <h6>CURRENT BALANCE</h6>
                            </div>
                            <div className="col-4 text-right">
                              <a href={ADVISER_PAYMENT_INVOICES}>See invoice</a>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-8">
                              <p>Amount due </p>
                              {/* 31-Aug-20 */}
                            </div>
                            <div className="col-4 text-right">
                              <span>£{advisorData.total_invoice}</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12">
                      <div className="tab-content month_quater_year_data">
                        <div
                          className="tab-pane container active p-0"
                          id="Month"
                        >
                          <div className="profile1 datas profile-datas mb-4">
                            <div className="row align-items-center">
                              <div className="col-8">
                                <h3>Performance</h3>
                              </div>
                              <div className="col-4 text-right">
                                <p>
                                  <span>in month</span>
                                </p>
                              </div>
                            </div>
                            <span className="border-bottom"></span>
                            <div className="current-data mb-0">
                              <div className="row align-items-center">
                                <div className="col-8">
                                  <h6>Conversion rate</h6>
                                </div>
                                <div className="col-4 text-right">
                                  <span>
                                    {advisorData.performance.conversion_rate}%
                                  </span>
                                </div>
                              </div>
                            </div>
                            <span className="border-bottom pt-2 mb-2"></span>
                            <div className="current-data mt-0 mb-0">
                              <div className="row align-items-center">
                                <div className="col-8">
                                  <h6>Cost of leads</h6>
                                </div>
                                <div className="col-4 text-right">
                                  <span>
                                    £{advisorData.performance.cost_of_leads}
                                  </span>
                                </div>
                              </div>
                            </div>
                            <span className="border-bottom pt-2 mb-2"></span>
                            <div className="current-data mt-0">
                              <div className="row align-items-center">
                                <div className="col-8">
                                  <h6>
                                    Estimated revenue
                                    <a
                                      href="#"
                                      onClick={(e) => e.preventDefault()}
                                      data-tip="Estimated revenue you will earn based on the leads won"
                                    >
                                      <img
                                        src="assets/images/information_gray.png"
                                        alt="information"
                                        className="img-fluid information"
                                      />
                                    </a>
                                  </h6>
                                </div>
                                <div className="col-4 text-right">
                                  <span>
                                    £{advisorData.performance.estimated_revenue}
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                      <div className="col-12">
                          <div className="profile1 datas profile-datas free-intro mb-4">
                              <div className="row align-items-center">
                                  <div className="col-12">
                                      <h3>
                                          Promotions
                                          <a href="#"
                                      onClick={(e) => e.preventDefault()}
                                      data-tip="Number of free introductions where you won’t pay a penny">                                                                                    
                                              <img src="../assets/images/information.png" alt="information" className="img-fluid information"/>
                                          </a>
                                      </h3>
                                  </div>
                              </div>
                              <span className="border-bottom"></span>                                            
                              <div className="current-data mb-0">
                                  <div className="row align-items-center">
                                      <div className="col-7">
                                          <h6>
                                              Free introductions
                                          </h6>
                                      </div>
                                      <div className="col-5 text-right">
                                          <span>
                                          {advisorData.promotions}
                                          </span>
                                      </div>
                                  </div>
                              </div>   
                          </div>
                      </div>
                  </div>
                  <div className="row">
                      <div className="col-12">
                          <div className="profile1 datas profile-datas mb-4">
                              <div className="row align-items-center">
                                  <div className="col-12">
                                      <h3>
                                          Refer a friend
                                      </h3>
                                  </div>
                              </div>
                              <span className="border-bottom"></span>                                            
                              <div className="current-data mb-0">
                                  <div className="row align-items-center">
                                      <div className="col-12">
                                          <p>
                                              If you enjoy using Mortgagebox, share it with friends and colleagues and we will give you five free introductions
                                          </p>
                                      </div>
                                      <div className="col-12 text-right">
                                          <Link
                                            to={`/invite-now`}
                                            className="read-btn mt-3 d-block"
                                          >
                                            Invite now
                                          </Link>
                                          {/* <a href="#" className="read-btn mt-3 d-block">
                                              Invite Now
                                          </a> */}
                                      </div>
                                  </div>
                              </div>   
                          </div>
                      </div>
                  </div>`
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
      <ResendEmail />
      <ReactTooltip />
    </>
  );
};

export default AdvisorDashboard;
