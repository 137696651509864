import React from "react";

export default (value: string, timeout: number) => {
  const [state, setState] = React.useState(value);
  React.useEffect(() => {
    const handler = setTimeout(() => setState(value), timeout);
    return () => clearTimeout(handler);
  }, [value, timeout]);

  return state;
};
