import React, { useEffect } from 'react';
import { ToastProvider } from "react-toast-notifications";
import PubNub from "pubnub";
import { PubNubProvider } from "pubnub-react";
import Layout from "./hoc/Layout/Layout";
import { initGoogleAnalytics } from './GoogleAnalytics';

const pubnub = new PubNub({
  publishKey: process.env.REACT_APP_PUBLISH_KEY || "",
  subscribeKey: process.env.REACT_APP_SUBSCRIBE_KEY || "",
  uuid: process.env.REACT_APP_UUID || "",
});

const App = () => {
  useEffect(() => {
    // Initialize Google Analytics
    initGoogleAnalytics();
  }, []);

  return (
    <ToastProvider autoDismiss autoDismissTimeout={6000}>
      <PubNubProvider client={pubnub}>
        <Layout />
      </PubNubProvider>
    </ToastProvider>
  );
};

export default App;
