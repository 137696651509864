import React, { useEffect, useState } from "react";
import { ADVISOR_DEFAULT_IMAGE } from "../../Constants";
import { prettyTime, prettyTimeAgo } from "../../utility/commonUtils";
import useLoggedInUserData from "../../utility/useLoggedInUserData";
import LoadingSpinner from "../common/LoadingSpinner";
import SendMessageModal from "../common/modals/SendMessageModal";
import { getRecentMessages, startChat } from "../common/service";

const RecentMessages = (props: any) => {
  const user = useLoggedInUserData();

  const [messages, setMessages] = useState<object[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [messageData, setMessageData] = useState<any>({
    from_user_id: user.id,
    to_user_id: 0,
    channel: [],
    data: [],
    bid: {},
  });

  useEffect(() => {
    getRecentMessagesCall();
  }, []);

  const getRecentMessagesCall = async () => {
    setIsLoading(true);
    const res = await getRecentMessages();
    if (res?.success || res?.status) {
      setMessages(res.data);
      props.setMessageCount(res.count);
    }
    setIsLoading(false);
  };

  const handleMessage = async (
    from_user_id: number,
    to_user_id: number,
    channel_id: number,
    display_name?: string,
    company_name?: string
  ) => {
    const res = await startChat({
      from_user_id: from_user_id,
      to_user_id: to_user_id,
      channel_id:channel_id,
      // advicearea_id: 0,
    });
    if (res.status) {
      setMessageData({
        from_user_id,
        to_user_id,
        channel: res.channel,
        data: res.data,
        bid: { name: display_name, company_name: company_name }, //This is for show receiver info in chat window
      });
      $("#message_data").modal("show");
    }
  };

  return (
    <>
      {messages.length ? (
        <>
          {props.advisorDashboard ? (
            messages.map((message: any, index) => {
              return (
                <>
                  <div
                    key={message.id}
                    className="msg-box unread"
                    onClick={() =>
                      handleMessage(
                        message.from_user_id,
                        message.to_user_id,
                        message.channel_id,
                        message.to_user.name,
                        ''
                      )
                    }
                  >
                    <div className="msg-content order-3 order-md-2">
                      <h6>
                        {message?.to_user?.name}
                        <sub>requires {message?.area_data.size_want_currency}{message?.area_data.size_want} {message?.area_data?.service?.name}</sub>
                      </h6>
                      <span></span>
                      <p>
                        {message.lastMessage}
                        {/* <a href="javascript:void(0)" className="read-btn">
                          Read more
                        </a> */}
                      </p>
                    </div>
                    <div className="msg-time order-2 order-md-3 mb-2 mb-md-0">
                      <span className="text-uppercase">
                        {prettyTime(message?.created_at, false, true)}
                      </span>
                    </div>
                  </div>
                </>
              );
            })
          ) : (
            <section className="pt-5">
              <div className="container">
                <div className="recent-box">
                  <div className="row justify-content-center">
                    <div className="col-md-11 mb-4">
                      <div className="sec-title">
                        <h5 className="m-0 theme-color">MESSAGES</h5>
                      </div>
                    </div>
                    <div className="col-md-11">
                      {messages.map((message: any, index) => {
                        return (
                          <>
                            <div
                              className="msg-box pb-5 mb-5"
                              key={`message-${message.id}-${index}`}
                            >
                              <div className="msg-img mr-4 order-1 mb-2 mb-md-0">
                                <img src={ADVISOR_DEFAULT_IMAGE} alt="" />
                              </div>
                              <div className="msg-content order-3 order-md-2">
                                <h6>{message?.name}</h6>
                                <span></span>
                                <p>
                                  {message.text}
                                  {/* <a href="javascript:void(0)" className="read-btn">
                                Read more
                              </a> */}
                                </p>
                                <div className="text-left mt-4">
                                  <a
                                    href="javascript:void(0)"
                                    className="msg-btn text-uppercase"
                                    onClick={() =>
                                      handleMessage(
                                        user.id,
                                        message.from_user_id,
                                        message.display_name,
                                        message.company_name
                                      )
                                    }
                                  >
                                    MESSAGE
                                  </a>
                                </div>
                              </div>
                              <div className="msg-time order-2 order-md-3 mb-2 mb-md-0">
                                <span className="text-uppercase">
                                  {prettyTime(message?.created_at, false, true)}{" "}
                                </span>
                              </div>
                            </div>
                          </>
                        );
                      })}
                    </div>
                  </div>
                </div>
              </div>
            </section>
          )}
          <SendMessageModal {...messageData} />
        </>
      ) : null}
    </>
  );
};
export default RecentMessages;
