import React from "react";

const OfferInaNutshellModal = () => {
  return (
    <>
      <div
        className="mail-popup modal fade popup-model nutshell_popup"
        id="nutshell_example"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-body">
              <div className="row">
                <div className="col-md-12">
                  <div className="heading text-center mb-4">
                    <h4 className="text-uppercase theme-color">
                      Here's our example of 'Offers!'
                    </h4>
                    <p className="nutshellp"><strong>Home visits</strong></p>
                    <p className="nutshellp">Depending on location and obviously COVID19 we can offer home visits where I will take you through your options on a one to one basis</p>
                    <p className="nutshellp"><strong>Cashback</strong></p>
                    <p className="nutshellp">Talk to us as we may be able to offer you a cashback :-)</p>
                    <p className="nutshellp"><strong>No Adviser Fees</strong></p>
                    <p className="nutshellp">We do not charge adviser fees for the majority of mortgages we arrange. In rare instance this is not the case we will let you know at the beginning of the process</p>
                    <p className="nutshellp"><strong>Evening call/appointments</strong></p>
                    <p className="nutshellp">Time short? We can work to times that suit you, let us know and we will arrange a suitable time around your schedule</p>
                    <p className="nutshellp"><strong>Free mortgage review</strong></p>
                    <p className="nutshellp">Not quite sure if you should remortgage / can afford a property? Speak to us and we will happily review your options for free</p>
                  </div>
                </div>
                <div className="col-md-4 offset-md-4 mb-md-0 mb-3 text-center">
                  <button
                    type="button"
                    className="theme-btn"
                    data-dismiss="modal"
                  >
                    OK
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default OfferInaNutshellModal;
