import React from "react";

const BidConfirmModal = (props: any) => {
  return (
    <>
      <div className="mail-popup modal fade popup-model" id="accept_lead">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-body">
              <div className="row text-center">
                <div className="col-md-12">
                  <div className="heading text-center mb-4">
                    <h4 className="text-uppercase theme-color">
                      CONFIRM YOUR LEAD PURCHASE
                    </h4>
                    <p>
                      {props.amount}
                      {/* This lead will cost{" "}
                      {props.amount != "This lead is free"
                        ? `This lead will cost ${props.amount}`
                        : `This lead will cost ${props.amount}`} */}
                    </p>
                  </div>
                </div>
                <div className="col-6 col-md-4 offset-md-2 mb-md-0 mb-3 text-center">
                  <button
                    type="button"
                    className="btn-border-blue w-100"
                    data-dismiss="modal"
                  >
                    CANCEL
                  </button>
                </div>
                <div className="col-6 col-md-4 text-center">
                <button
                    type="button"
                    className="theme-btn text-uppercase w-100"
                    onClick={() => props.handleBidAction(1)}
                  >
                    Confirm
                  </button>
                </div>
				
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default BidConfirmModal;
