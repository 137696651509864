import React, { useEffect, useState } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import ChangePassword from "../../components/common/modals/ChangePassword";
import useLoggedInUserData from "../../utility/useLoggedInUserData";
import moment from "moment";
import {
  ADVISER_NOTIFICATIONS,
  ADVISER_OFFERS,
  ADVISER_PAYMENT_BILLING_DETAILS,
  ADVISER_PAYMENT_INVOICES,
  ADVISER_PAYMENT_DETAILS,
  ADVISER_REVIEWS,
  ADVISER_TEAM_MEMBERS,
  ADVISER_MY_ACCOUNT,
  ADVISER_PREFERENCES_URL,
  ADVISER_PAYMENT_PAY_INVOICE,
  LOGGEDIN_USER,
  ADVISER_WEB_LINKS,
  ADVISER_ABOUT,
  CUSTOMER_ABOUT,
} from "../../Constants";
import { getAdviserUserProfile, getInvoiceData } from "../../components/common/service";
var checkInvoice = 0;

const AdviserMyAccount = () => {
    const history = useHistory();
    const user = useLoggedInUserData();
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [invoiceData, setInvoiceData] = useState<any>({});
    const [monthdata, setMonthData] = useState<any>({});
    const [monthYear, setMonthYear] = useState<any>({});
    const [invoiceDate, setInvoiceDate] = useState<any>(
        moment().format("MM/YYYY")
    );
    

    const getUserProfile = async () => {
      const res = await getAdviserUserProfile();
      if (res?.user) {
        checkInvoice = res.user.is_invoice_remaining;
        localStorage.setItem(LOGGEDIN_USER, JSON.stringify(res.user));
      }   
    };

    const getInvoiceCall = async () => {
      setIsLoading(true);
      const res = await getInvoiceData({'date':invoiceDate});
      if (res.status) {
        setInvoiceData(res.data.invoice_unpaid);
        setMonthData(res.data.month_data);
        setMonthYear(res.data.show_month_year);
      }
      setIsLoading(false);
    };

    useEffect(() => {
      getUserProfile();
      getInvoiceCall();
    }, []);

    const handlePayInvoice = () => {
      // ADVISER_PAYMENT_PAY_INVOICE
      var month = moment().format("MMMM");
      var year = moment().format("YYYY");

    // const month_array = invoiceDate.split('/');
    // if(month_array[1]!=undefined){
    //     year = month_array[1];
    // }
    // if(month_array[0]!=undefined){
      
    //   if(month_array[0]=='1'){
    //     month = 'January';
    //   }else if(month_array[0]=='2'){
    //     month = 'Feburary';
    //   }else if(month_array[0]=='3'){
    //     month = 'March';
    //   }else if(month_array[0]=='4'){
    //     month = 'April';
    //   }else if(month_array[0]=='5'){
    //     month = 'May';
    //   }else if(month_array[0]=='6'){
    //     month = 'June';
    //   }else if(month_array[0]=='7'){
    //     month = 'July';
    //   }else if(month_array[0]=='8'){
    //     month = 'August';
    //   }else if(month_array[0]=='9'){
    //     month = 'September';
    //   }else if(month_array[0]=='10'){
    //     month = 'October';
    //   }else if(month_array[0]=='11'){
    //     month = 'November';
    //   }else if(month_array[0]=='12'){
    //     month = 'December';
    //   }
    // }
      if (invoiceData) {
        history.push({
          pathname: ADVISER_PAYMENT_PAY_INVOICE,
          state: {
            invoice: {
              invoice_id: invoiceData.id,
              invoice_number: invoiceData.invoice_number,
              invoice_date: invoiceDate,
              invoice_amount: invoiceData.total_due,
              invoice_date_arr:monthdata,
              invoice_show_month : monthYear
            },
          },
        });
      }
    };
  return (
    <>
      <main>
        <section className="page-title my-account-title">
          <div className="container">
            <div className="row">
              <div className="col-md-12 mb-5 mb-md-0"></div>
            </div>
          </div>
        </section>

        <section className="recent-sec advice-area-sec register-final-sec pb-5 myaccount-sec">
          <div className="container">
            <div className="row">
              <div className="col-12 mb-4 mt-4">
                <h5 className="text-white text-uppercase">My Account</h5>
              </div>
            </div>
            {user.is_invoice_remaining==1?<div className="saved-card-sec">
              <a href="javascript:;" onClick={handlePayInvoice}>
                <div className="alert alert-warning alert-white rounded warning-data mb-3">
                  <div className="icon">
                    <i className="fa fa-warning"></i>
                  </div>
                  <strong>Warning!</strong> Your account is at risk of being 
                  suspended – pay outstanding invoice
                </div>
              </a>
            </div>:null}
            <div className="recent-box pb-0 mb-4">
              <div className="row justify-content-center">
                <div className="col-xl-10 col-lg-12 mb-4">
                  <div className="my-accoutns-data">
                    <h2>
                      <img
                        src="assets/images/my_accounts/icon3.png"
                        className="img-fluid"
                        alt=""
                      />
                      Profile
                    </h2>
                    <div className="data pt-2">
                      <h5>
                        <Link to={`${ADVISER_MY_ACCOUNT}/general-info`}>
                          ACCOUNT DETAILS
                        </Link>
                      </h5>
                      <p>
                        Adviser account details and optionally company details.
                      </p>
                    </div>
                    <div className="data pt-2">
                      <h5>
                        <Link to={`${ADVISER_MY_ACCOUNT}/about`}>
                          ABOUT
                        </Link>
                      </h5>
                      <p>
                        Your profile is key to attracting customers on
                        Mortgagebox. Use it to explain what makes you different
                        from your competitors and why people should select you.
                      </p>
                    </div>
                    <div className="data pt-2">
                      <h5>
                        <Link to={ADVISER_OFFERS}>OFFERS</Link>
                      </h5>
                      <p>
                        Detail your offers that will help close out the deal,
                        these might include weekends calls, cashback, quick
                        completion.
                      </p>
                    </div>
                    <div className="data pt-2">
                      <h5>
                        <Link to={ADVISER_REVIEWS}>REVIEWS</Link>
                      </h5>
                      <p>
                        Reviews are a great way of highlighting successes. You
                        can also invite past customers to leave reviews to
                        inform potential customers of the great job you do.
                      </p>
                    </div>
                    <div className="data pt-2">
                      <h5>
                        <Link to={ADVISER_WEB_LINKS}>WEB LINKS</Link>
                      </h5>
                      <p>
                        Let your customers easily access your online profiles.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* <div className="recent-box pb-0 mb-4">
              <div className="row justify-content-center">
                <div className="col-xl-10 col-lg-12 mb-4">
                  <div className="my-accoutns-data">
                    <h2>
                      <img
                        src="assets/images/my_accounts/icon3.png"
                        className="img-fluid"
                        alt=""
                      />
                      Profile
                    </h2>
                    <div className="data pt-2">
                      <h5>
                        <Link to={`${ADVISER_MY_ACCOUNT}/general-info`}>
                          MY PROFILE
                        </Link>
                      </h5>
                      <p>
                        Your profile is key to attracting customers on
                        Mortgagebox. Use it to explain what makes you different
                        from your competitors and why people should select you.
                      </p>
                    </div>
                    <div className="data pt-2">
                      <h5>
                        <Link to={ADVISER_OFFERS}>OFFERS</Link>
                      </h5>
                      <p>
                        Detail your offers that will help close out the deal,
                        these might include weekends calls, cashback, quick
                        completion.
                      </p>
                    </div>
                    <div className="data pt-2">
                      <h5>
                        <Link to={ADVISER_REVIEWS}>REVIEWS</Link>
                      </h5>
                      <p>
                        Reviews are a great way of highlighting successes. You
                        can also invite past customers to leave reviews to
                        inform potential customers of the great job you do.
                      </p>
                    </div>
                    <div className="data pt-2">
                      <h5>
                        <a
                          href="javascript:void(0)"
                          className="font-weight-bold"
                          data-toggle="modal"
                          data-target="#Change_Password"
                          data-backdrop="static"
                          data-keyboard="false"
                        >
                          ACCOUNT DETAILS
                        </a>
                      </h5>
                      <p>Your email address and password.</p>
                    </div>
                  </div>
                </div>
              </div>
            </div> */}
            {user.userDetails.company_id!=0?<div className="recent-box pb-0 mb-4">
              <div className="row justify-content-center">
                <div className="col-xl-10 col-lg-12 mb-4">
                  <div className="my-accoutns-data">
                    <h2>
                      <img
                        src="assets/images/my_accounts/icon2.png"
                        className="img-fluid"
                        alt=""
                      />
                      Company
                    </h2>
                    <div className="data pt-2">
                      <h5>
                        <Link to={ADVISER_TEAM_MEMBERS}>TEAM MEMBERS</Link>
                      </h5>
                      <p>
                        Add additional team members for your company. Each team
                        member can fully customise their lead preferences and is
                        treated as a separate billable entity.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>:""}
            <div className="recent-box pb-0 mb-4">
              <div className="row justify-content-center">
                <div className="col-xl-10 col-lg-12 mb-4">
                  <div className="my-accoutns-data">
                    <h2>
                      <img
                        src="assets/images/my_accounts/icon7.png"
                        className="img-fluid"
                        alt=""
                      />
                      Preferences
                    </h2>
                    <div className="data pt-2">
                      <h5>
                        <Link to={`${ADVISER_PREFERENCES_URL}/products`}>
                          PRODUCT
                        </Link>
                      </h5>
                      <p>
                        Define your areas of expertise and customer preferences
                        so we can send you tailored leads.
                      </p>
                    </div>
                    <div className="data pt-2">
                      <h5>
                        <Link to={`${ADVISER_PREFERENCES_URL}/location`}>
                          LOCATION
                        </Link>
                      </h5>
                      <p>
                        Tells us the area you cover so we can show you leads for
                        your location.
                      </p>
                    </div>
                    <div className="data pt-2">
                      <h5>
                        <Link to={`${ADVISER_PREFERENCES_URL}/firstmessage`}>
                          FIRST MESSAGE
                        </Link>
                      </h5>
                      <p>
                        Time saving template where you define a generic welcome
                        message for new leads. This message is optional and can
                        be customised prior to sending.
                      </p>
                    </div>
                    <div className="data pt-2">
                      <h5>
                        <Link to={`${ADVISER_PREFERENCES_URL}/customer`}>
                          CUSTOMERS
                        </Link>
                      </h5>
                      <p>
                        Focus on the most relevant leads by filtering out those
                        of less interest. Simply define your areas of expertise
                        and preferences and we will do the rest. You can change
                        these settings at any time.
                      </p>
                    </div>
                    <div className="data pt-2">
                      <h5>
                        <Link to={`${ADVISER_PREFERENCES_URL}/default`}>
                          DEFAULTS
                        </Link>
                      </h5>
                      <p>
                        Customise your defaults, such as estimated lead value
                        calculation.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="recent-box pb-0 mb-4">
              <div className="row justify-content-center">
                <div className="col-xl-10 col-lg-12 mb-4">
                  <div className="my-accoutns-data">
                    <h2>
                      <img
                        src="assets/images/my_accounts/icon1.png"
                        className="img-fluid"
                        alt=""
                      />
                      Notifications
                    </h2>
                    <div className="data pt-2">
                      <h5>
                        <Link to={ADVISER_NOTIFICATIONS}>ALERTS</Link>
                      </h5>
                      <p>
                        Configure the types of activity notifications you would
                        like to receive.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="recent-box pb-0 mb-4">
              <div className="row justify-content-center">
                <div className="col-xl-10 col-lg-12 mb-4">
                  <div className="my-accoutns-data">
                    <h2>
                      <img
                        src="assets/images/my_accounts/icon8.png"
                        className="img-fluid"
                        alt=""
                      />
                      Credits & Payments
                    </h2>
                    <div className="data pt-2">
                      <h5>
                        <Link to={ADVISER_PAYMENT_INVOICES}>INVOICES</Link>
                      </h5>
                      <p>View your current and historic invoices.</p>
                    </div>
                    <div className="data pt-2">
                      <h5>
                        <Link to={ADVISER_PAYMENT_BILLING_DETAILS}>
                          BILLING DETAILS
                        </Link>
                      </h5>
                      <p>Manage your account details.</p>
                    </div>
                    {/* <div className="data pt-2">
                      <h5>
                        <Link to={ADVISER_PAYMENT_DETAILS}>
                          PAYMENT DETAILS
                        </Link>
                      </h5>
                      <p>Set up your debit card and/or credit card.</p>
                    </div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <img
            src="assets/images/business_right.png"
            alt="help-1"
            className="img-fluid before-img"
          />
          <img
            src="assets/images/business_left.png"
            alt="help-1"
            className="img-fluid after-img"
          />
        </section>
      </main>
      <ChangePassword />
    </>
  );
};

export default AdviserMyAccount;
