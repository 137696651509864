import React, { useEffect, useState } from "react";
import { useToasts } from "react-toast-notifications";
import FirstMessageExampleModal from "../common/modals/FirstMessageExampleModal";
import { getAdvisorFirstMessage, updateFirstMessage } from "../common/service";

const initialState = {
  first_message: "",
};

const AdviserPrefrencesFirstmessage = (props: any) => {
  const { addToast } = useToasts();
  const [formState, setFormState] = useState(initialState);

  useEffect(() => {
    setFormState({ first_message: props.message });
  }, [props.message]);

  useEffect(() => {
    getAdvisorFirstMessageCall();
  }, []);

  const getAdvisorFirstMessageCall = async () => {
    const res = await getAdvisorFirstMessage();
    if (res.data) {
      setFormState(res.data);
    }
  };

  const onSubmitHandler = async (e: any) => {
    e.preventDefault();
    const res = await updateFirstMessage(formState);
    if (res.status) {
      addToast(res.message, { appearance: "success" });
    } else {
      addToast(res.message, { appearance: "error" });
    }
  };

  return (
    <>
      <div className="profile-datas">
        <h5 className="mb-3">First message</h5>
        <p>
          A time-saving template where you can store a welcome message for new
          leads. This message is optional and can be customised before sending.
        </p>
      </div>

      <form onSubmit={onSubmitHandler}>
        <div className="team-memebers-data preference-customer preference-location preference-message">
          <div className="profile-datas settings-data">
            <div className="row mt-4">
              <div className="col-lg-12">
                <div className="form-group nutshell_form mb-0">
                  <textarea
                    id="Nutshell"
                    name="first_message"
                    value={formState.first_message}
                    onChange={(e) =>
                      setFormState({
                        first_message: e.target.value,
                      })
                    }
                  ></textarea>
                  <img src="assets/images/icon10.png" alt="" />
                </div>
              </div>
            </div>
            <div className="row mt-1">
              <div className="col-lg-12 text-center">
                <a
                  href="javascript:void(0)"
                  data-toggle="modal"
                  data-target="#firstmessage_example"
                  className="example_btn"
                >
                  Here's our example of 'First message'
                </a>
              </div>
            </div>
            <div className="row mt-3 mb-3 text-center">
              <div className="col-md-12 text-center mt-lg-4 mt-0">
                <button
                  type="submit"
                  className="theme-btn text-uppercase mr-md-3 mr-1 mb-md-0 mb-3"
                >
                  Save
                </button>
                <button
                  type="button"
                  className="theme-btn-border text-uppercase"
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      </form>
      <FirstMessageExampleModal />
    </>
  );
};

export default AdviserPrefrencesFirstmessage;
