import { useContext } from "react";
import { LOGGEDIN_USER } from "../Constants";
import { AuthContext } from "../shared/context/auth-context";

const useLoggedInUserData = () => {
  let { user } = useContext(AuthContext);
  const loggedInUserInfo = localStorage.getItem(LOGGEDIN_USER);
  user = user?.id
    ? user
    : loggedInUserInfo === null
    ? {}
    : JSON.parse(loggedInUserInfo);
  return user;
};

export default useLoggedInUserData;
