import React from "react";

const OnRejectedExpiredBids = () => {
  return (
    <>
      <div className="col-lg-12">
        <div className="recent-box">
          <div className="row justify-content-center">
            <div className="col-12">
              <div className="row align-items-center version2-data">
                <div className="col-xl-7 col-lg-6">
                  <div className="data heading">
                    <h2>What's happening?</h2>
                    <span></span>
                    <p>
                    Unfortunately, we have not been able to find you a suitable Mortgage Adviser at this point in time.<br></br> <br></br> 
                    This usually happens when a mortgage need has been posted that is light on detail or is in some way incorrect.<br></br> <br></br> 
                    We therefore recommend reposting your mortgage need with additional details and/or clarity.
                    </p>
                  </div>
                </div>
                <div className="col-xl-5 col-lg-6 text-center">
                  <img
                    src="assets/images/v2_img.png"
                    alt="v2_img"
                    className="img-fluid"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default OnRejectedExpiredBids;
