export const ADVISER_BASE_URL = "/adviser";

export const ADVISER_DASHBOARD = ADVISER_BASE_URL + "/dashboard";
export const ADVISER_LEADS_FILTER = ADVISER_BASE_URL + "/leads-filter";
export const ADVISER_LEADS_ACCEPTED_FILTER = ADVISER_BASE_URL + "/leads-accepted-filter";

export const ADVISER_LEADS_MATCHES = ADVISER_BASE_URL + "/leads-matches";
export const ADVISER_LEADS_ACCEPTED = ADVISER_BASE_URL + "/leads-accepted";
export const ADVISER_SETTINGS = ADVISER_BASE_URL + "/settings";

export const ADVISER_MY_ACCOUNT = ADVISER_BASE_URL + "/my-account";
// export const ADVISER_MY_ACCOUNT_OPTION = ADVISER_BASE_URL + "/my-account-option";


export const ADVISER_MY_PROFILE = ADVISER_MY_ACCOUNT + "/:tab";
export const CUSTOMER_ABOUT = ADVISER_MY_ACCOUNT + "/about";
export const CUSTOMER_PRIVACY = ADVISER_MY_ACCOUNT + "/privacy-policy";
export const CUSTOMER_PRESS = ADVISER_MY_ACCOUNT + "/press";
export const CUSTOMER_SERVICES = ADVISER_MY_ACCOUNT + "/services";
export const CUSTOMER_TERMS_CONDITIONS = ADVISER_MY_ACCOUNT + "/terms-conditions";
export const ADVISER_ABOUT = ADVISER_BASE_URL + "/about";
export const ADVISER_PRIVACY = ADVISER_BASE_URL + "/privacy-policy";
export const ADVISER_PRESS = ADVISER_BASE_URL + "/press";
export const ADVISER_SERVICES = ADVISER_BASE_URL + "/services";
export const ADVISER_TERMS_CONDITIONS = ADVISER_BASE_URL + "/terms-conditions";
export const ADVISER_CONTACT_US = ADVISER_MY_ACCOUNT + "/contact-us";

export const ADVISER_OFFERS = ADVISER_MY_ACCOUNT + "/offers";
export const ADVISER_REVIEWS = ADVISER_MY_ACCOUNT + "/reviews";
export const ADVISER_WEB_LINKS = ADVISER_MY_ACCOUNT + "/web-links";

export const ADVISER_TEAM_MEMBERS = ADVISER_BASE_URL + "/team-member";
export const ADVISER_NOTIFICATIONS = ADVISER_BASE_URL + "/notifications";
export const ADVISER_PREFERENCES_URL = ADVISER_BASE_URL + "/preferences";
export const ADVISER_PREFERENCES = ADVISER_PREFERENCES_URL + "/:tab";

export const ADVISER_PAYMENT_INVOICES = ADVISER_BASE_URL + "/credit-invoice";
export const ADVISER_PAYMENT_PAY_INVOICE = ADVISER_BASE_URL + "/pay_invoice";
export const ADVISER_PAYMENT_BILLING_DETAILS =
  ADVISER_BASE_URL + "/credit-billing-details";
export const ADVISER_PAYMENT_DETAILS = ADVISER_BASE_URL + "/payment-details";
