import React, { useEffect, useState } from "react";
import moment from "moment";
import { Link } from "react-router-dom";
import useLoggedInUserData from "../../utility/useLoggedInUserData";
import InlineAlert from "../common/InlineAlert";
import { capitalize, numberWithCommas } from "../../utility/commonUtils";
import { ADVICEAREA_STATUS_ARR, DEFAULT_CURRENCY } from "../../Constants";
import LeaveReviewModal from "../common/modals/advisorModals/LeaveReviewModal";
import { getAdvisorProfile, addReview, getAdviceArea } from "../common/service";
var isLoaded = false;
const initialState = {
  status: 0,
  rating: 0,
  review_title: "",
  reviews: "",
  advisor_id: 0,
  user_id: 0,
  area_id: 0,
  isError: false,
  responseMessage: "",
};
const AdviceAreaRow = (props: { advice: any }) => {
  const user = useLoggedInUserData();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [profileData, setProfileData] = useState<any>({});
  const [offersData, setOffersData] = useState<any>([]);
  const [reviewRatingData, setReviewRatingData] = useState<any>([]);
  const [isAPICalled, setisAPICalled] = useState<boolean>(false);
  const [adviceArea, setAdviceArea] = useState<object[]>([]);
  const [formState, setFormState] = useState({
    ...initialState,
    user_id: user.id,
    area_id: props.advice.id
  });

  const handlePageState = (newStates: any) => {
    setFormState((prevStates) => {
      return {
        ...prevStates,
        ...newStates,
      };
    });
  };

  useEffect(() => {
    if(!isLoaded){
      isLoaded = true;
      // getAdvisorProfileCall(props.advice.advisor_id);
      // setisAPICalled(true);
    }
  }, []);

  const getAdvisorProfileCall = async (advisor_id: number) => {
    setIsLoading(true);
    const res = await getAdvisorProfile(advisor_id);
    if (res?.status) {
      
      setProfileData(res.data);
      setOffersData(res.offers);
      setReviewRatingData({
        avarageRating: res.avarageRating,
        avarageRatingTotal: res.total,
        data: res.review_rating,
      });
    }
    setIsLoading(false);
  };
  const showPopup = async (advice: any) => {
    $("#advisor_id").val(advice.advisor_id);
    $("#area_id").val(advice.id);
    setTimeout(() => {
      handlePageState({advisor_id: advice.advisor_id });
      $("#leaveReviewModal").modal("show");
    }, 1000);
  };

  const cancelReview = async (e: any) => {
    e.preventDefault();
    $("#leaveReviewModal").modal("hide");
    $("body").removeClass("modal-open");
    $(".modal-backdrop").remove();
    handlePageState({ ...initialState });
  };

  const getAdviceAreaCall = async () => {
    const res = await getAdviceArea();
    if (res?.success || res.status) {
      setAdviceArea(res.data);
      
      // isLoaded = true;
    }
    setIsLoading(false);
  };

  const onSubmitHandler = async (e: any) => {
    e.preventDefault();
    const formData = new FormData();
    const postedData = {
      advisor_id: $("#advisor_id").val(),
      user_id: user.id,
      rating: formState.rating,
      review_title: formState.review_title,
      reviews: formState.reviews,
      status: formState.status,
      area_id: $("#area_id").val()
    };
    formData.append("user_id", formState.user_id);
    setIsLoading(true);
    const res = await addReview(postedData);
    if (res.status) {
      handlePageState({ responseMessage: res.message, isError: false });
      setIsLoading(false);
      getAdviceAreaCall();
      props.advice.reviewed=1;
      setTimeout(() => {
        $("#leaveReviewModal").modal("hide");
        $("body").removeClass("modal-open");
        $(".modal-backdrop").remove();
        handlePageState({ ...initialState });
        
      }, 1000);
    } else {
      handlePageState({ responseMessage: res.message, isError: true });
    }
  };
  return (
    <>
      {props.advice && (
        <div className="row main-data">
          <div className="col-md-3 col-12 data order-md-1 order-3 sup-container-advicearea">
            <Link to={`advice-area/${props.advice.id}/offers`}>
              Mortage Adviser{" "}
              {props.advice.unread_message_count ? (
                <sup>{props.advice.unread_message_count}</sup>
              ) : null}
            </Link>
          </div>
          <div className="col-md-2 col-12 data second-data- order-md-4 order-4">
            <span>{moment(props.advice.created_at).format("ll")}</span>
          </div>
          <div className="col-md-3 col-6 data order-md-3 order-1">
            <span>
              {props.advice.service?(capitalize(props.advice.service.name))+", ":""}
              {props.advice.size_want_currency || DEFAULT_CURRENCY}
              {numberWithCommas(props.advice.size_want)}
            </span>
          </div>
          <div className="col-lg-2 col-md-1 col-12 data order-md-4 order-5">
            <span className="bind">
              <b>Bids</b>
            </span>
            <span>{props.advice?.offer_count}</span>
          </div>
          <div className="col-md-2 col-6 order-md-5 order-2 data">
            {/* <span>{ADVICEAREA_STATUS_ARR[props.advice.status]}</span> */}
            <span>{props.advice.show_status}</span>
            {props.advice.area_status==3?props.advice.reviewed==0?
            <button
                className="theme-btn-border text-uppercase review-btn-design"
                onClick={() => showPopup( props.advice )}
              >
                Give review
            </button>:null:null}
          </div>
        </div>
      )}

      <div className="mail-popup modal fade popup-model" id="leaveReviewModal">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-body">
              <div className="row">
                <div className="col-md-12">
                  <div className="heading text-center mb-4">
                    <h4 className="text-uppercase theme-color">
                      LEAVE A REVIEW 
                    </h4>
                    <span></span>
                  </div>
                  <div className="mb-4 mt-lg-5 mt-2">
                    <form onSubmit={onSubmitHandler}>
                      {formState.responseMessage && (
                        <InlineAlert
                          isError={formState.isError}
                          message={formState.responseMessage}
                        />
                      )}
                      <p className="m-2 mb-lg-4 mb-3">
                        Rate your experience
                        <span
                          className={
                            Math.round(Number(formState.rating)) > 0
                              ? `form-star mt-4 mb-4 text-center ml-3 review-star-rating-${Math.round(
                                  Number(formState.rating)
                                )}`
                              : "form-star mt-4 mb-4 text-center ml-3 review-star-rating-0"
                          }
                        >
                          <span
                            aria-hidden="true"
                            className={`fa fa-star ${
                              formState.rating >= 1 && "checked"
                            }`}
                            onClick={() => handlePageState({ rating: 1 })}
                          ></span>
                          <span
                            aria-hidden="true"
                            className={`fa fa-star ${
                              formState.rating >= 2 && "checked"
                            }`}
                            onClick={() => handlePageState({ rating: 2 })}
                          ></span>
                          <span
                            aria-hidden="true"
                            className={`fa fa-star ${
                              formState.rating >= 3 && "checked"
                            }`}
                            onClick={() => handlePageState({ rating: 3 })}
                          ></span>
                          <span
                            aria-hidden="true"
                            className={`fa fa-star ${
                              formState.rating >= 4 && "checked"
                            }`}
                            onClick={() => handlePageState({ rating: 4 })}
                          ></span>
                          <span
                            aria-hidden="true"
                            className={`fa fa-star ${
                              formState.rating >= 5 && "checked"
                            }`}
                            onClick={() => handlePageState({ rating: 5 })}
                          ></span>
                        </span>
                      </p>
                      <p className="m-2">Headline of the review</p>
                      <input type="hidden" name="advisor_id" id="advisor_id"  onChange={(e) =>
                            handlePageState({
                              advisor_id: e.target.value,
                            })
                          }/>
                          <input type="hidden" name="area_id" id="area_id"  onChange={(e) =>
                            handlePageState({
                              area_id: e.target.value,
                            })
                          }/>
                      <div className="form-group d-flex align-items-center">
                        <input
                          type="text"
                          className="mr-3 mb-0"
                          name="review_title"
                          value={formState.review_title}
                          onChange={(e) =>
                            handlePageState({
                              review_title: e.target.value,
                            })
                          }
                          placeholder="Whats the most important to know?"
                        />
                      </div>
                      <p className="m-2">Tell us about your experience</p>
                      <div className="form-group d-flex align-items-center mb-0">
                        <textarea
                          name="reviews"
                          value={formState.reviews}
                          onChange={(e) =>
                            handlePageState({
                              reviews: e.target.value,
                            })
                          }
                          placeholder="Share your honest experience, and help other people make better choices"
                        ></textarea>
                      </div>
                      <div className="row align-items-center">
                        <div className="col-md-6">
                          <button
                            type="button"
                            className="normal-btn text-uppercase"
                            onClick={cancelReview}
                          >
                            cancel
                          </button>
                        </div>
                        <div className="col-md-6 text-right">
                        {!isLoading?<button
                            type="submit"
                            className="theme-btn text-uppercase"
                          >
                            POST REVIEW
                          </button>:<button
                            type="button"
                            className="theme-btn text-uppercase"
                          >
                            Please Wait...
                          </button>}
                          {/* <button
                            type="submit"
                            className="theme-btn text-uppercase"
                          >
                            POST REVIEW
                          </button> */}
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default AdviceAreaRow;
