import React, { useState ,useEffect} from "react";
import { MORTGAGE_ADVICE_ARR } from "../../Constants";
import { getServiceType } from "../common/service";

const StepOne = (props: any) => {
  const [serviceType, setService] = useState<any>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [additionaloptions, setAdditionaloptions] = useState(false);
  const onRadioChanged = (event: { target: HTMLInputElement } | any) => {
    props.setFormData("service_type_id", event.target.value);
  };

  useEffect(() => {
    ContentPagesCall();
  }, []);
  const ContentPagesCall = async () => {
    setIsLoading(true);
    const res = await getServiceType();
    if (res?.success || res.status) {
      if(res.data.length!=0){
        props.setFormData("service_type_id", res.data[0].id);
      }
      setService(res.data);
    }
    setIsLoading(false);
  };

  return (
    <>
      <section className="recent-sec advice-area-sec advice-area-hs pt-2">
        <img
          src="assets/images/expert-right.png"
          alt="help-1"
          className="img-fluid after-img"
        />
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="panel panel-default">
                <div className="panel-body">
                  <div className="stepper">
                    <div className="active-title d-md-none text-center">
                      <p className="text-white mb-3 font-weight-bold">
                        How can we help?
                      </p>
                    </div>
                    <ul className="nav nav-tabs mb-3 mb-md-0" role="tablist">
                      <li role="presentation" className="active">
                        <a
                          className="persistant-disabled d-none d-md-flex"
                          href="javascript:void(0)"
                          data-toggle="tab"
                          aria-controls="stepper-step-1"
                          role="tab"
                          title="Step 1"
                        >
                          <span className="round-tab mr-3">1</span>
                          <p>How can we help?</p>
                        </a>
                      </li>
                      <li role="presentation">
                        <a
                          href="javascript:void(0)"
                          className="persistant-disabled d-none d-md-flex"
                          data-toggle="tab"
                          aria-controls="stepper-step-2"
                          role="tab"
                          title="Step 2"
                        >
                          <span className="round-tab mr-3">2</span>
                          <p>Your needs</p>
                        </a>
                      </li>
                      <li role="presentation">
                        <a
                          href="javascript:void(0)"
                          className="persistant-disabled d-none d-md-flex"
                          data-toggle="tab"
                          aria-controls="stepper-step-3"
                          role="tab"
                          title="Step 3"
                        >
                          <span className="round-tab  mr-3">3</span>
                          <p>About you</p>
                        </a>
                      </li>
                    </ul>
                    <form>
                      <div className="tab-content tab-data-content text-center">
                        <div
                          className="tab-pane fade in active show"
                          role="tabpanel"
                          id="stepper-step-1"
                        >
                          <h2>Let’s get started</h2>
                          <p className="sub-desc">
                          First tell us about the mortgage you are after. Don’t worry if you can’t answer all the questions at this point, your selected adviser will be there to guide you.
                          </p>
                          <div className="sub-tabbing">
                            <div className="row">
                              <div className="col-lg-12">
                                <div className="row need_data_layout">
                                  {serviceType.slice(0, 4).map(
                                    (adv_area: any) => {
                                      return (
                                        <div
                                          key={adv_area.id}
                                          className="col-md-3 col-6 col-xs-6"
                                        >
                                          <label className="radio-img text-uppercase">
                                            <input
                                              type="radio"
                                              name="need_data"
                                              value={adv_area.id}
                                              onChange={onRadioChanged}
                                              defaultChecked={
                                                serviceType[0].id ===
                                                adv_area.id
                                              }
                                              required
                                            />
                                            <span>{adv_area.name}</span>

                                            {/* checked={
                                                props.formState.service_type_id ===
                                                adv_area.id
                                              } */}
                                          </label>
                                        </div>
                                      );
                                    }
                                  )}
                                </div>
                                <div className="col-md-12 sub-data text-right">
                                  <p
                                    onClick={() =>
                                      setAdditionaloptions(
                                        (prevAdditionaloptions) =>
                                          !prevAdditionaloptions
                                      )
                                    }
                                  >
                                    {additionaloptions ? "Hide" : "Show"}{" "}
                                    additional options
                                  </p>
                                </div>

                                {additionaloptions && (
                                  <div
                                    className="row need_data_layout collapse1 text-left datas text-center"
                                    id="show_data1"
                                  >
                                    {serviceType.slice(4).map(
                                      (adv_area: any) => {
                                        return (
                                          <div
                                            key={adv_area.id}
                                            className="col-md-3 col-6 col-xs-6"
                                          >
                                            <label className="radio-img text-uppercase">
                                              <input
                                                type="radio"
                                                name="need_data"
                                                value={adv_area.id}
                                                onChange={onRadioChanged}
                                                
                                              />
                                              {/* checked={
                                                  props.formState
                                                    .service_type_id ===
                                                  adv_area.id
                                                } */}
                                              <span>{adv_area.name}</span>
                                            </label>
                                          </div>
                                        );
                                      }
                                    )}
                                  </div>
                                )}
                              </div>
                            </div>
                            <div className="col-lg-12 text-right">
                              <button
                                type="button"
                                className="btn-bg-blue text-uppercase"
                                onClick={() => props.nextStep(2)}
                              >
                                Next Step
                                <i
                                  className="fa fa-chevron-right"
                                  aria-hidden="true"
                                ></i>
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <img
          src="assets/images/expert-left.png"
          alt="help-1"
          className="img-fluid before-img"
        />
      </section>
    </>
  );
};

export default StepOne;
