import React, { useContext, useState, useEffect } from "react";
import { advisorAddOffer, advisorEditOffer } from "../service";
import $ from "jquery";
import "bootstrap";
import InlineAlert from "../InlineAlert";
import { AuthContext } from "../../../shared/context/auth-context";
import { LOGGEDIN_USER } from "../../../Constants";
import useLoggedInUserData from "../../../utility/useLoggedInUserData";

const initialState = {
  offer_id: "",
  offer: "",
  description: "",
  isError: false,
  responseMessage: "",
};

const AddOfferModal = (props: any) => {
  const user = useLoggedInUserData();

  const [formState, setFormState] = useState(initialState);

  useEffect(() => {
    handlePageState({
      offer_id: props.editItem.offer_id,
      offer: props.editItem.offer,
      description: props.editItem.description,
      isError: false,
      responseMessage: "",
    });
  }, [props.editItem]);

  const handlePageState = (newStates: any) => {
    setFormState((prevStates) => {
      return {
        ...prevStates,
        ...newStates,
      };
    });
  };

  const onSubmitHandler = async (e: any) => {
    e.preventDefault();
    if (formState.offer === "" || formState.description === "") {
      handlePageState({
        responseMessage: "All fields are required.",
        isError: true,
      });
      return;
    }
    let res;
    if (props.editItem.offer_id) {
      res = await advisorEditOffer(props.editItem.offer_id, {
        offer: formState.offer,
        description: formState.description,
      });
    } else {
      res = await advisorAddOffer({
        offer: formState.offer,
        description: formState.description,
      });
    }
    if (res.status) {
      if (props.editItem.offer_id) {
        props.updateItem({
          offer_id: formState.offer_id,
          offer: formState.offer,
          description: formState.description,
        });
      }
      handlePageState({ responseMessage: res.message, isError: false });
      setTimeout(() => {
        $("#add_offer").modal("hide");
        $("body").removeClass("modal-open");
        $(".modal-backdrop").remove();
        handlePageState({ ...initialState });
        props.handleResponse(true);
      }, 1000);
    } else {
      handlePageState({ responseMessage: res.message, isError: true });
    }
  };

  return (
    <>
      <div
        className="mail-popup modal fade popup-model add_offeres"
        id="add_offer"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-body">
              <div className="row">
                <div className="col-md-12">
                  <div className="heading text-center mb-4">
                    <h4>{props.editItem.offer_id ? "Edit" : "Add"} an Offer</h4>
                    <p>
                      Describe your great offer with a snappy headline along
                      with details.
                    </p>
                  </div>
                </div>
              </div>
              <form onSubmit={onSubmitHandler}>
                {formState.responseMessage && (
                  <InlineAlert
                    isError={formState.isError}
                    message={formState.responseMessage}
                  />
                )}
                <div className="row">
                  <div className="col-12">
                    <label>Headline</label>
                    <input
                      type="text"
                      id="headline"
                      name="offer"
                      value={formState.offer}
                      onChange={(e) =>
                        handlePageState({
                          offer: e.target.value,
                        })
                      }
                      placeholder="Headline"
                      onFocus={(e) => (e.target.placeholder = "")}
                      onBlur={(e) => (e.target.placeholder = "Headline")}
                      required
                    />
                  </div>
                  <div className="col-12">
                    <div className="description">
                      <label>Description</label>
                      <textarea
                        name="description"
                        id="description"
                        value={formState.description}
                        onChange={(e) =>
                          handlePageState({
                            description: e.target.value,
                          })
                        }
                        required
                      ></textarea>
                      <img src="assets/images/my_accounts/icon10.png" alt="" />
                    </div>
                  </div>
                  <div className="col-md-12 text-center mt-lg-2 mt-0 settings-data">
                    <button
                      type="submit"
                      className="theme-btn text-uppercase mr-md-3 mr-1 mb-md-0 mb-3"
                    >
                      Save
                    </button>
                    <button
                      type="button"
                      className="theme-btn-border text-uppercase"
                      data-dismiss="modal"
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default AddOfferModal;
