import React from "react";
import { ClipLoader } from "react-spinners";
import { SPINNER_COLOR } from "../../Constants";

const LoadingSpinner = (props: { loading: boolean; size?: number }) => {
  return (
    <>
      {props.loading && (
        <div style={{ textAlign: "center", marginTop: "10%" }}>
          <ClipLoader
            color={SPINNER_COLOR}
            loading={props.loading}
            size={props.size || 60}
          />
        </div>
      )}
    </>
  );
};
export default LoadingSpinner;
